import React from 'react'

import { StatelessMultiSelect } from 'common/MultiSelect.js'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation.js'

import QualificationsForm from './QualificationsForm.js'

const MAKE_DISTRIBUTION_QUALIFICATIONS = `mutation ($distributionId: UUID!, $qualificationIds: [UUID]!) {
  makeDistributionQualifications(input: {distributionId: $distributionId, vendorQualificationIds: $qualificationIds}) {
    distributionQualifications {
      id
      executeOrder
      stepByStepId {
        id
        status
      }
      distributionByDistributionId {
        id
      }
      vendorQualificationByVendorQualificationId {
        id
      }
    }
  }
}`

const formConfig = {
    fields: {
        qualification: {
            type: 'multiSelect',
            default: ({ qualifications }) => {
                const data = {}
                qualifications.nodes.forEach(
                    ({ vendorQualificationByVendorQualificationId: { id } }) => {
                        data[id] = { id }
                    },
                )
                return data
            },
        },
    },
}

export default Form(
    ({
        accountId,
        qualifications,
        distributionId,
        popup,
        form: { qualification },
    }) => {
        const handleSubmit = (qual) => {
            const old = []
            let add = Object.keys(qual)
            qualifications.nodes.forEach(
                ({ vendorQualificationByVendorQualificationId: { id } }) => {
                    old.push(id)
                },
            )
            add = add.filter((q) => !old.includes(q))
            if (add.length > 0) {
                new Mutation({
                    mutation: MAKE_DISTRIBUTION_QUALIFICATIONS,
                    onSubmit: () => ({
                        distributionId,
                        qualificationIds: add,
                    }),
                    onResolve: popup.close,
                }).submit()
            } else {
                popup.close()
            }
        }

        return (
            <div className='qualifications-conatiner'>
                <StatelessMultiSelect {...qualification}>
                    <QualificationsForm
                        handleSubmit={handleSubmit}
                        accountId={accountId}
                        popup={popup}
                    />
                </StatelessMultiSelect>
            </div>
        )
    },
    formConfig,
)

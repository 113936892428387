import { fragment as annotationFragment } from 'annotation/AnnotationCard.js'
import Payment from 'payment/index.js'
import { fragment as vestingFragment } from 'vesting/VestingCard.js'
import { fragment as priceFragment } from 'price/PriceCard.js'
import { fragment as qualificationFragment } from 'qualification/QualificationCard.js'
import { fragment as allocationFragment } from 'allocations/EditAllocationForm.js'
import Distribution from 'components/distribution/index.js'
import Account from 'components/account/index.js'
import Format from 'util/format/Format.js'
import { Decimal } from 'decimal.js'

const EditDistribution = {
    query: ({ distributionId, allocationId, accountId }) => `{
      allocationById(id:"${allocationId}"){
        id
        blockchainAddressId
      }
      accountById(id:"${accountId}"){
        id
        contactName
        email
      }
      distributionById(id: "${distributionId}") {
        id
        status
        amount
        ${Payment.fragment.nest('distributionPaymentsByDistributionId')}
        ${annotationFragment.distribution}
        ${vestingFragment.distribution}
        ${priceFragment.distribution}
        ${qualificationFragment.distribution}
      }
    }`,
    args: (props) => {
        const allocationId = props.allocationId
        const { distributionId, accountId } = props.multiSelect.getValues()[0]
        return {
            allocationId,
            distributionId,
            accountId,
        }
    },
    format: ({ allocationById, accountById, distributionById }) => {
        if (!distributionById) {
            return { incomplete: true }
        }
        const {
            id,
            status,
            amount,
            distributionPaymentsByDistributionId,
            distributionAnnotationsByDistributionId,
            distributionPricesByDistributionId,
            vestingScheduleByDistributionId,
            distributionQualificationsByDistributionId,
        } = distributionById
        const nodes = [{ status, amount }]

        distributionQualificationsByDistributionId.nodes.sort((a, b) => a.executeOrder - b.executeOrder)

        return {
            distributionId: id,
            allocation: allocationById,
            account: accountById,
            payments: distributionPaymentsByDistributionId,
            annotations: distributionAnnotationsByDistributionId,
            prices: distributionPricesByDistributionId,
            vesting: vestingScheduleByDistributionId,
            qualifications: distributionQualificationsByDistributionId,
            status: { nodes },
        }
    },
}
export { EditDistribution }

const AllocationCapTable = {
    query: (allocationId) => `{
  allocationById(id: "${allocationId}") {
    id
    blockchainAddressByBlockchainAddressId {
      id
    }
    roundByRoundId{
        id
        securityBySecurityId{
            id
            unitValue
        }
    }
    distributionsByAllocationId{
      nodes{
        id
        amount
        status
        locked
        ${qualificationFragment.distribution}
        accountByInvestorId{
          id
          contactName
          email
        }
      }
    }
  }
}`,
    format: (data, props) => {
        let total = new Decimal(0)
        let entries = 0
        let capTables = []
        const allocation = data.allocationById
        const unitValue = allocation.roundByRoundId.securityBySecurityId.unitValue

        allocation.distributionsByAllocationId.nodes.forEach(({
            id: distributionId,
            amount,
            status,
            locked,
            accountByInvestorId: {
                id: accountId,
                contactName,
            },
            ...distribution
        }) => {
            entries++
            total = total.add(amount)
            const onClick = () => {
                props.multiSelect.select({
                    id: distributionId,
                    value: {
                        accountId,
                        contactName,
                        amount,
                        status,
                        distribution,
                        allocation: {
                            blockchainId:
                                allocation
                                    .blockchainAddressByBlockchainAddressId.id,
                        },
                        distributionId,
                    },
                })
            }
            const capTable = {
                id: distributionId,
                status,
                contactName,
                distributionId,
                accountId,
                amount,
                distribution,
                onClick,
                locked,
                isSelected: props.multiSelect.includes(distributionId),
                unitValue,
            }
            capTables.push(capTable)
        })

        capTables = capTables.map((item) => {
            // eslint-disable-next-line
            item.percentage = Format.percent(item.amount, total)
            return item
        })

        capTables.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })

        return {
            summary: {
                entries: {
                    label: 'DISTRIBUTIONS',
                    value: entries,
                },
                total: {
                    label: 'UNITS',
                    value: Format.number(total),
                },
            },
            orderByData: capTables,
        }
    },
}

export { AllocationCapTable }

const MakeDistributionData = `
        ${Distribution.fragment.toString()}
        allocationByAllocationId {
            id
        }
        ${qualificationFragment.distribution}
        accountByInvestorId {
            ${Account.fragment.toString()}
            customersByInvestorId {
                nodes {
                    id
                    status
                }
            }
        }`

export { MakeDistributionData }

const EditAllocation = {
    query: (id) => `{
     allocationById(id: "${id}") {
        status
        ${allocationFragment.data}
        ${qualificationFragment.allocation}
        ${annotationFragment.allocation}
        ${vestingFragment.allocation}
      }
  }`,
    args: (props) => props.allocationId,
    format: ({ allocationById }) => {
        const {
            allocationAnnotationsByAllocationId,
            allocationQualificationsByAllocationId,
            allocationsVestingScheduleByAllocationId,
            ...allocation
        } = allocationById
        allocationQualificationsByAllocationId.nodes.sort((a, b) => a.executeOrder - b.executeOrder)
        return {
            annotations: allocationAnnotationsByAllocationId,
            qualifications: allocationQualificationsByAllocationId,
            vesting: allocationsVestingScheduleByAllocationId,
            defaultVestingStart: allocation.closesOn,
            allocation,
        }
    },
}

export { EditAllocation }

const GetCurrencies = {
    query: `{
    allCurrencies{
      nodes{
        id
        code
      }
    }
}`,
    format: ({ allCurrencies }) => ({
        currencies: allCurrencies.nodes.map((node) => ({ currency: node.code, value: node.id })),
    }),
}

export { GetCurrencies }

import Fragment from 'delv/Fragment.js'

import CountryFragment from 'components/country/fragment.js'

export default new Fragment(`
id
lineOne
lineTwo
lineThree
lineFour
city
province
postalCode
countryByCountry{
    ${CountryFragment}
}`)

import React from 'react'
import SafeReact from 'util/SafeReact.js'
import objectPath from 'object-path'

const Map = ({
    key = 'id',
    children,
    noneFound = <div />,
    arrayKey = 'data',
    ...props
}) => {
    const render = objectPath.get(props, arrayKey)
    if (render && render.length) {
        return SafeReact.cloneChildren(render, ({ context: { data } }) => ({
            key: render[key],
            [arrayKey]: undefined,
            ...props,
            ...data,
        }), children)
    }
    return SafeReact.cloneChildren(noneFound, props)
}

export default Map

import React from 'react'
import Box from 'common/Box'
import Button from 'common/Button'
import Address from './Address.js'

import PartyForm from './Party.js'

const AppendParty = ({
    label, party, addressFields, mailingFields, resolve, USA, ...props
}) => (
    <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <PartyForm label={label} fields={party} id={props.index} isPrimary={false} {...props} />
            <Address
                id={props.index}
                field='parties'
                emitter={props.emitter}
                stateHandler={props.stateHandler}
                index={props.index}
                addressFields={addressFields}
                mailingFields={mailingFields}
                USA={USA}
            />
        </Box>
        <Button sx={{ my: 2 }} variant='outlined' color='error' onClick={resolve}>{`Remove ${label} ${props.index + 1}`}</Button>
    </Box>
)

export default AppendParty

import * as React from 'react'
import Link from 'common/Link'

import Typography from 'common/Typography'

const StepNone = () => (
    <Typography variant='h5'>
        {'No further action is required. Click '}
        <Link to='/portfolio'>here</Link>
        {' to view your full portfolio.'}
        {' If you have any questions, please contact the issuer by clicking the email icon next to the issuer\’s name on the portfolio page.'}
    </Typography>
)

export default StepNone

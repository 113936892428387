import React from 'react'

import Delv from 'delv/delv-react'
import SubNav from 'common/SubNav.js'

const Page = ({
    Base,
    views: unfiltedViews,
    defaultView,
}) => {
    const viewKeys = Object.keys(unfiltedViews)
    Object.values(unfiltedViews).forEach((view, index) => {
        const key = viewKeys[index]
        // eslint-disable-next-line
        unfiltedViews[key].route = key
    })
    return (props) => {
        const views = {}
        Object.keys(unfiltedViews).forEach((key) => {
            const view = unfiltedViews[key]
            if (!view.allowedRoles || (view.allowedRoles && view.allowedRoles.includes(props.account.type))) {
                views[key] = view
            }
        })
        const currentIndex = props.match.params.view
        const current = views[currentIndex] ? views[currentIndex] : views[defaultView]
        const CurrentComponent = current.component

        const {
            children,
            ...cleanProps
        } = props

        if (current.query) {
            return (
                <Base {...cleanProps} key={current.route}>
                    <SubNav.nav>
                        {
                            Object.values(views).map((view) => <SubNav.item view={view} />)
                        }
                    </SubNav.nav>
                    <Delv.query key={current.route} {...current.query}>
                        <CurrentComponent />
                    </Delv.query>
                </Base>
            )
        }

        return (
            <Base {...cleanProps} key={current.route}>
                <SubNav.nav>
                    {
                        Object.values(views).map((view) => <SubNav.item view={view} />)
                    }
                </SubNav.nav>
                <CurrentComponent />
            </Base>
        )
    }
}

export default Page

import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import { Input, Date as DateInput } from 'common/FormUI.js'

const UPDATE_CAPITAL_DEPLOYMENT = `
    mutation (
        $updateCapitalDeploymentByIdInput: UpdateCapitalDeploymentByIdInput!
    ) {
        updateCapitalDeploymentById(
            input: $updateCapitalDeploymentByIdInput
        ) {
            capitalDeployment {
                id
                paidOn
                amount
                note
                currencyByCurrency {
                    id
                    code
                }
                roundByRoundId {
                    id
                }
            }
        }
    }
`

const formConfig = {
    fields: {
        paidOn: {
            type: 'date',
            error: 'Paid On is a required field.',
        },

        amount: {
            type: 'number',
            error: 'Amount is a required field.',
        },

        note: {
            type: 'string',
            error: 'Note is a required field.',
        },

        currency: {
            type: 'dropdown',
            default: 1,
        },
    },
    defaultValueKey: 'capitalDeployment',
}

const EditCapitalDeploymentForm = (props) => {
    const {
        capitalDeployment: { id },
        popup,
        form: {
            paidOn, amount, note, validateInput, getChanged,
        },
    } = props

    const handleSubmit = () => {
        const input = validateInput()

        if (!input.hasErrors) {
            const changedValues = getChanged(input)
            const returnVal = {
                updateCapitalDeploymentByIdInput: {
                    id,
                    capitalDeploymentPatch: changedValues,
                },
            }

            new Mutation({
                mutation: UPDATE_CAPITAL_DEPLOYMENT,
                onSubmit: () => returnVal,
                onResolve: () => popup.close(),
            }).submit()
        }
    }

    return (
        <form className='vrtl-round-allocation__add-form'>
            <h2 className='qualifications-form-title'>Edit Deployment</h2>
            <div className='card'>
                <div className='card-section'>
                    <div className='card-label'>PAID ON</div>
                    <div className='asset-form-input-container'>
                        <DateInput {...paidOn} />
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-section'>
                    <div className='card-label'>AMOUNT</div>
                    <div className='asset-form-input-container'>
                        <Input {...amount} />
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-section'>
                    <div className='card-label'>NOTE</div>
                    <div className='asset-form-input-container'>
                        <Input {...note} />
                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-section'>
                    <div className='card-label'>CURRENCY</div>
                    <div className='asset-form-input-container'>
                        USD
                    </div>
                </div>
            </div>

            <div className='card row--justify-between'>
                <div className='styled-button' onClick={popup.close}>
                    Cancel
                </div>
                <div className='styled-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </form>
    )
}

const EditCapitalDeploymentFormWrapper = Form(
    EditCapitalDeploymentForm,
    formConfig,
)

export default EditCapitalDeploymentFormWrapper

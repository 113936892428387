import React from 'react'

export default () => {
    const prefix = 'vrtl-investor-row'
    return (
        <div className={`${prefix}__heading-container`}>
            <div className={`${prefix}__section`}>Question</div>
            <div className={`${prefix}__section`}>Response</div>
        </div>
    )
}

const selectAssetType = ({ asset }) => {
    if (asset.assetTypeByType.type === 'Debt') {
        return 'debt'
    }

    if (asset.assetTypeByType.type === 'Fund') {
        return 'fund'
    }

    return 'equity'
}

const selectAssetCategory = ({ asset }) => asset.assetTypeByType.accountingType

export {
    selectAssetType,
    selectAssetCategory,
}

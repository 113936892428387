import React from 'react'
import Form from 'common/form/Form.js'
import Tombstone from 'common/Tombstone.js'
import FormUI from 'common/FormUI.js'
import Mutation from 'delv/Mutation'
import Delv from 'delv/delv-react'
import Typography from 'common/Typography'
import Loading from 'common/loading/index.js'

import './IntegrationRequirementForm.scss'

const queryMap = {
    allAccounts: {
        query: `{
            allAccounts(condition:{type:"issuer"}){
                nodes{
                    id
                    type
                    name
                }
            }
        }`,
        format: ({ allAccounts }) => ({
            options: allAccounts.nodes.map((account) => ({
                displayValue: account.name,
                value: account.id,
            })),
        }),
    },
    allRounds: {
        query: `{
            allRounds{
                nodes{
                    id
                    name
                }
            }
        }`,
        format: ({ allRounds }) => ({
            options: allRounds.nodes.map((round) => ({
                displayValue: round.name,
                value: round.id,
            })),
        }),
    },
}

const IntegrationRequirementForm = (props) => {
    const {
        mutation,
        title,
        queryArgs,
        form: {
            error,
            handleBlur,
            handleChange,
            validateInput,
            resetState,
            getChanged,
            getDefinition,
            loading,
            setLoading: formSetLoading,
            ...fields
        },
        onResolve,
        refetch,
    } = props

    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            new Mutation({
                mutation,
                onSubmit: () => {
                    formSetLoading(true)
                    return {
                        ...queryArgs,
                        blob: input,
                    }
                },
                onResolve,
                refetch,
                onError: (err) => {
                    const e = err[0].message
                    formSetLoading(e)
                },
            }).submit()
        }
    }

    if (typeof loading === 'boolean' && loading) {
        return <Loading.dots />
    }

    return (
        <div className='vrtl-integration-requirement-form'>
            <div className='vrtl-integration-requirement-form--header'>{title}</div>
            <div className='vrtl-integration-requirement-form__tombstone'>
                {typeof loading === 'string' && <Typography color='error'>{loading}</Typography>}
                <div className='vrtl-integration-requirement-form__body'>
                    {Object.keys(fields).map((key, index) => {
                        const value = fields[key]
                        const definition = getDefinition(value)
                        const Input = FormUI[definition.type] || FormUI.input
                        const RenderComponent = definition.patch.typeahead ? (
                            <Delv.query {...queryMap[definition.patch.typeahead]}>
                                <Input className='vrtl-integration-requirement-form__input' {...{ ...value, ...definition.patch }} />
                            </Delv.query>
                        ) : (
                            <Input className='vrtl-integration-requirement-form__input' {...{ ...value, ...definition.patch }} />
                        )
                        return (
                            <>
                                {/* eslint-disable react/no-array-index-key */}
                                <div className='vrtl-integration-requirement-form__row' key={index}>
                                    <div className='card-label vrtl-integration-requirement-form__label'>{definition.displayKey}</div>
                                    {RenderComponent}
                                </div>
                            </>
                        )
                    })}
                </div>
                <Tombstone.splitRow>
                    <div className='styled-button' onClick={onResolve}>Cancel</div>
                    <div className='styled-button' onClick={handleSubmit}>Submit</div>
                </Tombstone.splitRow>
            </div>
        </div>
    )
}

const buildFormConfig = (fields, output) => {
    const formConfig = {
        fields: {},
    }
    fields
        .sort((a, b) => a.order - b.order)
        .forEach(({
            displayKey, key, type, error, regex, options,
        }) => {
            const config = {
                type,
                displayKey,
                error: error || 'Invalid input',
                patch: {}, // patch is a blob that needs to be passed to input aswell
            }
            if (output && output[key]) {
                config.default = output[key]
            }

            if (regex && ['string', 'password'].includes(type)) {
                config.submit = (value) => {
                    if (value.match(regex)) {
                        return value
                    }
                    return {
                        error: true,
                    }
                }
            } else if (type === 'boolean') {
                config.options = [{
                    displayValue: 'Yes',
                    value: 'yes',
                }, {
                    displayValue: 'No',
                    value: 'no',
                }]
                config.type = 'dropdown'
            }
            if (options) {
                if (options.query) {
                    config.patch.typeahead = options.query
                } else {
                    config.patch.options = options
                }
            }
            formConfig.fields[key] = config
        })

    return formConfig
}

export default (props) => { // expect props to include integrationRequirementByRequirementId
    let defaultData = {}
    if (props.dynamicData.data) {
        defaultData = props.dynamicData.data
    }

    if (props.outputData) {
        defaultData = { ...defaultData, ...props.outputData }
    }

    const formConfig = buildFormConfig(props.schema.fields, defaultData)
    const CustomForm = Form(IntegrationRequirementForm, formConfig)
    return <CustomForm {...props} title={props.schema.title} />
}

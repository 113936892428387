const dateNotFuture = (value) => {
    if (new Date(value) > new Date()) {
        return {
            errorExists: true,
            value,
        }
    }

    return {
        errorExists: !value,
        value,
    }
}

export default dateNotFuture

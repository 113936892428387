import { collectionResolver } from './collection-resolver.js'
import { voidResolver } from './void-resolver.js'
import { entityResolver } from './entity-resolver.js'
import { leafResolver } from './leaf-resolver.js'
import { nodesResolver } from './nodes-resolver.js'

const resolverMap = {
    collection: collectionResolver,
    empty: voidResolver,
    entity: entityResolver,
    leaf: leafResolver,
    nodes: nodesResolver,
    null: voidResolver,
}

export { resolverMap }

import React, { useState } from 'react'
import DownArrow from '@material-ui/icons/KeyboardArrowDown'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'

const Card = ({
    className = 'card',
    addClassName = '',
    children,
    open,
    setOpen,
    style,
}) => {
    if (open || open === false) {
        return (
            <div style={style} className={cx(className, addClassName)}>
                {SafeReact.cloneChildren(children, { open, setOpen })}
            </div>
        )
    }
    return (
        <div style={style} className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(children)}
        </div>
    )
}

const Container = ({
    className = 'card-container',
    addClassName = '',
    children,
}) => (
    <div className={cx(className, addClassName)}>
        {SafeReact.cloneChildren(children)}
    </div>
)

const Section = ({
    className = 'card-section',
    addClassName = '',
    style,
    children,
}) => (
    <div style={style} className={cx(className, addClassName)}>
        {SafeReact.cloneChildren(children)}
    </div>
)

const Label = ({
    className = 'card-label',
    addClassName = '',
    children,
    ...props
}) => (
    <div className={cx(className, addClassName)}>
        {SafeReact.cloneChildren(children, props)}
    </div>
)

const CollapsibleContainer = ({
    className = 'card-container',
    children,
    addClassName = '',
    isOpen = false,
    open: propOpen,
    setOpen: propSetOpen,
    ...props
}) => {
    const [o, setO] = useState(isOpen)
    let open = o
    let setOpen = setO

    if (typeof propOpen === 'boolean') {
        open = propOpen
        setOpen = propSetOpen
    }

    const childrens = open ? children : children[0]

    return (
        <div className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(childrens, { open, setOpen, ...props })}
        </div>
    )
}

const AnimatedCollapsibleContainer = () => (
    <div>this component is disabled</div>
)

const Arrow = ({
    open,
    setOpen,
    addClassName = '',
}) => (open ? (
    <DownArrow onClick={() => setOpen(false)} className={cx('dropdown-arrow', addClassName)} />
) : (
    <RightArrow onClick={() => setOpen(true)} className={cx('dropdown-arrow', addClassName)} />
))

export default {
    card: Card,
    container: Container,
    section: Section,
    label: Label,
    collapsible: CollapsibleContainer,
    animatedCollapsible: AnimatedCollapsibleContainer,
    arrow: Arrow,
}

const getUserPathName = () => window.location.pathname.match(/^\/[^/]+/)?.[0] || '/'

const getAdminPathName = () => {
    const pathMatch = window.location.pathname.match(/^\/[^/]+(\/[^/]+)/) || []

    if (pathMatch.length > 1) {
        return pathMatch[1]
    }

    return '/'
}

const getPathName = (info) => {
    if (!info) return '/'

    return info.accountType === 'admin' ? getAdminPathName() : getUserPathName()
}

export { getPathName }

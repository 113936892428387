import * as React from 'react'
import { Decimal } from 'decimal.js'
import Help from '@mui/icons-material/Help'

import Box from 'common/Box'
import Button from 'common/Button'
import Card from 'common/Card/index.js'
import Divider from 'common/Divider'
import Link from 'common/Link'
import Typography from 'common/Typography'
import Tooltip from 'common/Tooltip'
import useQuery from 'delv/hooks/use-query'
import useTheme from 'hooks/UseTheme'
import { getBlockExplorerUrl } from 'util/blockchain'
import Format from 'util/format/Format'

import { TOKEN_BY_ID_QUERY } from './queries.js'

const TokenInfo = ({ Icon, title, value }) => (
    <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant='subtitle2'>{title}</Typography>
            {Icon}
        </Box>
        <Typography variant='body1' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{value}</Typography>
    </Box>
)

const HelpText = () => {
    const theme = useTheme()
    const color = theme.palette.mode === 'light' ? '#fff' : '#000'

    return (
        <Typography align='center' data-testid='help-text' sx={{ fontSize: 12 }}>
            {'All prices are provided by Coinbase. For more accurate pricing data, please visit '}
            <Link
                color={color}
                sx={{
                    textDecorationColor: color,
                }}
                target='_blank'
                href='https://www.coinbase.com/price'
                rel='noopener noreferrer'
            >
                Coinbase
            </Link>
            .
        </Typography>
    )
}

const TokenCard = ({
    token: {
        id,
        name,
        type,
        issuer,
        total,
        chain,
        contractAddress,
        coinbaseBuyPriceUSD,
        coinbaseSellPriceUSD,
        coinbaseSpotPriceUSD,
        activeBlockchainAddresses,
    },
}) => {
    const theme = useTheme()

    // This query keeps the cache up to date.
    // Coinbase is constantly changing the value of token.price, so we fetch every render.
    useQuery(TOKEN_BY_ID_QUERY, {
        args: id,
        networkPolicy: 'network-only',
    })

    const price = coinbaseBuyPriceUSD
    const hasPrice = new Decimal(price).isPos()

    const buyPrice = hasPrice ? Format.number(new Decimal(price)) : 'N/A'
    const sellPrice = hasPrice ? Format.number(new Decimal(coinbaseSellPriceUSD)) : 'N/A'
    const spotPrice = hasPrice ? Format.number(new Decimal(coinbaseSpotPriceUSD)) : 'N/A'
    const sellValue = hasPrice ? Format.number(new Decimal(total).mul(coinbaseSellPriceUSD)) : 'N/A'
    const spotValue = hasPrice ? Format.number(new Decimal(total).mul(coinbaseSpotPriceUSD)) : 'N/A'

    const isCrypto = type === 'Crypto'
    const issuerExists = !!issuer
    const exchange = isCrypto ? `https://www.coinbase.com/price/${name.replace(/\./g, '-').replace(/\s/g, '-').toLowerCase()}` : 'https://tzero.com'
    const contractLink = isCrypto
        ? `https://www.coinbase.com/accounts/${activeBlockchainAddresses[0].address}`
        : getBlockExplorerUrl(contractAddress, chain)
    const blockExplorerLink = getBlockExplorerUrl(contractAddress, chain)

    return (
        <Card sx={{ p: 1 }}>
            <Box sx={{ p: 2 }}>
                {blockExplorerLink ? (
                    <Link variant='h6' sx={{ pb: 2, display: 'block' }} href={blockExplorerLink} target='_blank' rel='noreferrer noopener'>
                        {name}
                    </Link>
                ) : (
                    <Typography variant='h6' sx={{ pb: 2, display: 'block' }}>
                        {name}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {
                        // Tokens fetched from coinbase won't have an issuer
                        issuerExists && <TokenInfo title='Issuer' value={issuer} />
                    }
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TokenInfo title='Holding' value={Format.number(total)} />
                        <TokenInfo title='Sell Value' value={sellValue} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TokenInfo title='Spot Value' value={spotValue} />
                        <TokenInfo
                            title='Buy Price'
                            value={buyPrice}
                            Icon={(
                                isCrypto && (
                                    <Tooltip title={<HelpText />}>
                                        <Help color='primary' data-testid='help-icon' sx={{ fontSize: 16 }} />
                                    </Tooltip>
                                )
                            )}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TokenInfo title='Sell Price' value={sellPrice} />
                        <TokenInfo title='Spot Price' value={spotPrice} />
                    </Box>
                    <Box sx={{ py: 2 }}>
                        <Divider sx={{ borderColor: theme.palette.primary.main }} />
                    </Box>
                    <TokenInfo title='Chain' value={chain} />
                    <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
                        <Typography variant='subtitle2'>{isCrypto ? 'Asset ID' : 'Contract Address'}</Typography>
                        {contractLink ? (
                            <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                <Link
                                    href={contractLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {contractAddress}
                                </Link>
                            </Box>
                        ) : (
                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} variant='body1'>{contractAddress}</Typography>
                        )}
                    </Box>
                    {
                        activeBlockchainAddresses.map(({ id: addressId, address }) => (
                            <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
                                <Typography variant='subtitle2'>{isCrypto ? 'Account ID' : 'Blockchain Address'}</Typography>
                                <Typography
                                    key={addressId}
                                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                    variant='body1'
                                >
                                    {address}
                                </Typography>
                            </Box>
                        ))
                    }
                    {
                        activeBlockchainAddresses.map(({ blockNumber }) => (
                            chain === 'ethereum-vertalo' && (
                                <Box sx={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
                                    <Typography variant='subtitle2'>Block Number</Typography>
                                    <Typography
                                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                        variant='body1'
                                    >
                                        <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                            {blockNumber}
                                        </Box>
                                    </Typography>
                                </Box>
                            )
                        ))
                    }
                </Box>
            </Box>
            {isCrypto ? (
                <Button
                    rel='noreferrer noopener'
                    target='_blank'
                    href={exchange}
                    variant='contained'
                    sx={{ width: '100%' }}
                >
                    {`Trade ${isCrypto && `on ${chain}`}`}
                </Button>
            ) : (
                <Button disabled variant='contained' sx={{ width: '100%' }}>Not Listed</Button>
            )}
        </Card>
    )
}

export default TokenCard

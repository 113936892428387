import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import {
    Input, Date, Dropdown, ErrorWrapper,
} from 'common/FormUI.js'
import Card from 'common/Card.js'

const UPDATE_ALLOCATION = `mutation ($id:UUID!, $allocation: AllocationPatch!) {
  updateAllocationById(input: {id:$id, allocationPatch:$allocation}) {
    allocation {
      id
      name
      closesOn
      opensOn
      status
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is a required field.',
        },
        opensOn: {
            type: 'date',
            error: 'Opens on is a required field.',
        },
        closesOn: {
            type: 'date',
            error: 'Closes ons is a required field.',
        },
        status: {
            type: 'dropdown',
        },
    },
    defaultValueKey: 'allocation',
}

const STATUS = [
    { status: 'Drafted', value: 'drafted' },
    { status: 'Active', value: 'active' },
    { status: 'Closed', value: 'closed' },
    { status: 'Stale', value: 'stale' },
]

const EditAllocationForm = Form(
    ({
        cancel,
        submit,
        onResolve,
        allocationId,
        form: {
            error,
            name,
            status,
            opensOn,
            closesOn,
            validateInput,
            getChanged,
        },
    }) => {
        const handleSubmit = (event) => {
            event.preventDefault()
            const input = validateInput()
            if (!input.hasErrors) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    const submitValue = {
                        id: allocationId,
                        allocation: changedInput,
                    }
                    new Mutation({
                        mutation: UPDATE_ALLOCATION,
                        onSubmit: () => submitValue,
                        onResolve,
                        onError: error.onError,
                    }).submit()
                } else {
                    onResolve()
                }
            }
        }

        return (
            <div>
                <ErrorWrapper {...error}>
                    <Card.card>
                        <Card.section>
                            <Card.label>NAME</Card.label>
                            <Input {...name} addClassName='token-form-input' />
                        </Card.section>
                        <Card.section>
                            <Card.label>STATUS</Card.label>
                            <Dropdown
                                {...status}
                                options={STATUS}
                                width='9rem'
                            />
                        </Card.section>
                        <Card.section>
                            <Card.label>OPENS ON</Card.label>
                            <Date
                                {...opensOn}
                                addClassName='token-form-input'
                            />
                        </Card.section>
                        <Card.section>
                            <Card.label>CLOSES ON</Card.label>
                            <Date
                                {...closesOn}
                                addClassName='token-form-input'
                            />
                        </Card.section>
                    </Card.card>
                </ErrorWrapper>
                <div className='row--justify-between'>
                    {cancel}
                    {React.cloneElement(submit, { onClick: handleSubmit })}
                </div>
            </div>
        )
    },
    formConfig,
)

const data = `id
name
opensOn
closesOn`

const query = (id) => `allocationByAllocationId(id:${id}){
    ${data}
}`

const fragment = {
    data,
    query,
}

export { fragment }

export default EditAllocationForm

import React from 'react'
import OrderBy from 'common/OrderBy.js'
import { isIssuer } from 'util/permissions.js'

import './styles.scss'

const OrderByHeader = ({ prefix = 'vrtl-securities-page', ...props }) => (
    <OrderBy.bar className={`${prefix}__bar`} {...props}>
        {isIssuer(props) && <div className='inline-offset--2' />}
        <OrderBy.filter className={`${prefix}__section--3`} field='name'>Name</OrderBy.filter>
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <OrderBy.sort className={`${prefix}__section--2`} field='unitValue'>Latest Price</OrderBy.sort>
        <div className={`${prefix}__section--2`} />
        {
            isIssuer(props) ? (
                <>
                    <div className={`${prefix}__section--2`} />
                    <div className={`${prefix}__section--2`} />
                </>
            ) : <div className={`${prefix}__section--2`} />
        }
        <div className={`${prefix}__icon-offset`} />
    </OrderBy.bar>
)

export default OrderByHeader

import React, { useState } from 'react'
import Format from 'util/format/Format.js'

import SafeReact from 'util/SafeReact.js'

const StatelessMultiSelect = (props) => {
    const {
        onChange, name, value, children, ...otherProps
    } = props
    const setState = (v) => onChange(Format.DOMEvent(name, v))

    const clear = () => setState(Object.create(null))

    const select = (node) => {
        if (node instanceof Array) {
            const data = Object.create(null)
            for (const item of node) {
                data[item.id] = item.value || true
            }
            setState(data)
        } else if (value[node.id]) {
            const newValue = Object.assign(Object.create(null), value)
            delete newValue[node.id]
            setState(newValue)
        } else {
            setState({
                ...value,
                [node.id]: node.value || true,
            })
        }
    }

    const includes = (v) => value[v]
    const length = () => Object.values(value).length

    const getValues = () => Object.values(value)

    const hasValue = () => Object.keys(value).length > 0

    const multiSelect = {
        getValues,
        clear,
        select,
        includes,
        hasValue,
        length,
        selected: value,
    }

    return SafeReact.cloneChildren(props.children, { ...otherProps, multiSelect })
}

export { StatelessMultiSelect }

const MultiSelect = (props) => {
    const [value, setValue] = useState(Object.create(null))
    const onChange = (event) => setValue(event.target.value)
    return <StatelessMultiSelect {...{ onChange, value, ...props }} />
}

export default MultiSelect

import React from 'react'

import Delv from 'delv/delv-react'

import EditSwitch from 'common/NewEditSwitch.js'
import Tombstone from 'common/Tombstone.js'
import GenericForm from 'common/form/GenericForm.js'
import { Dropdown } from 'common/FormUI.js'

const GET_EMAIL_PREFERENCES = (id) => `{
  allocationEmailPreferenceByAllocationId(allocationId:"${id}"){
    id
    allocationId
    sendDistribution
  }
}`

const EmailCard = ({
    sendDistribution,
    edit,
    cancel,
    submit,
}) => (
    <div>
        <h3 className='row--justify-between no-margin-header'>
            Distribution
            {edit}
        </h3>
        <Tombstone.tombstone>
            <Tombstone.splitRow>
                <div>Status update emails</div>
                <div>{typeof sendDistribution === 'boolean' ? sendDistribution ? 'Enabled' : 'Disabled' : sendDistribution}</div>
            </Tombstone.splitRow>
            <div className='row--justify-between'>
                {cancel}
                {submit}
            </div>
        </Tombstone.tombstone>
    </div>
)

const updateQuery = () => `mutation($id:UUID!, $patch:AllocationEmailPreferencePatch!){
  updateAllocationEmailPreferenceById(input:{id:$id, allocationEmailPreferencePatch:$patch}){
    allocationEmailPreference{
      id
      allocationId
      sendDistribution
    }
  }
}`

const formConfig = {
    fields: {
        sendDistribution: {
            type: 'dropdown',
            customUI: ({ value }) => {
                const options = [
                    { displayValue: 'Enabled', value: true },
                    { displayValue: 'Disabled', value: false },
                ]
                return <Dropdown {...value} options={options} />
            },
        },
    },
}

const EditEmailCard = GenericForm({
    updateField: updateQuery,
    prefix: 'vrtl-onboarding-question-set-row',
    formConfig,
    Display: EmailCard,
})

const Emails = ({
    canEdit,
    allocationId,
}) => (
    <div className='row flex-1' style={{ margin: '1rem' }}>
        <div className='flex-1'>
            <Delv.query
                query={GET_EMAIL_PREFERENCES(allocationId)}
                format={({ allocationEmailPreferenceByAllocationId }) => allocationEmailPreferenceByAllocationId}
                key='emails'
            >
                <EditSwitch.switch canEdit={canEdit} editIcon>
                    <EmailCard />
                    <EditEmailCard />
                </EditSwitch.switch>
            </Delv.query>
        </div>
        <div className='flex-1' />
    </div>
)

export default Emails

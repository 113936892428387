import React from 'react'
import Format from 'util/format/Format.js'

export const makeFormComponents = (Sheet) => {
    const Input = ({ error, ...props }) => (
        <Sheet.input.container>
            <Sheet.errorMessage>{error}</Sheet.errorMessage>

            <Sheet.input.inner
                autoComplete='off'
                {...props}
            />
        </Sheet.input.container>
    )

    const Date = ({
        onChange,
        onBlur,
        name,
        error,
        ...props
    }) => {
        const handleChange = (v) => onChange(Format.DOMEvent(name, v))
        const handleBlur = (v) => onBlur(Format.DOMEvent(name, v))

        return (
            <Sheet.date.container>
                <Sheet.errorMessage>{error}</Sheet.errorMessage>

                <Sheet.date.inner
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...props}
                />
            </Sheet.date.container>
        )
    }

    const CheckBox = ({
        name, onChange = () => {}, value, ...props
    }) => {
        const handleChange = () => onChange(Format.DOMEvent(name, !value))
        return value ? (
            <Sheet.checkbox.filled onClick={handleChange} {...props} />
        ) : (
            <Sheet.checkbox.unfilled onClick={handleChange} {...props} />
        )
    }

    const Radio = ({ name, onChange = () => {}, value }) => {
        const handleChange = () => onChange(Format.DOMEvent(name, !value))

        return value ? (
            <Sheet.radio.checked onClick={handleChange} />
        ) : (
            <Sheet.radio.unchecked onClick={handleChange} />
        )
    }

    const PhoneNumber = ({ name, onChange, value }) => {
        const handleChange = (v) => onChange(Format.DOMEvent(name, v))

        return <Sheet.phoneNumber.input value={value} onChange={handleChange} />
    }

    const Password = ({ type, ...props }) => <Input type='password' {...props} />

    const ErrorWrapper = ({ children, ...props }) => (
        <Sheet.errorWrapper>
            <Sheet.errorMessage>{props.error}</Sheet.errorMessage>

            {React.cloneElement(children, props)}
        </Sheet.errorWrapper>
    )

    return {
        input: Input,
        date: Date,
        checkbox: CheckBox,
        radio: Radio,
        phone: PhoneNumber,
        error: ErrorWrapper,
        password: Password,
    }
}

import * as React from 'react'

import InputLabel from 'common/InputLabel'
import FormControl from 'common/FormControl'
import FormHelperText from 'common/FormHelperText'
import Select from 'common/Select'
import MenuItem from 'common/MenuItem'

const Dropdown = ({
    id: propId,
    'data-testid': testId,
    error,
    label: propLabel,
    helperText,
    name,
    onChange,
    options = [],
    required,
    SelectProps = { sx: {} },
    SelectItemProps = {},
    value,
    fullWidth,
}) => {
    const id = propId || propLabel?.replace('/ /g', '-')
    const label = `${propLabel}${required ? ' *' : ''}`

    return (
        <FormControl fullWidth={fullWidth} data-testid={testId} error={error}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                aria-labelledby={id}
                label={label}
                labelId={id}
                name={name}
                onChange={onChange}
                value={value}
                {...SelectProps}
            >
                <MenuItem key='No Seletion' value='' {...SelectItemProps}>
                    No Selection
                </MenuItem>
                { options.map((option) => (
                    <MenuItem key={option.label} value={option.value} {...SelectItemProps}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            { Boolean(helperText) && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
        </FormControl>
    )
}

export default Dropdown

import React from 'react'
import { Input } from 'common/FormUI.js'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { MakeDistributionData } from './queries.js'

const MAKE_DISTRIBUTION = `mutation ($distribution: MakeDistributionInput!) {
  makeDistribution(input: $distribution) {
    distribution{
      ${MakeDistributionData}
    }
  }
}`

const BuyersForm = ({
    allocationId,
    form: {
        contactName,
        email,
        amount,
        validateInput,
        resetState,
    },
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const submitValue = {
                distribution: {
                    accountEmail: input.email,
                    amount: input.amount,
                    name: input.contactName,
                    _allocationId: allocationId,
                },
            }
            new Mutation({
                mutation: MAKE_DISTRIBUTION,
                onFetch: (promise, data) => {
                    data.queryManager.remove('roundPage', true)
                },
                onSubmit: () => submitValue,
                onResolve: resetState,
            }).submit()
        }
    }

    return (
        <div className='buyer-form'>
            <h2 className='buyer-form-header'>Investor Information</h2>
            <div className='card'>

                <div className='card-section investor-form-section'>
                    <div className='card-label'>Investor account email</div>
                    <Input {...email} />
                </div>
                <div className='card-section investor-form-section'>
                    <div className='card-label'>Amount</div>
                    <Input {...amount} />
                </div>
            </div>
            <div className='card'>
                <div className='card-section investor-form-section'>
                    <div className='card-label'>Investor account name</div>
                    <Input {...contactName} />
                </div>
            </div>
            <div
                className='buyer-form-button styled-button'
                onClick={handleSubmit}
            >
                Add
            </div>
        </div>
    )
}

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        contactName: {
            type: 'string',
            error: 'Contact name is a required field.',
            required: false,
        },
        email: {
            type: 'string',
            error: 'Email is a required field.',
        },
        amount: {
            type: 'number',
            error: 'Amount is a required field',
        },
    },
    constraints: {
        amount: {
            type: 'greaterThan',
            value: {
                type: 'number',
                isField: false,
                value: 0,
            },
        },
    },
}

export default Form(BuyersForm, formConfig)

const INVESTOR_OFFERING_DETAILS_BY_ID = (args) => `query InvestorOfferingDetailsById {
    investorOfferingDetailsById${args} {
        id
        issuerName
        sellerName
        assetName
        className
        classPrice
    }
}`

export {
    INVESTOR_OFFERING_DETAILS_BY_ID,
}

import React from 'react'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

export default ({
    children,
    ...props
}) => {
    const prefix = 'vrtl-issuer-order-by-header'

    return (
        <OrderBy.bar {...props} className={`${prefix}__bar`}>
            <OrderBy.filter field='name' className={`${prefix}__section--3`}>
                <div className='inline-offset' />
                Issuer Name
            </OrderBy.filter>
            <OrderBy.filter field='email' className={`${prefix}__section--2`}>
                Issuer Email
            </OrderBy.filter>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section`} />
            <div className={`${prefix}__section--2`} />
            <div className='icon-offset--2' />
            <div className='icon-offset--2' />
        </OrderBy.bar>
    )
}

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
createdOn
userByCreatedBy{
        id
        firstName
        lastName
}
accountByEntityId{
    id
    name
    email
    contactName
    supportEmail
    type
}`)

import * as React from 'react'

import ComponentConfig from 'common/FormV2/components'
import useForm from 'hooks/UseForm'

const useFormView = ({ fields }) => {
    const { config, keys, ...form } = useForm({ fields })

    const formView = keys.reduce((acc, key) => {
        const { Component: FieldComponent, __typename, ...props } = config[key]
        const Component = FieldComponent || ComponentConfig[__typename]

        acc[key] = <Component {...props} />

        return acc
    }, {})

    return { formView, config, ...form }
}

export default useFormView

import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default (props) => {
    const prefix = 'vrtl-sent-documents'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                Sent to
            </OrderBy.filter>
            <OrderBy.dropdown field='status' className={`${prefix}__section`}>
                Status
            </OrderBy.dropdown>
            <OrderBy.sort date field='sentOn' className={`${prefix}__section`}>
                Sent on
            </OrderBy.sort>
            <OrderBy.filter field='documentId' className={`${prefix}__section`}>
                Document ID
            </OrderBy.filter>
        </OrderBy.bar>
    )
}

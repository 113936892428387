import countryFragment from 'components/country/fragment.js'
import dateNotFuture from 'util/dateNotFuture'
import * as DateFns from 'date-fns'

import AccountForm from './Account.js'
import IndividualForm from './Individual.js'
import Joint from './Joint.js'

const TAX_COUNTRY = `{
    allCountries {
        nodes {
            ${countryFragment}
        }
    }
}`

// remove when account setup state handler is re-factored
const formatIndividual = (data) => {
    const {
        firstName,
        lastName,
        taxId,
        dateOfBirth,
        usCitizen,
    } = data.parties.individual

    data.account = {
        ...data.account,
        accountName: `${firstName} ${lastName}`,
        formationDate: dateOfBirth,
        taxId,
        usCitizen,
    }
    data.parties.individual.addresses = data.account.addresses
}

const account = {
    accountName: {
        type: 'string',
        label: 'Entity Legal Name',
    },
    taxId: {
        type: 'string',
        label: 'Tax ID',
        helperText: 'If not applicable, enter 000',
    },
}

const accountWithSSN = {
    ...account,
    taxId: {
        type: 'string',
        label: 'Tax ID / SSN',
        helperText: 'If not applicable, enter 000',
    },
}

const accountWithFormationDate = {
    ...account,
    formationDate: {
        type: 'date',
        label: 'Date of Formation *',
        required: true,
        onSubmit: dateNotFuture,
    },
}

const baseAddress = {
    city: {
        type: 'string',
        label: 'City',
    },
    province: {
        type: 'string',
        label: 'State / Province',
        onChange: (key, value, { setInput, input }) => {
            if (!input) {
                setInput({
                    ...input,
                    [key]: {
                        errorExists: true,
                        value,
                    },
                })
            }
            setInput({
                ...input,
                [key]: {
                    errorExists: true,
                    value,
                },
            })
        },
    },
    postalCode: {
        type: 'string',
        label: 'Postal / Zip Code',
    },
    country: {
        type: 'dropdownTypeaheadWithData',
        label: 'Country',
        query: TAX_COUNTRY,
        queryOptions: {
            format: (data) => data.allCountries.nodes.map((element) => ({
                label: element.name,
                value: element.id,
            })),
        },
    },
}

const address = {
    business: {
        fields: {
            ...baseAddress,
            lineOne: {
                type: 'string',
                label: 'Primary Business Address Line 1',
            },
            lineTwo: {
                type: 'string',
                label: 'Primary Business Address Line 2',
                required: false,
            },

        },
    },
    residential: {
        fields: {
            ...baseAddress,
            lineOne: {
                type: 'string',
                label: 'Primary Residential Address Line 1',
            },
            lineTwo: {
                type: 'string',
                label: 'Primary Residential Address Line 2',
                required: false,
            },
        },
    },
    mailing: {
        fields: {
            ...baseAddress,
            lineOne: {
                type: 'string',
                label: 'Mailing Address Line 1',
            },
            lineTwo: {
                type: 'string',
                label: 'Mailing Address Line 2',
                required: false,
            },
        },
    },
}

const party = {
    email: {
        type: 'email',
        label: 'Contact Email Address',
    },
    firstName: {
        type: 'string',
        label: 'First Name',
    },
    lastName: {
        type: 'string',
        label: 'Last Name',
    },
    taxId: {
        type: 'string',
        label: 'Tax ID / SSN',
        helperText: 'If not applicable, enter 000',
    },
    dateOfBirth: {
        type: 'date',
        label: 'Date of Birth',
        onSubmit: dateNotFuture,
    },
    usCitizen: {
        type: 'boolean',
        label: 'Is a US Citizen',
        helperText: 'Select whether or not this individual is a citizen of the United States',
        defaultValue: null,
    },
}

const partyWithAllowLogin = {
    ...party,
    allowLogin: {
        type: 'boolean',
        label: 'Add as Delegate',
        helperText: 'Delegation allows this individual to log in to this investor account',
        defaultValue: null,
    },
}

const trust = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. John Doe Living Trust',
            },
            formationDate: {
                type: 'date',
                label: 'Date Executed',
                required: true,
                onSubmit: dateNotFuture,
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this trustee is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this trustee to log in to this investor account',
                defaultValue: null,
            },
        },
        label: 'Trustee',
        instructions: 'Please list all trustees that are party to this account.',
    },
    addressFields: address.residential,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const individualOrSoleProp = {
    account: {
        fields: {
            ...party,
        },
    },
    addressFields: address.residential,
    mailingFields: address.mailing,
    Component: IndividualForm,
}

const joint = {
    account: {
        fields: {
            ...accountWithSSN,
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
        },
        label: 'Individual',
        instructions: 'Please list both individuals that are party to this account.',
    },
    addressFields: address.residential,
    mailingFields: address.mailing,
    Component: Joint,
    format: (data) => {
        data.account = {
            ...data.account,
            formationDate: DateFns.format(Date.now(), 'MM/dd/yyyy'),
        }
    },
}

const Estate = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. Sunstar, inc.',
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this officer is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this officer to log in to this investor account',
                defaultValue: null,
            },
        },
        instructions: 'Please list the administrator that is party this account.',
        label: 'Administrator',
    },
    addressFields: address.business,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const Corporation = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. Sunstar, Inc.',
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this officer is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this officer to log in to this investor account',
                defaultValue: null,
            },
        },
        instructions: 'Please list all officers that are party to this account.',
        label: 'Officer',
    },
    addressFields: address.business,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const LLC = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. Sunstar, inc.',
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this managing member is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this managing member to log in to this investor account',
                defaultValue: null,
            },
        },
        instructions: 'Please list all managing members that are party to this account.',
        label: 'Managing Member',
    },
    addressFields: address.business,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const LimitedPartnership = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. Sunstar, PLLC',
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this Partner is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this Partner to log in to this investor account',
                defaultValue: null,
            },
        },
        instructions: 'Please list all partners that are party to this account.',
        label: 'Partner',
    },
    addressFields: address.business,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const IRA = {
    account: {
        fields: {
            ...accountWithFormationDate,
            accountName: {
                type: 'string',
                label: 'Entity Legal Name',
                helperText: 'ex. Sunstar FBO John Doe Roth IRA',
            },
            formationDate: {
                type: 'date',
                label: 'Date Established',
                required: true,
                onSubmit: dateNotFuture,
            },
        },
    },
    party: {
        fields: {
            ...partyWithAllowLogin,
            usCitizen: {
                type: 'boolean',
                label: 'Is a US Citizen',
                helperText: 'Select whether or not this beneficiary is a citizen of the United States',
                defaultValue: null,
            },
            allowLogin: {
                type: 'boolean',
                label: 'Add as Delegate',
                helperText: 'Delegation allows this beneficiary to log in to this investor account',
                defaultValue: null,
            },
        },
        label: 'Beneficiary',
        instructions: 'Please list the beneficiary that is party to this account.',
    },
    addressFields: address.business,
    mailingFields: address.mailing,
    Component: AccountForm,
}

const typeMap = {
    Individual: {
        ...individualOrSoleProp,
        format: formatIndividual,
    },
    'Individual IRA': {
        ...IRA,
    },
    'Individual Roth IRA': {
        ...IRA,
    },
    'Joint TIC': {
        ...joint,
        account: {
            fields: {
                ...joint.account.fields,
                accountName: {
                    type: 'string',
                    label: 'Entity Legal Name',
                    helperText: 'ex. John Doe and Jane Doe TIC',
                },
            },
        },
    },
    'Joint TWROS': {
        ...joint,
        account: {
            fields: {
                ...joint.account.fields,
                accountName: {
                    type: 'string',
                    label: 'Entity Legal Name',
                    helperText: 'ex. John Doe and Jane Doe TWROS',
                },
            },
        },
    },
    'Revocable Trust': {
        ...trust,
    },
    'Irrevocable Trust': {
        ...trust,
    },
    'Sole Proprietorship': {
        ...individualOrSoleProp,
        format: formatIndividual,
    },
    'Sole Proprietorship SEP IRA': {
        ...IRA,
    },
    'Sole Proprietorship Simple IRA': {
        ...IRA,
    },
    'Limited Partnership': {
        ...LimitedPartnership,
    },
    'Limited Partnership SEP IRA': {
        ...IRA,
    },
    'Limited Partnership Simple IRA': {
        ...IRA,
    },
    LLC: {
        ...LLC,
    },
    'LLC SEP IRA': {
        ...IRA,
    },
    'LLC Simple IRA': {
        ...IRA,
    },
    Corporation: {
        ...Corporation,
    },
    'Corporation SEP IRA': {
        ...IRA,
    },
    'Corporation Simple IRA': {
        ...IRA,
    },
    Estate: {
        ...Estate,
    },
}

export default typeMap

import React from 'react'
import Mutation from 'delv/Mutation'
import { TextArea } from 'common/FormUI.js'
import Form from 'common/form/Form'
import './AnnotationForm.scss'

const mutations = {
    allocation: `mutation ($annotation: String!, $id:UUID!) {
      createAllocationAnnotation(input: {allocationAnnotation: {allocationId:$id, annotation:$annotation}}) {
        allocationAnnotation {
          id
          annotation
          createdOn
          allocationId
          allocationByAllocationId {
            id
          }
        }
      }
    }`,
    distribution: `mutation ($annotation: String!, $id:UUID!) {
      createDistributionAnnotation(input: {distributionAnnotation: {distributionId: $id, annotation: $annotation}}) {
        distributionAnnotation {
          id
          annotation
          createdOn
          distributionId
          distributionByDistributionId {
            id
          }
        }
      }
    }`,
}

const AnnotationForm = ({
    type,
    popup,
    form: { annotation, validateInput },
    ...props
}) => {
    if (!mutations[type]) {
        throw new Error('Unsupported type supplied to annotation from.')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const submitValue = {
                ...input,
                id: props[`${type}Id`],
            }
            new Mutation({
                mutation: mutations[type],
                onSubmit: () => submitValue,
                onResolve: popup.close,
            }).submit()
        }
    }

    return (
        <div className='annotation-form'>
            <form className='card--padding'>
                <div className='note-form-section'>
                    {annotation.error ? (
                        <div className='error'>{annotation.error}</div>
                    ) : (
                        <div className='card-label'>NOTE</div>
                    )}
                    <TextArea {...annotation} />
                </div>
            </form>
            <div className='price-form-button-container'>
                <div className='price-form-button' onClick={popup.close}>
                    Cancel
                </div>
                <div className='price-form-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </div>
    )
}

const formConfig = {
    fields: {
        annotation: {
            type: 'string',
            error: 'Note is required',
        },
    },
}

export default Form(AnnotationForm, formConfig)

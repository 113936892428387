import React from 'react'

export default (icons, prefix) => icons.map((icon) => {
    if (icon) {
        if (icon instanceof Array) {
            return icon.filter((item) => item)[0] || <div className={`${prefix}__icon-offset`} />
        }
        return icon
    }
    return <div className={`${prefix}__icon-offset`} />
})

import React from 'react'

import './TransferOwnership.scss'

export default ({
    email,
    cancel,
    submit,
}) => {
    const prefix = 'vrtl-transfer-ownership-row'
    return (
        <>
            <h2 className={`${prefix}__header`}>
                Transfer Ownership
            </h2>
            <div className={`${prefix}__container`}>
                <div className={`${prefix}__section--2`}>
                    <div className={`${prefix}__label`}>
                        Email Of Recipient:
                    </div>
                    <div className={`${prefix}__content`}>
                        {email}
                    </div>
                    <div className={`${prefix}__content`}>
                        <div className={`${prefix}__sub-text`}>
                            After the ownership transfer you will be recognized as
                            an account admin.
                            {' '}
                            <br />
                            {' '}
                            You will
                            have to log back in after transfer.
                        </div>
                    </div>
                </div>
                <div className={`${prefix}__icon-offset`} />
                {cancel}
                <div className={`${prefix}__icon-offset`} />
                {submit}
            </div>
        </>
    )
}

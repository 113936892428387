import React from 'react'

import Delv from 'delv/delv-react'
import { Dropdown } from 'common/FormUI.js'
import GenericForm from 'common/form/GenericForm.js'

import Fragment from './Fragment.js'

const typeTypeahead = {
    query: `{
  allOnboardingQuestionTypes{
    nodes{
      id:type
      type
    }
  }
}`,
    cachePolicy: 'query',
    format: ({ allOnboardingQuestionTypes }) => {
        const options = allOnboardingQuestionTypes.nodes.map(({ type }) => ({ displayValue: type, value: type }))
        return { options }
    },
}

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Field name is required',
        },
        type: {
            type: 'dropdown',
            error: 'Field type is required',
            customUI: ({ value }) => (
                <Delv.query {...typeTypeahead}>
                    <Dropdown {...value} />
                </Delv.query>
            ),
        },
        description: {
            type: 'textArea',
            error: 'Description is required',
        },
        errorMessage: {
            type: 'textArea',
            error: 'error message is required',
        },
    },
}

const createQuery = `mutation($question:OnboardingQuestionInput!){
  createOnboardingQuestion(input:{onboardingQuestion:$question}){
    onboardingQuestion{
      ${Fragment.toString()}
    }
  }
}`

const updateQuery = `mutation ($id: UUID!, $patch: OnboardingQuestionPatch!) {
  updateOnboardingQuestionById(input: {id: $id, onboardingQuestionPatch: $patch}) {
    onboardingQuestion {
      ${Fragment.toString()}
    }
  }
}`

export default GenericForm({
    createField: createQuery,
    updateField: updateQuery,
    formConfig,
    prefix: 'vrtl-onboarding-question-row',
    errorMap: {
        'duplicate key value violates unique constraint "unique_name"': 'Questions must have a unique name.',
        "No values were updated in collection 'onboarding_questions' because no values you can update were found matching these criteria.":
            'Questions that are part of a question set cannot be edited.',
    },
    createArgs: (input) => ({ question: input }),
})

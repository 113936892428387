import React from 'react'
import numeral from 'numeral'
import HoverToolTip from 'common/HoverToolTip.js'

import './Number.scss'

const popupContentStyle = {
    width: 'auto',
    backgroundColor: '#5b6269',
    border: '0!important',
    padding: '0!important',
    top: '1rem!important',
}
const popupArrowStyle = {
    backgroundColor: '#5b6269',
}

// i saw this in leftpad so lets be cool and copy it

const formatCache = {
    0: '',
    1: '[0]',
    2: '[00]',
}

export default ({
    value,
    prefix,
    postfix,
    optionalTruncate = 2,
    className,
}) => {
    const truncateString = formatCache[optionalTruncate]
    const displayValue = numeral(value.toString()).format(`0,0.00${truncateString}`)
    const fullValue = numeral(value.toString()).format('0,0.00[0000000000000000]')

    if (displayValue === fullValue) {
        return (
            <div className={className}>
                {prefix}
                {displayValue}
                {postfix}
            </div>
        )
    }

    return (
        <HoverToolTip position='bottom left' arrowStyle={popupArrowStyle} arrow={false} style={popupContentStyle}>
            <div className='vrtl-number__container'>
                {prefix}
                {displayValue}
                ...
                {postfix}
            </div>
            <div className='vrtl-number__tooltip-content'>
                <div>Full value:</div>
                <div>
                    {prefix}
                    {fullValue}
                    {postfix}
                </div>
            </div>
        </HoverToolTip>
    )
}

import * as React from 'react'
import * as DateFns from 'date-fns'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Link } from 'react-router-dom'
import Box from 'common/Box/index.js'
import Format from 'util/format/Format'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import DataGridToolbar from 'common/DataGridToolbar'
import Typography from 'common/Typography'
import { GridNoneFound } from 'common/NoneFound'
import {
    removeReactFromColumns,
    addReactToColumns,
    syncVisibilityModel,
} from 'common/DataGrid/utils/column-utils'
import useQuery from 'delv/hooks/use-query.js'
import useLocalStorage from 'hooks/use-local-storage'
import PageLayout from 'layouts/page-layout'

import {
    CurrencyCell,
    DateCell,
    NumberCell,
    PercentCell,
    TooltipCell,
} from 'common/DataGridCell'
import {
    selectHoldingPurchasePrice,
    selectInvestmentInitial,
    selectAmount,
    selectIsTokenized,
    selectPercentOfSecurityTotal,
} from 'selectors/investment-selectors'

const MaybePercentCell = ({ value }) => (value === undefined ? 'N/A' : PercentCell({ value }))
const MaybeTermCell = ({ value }) => (value === undefined ? 'N/A' : NumberCell({ value }))

// The round id is not used. It ensures that the tranche is in the output.
// Without the round id a null tranche rolls up to a null entry in [nodes].
const HOLDINGS_QUERY = `
{
    allHoldings {
        nodes {
            id
            amount
            purchasePrice
            issuedOn
            holdingBlockchainAddressesByHoldingId{
                nodes{
                    id
                    status
                    blockchainAddressByBlockchainAddressId{
                        id
                        chain
                    }
                }
            }
            accountByInvestorId {
                id
                name
                email
            }
            securityBySecurityId {
                id
                name
                total
                roundsBySecurityId {
                    nodes {
                        id
                        trancheBondDatumByRoundId{
                            id
                            term
                            coupon
                        }
                    }
                }
            }
        }
    }
}`

const BREADCRUMBS = [
    {
        display: 'Dashboard',
        link: '/dashboard',
        active: false,
    },
    {
        display: 'Holdings',
        link: '/holdings',
        active: true,
    },
]

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
    filterable: true,
}

const numberColumnDefaults = {
    ...columnDefaults,
    align: 'right',
}

const initialColumns = [
    {
        ...columnDefaults,
        field: 'investor',
        headerName: 'Investor',
        minWidth: 150,
        renderCell: ({ value }) => (
            <Typography variant='subtitle2'>
                {value}
            </Typography>
        ),
    },
    {
        ...columnDefaults,
        field: 'email',
        headerName: 'Investor Email',
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'securityName',
        headerName: 'Security Name',
        minWidth: 150,
    },
    {
        ...numberColumnDefaults,
        field: 'amount',
        headerName: 'Total Units',
        minWidth: 150,
        valueFormatter: ({ value }) => value.amount,
        renderCell: ({ value }) => (
            <Box className='row' sx={{ gap: 1 }}>
                <Link className='pointer' to={`/holding/${value.id}`}>
                    <Typography variant='body2'>
                        {Format.number(value.amount)}
                    </Typography>
                </Link>
            </Box>
        ),
    },
    {
        ...numberColumnDefaults,
        field: 'percentOfSecurityTotal',
        headerName: 'Percent Of Raise',
        minWidth: 150,
        renderCell: MaybePercentCell,
        renderHeader: (props) => (
            <TooltipCell tooltip="Calculated by dividing the holding's quantity of shares by the security's authorized total." {...props} />
        ),
    },
    {
        ...numberColumnDefaults,
        field: 'costBasis',
        headerName: 'Cost Basis',
        minWidth: 150,
        renderCell: CurrencyCell,
        renderHeader: (props) => <TooltipCell tooltip='Price paid per share' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'isTokenized',
        headerName: 'Tokenized',
        type: 'boolean',
        minWidth: 75,
    },
    {
        ...numberColumnDefaults,
        field: 'term',
        headerName: 'Term',
        renderCell: MaybeTermCell,
        renderHeader: (props) => <TooltipCell tooltip='Years to maturity' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'yield',
        headerName: 'Yield',
        renderCell: MaybePercentCell,
    },
    {
        ...numberColumnDefaults,
        field: 'totalValue',
        headerName: 'Total Value',
        renderCell: CurrencyCell,
        renderHeader: (props) => <TooltipCell tooltip='Quantity of shares * share price' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'issuedOn',
        headerName: 'Issued On',
        renderCell: DateCell,
    },
]

const format = (data) => {
    const allHoldings = data.allHoldings.nodes

    const rows = allHoldings.map((investment) => {
        const security = investment.securityBySecurityId
        const investor = investment.accountByInvestorId.name
        const email = investment.accountByInvestorId.email
        const tranche = security.roundsBySecurityId.nodes[0].trancheBondDatumByRoundId || { }
        const percentOfSecurityTotal = selectPercentOfSecurityTotal({ investment })
        const isTokenized = selectIsTokenized({ investment })
        const costBasis = selectHoldingPurchasePrice({ investment })
        const totalValue = selectInvestmentInitial({ investment })
        const issuedOn = investment.issuedOn
        const amount = {
            amount: selectAmount({ investment }),
            id: investment.id,
        }

        return {
            id: investment.id,
            investor,
            email,
            securityName: security.name,
            amount,
            isTokenized,
            costBasis,
            issuedOn,
            term: tranche.term,
            yield: tranche.coupon,
            totalValue,
            percentOfSecurityTotal,
        }
    })
    return { rows }
}

const initialColumnVisibilityModel = {
    term: false,
    yield: false,
    isTokenized: false,
}

const Holdings = () => {
    const { data, loading } = useQuery(HOLDINGS_QUERY, { format })
    const [density, setDensity] = useLocalStorage('row-density', 'compact')
    const [columns, setColumns] = useLocalStorage('holdings-columns', initialColumns, {
        serialize: removeReactFromColumns,
        deserialize: addReactToColumns(initialColumns),
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage('holdings-column-visibility', initialColumnVisibilityModel, {
        deserialize: syncVisibilityModel(initialColumnVisibilityModel),
    })

    const rows = data?.rows || []

    const resetGrid = () => {
        setColumns(initialColumns)
        setColumnVisibilityModel(initialColumnVisibilityModel)
    }

    return (
        <PageLayout breadcrumbs={BREADCRUMBS}>
            <DataGridPro
                disableColumnResize={false}
                disableColumnPinning
                onColumnOrderChange={(e) => {
                    const target = e.targetIndex
                    const old = e.oldIndex
                    const newColumns = [...columns]
                    newColumns[target] = columns[old]
                    newColumns[old] = columns[target]
                    setColumns(newColumns)
                }}
                rows={rows}
                columns={columns}
                loading={loading}
                rowThreshold={0}
                density={density}
                onColumnWidthChange={(e) => {
                    const newColumns = [...columns].map((col) => (
                        col.field === e.colDef.field
                            ? ({
                                ...col,
                                flex: undefined,
                                width: e.colDef.width,
                            }) : col
                    ))
                    setColumns(newColumns)
                }}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onStateChange={(params) => {
                    if (params.density.value !== density) {
                        setDensity(params.density.value)
                    }
                }}
                sx={{
                    border: 'none',
                    backgroundColor: '#ffffff',
                    '& .Mui-selected': {
                        background: 'transparent !important',
                    },
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                    Footer: DataGridFooter,
                    NoRowsOverlay: GridNoneFound,
                    Toolbar: DataGridToolbar,
                }}
                componentsProps={{
                    noRowsOverlay: {
                        children: 'No Holdings Found',
                    },
                    toolbar: {
                        resetGrid,
                        filter: true,
                        export: true,
                        printOptions: {
                            disableToolbarButton: true,
                        },
                        csvOptions: {
                            fileName: `holdings_vertalo_${DateFns.format(new Date(), 'yyyy_MM_dd')}`,
                        },
                    },
                    footer: {
                        rows,
                        totalCount: rows.length,
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
            />
        </PageLayout>
    )
}

export default Holdings

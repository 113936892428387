const localStorage = {
    getItem: (key) => window.localStorage.getItem(key),
    removeItem: (key) => window.localStorage.removeItem(key),
    clear: () => window.localStorage.clear(),
    setItem: (key, value) => {
        try {
            window.localStorage.setItem(key, value)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Ran out of localStorage memory whens setting ${key}`)
            localStorage.clear()
            window.localStorage.setItem(key, value)
        }
    },
}

export default localStorage

import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'
import HelpIcon from '@material-ui/icons/Help'

export default ({
    children,
    className,
    ...props
}) => {
    const prefix = 'vrtl-allocation-link'
    return (
        <div className={`${prefix}__heading-container ${prefix}__heading-margin`}>
            <div className={`${prefix}__section--3`}>Link Name</div>
            <div className={`${prefix}__section--4`}>
                Agent
            </div>
            <div className={`${prefix}__section--2`}>
                Unauthorized Link
                <HoverToolTip>
                    <HelpIcon
                        className='field-edit-icon'
                    />
                    <div>
                        Requires the user to login before viewing, usable for email marketing or web pages outside Vertalo.
                    </div>
                </HoverToolTip>
            </div>
            <div className={`${prefix}__section--2`}>
                Authorized Link
                <HoverToolTip>
                    <HelpIcon
                        className='field-edit-icon'
                    />
                    <div>
                        Assumes the user is already logged in, usable for web pages hosted on Vertalo.
                    </div>
                </HoverToolTip>
            </div>
            <div className={`${prefix}__icon-offset`} />
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </div>
    )
}

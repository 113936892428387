import React from 'react'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'

import SubNavLayout from 'layouts/sub-nav-layout.js'
import { qualificationAnswersBreadcrumbs, selectSettingsTabs } from 'constants/settings'
import Typography from 'common/Typography'
import Box from 'common/Box'
import Grid from 'common/Grid'
import Card from 'common/Card/index.js'
import Table from 'common/Table'
import TableBody from 'common/TableBody'
import TableCell from 'common/TableCell'
import CircularProgress from 'common/CircularProgress'
import TableHead from 'common/TableHead'
import TableRow from 'common/TableRow'
import useMediaQuery from 'hooks/use-media-query'
import useQuery from 'delv/hooks/use-query'

import InstancedAccountInformation from 'components/instancedAccountInformation/index.js'
import Account from 'components/account/index.js'

const QualificationAnswersQuery = `query {
    allAccounts(filter: { type: { notEqualTo: "investor" } }) {
        nodes {
            ${Account.fragment.toString()}
            instancedAccountInformationsByVendorAccountId {
                nodes {
                    ${InstancedAccountInformation.fragment.toString()}
                }
            }
        }
    }
}`

const formatAccountQualificationAnswers = (res) => res?.allAccounts.nodes
    .filter((account) => (
        account.instancedAccountInformationsByVendorAccountId.nodes.length
    ))
    .map((account) => ({
        id: account.id,
        name: account.name,
        questionSets: [
            ...account.instancedAccountInformationsByVendorAccountId.nodes,
        ],
    }))

const selectRowValueDisplay = (value) => {
    if (!value) {
        return null
    }

    if (new Date(value).toString() !== 'Invalid Date') {
        return format(new Date(value), 'MM/dd/yyyy')
    }

    return value
}

const QualificationsAnswersSettings = ({ account }) => {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('md'))
    const { loading, data } = useQuery(QualificationAnswersQuery, {
        format: formatAccountQualificationAnswers,
    })
    const tabs = selectSettingsTabs(account.type)

    return (
        <SubNavLayout breadcrumbs={qualificationAnswersBreadcrumbs} tabs={tabs}>
            <Box sx={{ background: 'white', px: isSm ? 0 : 4, py: 2 }}>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            minHeight: '25vh',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography variant='h6' sx={{ mb: 2 }}>
                            {
                                data.length ? (
                                    'Your most recent answers to qualification question sets will be auto-filled in subsequent qualification rounds.'
                                ) : (
                                    'You have no completed qualification question sets.'
                                )
                            }
                        </Typography>
                        <Grid container spacing={isSm ? 2 : 4}>
                            {data.map((acc) => (
                                <Grid item xs={12} sm={6} lg={4} xl={3} key={acc.id}>
                                    <Card elevation={2} sx={{ boxSizing: 'border-box', height: '100%', p: 2 }}>
                                        <Typography variant='subtitle2' as='strong' sx={{ mb: 2 }}>{acc.name}</Typography>
                                        <Table aria-label='simple table' size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ pl: 0 }}>Question</TableCell>
                                                    <TableCell sx={{ pl: 0 }}>Answer</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    acc.questionSets.map((row) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                                                        >
                                                            <TableCell sx={{ pl: 0, verticalAlign: 'top' }}>{row.field}</TableCell>
                                                            <TableCell sx={{
                                                                pl: 0, verticalAlign: 'top', overflowWrap: 'break-word', wordBreak: 'break-word',
                                                            }}
                                                            >
                                                                {selectRowValueDisplay(row.value)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Box>
        </SubNavLayout>
    )
}

export default QualificationsAnswersSettings

import React from 'react'

import { Link } from 'react-router-dom'
import Format from 'util/format/Format.js'
import Edit from '@material-ui/icons/Edit'
import IconHelper from 'common/IconHelper.js'
import OpenInNew from '@material-ui/icons/OpenInNew'

export default ({
    id,
    amount,
    contactName,
    edit,
    cancel,
    submit,
    offset = '',
}) => {
    const prefix = 'vrtl-holding-card'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--3`}>
                {offset}
                {contactName}
            </div>
            <div className={`${prefix}__section--2`}>{typeof amount === 'string' ? Format.number(amount) : amount}</div>
            {
                IconHelper([
                    [cancel, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />],
                    false,
                    [submit, <Link to={`/asset-class/${id}`}><OpenInNew className={`${prefix}__icon-open-in-new`} /></Link>],
                ], prefix)
            }
            <div className={`${prefix}__icon-offset`} />

        </div>
    )
}

import * as React from 'react'

import Typography from 'common/Typography'
import Format from 'util/format/Format'

const NumberCell = ({ value }) => (
    <Typography variant='body2'>
        {Format.number(value)}
    </Typography>
)

export default NumberCell

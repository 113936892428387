import React, { useState } from 'react'
import stringTranformers from 'util/stringTranformers.js'
import GridChildProvider from 'common/grid/GridChildProvider.js'
import { CheckBox } from 'common/FormUI.js'
import Delv from 'delv/delv-react.js'
import Div from 'common/Div.js'
import Document from 'components/document/index.js'
import ModalUpload from 'common/ModalUpload/index.js'

const ShareWithOptions = ({
    allowedShare,
    prefix = 'vrtl-document-upload-form',
    multiSelect,
}) => {
    const [isSelected, setSelected] = useState(false)
    const onClick = () => {
        multiSelect.select({ value: allowedShare.toRole, id: allowedShare.name })
        setSelected(!isSelected)
    }
    return (
        <div className={`${prefix}__container--check-box`}>
            <CheckBox onClick={onClick} value={isSelected} className={`${prefix}__icon-checkbox`} />
            <div className={`${prefix}__text capitalize`}>{allowedShare.name}</div>
        </div>
    )
}

const DocumentUpload = ({
    prefix = 'vrtl-document-upload-form',
    docType,
    handleSubmit,
    popup,
    ...props
}) => {
    const currentAccount = props.account.type

    const shareWithQuery = {
        query: `{
            allDocumentSharingAllowedShares(condition:{type: "${docType}", fromAccountType: "${currentAccount}"}){
              nodes{
                    ${Document.allowedShares.fragment.toString()}
                }
            }
          }`,
        format: ({ allDocumentSharingAllowedShares: { nodes } }) => ({ allowedShares: nodes.map((allowedShare) => ({ allowedShare })) }),
    }

    const allowedExtensions = ['.csv', '.tsv', '.pdf', '.xls', '.txt']
    const upperDocType = stringTranformers.capitalize(docType)

    const instructions = (
        <>
            <Div className={`${prefix}__text`}>
                Upload this document to your account and select additional accounts to share with.
            </Div>
            <Delv.query {...shareWithQuery}>
                <GridChildProvider itemsPer={2} arrayKey='allowedShares'>
                    <ShareWithOptions multiSelect={props.multiSelect} />
                </GridChildProvider>
            </Delv.query>
        </>
    )

    const heading = `${upperDocType} Document Upload`

    return (
        <ModalUpload
            instructions={instructions}
            extensions={allowedExtensions.join(',')}
            onClick={(file) => handleSubmit(file, props.multiSelect)}
            heading={heading}
            urlType='signedUrl'
            icon
            {...props}
        />
    )
}

export default DocumentUpload

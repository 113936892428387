import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import Security from 'components/security/index.js'
import Round from 'components/round/index.js'
import Asset from 'components/asset/index.js'
import Holding from 'components/holding/index.js'
import Div from 'common/Div.js'
import Document from 'components/document/index.js'
import Account from 'components/account/index.js'
import HoldingDocument from 'components/holdingDocument/index.js'
import NewEditSwitch from 'common/NewEditSwitch.js'
import Map from 'common/Map.js'
import Delv from 'delv/delv-react'
import NoneFound from 'common/NoneFound/index.js'
import { canEditDefault } from 'util/permissions.js'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

const HoldingQuery = {
    query: (id) => `{
        holdingById(id: "${id}") {
            ${Holding.fragment.toString()}
            holdingDocumentsByHoldingId{
                nodes{
                    ${HoldingDocument.fragment.toString()}
                    documentByDocumentId{
                        ${Document.fragment.toString()}
                        documentSharingsByDocumentId {
                            nodes{
                                ${Document.sharing.fragment.toString()}
                            }
                        }
                    }
                }
            }
            accountByInvestorId{
                ${Account.fragment.toString()}
            }
          securityBySecurityId {
                ${Security.fragment.toString()}
            roundsBySecurityId {
              nodes {
                ${Round.fragment.toString()}
                trancheBondDatumByRoundId{
                    ${Round.tranche.fragment.toString()}
                  }
                assetByAssetId {
                    ${Asset.fragment.toString()}
                  accountByIssuerId {
                      ${Account.fragment.toString()}
                  }
                }
              }
            }
          }
        }
      }`,
    args: (props) => props.match.params.holding_id,
    format: ({
        holdingById: {
            id,
            purchasePrice,
            amount,
            accountByInvestorId: {
                name: investorName,
            },
            securityBySecurityId: {
                unitValue,
                roundsBySecurityId: {
                    nodes,
                },
            },
            holdingDocumentsByHoldingId,
        },
    }) => {
        holdingDocumentsByHoldingId.nodes = holdingDocumentsByHoldingId.nodes.map((doc) => ({
            ...doc,
            name: doc.documentByDocumentId.name,
            createdOn: doc.documentByDocumentId.createdOn,
            status: doc.documentByDocumentId.uploadStatus,
            sharedWith: doc.documentByDocumentId.documentSharingsByDocumentId,
        }))

        return (
            {
                id,
                amount,
                unitValue,
                investorName,
                purchasePrice,
                termsUrl: nodes[0].termsUrl,
                assetName: nodes[0].assetByAssetId.name,
                tranche: nodes[0].trancheBondDatumByRoundId,
                issuerName: nodes[0].assetByAssetId.accountByIssuerId.name,
                round: {
                    roundName: nodes[0].name,
                    id: nodes[0].id,
                },
                orderByData: {
                    holdingDocumentsByHoldingId,
                },
            }
        )
    },
}

const HoldingPage = ({
    prefix = 'vrtl-holding-page',
    ...props
}) => {
    const roundName = props.round.roundName
    const assetName = props.assetName
    const roundId = props.round.id

    const isIssuer = props.account.type === 'issuer'
    const BREADCRUMBS = props.account.type === 'investor'
        ? [
            { display: 'Portfolio', link: '/portfolio' },
            { display: roundName, link: '/portfolio' },
            { display: 'Holding', link: '#', active: true },
        ]
        : [
            { display: 'Assets', link: '/assets' },
            { display: assetName, link: '/assets' },
            { display: roundName, link: `/asset-class/${roundId}/manage` },
            { display: 'Holding', link: '#', active: true },
        ]

    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                <div className={`${prefix}__container`}>
                    <div className={`${prefix}__card`}>
                        <Holding.card {...props} />
                    </div>
                    <div className='column'>
                        <Div className={`${prefix}__table`}>
                            <OrderBy.order
                                orderByData={props.orderByData.holdingDocumentsByHoldingId.nodes}
                            >
                                <Document.orderByheader sharedVia={!isIssuer}>
                                    { isIssuer ? <HoldingDocument.form {...props} /> : <div className={`${prefix}__icon-offset`} /> }
                                </Document.orderByheader>
                                <Map
                                    arrayKey='_orderBy.data'
                                    noneFound={<NoneFound>No documents found</NoneFound>}
                                    {...props}
                                >
                                    <NewEditSwitch.switch
                                        editIcon
                                        canEdit={(properties) => canEditDefault(properties)}
                                        className='no-margin'
                                    >
                                        <HoldingDocument.row />
                                        <HoldingDocument.form />
                                    </NewEditSwitch.switch>
                                </Map>
                            </OrderBy.order>
                        </Div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default Delv.queryHOC(HoldingPage, HoldingQuery)

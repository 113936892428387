import React from 'react'
import Popup from 'reactjs-popup'
import SafeReact from 'util/SafeReact.js'

const HoverToolTip = ({
    arrowStyle,
    className,
    style,
    arrow = true,
    width = '10rem',
    position = 'bottom center',
    children,
}) => {
    const trigger = children[0]
    return (
        <Popup
            on='hover'
            arrowStyle={arrowStyle}
            className={className}
            trigger={trigger}
            contentStyle={{ width, ...style }}
            position={position}
            mouseEnterDelay={800}
            arrow={arrow}
        >
            {SafeReact.cloneChildren(children[1])}
        </Popup>
    )
}

export default HoverToolTip

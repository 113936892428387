import React from 'react'

import Format from 'util/format/Format.js'
import Edit from '@material-ui/icons/Edit'
import IconHelper from 'common/IconHelper.js'

export default ({
    amount,
    paidOn,
    status,
    edit,
    submit,
    cancel,
    remove,
}) => {
    const prefix = 'vrtl-payment'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--2`}>{Format.ifString(Format.number, amount)}</div>
            <div className={`${prefix}__section--2`}>{status}</div>
            <div className={`${prefix}__section--3`}>{Format.ifString(Format.date, paidOn, 'MMM DD YYYY')}</div>

            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

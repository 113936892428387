import React, { useState } from 'react'

import CreateIcon from '@material-ui/icons/Create'
import SafeReact from 'util/SafeReact.js'

const EditSwitch = ({
    children,
    canEdit,
    ...props
}) => {
    const [edit, setEdit] = useState(false)
    const [display, form] = children

    const allowEdit = canEdit instanceof Function ? canEdit(props) : canEdit

    if (allowEdit) {
        if (edit) {
            return SafeReact.cloneChildren(form, {
                ...props,
                onResolve: () => setEdit(false),
                cancel: (
                    <div
                        className='styled-button'
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </div>
                ),
                submit: <div className='styled-button'>Finish</div>,
            })
        }

        return SafeReact.cloneChildren(display, {
            ...props,
            edit: (
                <div className='clickable'>
                    <CreateIcon
                        className='action-icon'
                        onClick={() => setEdit(true)}
                    />
                </div>
            ),
        })
    }

    return SafeReact.cloneChildren(display, props)
}

export default EditSwitch

export default `id
name
mapTo
mappingRoleByMapTo{
    id
    description
}
toEmail
toName
type`

import * as React from 'react'

import Box from 'common/Box'
import DelvWebSocket from 'delv/websocket'
import useStepper from 'hooks/UseStepper'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import StepBody from './step-body.js'
import StepNone from './step-none.js'
import StepsStepper from './stepper.js'

const Steps = ({ proc, level }) => {
    const steps = proc.shortSteps.nodes
        .sort((a, b) => a.order - b.order)
        .map((step) => (step.id === proc.currentStep?.id ? {
            ...step,
            ...proc.currentStep,
        } : step))
    const currentStep = {
        ...steps.find((step) => step.id === proc.currentStep?.id),
        ...proc.currentStep || { status: 'completed' },
    }
    const index = currentStep.order ? currentStep.order - 1 : steps.length - 1

    const { activeStepIndex, setActiveStep } = useStepper({ length: steps.length, index })

    React.useEffect(() => {
        setActiveStep(index)
    }, [proc.currentStep])

    if (proc?.currentStep) {
        DelvWebSocket.connect()
        DelvWebSocket.subscribe({ type: 'steps', ids: [currentStep?.id], queryId: proc.id })
    }

    if (steps.length === 0) {
        return <StepNone currentStep={currentStep} />
    }

    const activeStep = steps[activeStepIndex]
    const isCurrentStep = activeStep.id === currentStep?.id

    const getKey = (step) => step.id
    const getLabel = (step) => step.renderAttributes?.progressBarTitle
    const getCompleted = (step) => step?.status === 'complete'
    const goToCurrentStep = () => setActiveStep(index)

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const orientation = isSmall ? 'vertical' : 'horizontal'
    return (
        <Box sx={{ width: '100%', overflowX: steps.length > 7 ? 'scroll' : 'inherit' }}>
            <StepsStepper
                steps={steps}
                activeStep={activeStepIndex}
                setActiveStep={setActiveStep}
                getLabel={getLabel}
                getKey={getKey}
                getCompleted={getCompleted}
                orientation={orientation}
            />
            <StepBody
                activeStep={activeStep}
                currentStep={currentStep}
                goToCurrentStep={goToCurrentStep}
                id={proc.id}
                isCurrentStep={isCurrentStep}
                level={level}
            />
        </Box>
    )
}

export default Steps

import React from 'react'
import { Link } from 'react-router-dom'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Format from 'util/format/Format.js'
import NumberUI from 'common/NumberUI.js'
import { Decimal } from 'decimal.js'
import { isIssuer } from 'util/permissions.js'

export default ({
    prefix = 'vrtl-security-row',
    id,
    endSlot,
    startSlot,
    unitValue,
    total,
    name,
    ticker,
    roundsBySecurityId,
    entityPage,
    accountByIssuerId,
    ...props
}) => {
    const latestValue = Format.number(new Decimal(unitValue).mul(total))
    const assetName = roundsBySecurityId?.nodes[0]?.assetByAssetId.name
    const assetType = roundsBySecurityId?.nodes[0]?.assetByAssetId.type
    const isAnIssuer = isIssuer(props)
    const determineLinkDisplay = () => {
        if (entityPage) {
            if (isAnIssuer) {
                return (
                    <Link className={`${prefix}__section--2-block`} to={`/asset-class/${roundsBySecurityId.nodes[0].id}/manage`}>
                        <OpenInNew className={`${prefix}__icon-open-in-new ${prefix}__section--2`} />
                    </Link>
                )
            }
            return <div className={`${prefix}__icon-offset`} />
        }
        return (
            <Link className={`${prefix}--flex-end`} to={`/security/${id}`}>
                <OpenInNew className={`${prefix}__icon-open-in-new`} />
            </Link>
        )
    }

    return (
        <div className={`${prefix}__container--row`}>
            {startSlot}
            <div className={`${prefix}__section--3`}>{name}</div>
            <div className={`${prefix}__section--2`}>{ticker || 'N/A'}</div>
            <div className={`${prefix}__section--2`}><NumberUI prefix='$' value={latestValue} /></div>
            <div className={`${prefix}__section--2`}><NumberUI prefix='$' value={unitValue} /></div>
            <div className={`${prefix}__section--2`}>{Format.number(total)}</div>
            {
                isAnIssuer ? (
                    <>
                        <div className={`${prefix}__section--2`}>{assetName}</div>
                        <div className={`${prefix}__section--2`}>{assetType}</div>
                    </>
                ) : (
                    <div className={`${prefix}__section--2`}>{accountByIssuerId.name}</div>
                )
            }
            {determineLinkDisplay()}
            {endSlot}
        </div>
    )
}

import React from 'react'
import Cancel from '@material-ui/icons/Cancel'
import AddCircle from '@material-ui/icons/CheckCircle'

import Form from 'common/form/Form'
import { Input, Dropdown } from 'common/FormUI.js'
import Mutation from 'delv/Mutation'
import Fragment from './Fragment.js'

const CREATE_MAPPED_TEMPLATE = `
mutation($templateId:UUID!, $name:String!){
  createMappedSigningTemplate(input:{mappedSigningTemplate:{signingTemplateId:$templateId, name:$name}}){
    mappedSigningTemplate{
      ${Fragment}
    }
  }
}`

const UPDATE_MAPPED_TEMPLATE = `mutation ($id: UUID!, $patch: MappedSigningTemplatePatch!) {
  updateMappedSigningTemplateById(input: {id: $id, mappedSigningTemplatePatch: $patch}) {
    mappedSigningTemplate{
      id
      locked
      active
    }
  }
}`

const MappedSigningTemplateForm = ({
    id,
    locked,
    name,
    Display,
    signingTemplateId,
    resolve,
    form: {
        getChanged,
        validateInput,
        setLoading,
        loading,
        error: {
            onError,
            error,
        },
        ...form
    },
    ...props
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    new Mutation({
                        mutation: UPDATE_MAPPED_TEMPLATE,
                        onSubmit: () => {
                            setLoading(true)
                            return {
                                id,
                                patch: changedInput,
                            }
                        },
                        onResolve: resolve,
                        onError,
                    }).submit()
                } else {
                    resolve()
                }
            } else {
                const submitValue = {
                    templateId: signingTemplateId,
                    name: input.name,
                }
                new Mutation({
                    mutation: CREATE_MAPPED_TEMPLATE,
                    onSubmit: () => submitValue,
                    onResolve: resolve,
                }).submit()
                setLoading(true)
            }
        }
    }

    const prefix = 'vrtl-mapped-template'
    const displayProps = {
        ...props,
        id,
        name: !id ? <Input className='relative' {...form.name} /> : name,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <AddCircle className={`${prefix}__icon-add`} onClick={handleSubmit} />,
        error: <div className='absolute-error'>{error}</div>,
    }
    if (!id) {
        displayProps.active = false
        displayProps.locked = false
    } else if (locked) { // locked updates template
        if (!loading) {
            const activeOptions = [{
                active: 'Active',
                value: true,
            }, {
                active: 'Not active',
                value: false,
            }]
            displayProps.active = <Dropdown {...form.active} options={activeOptions} />
            displayProps.locked = true
        } else {
            displayProps.active = 'Updating'
            displayProps.locked = true
        }
    } else if (!loading) {
        const lockedOptions = [{
            locked: 'Locked',
            value: true,
        }, {
            locked: 'Not locked',
            value: false,
        }]
        displayProps.active = false
        displayProps.locked = <Dropdown {...form.locked} options={lockedOptions} />
    } else {
        displayProps.active = false
        displayProps.locked = 'Updating'
    }
    return React.cloneElement(Display, displayProps)
}

export default ({ id, ...props }) => {
    const createConfig = {
        fields: {
            name: {
                type: 'string',
                error: 'Template name is required.',
            },
        },
    }

    const updateConfig = {
        fields: {
            locked: {
                named: 'locked',
                type: 'dropdown',
            },
            active: {
                named: 'active',
                type: 'dropdown',
            },
        },
    }
    const FormComponent = Form(MappedSigningTemplateForm, id ? updateConfig : createConfig)

    return <FormComponent {...{ id, ...props }} />
}

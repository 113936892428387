import React, { useState } from 'react'

import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation'
import Card from 'common/Card.js'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'
import Format from 'util/format/Format.js'
import Loading from 'common/loading/index.js'
import Template from './template/index.js'
import './Templates.scss'

const GET_SIGNING_TEMPALTES = {
    query: `{
  allSigningTemplates{
    nodes{
      ${Template.fragment}
    }
  }
}`,
    format: (data) => ({
        orderByData: data.allSigningTemplates.nodes,
    }),
}

const Right = ({ match }) => (
    <div className='vrtl-signing-templates__container--templates'>
        <div className='gray-header'>Templates</div>
        <Delv.query {...GET_SIGNING_TEMPALTES}>
            <OrderBy.order>
                <Template.orderByHeader />
                <Div className='buyers-container'>
                    <Div className='order-by-inner'>
                        <Map arrayKey='_orderBy.data'>
                            <Template.row match={match} />
                        </Map>
                    </Div>
                </Div>
            </OrderBy.order>
        </Delv.query>
    </div>
)

const SYNC_TEMPLATES = `mutation ($id: UUID!) {
  docusignSyncTemplateList(id: $id) {
    id
    syncedOn
    signingTemplatesBySigningAccountId {
      nodes {
        ${Template.fragment}
      }
    }
  }
}`

const Left = ({
    allSigningAccounts: {
        nodes: [{
            id,
            name,
            syncedOn,
        }],
    },
}) => {
    const [state, setState] = useState('sync')
    const handleSubmit = (event) => {
        event.preventDefault()
        new Mutation({
            mutation: SYNC_TEMPLATES,
            onSubmit: () => {
                setState('loading')
                return {
                    id,
                }
            },
            onResolve: () => setState('sync'),
            onError: () => setState('error'),
        }).submit()
    }
    return (
        <div className='vrtl-signing-left__section'>
            <div className='vrtl-signing__header'>Account</div>
            <div className='error'>{state === 'error' && 'Error loading templates, please try agian.'}</div>
            <Card.section>
                <Card.label>Account name</Card.label>
                {name}
            </Card.section>
            <Card.section>
                <Card.label>Last sync</Card.label>
                {syncedOn ? Format.date(syncedOn) : 'Not synced yet'}
            </Card.section>
            {
                state === 'loading'
                    ? <Loading.dots />
                    : <div className='vrtl-step-card--action-btn vrtl-signing__button' onClick={handleSubmit}>Sync template list</div>
            }
        </div>
    )
}

Left.delvConfig = {
    query: `{
  allSigningAccounts{
    nodes{
      id
      name
      syncedOn
    }
  }
}`,
}

export default {
    right: Right,
    left: Delv.queryHOC(Left, Left.delvConfig),
}

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
uploadStatus
downloadUrl
extension
name
createdOn
type
uploadedBy
`)

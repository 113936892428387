import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from 'common/Box'
import Tab from 'common/Tab'

const TabLinkDisplay = React.forwardRef((props, ref) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flex: 1,
            cursor: 'pointer',
        }}
    >
        <RouterLink style={{ width: '100%' }} {...props} ref={ref} />
    </Box>
))

const TabLink = ({ href, ...props }) => (
    <Tab component={TabLinkDisplay} onClick={(e) => e.preventDefault()} to={href} {...props} />
)

export default TabLink

import { useState } from 'react'

const useViewSwitch = () => {
    const [history, setHistory] = useState([0])
    const view = history[0]

    const next = () => setHistory([view + 1, ...history])

    const back = () => setHistory(history.slice(1, history.length))

    const setUI = (UI) => setHistory([UI, ...history])

    return {
        next,
        back,
        setUI,
        view,
    }
}

export default useViewSwitch

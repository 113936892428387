import React from 'react'

import GenericForm from 'common/form/GenericForm.js'
import VendorQualification from 'components/vendorQualification/index.js'
import { Dropdown } from 'common/FormUI.js'

import Fragment from './Fragment.js'

const createQuery = () => `mutation($questionSet:OnboardingQuestionSetInput!){
  createOnboardingQuestionSet(input:{onboardingQuestionSet:$questionSet}){
    onboardingQuestionSet{
      ${Fragment.toString()}
      vendorQualificationByVendorQualificationId{
        ${VendorQualification.fragment.toString()}
      }
    }
  }
}`

const updateQuery = () => `mutation($id:UUID!, $patch:OnboardingQuestionSetPatch!){
  updateOnboardingQuestionSetById(input:{id:$id, onboardingQuestionSetPatch:$patch}){
    onboardingQuestionSet{
       ${Fragment.toString()}
       vendorQualificationByVendorQualificationId{
        ${VendorQualification.fragment.toString()}
      }
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Please provide a unique question set name',
            skipRenderOn: ({ props }) => props.locked,
        },
        active: {
            type: 'dropdown',
            skipRenderOn: ({ type, props }) => !props.locked || type === 'create',
            customUI: ({ value }) => {
                const options = [
                    { displayValue: 'Active', value: true },
                    { displayValue: 'Not Active', value: false },
                ]
                return <Dropdown {...value} options={options} />
            },
        },
        locked: {
            type: 'dropdown',
            customUI: ({ value, props, type }) => {
                if (type === 'create') {
                    return <div />
                }
                if (props.locked) {
                    return <div>Locked</div>
                }
                const options = [
                    { displayValue: 'Locked', value: true },
                    { displayValue: 'Not Locked', value: false },
                ]
                return <Dropdown {...value} options={options} />
            },
        },
    },
}

export { formConfig }

export default GenericForm({
    createField: createQuery,
    updateField: updateQuery,
    prefix: 'vrtl-onboarding-question-set-row',
    formConfig,
    errorMap: {
        'duplicate key value violates unique constraint "unique_question_set_name"': 'Question set names must be unique.',
    },
    createArgs: (input) => ({ questionSet: { name: input.name } }),
})

import React from 'react'

export default ({
    children,
    className,
    ...props
}) => {
    const prefix = 'vrtl-onboarding-question-join-row'
    return (
        <div className={`${prefix}__heading-container ${prefix}__heading-margin`}>
            <div className={`${prefix}__section`}>Name</div>
            <div className={`${prefix}__section`}>Order</div>
            <div className={`${prefix}__icon-offset`} />
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </div>
    )
}

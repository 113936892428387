import React from 'react'
import SafeReact from 'util/SafeReact.js'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Template from './Template.js'

import './styles.scss'

const Form = ({
    children,
    prefix = 'vrtl-poi-form',
    ...props
}) => {
    const multiSelect = props.multiSelect
    const selected = multiSelect.getValues()
    const investor = selected[0]
    const base64UUID = investor ? Buffer.from(investor.id).toString('base64').slice(41, 47) : null
    const name = investor?.contactName.split(' ').join('_')

    return (
        <div>
            <h2 className='header'>
                Investors
            </h2>
            {SafeReact.cloneChildren(children, { ...props })}
            <div className={`${prefix}__container--modal`}>
                <div className={`${prefix}__button--basic`} onClick={props.popup.close}>
                    Cancel
                </div>
                {
                    selected.length === 1
                        ? (
                            <PDFDownloadLink
                                document={<Template {...investor} />}
                                fileName={`${name}_proof_of_issuance_${base64UUID}.pdf`}
                                style={{ color: 'black' }}
                            >
                                {({
                                    blob, url, loading, error,
                                    // eslint-disable-next-line
                                }) => console.error(blob, url, loading, error)}
                                <div className={`${prefix}__button--submit`}>
                                    Download
                                </div>
                            </PDFDownloadLink>
                        ) : (
                            <div className={`${prefix}__button--submit`}>
                                Download
                            </div>
                        )
                }
            </div>

        </div>

    )
}

export default Form

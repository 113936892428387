import * as React from 'react'
import PhoneInput from 'react-phone-number-input'

import TextField from 'common/TextField'

import 'react-phone-number-input/style.css'

const MuiTextField = React.forwardRef((props, ref) => (
    <TextField inputRef={ref} {...props} />
))

const InputPhone = ({
    onBlur,
    onChange,
    name,
    ...props
}) => {
    const handleChange = (e) => {
        onChange({
            target: {
                value: e,
                name,
            },
        })
    }

    return (
        <PhoneInput
            defaultCountry='US'
            international
            withCountryCallingCode
            placeholder='Enter phone number'
            inputComponent={MuiTextField}
            onChange={handleChange}
            name={name}
            {...props}
        />
    )
}

export default InputPhone

import * as React from 'react'
import { grey } from '@mui/material/colors'
import Inbox from '@mui/icons-material/Inbox'

import Box from 'common/Box'
import Typography from 'common/Typography'
import { isSm } from 'constants/media-queries'

const GridNoneFound = ({ children = 'None Found.' }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                [isSm]: {
                    flexDirection: 'row',
                },
            }}
        >
            <Inbox data-testid='inbox-icon' sx={{ fontSize: 32, color: grey[600], [isSm]: { fontSize: 18 } }} />
            <Typography as='strong' color={grey[600]} sx={{ display: 'none', [isSm]: { display: 'flex' } }} variant='subtitle2'>
                {children}
            </Typography>
            <Typography as='strong' color={grey[600]} sx={{ display: 'flex', [isSm]: { display: 'none' } }} variant='h6'>
                {children}
            </Typography>
        </Box>
    </Box>
)

export default GridNoneFound

import React from 'react'

import Delv from 'delv/delv-react'
import Process from 'process/Process.js'

import Card from 'common/Card.js'
import Div from 'common/Div.js'
import Popup from 'common/Popup.js'

import MappedTemplate from './mappedTemplate/index.js'
import SigningRole from './signingRole/index.js'

const FIX_THIS_REFETCH_FEATURE = (id) => `{
    allSigningDocuments(condition:{mappedSigningTemplateId:"${id}"}){
      nodes{
        id
        name
        mappedSigningTemplateId
      }
    }
    ${Delv.nest('allSigningRoles', SigningRole.fragment, { mappedSigningTemplateId: id })}
}`

const Right = () => <div />

const LeftCard = ({
    paymentInstrumentById: {
        stepByStepId,
        id,
        name,
    },
}) => (
    <div>
        <div className='vrtl-signing-left__section'>
            <div className='row--justify-between'>
                <div className='vrtl-signing__header'>Offering</div>
            </div>
            <Card.section>
                <Card.label>Offering name</Card.label>
                {name}
            </Card.section>
            {/* eslint-disable-next-line */}
                {stepByStepId.status != 'complete' && (
                <Card.section>
                    <Card.label>Mapping process</Card.label>
                    <Process.process proc={stepByStepId} refetch={FIX_THIS_REFETCH_FEATURE(id)}>
                        <Div className='vrtl-process-short-steps'>
                            <Process.shortSteps>
                                <Process.shortStep />
                            </Process.shortSteps>
                        </Div>
                        <Div className='vrtl-step-card__footer'>
                            <Popup.popup>
                                <Popup.inner>
                                    <Popup.close />
                                    <Process.currentStep />
                                    <div />
                                </Popup.inner>
                                <Popup.trigger>Next step</Popup.trigger>
                            </Popup.popup>
                        </Div>
                    </Process.process>
                </Card.section>
            )}
        </div>
    </div>
)

const GET_LOCKED_STATUS = {
    query: (id) => `{
        paymentInstrumentById(id:"${id}"){
            ${MappedTemplate.fragment}
            ${Process.fragment}
        }
    }`,
    args: (props) => props.match.params.instrument_id,
}

export default {
    right: Delv.queryHOC(Right, GET_LOCKED_STATUS), // this is fine to do because delv wont make the same request twice
    left: Delv.queryHOC(
        LeftCard,
        GET_LOCKED_STATUS,
    ),
}

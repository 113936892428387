import * as React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'

import Avatar from 'common/Avatar'
import Box from 'common/Box'
import Paper from 'common/Paper'
import Typography from 'common/Typography'
import useTheme from 'hooks/UseTheme.js'
import { getAccountName, getAccountType, getAccountInitials } from 'util/account-utils.js'

import './styles.scss'

const NavOptionsTrigger = ({
    open,
    prefix = 'vrtl-nav-options-trigger',
    getUsersData,
    onClick,
    ...props
}) => {
    const accountName = getAccountName(getUsersData)
    const accountType = getAccountType(getUsersData)
    const accoutInitials = getAccountInitials(getUsersData)

    const theme = useTheme()
    const primary = theme.palette.primary.main

    return (
        <Box className={prefix}>
            <Avatar
                className={`${prefix}__avatar`}
                sx={{ bgcolor: 'primary.main', borderRadius: '0.25rem 0 0 0.25rem', minWidth: '3rem' }}
                variant='square'
            >
                {accoutInitials}
            </Avatar>
            <Paper
                square
                elevation={0}
                sx={{ border: `1px solid ${primary}`, borderRadius: `0 0.25rem ${open ? 0 : '0.25rem'} 0` }}
                className={`${prefix}__paper`}
                onClick={onClick}
                {...props}
            >
                <Box className={`${prefix}__box`}>
                    <Box className={`${prefix}__box-account`}>
                        <Typography className={`${prefix}__box-account-type`} color='primary' sx={{ fontWeight: 500 }}>
                            {accountType}
                        </Typography>
                        <Typography className={`${prefix}__box-account-name`} color='primary' sx={{ fontWeight: 600 }}>
                            {accountName}
                        </Typography>
                    </Box>
                    <Box className={`${prefix}__icon`}>
                        {open ? <ArrowDropDownIcon color='primary' /> : <ArrowLeftIcon color='primary' />}
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default NavOptionsTrigger

import React from 'react'

export default ({
    offset = '',
    prefix = 'vrtl-allocation-link',
}) => (
    <div className={`${prefix}__container--heading`}>
        <div className={`${prefix}__section--2`}>
            {offset}
            Asset
        </div>
        <div className={`${prefix}__section`}>Round</div>
        <div className={`${prefix}__section`}>Unit Quantity</div>
        <div className={`${prefix}__section`}>Investment Status</div>
        <div className={`${prefix}__section--1.5`}>Qualification Status</div>
        <div className={`${prefix}__section`}>CreatedOn </div>
        <div className={`${prefix}__section addding`}>Term (Years)</div>
        <div className={`${prefix}__section`}>Yield</div>
        <div className={`${prefix}__icon-offset--3`} />
    </div>
)

import React from 'react'
import './styles.scss'

export default ({
    accountById: {
        contactName,
        email,
    },
    prefix = 'vrtl-account-details-tombstone',
}) => (
    <div className={`${prefix}__container`}>
        <h3 className='header'>
            Investor Details
        </h3>
        <div className={`${prefix}__section`}>
            <div className={`${prefix}__label`}>
                Contact Name
            </div>
            <div className={`${prefix}__label`}>
                {contactName}
            </div>
        </div>
        <div className={`${prefix}__section`}>
            <div className={`${prefix}__label`}>
                Email
            </div>
            <div className={`${prefix}__label`}>
                {email}
            </div>
        </div>
    </div>
)

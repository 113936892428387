import React from 'react'
import Div from 'common/Div.js'
import Popup from 'common/Popup.js'
import Map from 'common/Map.js'
import OrderBy from 'common/OrderBy.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Card from 'common/Card.js'
import Format from 'util/format/Format.js'
import { CheckBox } from 'common/FormUI.js'
import Delv from 'delv/delv-react'
import AddCircle from '@material-ui/icons/AddCircle'
import MultiSelect, { StatelessMultiSelect } from 'common/MultiSelect.js'
import Round from 'components/round/index.js'
import * as permissions from 'util/permissions.js'
import NoneFound from 'common/NoneFound/index.js'
import ProofOfIssuance from './proofOfIssuance/index.js'
import AssetManagerCard from './AssetManagerCard.js'
import BrokerAllocationForm from './BrokerAllocationForm.js'
import InvestorCard from './InvestorCard.js'
import AllocationsCard from './AllocationsCard.js'
import CapitalSummary from './CapitalSummary.js'
import IssuanceForm from './IssuanceForm.js'
import { MintQuery, HoldingsQuery } from './queries.js'
import './manage.scss'

const DistributionCard = ({
    name, units, onClick, isSelected,
}) => (
    <Card.card>
        <CheckBox value={isSelected} onChange={onClick} />
        <Card.section>
            <Card.label>Name</Card.label>
            {name}
        </Card.section>
        <Card.section />
        <Card.section>
            <Card.label>Units</Card.label>
            {Format.number(units)}
        </Card.section>
    </Card.card>
)

const Manage = ({
    klass, orderByData, ...props
}) => {
    const tranche = {
        ...props,
        type: klass?.assetByAssetId?.type,
        term: klass?.trancheBondDatumByRoundId?.term,
        coupon: klass?.trancheBondDatumByRoundId?.coupon,
    }
    const isIssuer = permissions.isIssuer(props)
    const canEdit = permissions.canEditDefault(props)
    const roundProps = {
        ...klass,
        ...props,
        ...tranche,
        unitValue: klass?.securityBySecurityId.unitValue,
    }
    return (
        <>
            <div className='padded-card-container flex-0'>
                <Div className='vrtl-round-editor__heading'>
                    <div className='vrtl-round-editor__heading-text'>Round Information</div>
                </Div>
                <Round.rowHeading {...tranche} entityPage prefix='vrtl-round-card--old' />
                <EditSwitch.switch canEdit={canEdit}>
                    <Round.row {...roundProps} prefix='vrtl-round-card--old' entityPage />
                    <Round.form {...roundProps} entityPage />
                </EditSwitch.switch>
            </div>
            <div className='row flex-1'>
                <div className='flex-1'>
                    <div className='row'>
                        <div className='padded-card-container asset-manager-container'>
                            <Div className='vrtl-round-editor__heading'>
                                <div className='vrtl-round-editor__heading-text'>Distributors</div>
                                {canEdit && (
                                    <Popup.popup>
                                        <Popup.inner>
                                            <Popup.close />
                                            <BrokerAllocationForm roundId={klass.id} />
                                        </Popup.inner>
                                        <Popup.trigger className='vrtl-round-editor__heading-action'>
                                            <AddCircle className='distributor-add-circle' />
                                        </Popup.trigger>
                                    </Popup.popup>
                                )}
                            </Div>
                            <div>
                                <Map data={klass.brokerAllocationsByRoundId.nodes}>
                                    <AssetManagerCard {...klass} />
                                </Map>
                            </div>
                        </div>
                    </div>
                    {isIssuer ? <CapitalSummary assetClass={klass} /> : ''}

                    <AllocationsCard assetClass={klass} roundId={klass.id} currentAccount={props.account} />
                </div>

                <div className='flex-1 column'>
                    <div className='vrtl-round-incoming-cap__container'>
                        <div className='row--justify-between'>
                            <div className='vrtl-round-incoming-cap__heading'>
                                <div className='vrtl-round-incoming-cap__heading-text'>Cap Table</div>
                            </div>
                            {canEdit ? (
                                <div className='row'>
                                    <Popup.popup key='1'>
                                        <Popup.inner>
                                            <Div cleanProps={({ onResolve, ...properties }) => properties}>
                                                <IssuanceForm>
                                                    <StatelessMultiSelect>
                                                        <Delv.query
                                                            key='issuancequery'
                                                            {...MintQuery}
                                                            roundId={klass.id}
                                                        >
                                                            <OrderBy.order arrayKey='capTables'>
                                                                <OrderBy.bar>
                                                                    <OrderBy.select
                                                                        map={(data) => ({
                                                                            id: data.id,
                                                                            value: data,
                                                                        })}
                                                                        className='icon-checkbox'
                                                                    />
                                                                    <OrderBy.filter
                                                                        className='flex-2'
                                                                        field='name'
                                                                    >
                                                                        Name
                                                                    </OrderBy.filter>
                                                                    <OrderBy.sort field='units'>
                                                                        Units
                                                                    </OrderBy.sort>
                                                                </OrderBy.bar>
                                                                <Div className='vrtl-asset-class-distributions'>
                                                                    <Div className='order-by-inner'>
                                                                        <Map
                                                                            key='issuancequery'
                                                                            arrayKey='_orderBy.capTables'
                                                                            noneFound={(
                                                                                <NoneFound>
                                                                                    No closed
                                                                                    distributions found.
                                                                                </NoneFound>
                                                                            )}
                                                                        >
                                                                            <DistributionCard />
                                                                        </Map>
                                                                    </Div>
                                                                </Div>
                                                            </OrderBy.order>
                                                        </Delv.query>
                                                    </StatelessMultiSelect>
                                                </IssuanceForm>
                                            </Div>
                                        </Popup.inner>
                                        <Popup.trigger className='card-form-trigger'>
                                            Issue
                                        </Popup.trigger>
                                    </Popup.popup>
                                    <Popup.popup>
                                        <Popup.inner>
                                            <Div cleanProps={({ onResolve, ...properties }) => properties}>
                                                <MultiSelect>
                                                    <ProofOfIssuance.form>
                                                        <Delv.query
                                                            query={HoldingsQuery.query}
                                                            format={HoldingsQuery.format}
                                                            args={HoldingsQuery.args}
                                                            roundId={klass.id}
                                                        >
                                                            <OrderBy.order>
                                                                <OrderBy.bar>
                                                                    <OrderBy.filter className='flex-2' field='contactName'>
                                                                        Contact Name
                                                                    </OrderBy.filter>
                                                                    <OrderBy.filter className='flex-2' field='legalName'>
                                                                        Account Name
                                                                    </OrderBy.filter>
                                                                    <OrderBy.sort field='totalAmount'>
                                                                        Total Units
                                                                    </OrderBy.sort>
                                                                </OrderBy.bar>
                                                                <Div className='vrtl-asset-class-distributions'>
                                                                    <Div className='order-by-inner'>
                                                                        <Map
                                                                            key='holdingsQuery'
                                                                            arrayKey='_orderBy.data'
                                                                            noneFound={(
                                                                                <NoneFound>
                                                                                    No investors with issued holdings found.
                                                                                </NoneFound>
                                                                            )}
                                                                        >
                                                                            <ProofOfIssuance.row />
                                                                        </Map>
                                                                    </Div>
                                                                </Div>
                                                            </OrderBy.order>
                                                        </Delv.query>
                                                    </ProofOfIssuance.form>
                                                </MultiSelect>
                                            </Div>
                                        </Popup.inner>
                                        <Popup.trigger className='card-form-trigger'>
                                            Proof of Issuance
                                        </Popup.trigger>
                                    </Popup.popup>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <OrderBy.order dropdownFields={['status']} orderByData={orderByData}>
                            <OrderBy.bar>
                                <OrderBy.filter field='name' className='center-row flex-2'>
                                    Name
                                </OrderBy.filter>
                                <OrderBy.sort field='amount' className='center-row flex-1'>
                                    Units
                                </OrderBy.sort>
                                <OrderBy.sort field='purchasePrice' className='center-row flex-1' castToNumber>
                                    Value
                                </OrderBy.sort>
                                <OrderBy.dropdown field='status' width='5rem'>
                                    Status
                                </OrderBy.dropdown>
                            </OrderBy.bar>
                            <Div className='buyers-container'>
                                <Div className='order-by-inner'>
                                    <Map arrayKey='_orderBy.holding'>
                                        <InvestorCard prefix='vrtl-asset-class-holding' />
                                    </Map>
                                    <Map arrayKey='_orderBy.distribution'>
                                        <InvestorCard prefix='vrtl-asset-class-distribution' />
                                    </Map>
                                </Div>
                            </Div>
                        </OrderBy.order>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Manage

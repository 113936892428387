import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import Div from 'common/Div.js'
import { isIssuer } from 'util/permissions.js'
import IssuerView from './IssuerView.js'
import AtsView from './AtsView.js'

import './styles.scss'

const BREADCRUMBS = [
    {
        active: true,
        display: 'Securities',
        link: '/securities',
    },
]

const SecuritiesPage = ({
    ...props
}) => (
    <Div>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            {isIssuer(props) ? <IssuerView {...props} /> : <AtsView {...props} />}
        </Container>
        <Footer />
    </Div>
)

export default SecuritiesPage

import React from 'react'
import { Link } from 'react-router-dom'

export default ({
    id,
    name,
    match,
}) => {
    const prefix = 'vrtl-signing-template'
    return (
        <div className={`${prefix}__container`}>
            <Link to={`${match.url}/${id}`} className={`${prefix}__a--container`}>
                <div className={`${prefix}__section`}>
                    {name}
                </div>
            </Link>
        </div>
    )
}

import React from 'react'
import Mutation from 'delv/Mutation'
import ArrowForward from '@material-ui/icons/ArrowForward'
import Form from 'common/form/Form'
import UseViewSwitch from 'hooks/UseViewSwitch.js'
import { StatelessMultiSelect } from 'common/MultiSelect.js'

import Holding from 'components/holding/index.js'
import HoldingBCA from 'components/holdingBlockchainAddress/index.js'

const MINT_ALLOCATION = (assetClassId) => `mutation ($ids:[UUID]!) {
  tokenizeHoldings(input: {holdingIds: $ids}) {
    query{
      roundById(id: "${assetClassId}") {
        id
        allocationsByRoundId {
          nodes {
            id
            distributionsByAllocationId {
              nodes {
                id
                status
              }
            }
          }
        }
      }
    }
    holdings{
     ${
    Holding.fragment.merge(
        HoldingBCA.fragment.nest('holdingBlockchainAddressesByHoldingId'),
    ).toString()
}
    }
  }
}`

const formConfig = {
    fields: {
        distributions: {
            type: 'multiSelect',
        },
    },
    defaultValueKey: 'token',
}

const MintViewSwitch = ({
    children,
    popup,
    assetClassId,
    form: { distributions, validateInput, ...form },
}) => {
    const { setUI, view } = UseViewSwitch()

    const getNextButtonEvent = () => {
        if (view === 0) {
            const input = validateInput()
            const ids = Object.keys(input.distributions)
            if (!input.hasErrors) {
                new Mutation({
                    mutation: MINT_ALLOCATION(assetClassId),
                    onSubmit: () => ({ ids }),
                }).submit()
                popup.close()
            }
        }
    }

    const child = children[view]
    return (
        <div className='token-form'>
            <div className='flex-1'>
                <StatelessMultiSelect {...distributions}>
                    {React.Children.map(child, (fnChild) => React.cloneElement(fnChild, { form, setUI }))}
                </StatelessMultiSelect>
            </div>
            <div className='class-form-arrows-container'>
                <div />
                <div
                    className='class-form-arrow-container'
                    onClick={getNextButtonEvent}
                >
                    <div className='class-form-arrow-label'>SUBMIT</div>
                    <ArrowForward className='class-form-arrow' />
                </div>
            </div>
        </div>
    )
}

export default Form(MintViewSwitch, formConfig)

import React, { useEffect } from 'react'
import Box from 'common/Box'
import useFormView from 'hooks/UseFormView'
import { isSm } from 'constants/media-queries'

const IndividualForm = ({ fields, emitter, stateHandler }) => {
    const {
        validateInput,
        formView: {
            email,
            firstName,
            lastName,
            taxId,
            dateOfBirth,
            usCitizen,
        },
    } = useFormView(fields)

    useEffect(() => {
        const submitIndividualInput = () => {
            const { valid, input } = validateInput()

            // radio input components currently cast values to string so we convert here
            const isCitizen = input.usCitizen === 'yes'

            if (valid) {
                stateHandler.party('individual', {
                    ...input, isPrimary: true, allowLogin: true, usCitizen: isCitizen,
                })
            } else {
                stateHandler.error()
            }
        }

        emitter.on('submitInput', submitIndividualInput)
        return () => emitter.removeListener('submitInput', submitIndividualInput)
    })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {email}
            <Box sx={{ display: 'flex', gap: 4, [isSm]: { flexDirection: 'column' } }}>
                {firstName}
                {lastName}
            </Box>
            <Box sx={{ display: 'flex', gap: 4, [isSm]: { flexDirection: 'column' } }}>
                {taxId}
                {dateOfBirth}
            </Box>
            {usCitizen}
        </Box>
    )
}

export default IndividualForm

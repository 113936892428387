import Row from './Row.js'
import Form from './Form.js'
import Fragment from './Fragment.js'
import RowHeading from './RowHeading.js'
import Card from './Card.js'
import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    form: Form,
    card: Card,
    rowHeading: RowHeading,
}

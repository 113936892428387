import TypeMap from '../TypeMap.js'

const collectionResolver = ({
    root, fieldName, context, args,
}) => {
    const fieldType = TypeMap.get(fieldName)
    const childType = TypeMap.guessChildType(fieldType)

    const typename = root[childType] ? childType : fieldName
    const collection = root[typename]

    context.node = {
        ...context.node,
        __typename: childType,
        args,
        collection,
    }

    const result = {}
    const totalCount = root[childType]?.[context.query.hash]?.totalCount
    if (totalCount) {
        result.totalCount = totalCount
    }

    return result
}

export { collectionResolver }

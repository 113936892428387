import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
__typename
amount
status
createdOn
paidInCapital
allocationId
locked
qualificationsComplete
`)

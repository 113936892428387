import React from 'react'
import Brand from 'logos/Brand.js'
import Avatar from 'common/Avatar'

const colors = [
    'rgb(39, 150, 184)',
    'rgb(173, 46, 46)',
    'rgb(190, 173, 58)',
    'rgb(87, 190, 59)',
    'rgb(112, 53, 171)',
]

function DelegateRole({
    id,
    role,
    index,
    accountByAccountId: {
        name,
        slug,
        type,
    },
    search,
}) {
    const onClick = () => window.location.assign(`/authenticate/role/${id}${search || ''}`)
    const accountRole = role.replace('_', ' ')

    // Clients are required to have a slug for branding,
    // but we must support clients that pre-date this policy.
    if (slug) {
        return (
            <div className='delegate-role' onClick={onClick}>
                <div>
                    <Brand slug={slug} className='delegate-role-logo' />
                    <div className='account-role-name'>{name}</div>
                    <div id={slug} className='delegate-role-role'>
                        {`${type} ${accountRole}`}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='delegate-role' onClick={onClick}>
            <Avatar
                className='delegate-role-avatar'
                sx={{ bgcolor: colors[index % colors.length], p: 0 }}
            >
                {name.substring(0, 1)}
            </Avatar>
            <div className='delegate-role-text-container'>
                <h3 className='delegate-role-name'>{name}</h3>
                <div className='delegate-role-role'>
                    {`${type} ${accountRole}`}
                </div>
            </div>
        </div>
    )
}

export default DelegateRole

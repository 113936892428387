import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import FormUI from 'common/FormUI.js'

import Done from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'
import Delete from '@material-ui/icons/Delete'

import SafeReact from 'util/SafeReact.js'

const makeFormComponent = ({
    fields, getDefinition, type, ...props
}) => {
    const newFields = {}
    Object.keys(fields).forEach((key) => {
        const value = fields[key]
        const definition = getDefinition(value)
        newFields[key] = definition.skipRenderOn && definition.skipRenderOn({ value, props, type })
            ? value.value
            : definition.customUI
                ? definition.customUI({ value, props, type })
                : FormUI[definition.type]
                    ? React.createElement(FormUI[definition.type], value)
                    : <FormUI.input {...value} />
    })
    return newFields
}

const makeLoadingComponent = ({ fields }) => {
    const newFields = {}
    Object.keys(fields).forEach((key) => {
        newFields[key] = fields[key].value
    })
    return newFields
}

const createArg = (input) => input

function GenericForm({
    Display,
    createField,
    updateField,
    deleteField,
    prefix,
    formConfig,
    makeFormComponents = makeFormComponent,
    makeLoadingComponents = makeLoadingComponent,
    createArgs = createArg,
    errorMap = {},
}) {
    const WrappedComponenet = ({
        id,
        resolve,
        offset,
        display: displayOverride,
        form: {
            error,
            handleBlur,
            handleChange,
            validateInput,
            resetState,
            getChanged,
            getDefinition,
            setLoading,
            loading,
            ...fields
        },
        key,
        ...props
    }) => {
        const onError = (errors) => {
            error.set(errors[0].message)
            setLoading('error')
        }

        const handleSubmit = (event) => {
            event.preventDefault()
            const input = validateInput()
            if (!input.hasErrors) {
                if (id) {
                    const changedInput = getChanged(input)
                    if (changedInput) {
                        setLoading('create')
                        new Mutation({
                            mutation: updateField instanceof Function ? updateField() : updateField,
                            onSubmit: () => ({ id, patch: changedInput }),
                            onResolve: resolve,
                            onError,
                        }).submit()
                    } else {
                        resolve()
                    }
                } else {
                    setLoading('create')
                    new Mutation({
                        mutation: createField instanceof Function ? createField() : createField,
                        onSubmit: () => createArgs(input, props),
                        onResolve: resolve,
                        onError,
                    }).submit()
                }
            }
        }
        const deleteRow = deleteField
            ? (event) => {
                event.preventDefault()
                setLoading('delete')
                new Mutation({
                    mutation: deleteField instanceof Function ? deleteField() : deleteField,
                    onSubmit: () => ({ id }),
                    onResolve: resolve,
                    cacheProcess: 'delete',
                    onError,
                }).submit()
            }
            : () => {}

        if (loading === 'create') {
            return (
                displayOverride
                    ? SafeReact.cloneChildren(displayOverride, makeLoadingComponents({
                        ...props, id, fields, defaultFunc: makeFormComponent, getDefinition,
                    }))
                    : Display
                        ? (
                            <Display {...makeLoadingComponents({
                                ...props, id, fields, defaultFunc: makeFormComponent, getDefinition,
                            })}
                            />
                        )
                        : <div>THERE IS NO DISPLAY VALUE PROVIDED TO THIS GENERIC FORM!!!</div>
            )
        }

        if (loading === 'delete') {
            return <div />
        }

        if (loading === 'error') {
            return errorMap instanceof Function
                ? errorMap({ error })
                : <div className='error'>{errorMap[error.error] ? errorMap[error.error] : error.error}</div>
        }
        const displayProps = {
            remove: id && deleteField && <Delete className={`${prefix}__icon-delete`} onClick={deleteRow} />,
            cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
            submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
            key: `form-${id}`,
            offset,
            ...makeFormComponents({
                ...props, id, fields, defaultFunc: makeFormComponent, getDefinition, type: id ? 'update' : 'create',
            }),
        }

        return (
            displayOverride
                ? SafeReact.cloneChildren(displayOverride, displayProps)
                : Display
                    ? <Display {...displayProps} />
                    : <div>THERE IS NO DISPLAY VALUE PROVIDED TO THIS GENERIC FORM!!!</div>
        )
    }

    return Form(WrappedComponenet, formConfig)
}

export default GenericForm

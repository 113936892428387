import React from 'react'
import Link from 'common/Link'
import Email from 'account/Email.js'
import Address from 'components/address/index.js'
import Map from 'common/Map.js'
import Party from 'components/party/index.js'

import './styles.scss'

const Card = ({
    id,
    name,
    email,
    type,
    url,
    supportEmail,
    twitterHandle,
    slug,
    taxShort,
    canEdit,
    prefix = 'vrtl-account-details-tombstone',
    setup,
    addresses,
    parties,
}) => {
    const isInvestor = type === 'investor'
    const isIssuerOrBroker = type === 'issuer' || type === 'broker'
    const emailProps = { account: { id } }
    const isSetup = !!setup

    return (
        <div>
            <div className={`${prefix}__container`}>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>Name:</div>
                    <div className={`${prefix}__content`}>{name}</div>
                    <div className={`${prefix}__content--spacer-triple`} />
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>Email:</div>
                    <div className={`${prefix}__content`}>{email}</div>
                    <div className={`${prefix}__content--spacer-triple`} />
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>URL:</div>
                    <div className={`${prefix}__content`}>{url}</div>
                    <div className={`${prefix}__content--spacer-triple`} />
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>Support Email:</div>
                    <div className={`${prefix}__content`}>{supportEmail}</div>
                    <div className={`${prefix}__content--spacer-triple`} />
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>Twitter Handle:</div>
                    <div className={`${prefix}__content`}>{twitterHandle}</div>
                    <div className={`${prefix}__content--spacer-triple`} />
                </div>
                {
                    isInvestor && (
                        <>
                            {
                                isSetup ? (
                                    <>
                                        <div className={`${prefix}__section`}>
                                            <div className={`${prefix}__label`}>SSN last 4:</div>
                                            <div className={`${prefix}__content`}>{taxShort}</div>
                                            <div className={`${prefix}__content--spacer-triple`} />
                                        </div>
                                        <h2>Addresses</h2>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ padding: '16px', display: 'flex' }}>
                                                <Map data={addresses}>
                                                    <Address.card />
                                                </Map>
                                            </div>
                                        </div>
                                        <div>
                                            <h2>Parties</h2>
                                            <Map data={parties}>
                                                <Party.card />
                                            </Map>
                                        </div>
                                    </>
                                ) : (
                                    <div className={`${prefix}__section`}>
                                        <div className={`${prefix}__content`}>
                                            <Link to='/account/setup'>Set Up Account</Link>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {
                    isIssuerOrBroker && (
                        <>
                            <div className={`${prefix}__section`}>
                                <div className={`${prefix}__label`}>Slug:</div>
                                <div className={`${prefix}__content`}>{slug}</div>
                                <div className={`${prefix}__content--spacer-triple`} />
                            </div>
                            <div className={`${prefix}__line`} />
                            <div className={`${prefix}__section`}>
                                <div className={`${prefix}__sub-heading`}>Default Settings</div>
                            </div>
                            <Email {...emailProps} canEdit={canEdit} />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Card

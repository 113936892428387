import * as React from 'react'

import Box from 'common/Box'

const IconCell = ({ children, Icon }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {children}
        <Icon fontSize='12' color='action' />
    </Box>
)

export default IconCell

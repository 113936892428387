import React, { useState } from 'react'
import cx from 'classnames'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import AddCircle from '@material-ui/icons/AddCircle'
import Edit from '@material-ui/icons/Edit'
import { Decimal } from 'decimal.js'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'

import Popup from 'common/Popup.js'
import Format from 'util/format/Format.js'

import CapitalDeploymentForm from './CapitalDeploymentForm.js'
import EditCapitalDeploymentForm from './EditCapitalDeploymentForm.js'

import './CapitalManagement.scss'

const investorHeader = (
    <div className='vrtl-round-incoming-cap__header'>
        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--shrink' />

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--3'>
            Investor
        </div>

        <div className='vrtl-round-incoming-cap__column'>Units</div>

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Committed
        </div>

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Total Paid In
        </div>

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Expected
        </div>
    </div>
)

const paymentsHeader = (
    <div className='vrtl-round-incoming-cap__header'>
        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--shrink' />

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--3' />

        <div className='vrtl-round-incoming-cap__column' />

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Capital Paid In
        </div>

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Paid In Currency
        </div>

        <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
            Date Paid In
        </div>
    </div>
)

const InvestorRow = (props) => {
    const {
        entry, last, committed,
    } = props

    const {
        investor, payments, amount, totalPaidIn,
    } = entry

    const [open, setOpen] = useState(false)

    const toggle = () => setOpen(!open)

    const columnArrowClassName = cx('vrtl-round-incoming-cap__column-arrow', {
        'vrtl-round-incoming-cap__column-arrow--open': open,
    })

    const formattedUnits = Format.number(amount)

    const formattedCommittedCapital = Format.number(committed)

    const formattedTotalPaidIn = Format.number(totalPaidIn)

    const formattedExpectedCapital = Format.number(committed.sub(totalPaidIn))

    const row = (
        <div className='vrtl-round-incoming-cap__row'>
            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--shrink'>
                <div className={columnArrowClassName} onClick={toggle}>
                    <RightArrow />
                </div>
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--3'>
                {investor.contactName}
            </div>

            <div className='vrtl-round-incoming-cap__column'>
                {formattedUnits}
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {formattedCommittedCapital}
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {formattedTotalPaidIn}
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {formattedExpectedCapital}
            </div>
        </div>
    )

    const paymentRows = payments.map((payment) => (
        <div className='vrtl-round-incoming-cap__row' key={payment.id}>
            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--shrink' />

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--3' />

            <div className='vrtl-round-incoming-cap__column' />

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {Format.number(payment.amount)}
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {payment.currency}
            </div>

            <div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--2'>
                {Format.date(payment.paidOn)}
            </div>
        </div>
    ))

    const conditionalPaymentsRows = paymentRows.length === 0 ? (
        <div className='none-found'>No Payments</div>
    ) : (
        paymentRows
    )

    if (open) {
        return (
            <>
                {row}
                {paymentsHeader}
                {conditionalPaymentsRows}
                {last ? null : investorHeader}
            </>
        )
    }
    return <>{row}</>
}

const IncomingCapital = (props) => {
    const { entries, token, price } = props

    const investorRows = entries.map((entry, index) => { // we need to generate now to sort later
        const units = entry.amount
        const committed = new Decimal(units).mul(price)
        const expected = Format.number(committed.sub(entry.totalPaidIn))
        return {
            name: entry.investor.contactName,
            key: index,
            committed,
            expected,
            units: entry.amount,
            totalPaidIn: entry.totalPaidIn,
            entry,
            token,
            price,
            last: index === entries.length - 1,
        }
    })

    return (
        <div className='vrtl-round-incoming-cap__container'>
            <div className='vrtl-round-incoming-cap__heading'>
                <div className='vrtl-round-incoming-cap__heading-text'>
                    Incoming Capital
                </div>
            </div>
            <OrderBy.order orderByData={investorRows}>
                <OrderBy.bar>
                    <Div className='vrtl-round-incoming-cap__column vrtl-round-incoming-cap__column--shrink' />
                    <OrderBy.filter addClassName='flex-3' field='name'>Name</OrderBy.filter>
                    <OrderBy.sort addClassName='flex-1' field='units'>Units</OrderBy.sort>
                    <OrderBy.sort addClassName='flex-2' field='committed'>Committed</OrderBy.sort>
                    <OrderBy.sort addClassName='flex-2' field='totalPaidIn'>Total Paid In</OrderBy.sort>
                    <OrderBy.sort addClassName='flex-2' field='expected'>Expected</OrderBy.sort>
                </OrderBy.bar>
                <Div className='vrtl-round-incoming-cap__order-by-container'>
                    <Div className='order-by-inner'>
                        <Map arrayKey='_orderBy.data'>
                            <InvestorRow />
                        </Map>
                    </Div>
                </Div>
            </OrderBy.order>
        </div>
    )
}

const CapitalDeploymentRow = (props) => {
    const { deployment } = props

    return (
        <div className='vrtl-round-cap-deploys__row'>
            <div className='vrtl-round-cap-deploys__column'>
                {Format.number(deployment.amount)}
            </div>

            <div className='vrtl-round-cap-deploys__column'>
                {Format.number(deployment.amount)}
                {' '}
                {deployment.currencyByCurrency.code}
            </div>

            <div className='vrtl-round-cap-deploys__column vrtl-round-cap-deploys__column--2'>
                {Format.date(deployment.paidOn)}
            </div>

            <div className='vrtl-round-cap-deploys__column vrtl-round-cap-deploys__column--shrink'>
                <Popup.popup>
                    <Popup.inner>
                        <EditCapitalDeploymentForm
                            capitalDeployment={deployment}
                        />
                    </Popup.inner>

                    <Popup.trigger className='vrtl-round-cap-deploys__add'>
                        <Edit style={{ color: 'gray' }} />
                    </Popup.trigger>
                </Popup.popup>
            </div>
        </div>
    )
}

const CapitalDeploymentsCard = (props) => {
    const {
        roundId,
        capitalDeployments,
    } = props

    const rows = capitalDeployments.map((deployment, index) => (
        <>
            {/* eslint-disable react/no-array-index-key */}
            <CapitalDeploymentRow key={index} deployment={deployment} />
        </>
    ))

    const conditionalRows = rows.length === 0 ? (
        <div className='none-found'>No Deployments</div>
    ) : (
        rows
    )

    return (
        <div className='vrtl-round-cap-deploys__container' style={{ flex: '0' }}>
            <div className='vrtl-round-cap-deploys__heading'>
                <div className='vrtl-round-cap-deploys__heading-text'>
                    Capital Deployment
                </div>
            </div>

            <div className='vrtl-round-cap-deploys__header'>
                <div className='vrtl-round-cap-deploys__column'>Deployed Capital</div>

                <div className='vrtl-round-cap-deploys__column'>Deployed in Currency</div>

                <div className='vrtl-round-cap-deploys__column vrtl-round-cap-deploys__column--2'>
                    Date Deployed
                </div>

                <div className='vrtl-round-cap-deploys__column vrtl-round-cap-deploys__column--shrink'>
                    <Popup.popup>
                        <Popup.inner>
                            <CapitalDeploymentForm roundId={roundId} />
                        </Popup.inner>

                        <Popup.trigger className='vrtl-round-cap-deploys__add'>
                            <AddCircle />
                        </Popup.trigger>
                    </Popup.popup>
                </div>
            </div>

            {conditionalRows}
        </div>
    )
}

const CapitalManagement = (props) => (
    <div className='vrtl-round-capman__container'>
        <div className='vrtl-round-capman__column'>
            <CapitalDeploymentsCard {...props} />
        </div>

        <div className='vrtl-round-capman__column'>
            <IncomingCapital {...props} />
        </div>
    </div>
)

export default CapitalManagement

import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import { Input } from 'common/FormUI.js'
import { MakeDistributionData } from './queries.js'

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        amount: {
            type: 'number',
            error: 'Amount is a required field',
        },
    },
    constraints: {
        amount: {
            type: 'greaterThan',
            value: {
                type: 'number',
                isField: false,
                value: 0,
            },
        },
    },
}

export default Form(({ multiSelect, allocationId, form: { amount, validateInput } }) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors && multiSelect.length() > 0) {
            const fragment = (investorId, email) => `m${investorId.replace(
                /\-/g,
                '_',
            )}:makeDistribution(input:{accountEmail:"${email}",amount:"${input.amount}", _allocationId:"${allocationId}"}){
                  distribution{
                    ${MakeDistributionData}
                  }
                }`

            let fragments = ''
            multiSelect.getValues().forEach(({ accountByInvestorId: { id, email } }) => {
                fragments += fragment(`${id}`, email)
            })
            new Mutation({
                mutation: `mutation {${fragments}}`,
                onFetch: (promise, data) => {
                    data.queryManager.remove('roundPage', true)
                },
                onSubmit: () => ({}),
            }).submit()
        }
    }

    return (
        <div className='add-customers-card'>
            <div className='card-section'>
                <div className='card-label'>AMOUNT</div>
                <Input {...amount} />
            </div>
            <div className='card-section' />
            <div className='card-section' />
            <div className='card-section'>
                <div className='styled-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </div>
    )
}, formConfig)

import React from 'react'
import Card from 'common/Card.js'
import Address from 'components/address/index.js'
import Map from 'common/Map.js'

const PartyCard = ({
    dateOfBirth,
    usCitizen,
    partyAddressesByPartyId,
    taxShort,
    userByUserId,
}) => {
    const prefix = 'vrtl-party-card'
    return (
        <div className={`${prefix}__container`}>
            <div style={{ display: 'flex' }}>
                <Card.section>
                    <div className={`${prefix}__label`}>First Name</div>
                    {userByUserId?.firstName}
                </Card.section>
                <Card.section>
                    <div className={`${prefix}__label`}>Last Name</div>
                    {userByUserId?.lastName}
                </Card.section>
                <Card.section>
                    <div className={`${prefix}__label`}>Date of Birth</div>
                    {dateOfBirth}
                </Card.section>
                <Card.section>
                    <div className={`${prefix}__label`}>Us Citizen</div>
                    {usCitizen ? 'Yes' : 'No'}
                </Card.section>
                {
                    taxShort && (
                        <Card.section>
                            <div className={`${prefix}__label`}>SSN last Four</div>
                            {taxShort}
                        </Card.section>
                    )
                }
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <Map data={partyAddressesByPartyId}>
                        <Address.card />
                    </Map>
                </div>
            </div>
        </div>
    )
}

export default PartyCard

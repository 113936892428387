import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'

import ReportsDownload from './ReportsDownload.js'

import './styles.scss'

const reportsDownloadFragment = (
    <ReportsDownload reports={[{
        name: 'Cap Table',
        url: '/api/v2/reports/cap-table',
    },
    {
        name: 'Contacts',
        url: '/api/v2/reports/contacts',
    }]}
    />
)

const BREADCRUMBS = [
    {
        display: 'Reports',
        link: '/reports',
        active: true,
    },
]

const Reports = () => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            {reportsDownloadFragment}
        </Container>
        <Footer />
    </>
)

export default Reports

import React from 'react'

import Format from 'util/format/Format.js'
import Edit from '@material-ui/icons/Edit'
import IconHelper from 'common/IconHelper.js'

export default ({
    amount,
    status,
    round,
    createdOn,
    qualificationStatus,
    coupon,
    term,
    type,
    asset,
    edit,
    cancel,
    submit,
    offset = '',
    prefix = 'vrtl-round-card',
}) => (
    <div className={`${prefix}__container--row`}>
        <div className={`${prefix}__section--2`}>
            {offset}
            {asset}
        </div>
        <div className={`${prefix}__section ${prefix}--pascal`}>{round}</div>
        <div className={`${prefix}__section`}>{typeof amount === 'string' ? Format.number(amount) : amount}</div>
        <div className={`${prefix}__section ${prefix}--pascal`}>{status}</div>
        <div className={`${prefix}__section--1.5 ${prefix}--pascal`}>{qualificationStatus}</div>
        <div className={`${prefix}__section`}>{Format.date(createdOn, 'MMMM DD YYYY')}</div>
        {
            type.toLowerCase() === 'debt' ? (
                <>
                    <div className={`${prefix}__section`}>{typeof term === 'string' ? Format.number(term) : term}</div>
                    <div className={`${prefix}__section`}>{typeof coupon === 'string' ? Format.percent(coupon, 100) : coupon}</div>
                </>
            ) : (
                <>
                    <div className={`${prefix}__section`}>N/A</div>
                    <div className={`${prefix}__section`}>N/A</div>
                </>
            )
        }
        {
            IconHelper([cancel, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />, submit], prefix)
        }
    </div>
)

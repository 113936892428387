import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default ({ children, ...props }) => {
    const prefix = 'vrtl-sent-documents'

    const lockedOptions = {
        locked: {
            true: {
                locked: 'Locked',
                value: true,
            },
            false: {
                locked: 'Not Locked',
                value: false,
            },
        },
    }

    const activeOptions = {
        active: {
            true: {
                active: 'Active',
                value: true,
            },
            false: {
                active: 'Not Active',
                value: false,
            },
        },
    }

    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                Name
            </OrderBy.filter>
            <OrderBy.dropdown dropdownFields={activeOptions} field='active' className={`${prefix}__section`}>
                Active
            </OrderBy.dropdown>
            <OrderBy.dropdown dropdownFields={lockedOptions} field='locked' className={`${prefix}__section`}>
                Locked
            </OrderBy.dropdown>
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </OrderBy.bar>
    )
}

import React from 'react'
import NetworkMessage from 'common/NetworkMessage'

const DEFAULT_MESSAGE = 'Oops, something went wrong. Please try again.'

const NetworkError = ({ message, AlertProps }) => (
    <NetworkMessage message={message ?? DEFAULT_MESSAGE} severity='error' {...AlertProps} />
)

export default NetworkError

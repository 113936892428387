import React from 'react'
import Add from '@material-ui/icons/AddCircle'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import OrderBy from 'common/OrderBy.js'
import ViewAppend from 'common/ViewAppend.js'
import Div from 'common/Div.js'
import Map from 'common/Map.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Delv from 'delv/delv-react'

import QuestionSetJoin from './questionSetJoins/index.js'
import QuestionSets from './questionSets/index.js'
import Question from './question/index.js'
import './styles.scss'

export default (props) => {
    const canEdit = ['account_admin', 'account_owner'].includes(props.userAccount.role)

    const GET_QUESTION_SETS = {
        query: `{
            allOnboardingQuestionSets {
              nodes {
                ${QuestionSets.fragment.toString()}
              }
            }
          }`,
        format: ({ allOnboardingQuestionSets }) => ({ orderByData: allOnboardingQuestionSets.nodes }),
    }

    const GET_QUESTIONS = {
        query: `{
            allOnboardingQuestions {
              nodes {
                ${Question.fragment.toString()}
              }
            }
          }`,
        format: ({ allOnboardingQuestions }) => {
            const formatted = allOnboardingQuestions.nodes.map(({ onboardingQuestionTypeByType, ...other }) => ({
                ...onboardingQuestionTypeByType,
                ...other,
            }))
            return { orderByData: formatted }
        },
    }
    const GET_QUESTION_JOINS = {
        query: (id) => `{
            allOnboardingQuestionJoins(condition: {onboardingQuestionSetId: "${id}"}) {
              nodes {
                ${QuestionSetJoin.fragment.toString()}
              }
            }
          }`,
        format: ({ allOnboardingQuestionJoins }) => {
            const formatted = allOnboardingQuestionJoins.nodes.map((node) => ({
                name: node.onboardingQuestionByOnboardingQuestionId.name,
                ...node,
            }))

            formatted.sort((a, b) => a.executeOrder - b.executeOrder)

            return { data: formatted }
        },
        args: (argProps) => argProps.questionSetId,
    }
    const prefix = 'vrtl-onboarding-question-page'

    const BREADCRUMBS = [
        {
            display: 'Account Settings',
            link: '/account',
        },
        {
            active: true,
            display: 'Question Set Management',
            link: 'onboarding-questions',
        },
    ]

    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                <div className={`${prefix}__main`}>
                    <div className={`${prefix}__left`}>
                        <div className='gray-header row--justify-between'>
                            Question Sets
                        </div>
                        <ViewAppend.container target canEdit>
                            <Delv.query {...GET_QUESTION_SETS}>
                                <OrderBy.order>
                                    <QuestionSets.orderByHeader>
                                        <ViewAppend.icon append={<QuestionSets.form display={<QuestionSets.row />} />}>
                                            <Add />
                                        </ViewAppend.icon>
                                    </QuestionSets.orderByHeader>
                                    <Div className='buyers-container'>
                                        <Div className='order-by-inner'>
                                            <ViewAppend.target />
                                            <Map arrayKey='_orderBy.data'>
                                                <EditSwitch.switch canEdit={canEdit}>
                                                    <QuestionSets.row>
                                                        <ViewAppend.container target canEdit={({ locked }) => !locked && canEdit}>
                                                            <Delv.query {...GET_QUESTION_JOINS}>
                                                                <Div>
                                                                    <QuestionSetJoin.rowHeader>
                                                                        <ViewAppend.icon append={<QuestionSetJoin.form display={<QuestionSetJoin.row />} />}>
                                                                            <Add />
                                                                        </ViewAppend.icon>
                                                                    </QuestionSetJoin.rowHeader>
                                                                    <Div className='column'>
                                                                        <ViewAppend.target />
                                                                        <Map>
                                                                            <EditSwitch.switch canEdit={({ locked }) => !locked && canEdit}>
                                                                                <QuestionSetJoin.row />
                                                                                <QuestionSetJoin.form display={<QuestionSetJoin.row />} />
                                                                            </EditSwitch.switch>
                                                                        </Map>
                                                                    </Div>
                                                                </Div>
                                                            </Delv.query>
                                                        </ViewAppend.container>
                                                    </QuestionSets.row>
                                                    <QuestionSets.form display={<QuestionSets.row />} />
                                                </EditSwitch.switch>
                                            </Map>
                                        </Div>
                                    </Div>
                                </OrderBy.order>
                            </Delv.query>
                        </ViewAppend.container>
                    </div>
                    <div className={`${prefix}__right`}>
                        <div className='gray-header row--justify-between'>
                            Questions
                        </div>
                        <ViewAppend.container target canEdit>
                            <Delv.query {...GET_QUESTIONS}>
                                <OrderBy.order dropdownFields={['type']}>
                                    <Question.orderByHeader>
                                        <ViewAppend.icon append={<Question.form display={<Question.row />} />}>
                                            <Add />
                                        </ViewAppend.icon>
                                    </Question.orderByHeader>
                                    <Div className='buyers-container'>
                                        <Div className='order-by-inner'>
                                            <ViewAppend.target />
                                            <Map arrayKey='_orderBy.data'>
                                                <EditSwitch.switch canEdit>
                                                    <Question.row />
                                                    <Question.form display={<Question.row />} />
                                                </EditSwitch.switch>
                                            </Map>
                                        </Div>
                                    </Div>
                                </OrderBy.order>
                            </Delv.query>
                        </ViewAppend.container>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    )
}

import React from 'react'
import Edit from '@material-ui/icons/Edit'

import Format from 'util/format/Format.js'
import IconHelper from 'common/IconHelper.js'
import { CheckBox } from 'common/FormUI.js' // this is not very flexable, revisit later

export default ({
    contactName,
    amount,
    percentage,
    locked,
    status,
    isSelected,
    onClick,
    edit,
    remove,
    cancel,
    submit,
    children,
    ...props
}) => {
    const prefix = 'vrtl-buyer-row'
    return (
        <div className={`${prefix}__container`}>
            <CheckBox onClick={onClick} value={isSelected} className={`${prefix}__icon-checkbox`} />
            <div className={`${prefix}__section--2 ${prefix}__name-container`}>
                <span>{contactName}</span>
                <span>
                    {
                        children && React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    }
                </span>
            </div>
            <div className={`${prefix}__section`}>{Format.ifString(Format.number, amount)}</div>
            <div className={`${prefix}__section`}>{percentage}</div>
            <div className={`${prefix}__section`}>{status}</div>
            {
                IconHelper([remove, cancel, [submit, status !== 'locked' && edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

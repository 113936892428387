import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default (props) => {
    const prefix = 'vrtl-buyer-row'
    const map = ({
        distributionId, id, accountId, address, amount, status, contactName,
    }) => ({
        id,
        value: {
            distributionId,
            accountId,
            address,
            amount,
            status,
            contactName,
        },
    })
    return (
        <OrderBy.bar {...props}>
            <OrderBy.select map={map} className={`${prefix}__icon-checkbox`} />
            <OrderBy.filter field='contactName' className={`${prefix}__section--2`}>
                Name
            </OrderBy.filter>
            <OrderBy.sort field='amount' className={`${prefix}__section`}>
                Amount
            </OrderBy.sort>
            <OrderBy.item className={`${prefix}__section`}>
                Percent
            </OrderBy.item>
            <OrderBy.dropdown field='status' className={`${prefix}__section`}>
                Status
            </OrderBy.dropdown>
            <div className={`${prefix}__icon-offset--3`} />
        </OrderBy.bar>
    )
}

import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import Card from 'common/Card.js'
import { ReactQueryHOC } from 'delv/delv-react'
import Format from 'util/format/Format.js'
import GridChildProvider from 'common/grid/GridChildProvider.js'
import Ledger from 'components/ledger/index.js'

import LedgerCard from './LedgerCard.js'
import './styles.scss'

const Transaction = (props) => {
    const transaction = props.transactionById

    const BREADCRUMBS = [
        {
            display: 'Dashboard',
            link: '/dashboard',
        },
        {
            display: 'Transactions',
            link: '/transactions',
        },
        {
            display: Format.blockchainAddress(transaction.id),
            link: `/transactions/${transaction.id}`,
        },
    ]

    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                <div>
                    <Card.card>
                        <Card.section>
                            <Card.label>SANCTIONED BY</Card.label>
                            {transaction.accountByBrokerId
                                ? transaction.accountByBrokerId.name
                                : 'Unknown'}
                        </Card.section>
                        <Card.section>
                            <Card.label>TRANSACTION</Card.label>
                            {transaction.id}
                        </Card.section>
                    </Card.card>
                </div>
                <h2>Ledger entries</h2>
                <GridChildProvider
                    arrayKey='nodes'
                    itemsPer={3}
                    nodes={transaction.ledgersByTransactionId}
                >
                    <LedgerCard isCapitalDeployment={transaction.chainData && transaction.chainData.paid_on} />
                </GridChildProvider>
            </Container>
            <Footer />
        </>
    )
}

const GET_TRANSACTION = (id) => `{
  transactionById(id:"${id}"){
    id
    chainData
    accountByBrokerId{
      id
      name
    }
    ledgersByTransactionId{
      nodes{
        ${Ledger.fragment.toString()}
        blockchainAddressByBlockchainAddressId{
          id
          address
          chain
          accountsBlockchainAddressesByBlockchainAddressId{
            nodes{
              id
              name
            }
          }
        }
        tokenByTokenId{
          id
          precision
          type
          roundByTokenId{
            id
            name
            assetByAssetId{
              id
              name
            }
          }
        }
        accountByInvestorId{
          id
          contactName
          type
        }
      }
    }
  }
}`

export default ReactQueryHOC(Transaction, {
    query: GET_TRANSACTION,
    args: (props) => props.match.params.transaction_id,
})

import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs/index.js'
import Container from 'common/Container/index.js'
import Footer from 'common/Footer/index.js'
import Nav from 'common/Nav/index.js'
import { ReactQueryHOC } from 'delv/delv-react'
import Logo from 'logos/Brand.js'

import './styles.scss'

const Issuer = (props) => {
    const issuer = props.accountById
    const link = (
        <a href={issuer.url} target='_blank' rel='noopener noreferrer'>
            {issuer.url}
        </a>
    )
    const BREADCRUMBS = [
        {
            display: 'Dashboard',
            link: '/dashboard',
        },
        {
            display: 'Issuers',
            link: '/issuers',
        },
        {
            active: true,
            display: issuer.name,
            link: `/issuer/${issuer.id}`,
        },
    ]

    return (
        <>
            <Nav />
            <Container className='issuer'>
                <Breadcrumbs items={BREADCRUMBS} />
                {issuer.slug ? (
                    <Logo slug={issuer.slug} />
                ) : (
                    <h2>{issuer.name}</h2>
                )}
                <div className='issuer-information'>
                    <div className='padded-card-container'>
                        <h3>Account Information</h3>
                        <div>
                            Name:
                            {issuer.name}
                        </div>
                        <div>
                            Email:
                            {issuer.email}
                        </div>
                        <div>
                            Jurisdiction:
                            {' '}
                            {issuer.countryByJurisdiction.alpha3}
                        </div>
                        <div>
                            Website:
                            {link}
                        </div>
                    </div>
                </div>
                <div />
                <div />
            </Container>
            <Footer />
        </>
    )
}

const GET_ISSUER = (id) => `{
  accountById(id:"${id}"){
    id
    name
    email
    url
    slug
    countryByJurisdiction{
      id
      name
      alpha3
    }
    assetsByIssuerId{
      nodes{
        id
        name
        authorizedTotal
      }
    }
  }
}`

export default ReactQueryHOC(Issuer, {
    query: GET_ISSUER,
    args: (props) => props.match.params.issuer_id,
})

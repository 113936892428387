import React from 'react'
import Div from 'common/Div.js'
import Card from 'common/Card.js'
import Delv from 'delv/delv-react.js'
import Asset from 'components/asset/index.js'
import Round from 'components/round/index.js'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import Security from 'components/security/index.js'
import NoneFound from 'common/NoneFound/index.js'
import Issuer from './issuer/index.js'
import './styles.scss'

export default (props) => {
    const GET_ISSUERS = {
        query: `{
            allAccounts(condition: {type: "issuer"}, filter: {assetsByIssuerIdExist: true}) {
              nodes {
                id
                type
                name
                email
                assetsByIssuerId {
                  nodes {
                    roundsByAssetId(orderBy: OPENS_ON_DESC) {
                      nodes {
                        ${Round.fragment.toString()}
                        securityBySecurityId {
                            ${Security.fragment.toString()}
                        }
                        trancheBondDatumByRoundId {
                            ${Round.tranche.fragment.toString()}
                        }
                      }
                    }
                    id
                    name
                    status
                    authorizedTotal
                    createdOn
                    assetTypeByType {
                      type
                      accountingType
                    }
                  }
                }
                countryByJurisdiction {
                  id
                  alpha3
                }
              }
            }
          }`,
        format: (response) => {
            const orderByData = response.allAccounts.nodes.map((account) => ({
                ...account,
                assetsByIssuerId: account.assetsByIssuerId.nodes.map((asset) => ({
                    ...asset,
                    type: asset.assetTypeByType.type,
                    roundsByAssetId: asset.roundsByAssetId.nodes.map((node) => {
                        const term = node.trancheBondDatumByRoundId?.term
                        const coupon = node.trancheBondDatumByRoundId?.coupon
                        const unitValue = node.securityBySecurityId.unitValue
                        const isBrokerDealer = true
                        // eslint-disable-next-line
                        return { ...node, term, coupon, unitValue, isBrokerDealer, }
                    }),
                })),
            }))
            return { orderByData }
        },
    }

    const prefix = 'vrtl-asset-broker-view'

    return (
        <Delv.query {...props} {...GET_ISSUERS}>
            <OrderBy.order>
                <Issuer.orderByHeader />
                <Map
                    arrayKey='_orderBy.data'
                    noneFound={<NoneFound size='lg'>No assets found.</NoneFound>}
                >
                    <Div className={`${prefix}__table--small ${prefix}--curved-top`}>
                        <Issuer.rowHeading offset={<div className='inline-offset' />} />
                        <Card.collapsible className='' isOpen>
                            <Issuer.row collapse={<Card.arrow />} />
                            <Asset.rowHeading
                                offset={[
                                    <div className='inline-offset' />,
                                    <div className='icon-offset' />,
                                ]}
                            />
                            <Map arrayKey='assetsByIssuerId'>
                                <Div cleanProps={({ open, setOpen, ...properties }) => properties}>
                                    <Card.collapsible className='' isOpen>
                                        <Asset.row
                                            offset={<div className='inline-offset' />}
                                            collapse={<Card.arrow />}
                                        />
                                        <Round.rowHeading
                                            offset={<div className='inline-offset--2' />}
                                        />
                                        <Map
                                            arrayKey='roundsByAssetId'
                                            noneFound={<NoneFound>No rounds found.</NoneFound>}
                                        >
                                            <Round.row
                                                offset={<div className='inline-offset--2' />}
                                            />
                                        </Map>
                                    </Card.collapsible>
                                </Div>
                            </Map>
                        </Card.collapsible>
                    </Div>
                </Map>
            </OrderBy.order>
        </Delv.query>
    )
}

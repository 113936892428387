import React from 'react'

import Delv from 'delv/delv-react'

import { Dropdown } from 'common/FormUI.js'
import GenericForm from 'common/form/GenericForm.js'

const nameTypeahead = {
    query: `{
  allOnboardingQuestions{
    nodes{
      id
      name
    }
  }
}`,
    cachePolicy: 'query',
    format: ({ allOnboardingQuestions }) => {
        const options = allOnboardingQuestions.nodes.map(({ id, name }) => ({ displayValue: name, value: id }))
        return { options }
    },
}

const formConfig = {
    fields: {
        name: {
            type: 'dropdownTypeahead',
            error: 'Name field is required',
            customUI: ({ value, type }) => {
                if (type === 'create') {
                    return (
                        <Delv.query {...nameTypeahead}>
                            <Dropdown {...value} />
                        </Delv.query>
                    )
                }
                return <div>{value.value}</div>
            },
        },
    },
}

const createQuery = `mutation($join:OnboardingQuestionJoinInput!){
  createOnboardingQuestionJoin(input:{onboardingQuestionJoin:$join}){
    onboardingQuestionJoin{
      id
      executeOrder
      onboardingQuestionSetId
      onboardingQuestionByOnboardingQuestionId{
        id
      }
      onboardingQuestionSetByOnboardingQuestionSetId{
        id
      }
    }
  }
}`

const deleteQuery = `mutation($id:UUID!){
  deleteOnboardingQuestionJoinById(input: {id: $id}) {
    onboardingQuestionJoin {
      id
      onboardingQuestionByOnboardingQuestionId {
        id
      }
      onboardingQuestionSetByOnboardingQuestionSetId {
        id
      }
    }
  }
}`

export default GenericForm({
    createField: createQuery,
    deleteField: deleteQuery,
    formConfig,
    prefix: 'vrtl-onboarding-question-row',
    createArgs: (input, props) => ({
        join: {
            onboardingQuestionId: input.name,
            onboardingQuestionSetId: props.questionSetId,
        },
    }),
})

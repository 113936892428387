import React from 'react'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation.js'
import {
    Input, Date, Dropdown, CheckBox,
} from 'common/FormUI.js'
import './VestingForm.scss'

const mutation = {
    distribution: `mutation ($id:UUID!, $vesting: VestingSchedulePatch!) {
  updateVestingScheduleById(input: {id:$id, vestingSchedulePatch:$vesting}) {
    vestingSchedule {
      id
      cliff
      periods
      automatic
      startsOn
      currentPeriod
      duration
    }
  }
}`,
    allocation: `mutation ($id: UUID!, $vesting: AllocationsVestingSchedulePatch!) {
  updateAllocationsVestingScheduleById(input: {id: $id, allocationsVestingSchedulePatch: $vesting}) {
    allocationsVestingSchedule {
      id
      cliff
      periods
      automatic
      startsOn
      duration
    }
  }
}`,
}

const VestingForm = ({
    type,
    popup: { close },
    vesting: { id },
    form: {
        automatic,
        cliff,
        periods,
        duration,
        startsOn,
        validateInput,
        getChanged,
    },
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const changedInput = getChanged(input)
            if (changedInput) {
                new Mutation({
                    mutation: mutation[type],
                    onSubmit: () => ({ id, vesting: changedInput }),
                    onResolve: close,
                }).submit()
            } else {
                close()
            }
        }
    }

    return (
        <div className='margin'>
            <h2 className='buyer-form-header'>Edit Vesting Schedule</h2>
            <div className='card'>
                <div className='vesting-section'>
                    <div className='card-label'>PERIOD DURATION</div>
                    <Dropdown
                        options={[{ duration: 'MONTHS', value: 'month' }]}
                        width='8rem'
                        {...duration}
                    />
                </div>
                <div className='vesting-section'>
                    <div className='card-label'>CLIFF PERIODS</div>
                    <div className='vesting-input-container'>
                        <Input {...cliff} />
                    </div>
                </div>
                <div className='vesting-section'>
                    <div className='card-label'>PERIODS</div>
                    <div className='vesting-input-container'>
                        <Input {...periods} />
                    </div>
                </div>

                <div className='vesting-section'>
                    <div className='card-label'>START DATE</div>
                    <Date {...startsOn} />
                </div>
            </div>
            <div className='card'>
                <div className='vesting-section'>
                    <div className='vesting-automatic-container'>
                        AUTOMATIC VESTING:
                        <CheckBox {...automatic} />
                    </div>
                </div>
            </div>
            <div className='price-form-button-container'>
                <div className='price-form-button' onClick={close}>
                    Cancel
                </div>
                <div className='price-form-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </div>
    )
}

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        cliff: {
            type: 'integer',
            error: 'Cliff is required.',
        },
        duration: {
            type: 'dropdown',
        },
        periods: {
            type: 'integer',
            error: 'Periods is required',
        },
        automatic: {
            type: 'boolean',
        },
        startsOn: {
            type: 'date',
            error: 'Starts on is required',
        },
    },
    defaultValueKey: 'vesting',
}

export default Form(VestingForm, formConfig)

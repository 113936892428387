import AccountsBlockchainAddressesFragment from 'components/accountsBlockchainAddresses/fragment'
import AccountFragment from 'components/account/Fragment'
import AssetFragment from 'components/asset/Fragment'
import BlockchainAddressFragment from 'components/blockchainAddress/fragment'
import CapTableFragment from 'components/capTable/fragment'
import ContractFragment from 'components/contract/fragment'
import TransactionFragment from 'components/transaction/fragment'
import RoundFragment from 'components/round/Fragment'
import LedgerFragment from 'components/ledger/Fragment'

import TokenFragment from './fragment.js'

const TOKENS_QUERY = (args) => `{
    allTokens${args} {
        nodes{
            ${TokenFragment}
            roundByTokenId {
                ${RoundFragment.toString()}
                assetByAssetId {
                    ${AssetFragment.toString()}
                    accountByIssuerId {
                        ${AccountFragment.toString()}
                    }
                }
            }
            contractsByTokenId {
                nodes {
                    ${ContractFragment}
                }
            }
            capTablesByTokenId {
                nodes {
                    ${CapTableFragment}
                    blockchainAddressByBlockchainAddressId {
                        ${BlockchainAddressFragment}
                        ledgersByBlockchainAddressId {
                            nodes {
                                ${LedgerFragment.toString()}
                                transactionByTransactionId {
                                    ${TransactionFragment}
                                }
                            }
                        }
                        accountsBlockchainAddressesByBlockchainAddressId {
                            nodes {
                                ${AccountsBlockchainAddressesFragment}
                            }
                        }
                    }
                }
            }
        }
    }
}`

const TOKEN_BY_ID_QUERY = (args) => `
    query TokenById {
        tokenById(id: "${args}") {
            ${TokenFragment}
        }
    }
`

export { TOKENS_QUERY, TOKEN_BY_ID_QUERY }

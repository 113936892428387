import React from 'react'
import Popup from 'common/Popup.js'

import AddCircle from '@material-ui/icons/AddCircle'

export default ({
    children,
    ...props
}) => { // qualifications are hard coded to work with popups for now
    const prefix = 'vrtl-qualification'
    return (
        <div className={`${prefix}__heading-container`}>
            <div className={`${prefix}__section`}>Order</div>
            <div className={`${prefix}__section--3`}>Name</div>
            {
                children
                    ? [
                        <div className={`${prefix}__icon-offset--2`} />,
                        React.cloneElement(children, {
                            ...props,
                            children: [children.props.children, <Popup.trigger className=''><AddCircle className={`${prefix}__icon-add`} /></Popup.trigger>],
                        }),
                    ]
                    : <div className={`${prefix}__icon-offset--3`} />
            }
        </div>
    )
}

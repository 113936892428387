import * as React from 'react'

import Chip from 'common/Chip'

// MUI requires props are spread on same element that holds ref
// Chips are not capable of holding refs: https://mui.com/material-ui/guides/composition/#caveat-with-refs
const ChipWithRef = React.forwardRef(({
    color,
    label,
    size,
    icon,
    onClick,
    ...props
}, chipRef) => (
    <div ref={chipRef} {...props}>
        <Chip
            mui
            color={color}
            label={label}
            size={size}
            icon={icon}
            onClick={onClick}
        />
    </div>
))

export default ChipWithRef

import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'
import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'

const FieldCard = ({
    name,
    value,
    valueVariable,
    roleName,
    mapTo,
    description,
    edit,
    cancel,
    submit,
}) => {
    const prefix = 'vrtl-signing-field'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--2`}>{roleName}</div>
            <div className={`${prefix}__section--2`}>{name}</div>
            {
                description
                    ? (
                        <HoverToolTip position='bottom left'>
                            <div className={`${prefix}__section--2`}>{mapTo}</div>
                            <div>{description}</div>
                        </HoverToolTip>
                    )
                    : <div className={`${prefix}__section--2`}>{mapTo}</div>
            }
            <div className={`${prefix}__section--2`}>{value || 'N/A'}</div>
            <div className={`${prefix}__section--3`}>{valueVariable}</div>
            {
                IconHelper([cancel, [submit, edit && mapTo !== 'not supported' && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

export default FieldCard

import React from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { grey } from '@mui/material/colors'

import Box from 'common/Box'
import Button from 'common/Button'
import Card from 'common/Card/index.js'
import Logo from 'common/Logo'
import Typography from 'common/Typography'

import './styles.scss'

const LoginButton = ({
    children, href, prefix = 'vrtl-login__btn', startIcon: StartIcon, title,
}) => (
    <Button className={prefix} href={href} size='large' title={title} variant='outlined'>
        <Box className={`${prefix}-icon`} sx={{ pr: 2 }}>
            {Boolean(StartIcon) && <StartIcon />}
        </Box>
        <Box className={`${prefix}-text`}>
            <Typography>{children}</Typography>
        </Box>
    </Button>
)

const Login = (props) => {
    const prefix = 'vrtl-login'

    return (
        <Box className={prefix} sx={{ px: 1, bgcolor: grey[300] }}>
            <link
                href='https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'
                rel='stylesheet'
            />
            <link href='/content/styles/styles.css' rel='stylesheet' />
            <Card className='column' sx={{ p: 6 }}>
                <Logo prefix={`${prefix}-logo`} logo='color' />
                <Box className='column' sx={{ gap: 2 }}>
                    <LoginButton
                        href={`/authenticate/login${props.location.search}`}
                        title='sign in/sign up'
                        startIcon={() => <EmailIcon data-testid='email-icon' color='primary' size='large' />}
                    >
                        Sign in / Sign up
                    </LoginButton>
                </Box>
            </Card>
        </Box>
    )
}

export default Login

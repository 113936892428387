import graphql from 'graphql-anywhere'
import gql from 'graphql-tag'

import Delv from './delv.js'
import TypeMap from './TypeMap.js'
import CacheEmitter from './CacheEmitter.js'

const networkPolicies = [
    'cache-first',
    'cache-only',
    'network-only',
    'network-once',
]

class Query {
    constructor({
        query,
        variables,
        networkPolicy,
        onFetch,
        onResolve,
        onError,
        cacheProcess,
        formatResult,
    }) {
        this.q = query
        this.variables = variables
        this.fetch = onFetch
        this.resolve = onResolve
        this.error = onError
        this.resolved = true
        this.format = formatResult
        this.id = `_${Math.random().toString(36).substr(2, 9)}`
        this.types = []
        this.networkPolicy = networkPolicy || 'network-once'
        this.format = formatResult
        this.cacheProcess = cacheProcess || 'default'
        if (this.networkPolicy !== 'network-only') {
            this.mapTypes()
        }
        this.checkPolicies()
    }

    checkPolicies = () => {
        if (!networkPolicies.includes(this.networkPolicy)) {
            throw new Error(`Network policy not allowed ${this.networkPolicy}`)
        }
    }

    mapTypes = () => {
        const ast = gql`
            ${this.q}
        `
        if (this.cacheProcess === 'query') {
            const type = ast.definitions[0].name && `__${ast.definitions[0].name.value}`
            if (type) {
                this.types.push(type)
            }
            return
        }
        const resolver = (fieldName, root, args, context, info) => {
            if (!info.isLeaf && fieldName !== 'nodes') {
                this.types.push(TypeMap.guessChildType(TypeMap.get(fieldName)))
            }
            return {}
        }
        graphql(resolver, ast, null)
    }

    addCacheListener = () => {
        CacheEmitter.on(this.id, this.onCacheUpdate)
    }

    removeCacheListener = () => {
        CacheEmitter.removeAllListeners(this.id)
    }

    query = () => {
        Delv.query({
            query: this.q,
            variables: this.variables,
            networkPolicy: this.networkPolicy,
            onFetch: this.onFetch,
            onResolve: this.onResolve,
            onError: this.onError,
            cacheProcess: this.cacheProcess,
        })
    }

    onFetch = (promise) => {
        this.resolved = false
        if (this.fetch) {
            this.fetch(promise)
        }
    }

    onResolve = (data) => {
        if (this.resolve) {
            if (this.format) {
                this.resolve(this.format(data))
            } else {
                this.resolve(data)
            }
        }
        this.resolved = true
    }

    onError = (error) => {
        if (this.error) {
            this.error(error)
        } else {
            throw new Error(
                `Unhandled graphql error recived in Delv query component: ${error.message}`,
            )
        }
    }

    removeListeners = () => {
        this.resolve = null
        this.fetch = null
        this.resolve = null
    }

    onCacheUpdate = (types) => {
        if (this.resolved) {
            const includesType = this.types.some((r) => types.includes(r))
            if (includesType) {
                this.query()
            }
        }
    }
}

export default Query

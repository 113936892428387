import React from 'react'
import Mutation from 'delv/Mutation'
import Fragment from 'customer/Fragment.js'
import ModalUpload from 'common/ModalUpload/index.js'

const BULK_UPLOAD = `mutation($fileName:String!){
  bulkCustomers(fileName:$fileName){
    ${Fragment.toString()}
  }
}`

export default (props) => {
    const importCapTable = (res) => {
        const fileName = res.data.fileName
        return new Promise((resolve, reject) => {
            new Mutation({
                mutation: BULK_UPLOAD,
                onSubmit: () => ({ fileName }),
                onError: (response) => {
                    reject(response)
                },
                onResolve: (response) => {
                    resolve(response)
                },
            }).submit()
        })
    }

    const extensions = '.csv'
    const heading = 'Import CSV'
    const exampleFileHref = '/api/v2/example/download/contacts.csv'

    return (
        <ModalUpload
            extensions={extensions}
            exampleFileHref={exampleFileHref}
            onUploadFinish={importCapTable}
            heading={heading}
            {...props}
        />
    )
}

import * as React from 'react'
import MUIContainer from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const Container = ({ sx, ...props }) => {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    return <MUIContainer data-testid='container' sx={{ p: isSm ? '1rem !important' : '2rem !important', width: '100%', ...sx }} {...props} />
}

export default Container

import React from 'react'
import NumberUI from 'common/NumberUI.js'
import HoverToolTip from 'common/HoverToolTip'
import Format from 'util/format/Format.js'
import { Decimal } from 'decimal.js'
import { isIssuer } from 'util/permissions.js'
import OpenInNew from '@material-ui/icons/OpenInNew'

export default ({
    investorName,
    issuerName,
    assetName,
    unitValue,
    termsUrl,
    amount,
    tranche,
    round: {
        roundName,
    },
    prefix = 'vrtl-holding-card-new',
    ...props
}) => {
    const latestValue = Format.number(new Decimal(amount).mul(unitValue))
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__row`}>
                <div className={`${prefix}__section--2`}>
                    <div className={`${prefix}__heading`}>{isIssuer(props) ? 'Investor' : 'Issuer'}</div>
                    <div className={`${prefix}__value ${prefix}--pascal`}>{isIssuer(props) ? investorName : issuerName}</div>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>
                        Asset
                    </div>
                    <div className={`${prefix}__value`}>
                        {assetName}
                    </div>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>
                        Round
                    </div>
                    <div className={`${prefix}__value`}>
                        {roundName}
                    </div>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>Total Units</div>
                    <div className={`${prefix}__value`}>{Format.number(amount)}</div>
                </div>
                <div className={`${prefix}__section`}>
                    <HoverToolTip width='fit-content' position='top left' arrow={false}>
                        <div className={`${prefix}__heading`}>Latest Price</div>
                        <div className='popup-contents'>
                            $ / Unit
                        </div>
                    </HoverToolTip>
                    <NumberUI className={`${prefix}__value`} prefix='$' value={unitValue} />
                </div>
                <div className={`${prefix}__section`}>
                    <HoverToolTip position='top left' arrow={false}>
                        <div className={`${prefix}__heading`}>Latest Value</div>
                        <div className='popup-contents'>
                            Last Price x Units. Last price is the most recent price per unit recorded for the security.
                        </div>
                    </HoverToolTip>
                    <NumberUI className={`${prefix}__value`} prefix='$' value={latestValue} />
                </div>
                {
                    !!tranche && (
                        <>

                            <HoverToolTip width='fit-content' position='top left' arrow={false}>
                                <div className={`${prefix}__section`}>
                                    <div className={`${prefix}__heading ${prefix}--hover-highlight`}>Term</div>
                                    <div className={`${prefix}__value`}>{Format.number(tranche.term)}</div>
                                </div>
                                <div className='popup-contents'>
                                    Years
                                </div>
                            </HoverToolTip>
                            <div className={`${prefix}__section`}>
                                <div className={`${prefix}__heading`}>Coupon</div>
                                <div className={`${prefix}__value`}>{Format.percent(tranche.coupon, 100)}</div>
                            </div>
                        </>
                    )
                }
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>Dataroom</div>
                    <a href={termsUrl} className={`${prefix}__link `}>
                        <OpenInNew className={`${prefix}__icon-open-in-new ${prefix}__section no-margin`} />
                    </a>
                </div>
            </div>
        </div>
    )
}

import * as React from 'react'

const UseToggle = (defaultValue = false) => {
    const [toggle, setToggle] = React.useState(defaultValue)

    const enable = () => {
        if (!toggle) {
            setToggle(true)
        }
    }

    const disable = () => {
        if (toggle) {
            setToggle(false)
        }
    }

    const swap = () => setToggle(!toggle)

    return {
        toggle, disable, enable, swap,
    }
}

export default UseToggle

import * as React from 'react'

const useStepper = ({ length, index = 0 }) => {
    const [activeStepIndex, setActiveStep] = React.useState(index)

    const prevStep = () => {
        const desiredNewStep = activeStepIndex - 1
        const newStep = desiredNewStep > 0 ? desiredNewStep : 0
        setActiveStep(newStep)
    }

    const nextStep = () => {
        const desiredNewStep = activeStepIndex + 1
        const newStep = desiredNewStep < length - 1 ? desiredNewStep : length - 1
        setActiveStep(newStep)
    }

    return {
        activeStepIndex,
        nextStep,
        prevStep,
        setActiveStep,
    }
}

export default useStepper

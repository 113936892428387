import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'

import Button from 'common/Button'
import Dialog from 'common/Dialog'
import DialogTitle from 'common/DialogTitle'
import UseToggle from 'hooks/UseToggle.js'

const StepIframe = ({ currentStep, iframeRef, IframeProps = {} }) => {
    const { toggle, enable, disable } = UseToggle()

    const title = currentStep?.renderAttributes?.title

    return (
        <>
            <Button variant='contained' onClick={enable}>Begin</Button>
            <Dialog
                fullScreen
                open={toggle}
                onClose={disable}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <CloseIcon onClick={disable} sx={{ cursor: 'pointer' }} />
                </DialogTitle>
                <iframe
                    className='vrtl-process-iframe'
                    data-testid='iframe-step'
                    ref={iframeRef}
                    src={currentStep.schema.link}
                    key={currentStep.id}
                    {...IframeProps}
                />
            </Dialog>
        </>
    )
}

export default StepIframe

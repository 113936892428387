import * as React from 'react'
import EmailIcon from '@mui/icons-material/Email'

import Link from 'common/Link'

const SupportEmailIcon = ({ email, ...props }) => (
    <Link sx={{ pt: 0.5 }} href={`mailto:${email}`}>
        <EmailIcon {...props} fontSize='14' />
    </Link>
)

export default SupportEmailIcon

import React from 'react'
import IconHelper from 'common/IconHelper.js'

import './styles.scss'

export default ({
    role,
    status,
    edit,
    email,
    firstName,
    lastName,
    cancel,
    submit,
}) => {
    const roleDictionary = {
        account_owner: 'Owner',
        account_admin: 'Admin',
        account_viewer: 'Viewer',
    }

    const prefix = 'vrtl-delegate-card'

    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--2`}>
                <div className={`${prefix}__label`}>
                    FIRST NAME
                </div>
                {firstName}
            </div>
            <div className={`${prefix}__section--2`}>
                <div className={`${prefix}__label`}>
                    LAST NAME
                </div>
                {lastName}
            </div>
            <div className={`${prefix}__section--3`}>
                <div className={`${prefix}__label`}>
                    EMAIL
                </div>
                {email}
            </div>
            <div className={`${prefix}__section`}>
                <div className={`${prefix}__label`}>
                    ROLE
                </div>
                {
                    submit ? role : roleDictionary[role]
                }
            </div>
            <div className={`${prefix}__section`}>
                <div className={`${prefix}__label`}>
                    STATUS
                </div>
                {status}
            </div>
            <div className={`${prefix}__icon-container`}>
                {
                    IconHelper([false, cancel, false, submit || edit], prefix)
                }
            </div>
        </div>
    )
}

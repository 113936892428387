import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import OrderBy from 'common/OrderBy.js'
import Delv from 'delv/delv-react'
import Div from 'common/Div.js'
import Map from 'common/Map.js'

import InvestorCard from './InvestorCard.js'
import Summary from './Summary.js'
import { CapByStakeholder, SOPByStakeholder } from './queries.js'
import CapitalizationViewSwitch from './CapitalizationViewSwitch.js'
import './styles.scss'

const BREADCRUMBS = [
    {
        display: 'Capitalization',
        link: '/capitalization',
        active: true,
    },
]

const CapTable = () => (
    <Delv.query {...CapByStakeholder} key='CapByStakeholder'>
        <Div className='column flex-1'>
            <Summary />
            <OrderBy.order dropdownFields={['asset', 'status']}>
                <OrderBy.bar>
                    <OrderBy.filter field='name'>
                        Investor
                    </OrderBy.filter>
                    <OrderBy.sort field='amount'>
                        Units
                    </OrderBy.sort>
                    <OrderBy.dropdown field='asset' width='10rem'>
                        Asset
                    </OrderBy.dropdown>
                    <OrderBy.dropdown field='status' width='5rem'>
                        Status
                    </OrderBy.dropdown>
                </OrderBy.bar>
                <Div className='buyers-container'>
                    <Div className='order-by-inner'>
                        <Map arrayKey='_orderBy.data'>
                            <InvestorCard />
                        </Map>
                    </Div>
                </Div>
            </OrderBy.order>
        </Div>
    </Delv.query>
)

const ScheduleOfPurchasers = () => (
    <Delv.query {...SOPByStakeholder} key='SOPByStakeholder'>
        <Div className='column flex-1'>
            <Summary />
            <OrderBy.order dropdownFields={['asset']}>
                <OrderBy.bar>
                    <OrderBy.filter field='name'>
                        Investor
                    </OrderBy.filter>
                    <OrderBy.sort field='amount'>
                        Units
                    </OrderBy.sort>
                    <OrderBy.dropdown field='asset' width='10rem'>
                        Asset
                    </OrderBy.dropdown>
                    <OrderBy.sort date field='paidOn'>
                        Paid On
                    </OrderBy.sort>
                </OrderBy.bar>
                <Div className='buyers-container'>
                    <Div className='order-by-inner'>
                        <Map arrayKey='_orderBy.data'>
                            <InvestorCard />
                        </Map>
                    </Div>
                </Div>
            </OrderBy.order>
        </Div>
    </Delv.query>
)

const Capitalization = () => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            <CapitalizationViewSwitch>
                <CapTable />
                <ScheduleOfPurchasers />
            </CapitalizationViewSwitch>
        </Container>
        <Footer />
    </>
)

export default Capitalization

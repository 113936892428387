import { createTheme as MUICreateTheme } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import { isSm } from 'constants/media-queries'

const primary = {
    main: '#004A98',
    contrastText: '#ffffff',
}

const secondary = {
    main: '#008FBE',
}

const createTheme = (options) => {
    const themeOptions = {
        components: {
            MuiContainer: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: 'calc(100vh - 10.5rem)',
                        maxWidth: '100% !important',
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        alignItems: 'center',
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    colorPrimary: {
                        color: `${window.branding?.palette?.primary?.main || primary.main} !important`,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        padding: '0 0.25rem',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: 'inherit',
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: 'inherit',
                        textTransform: 'capitalize',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        textDecorationColor: theme.palette.primary.main,
                    }),
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: `${theme.palette.primary.main} !important`,
                        backgroundColor: 'white',
                        borderBottom: `2px ${theme.palette.primary.main} solid`,
                        borderRadius: '0.25rem 0.25rem 0 0',
                    }),
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: ({ theme, ownerState }) => ({
                        color: `${theme.palette.primary.main} !important`,
                        backgroundColor: 'white',
                        ...(ownerState.selected && {
                            backgroundColor: 'white',
                            border: `2px ${theme.palette.primary.main} solid`,
                            borderBottom: '0',
                            borderRadius: '0.25rem 0.25rem 0 0',
                        }),
                    }),
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.fullScreen && {
                            margin: '3rem',
                            [isSm]: {
                                margin: 0,
                            },
                        }),
                    }),
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'inherit',
                    },
                },
            },
        },
        palette: {
            primary: {
                main: primary.main,
                contrastText: primary.contrastText,
                ...window.branding?.palette?.primary,
            },
            secondary: {
                main: secondary.main,
                ...window.branding?.palette?.secondary,
            },
            cancel: {
                main: blueGrey[700],
            },
        },
        typography: {
            fontFamily: '"Inter", "Roboto", -apple-system, sans-serif',
        },
        ...options,
    }

    const theme = MUICreateTheme(themeOptions)

    return theme
}

export { createTheme }

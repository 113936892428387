import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Input, Date as DateInput } from 'common/FormUI.js'

import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'

import Row from './Row.js'
import Fragment from './Fragment.js'

const UPDATE_DISTRIBUTION_PAYMENT = `mutation($id: UUID!, $distributionPayment: DistributionPaymentPatch!) {
  updateDistributionPaymentById(input:{id: $id, distributionPaymentPatch:$distributionPayment}){
    distributionPayment{
      ${Fragment.toString()}
      distributionByDistributionId {
        id
        allocationByAllocationId {
          id
          accountByOwnerId{
            id
            holdings
          }
          roundByRoundId {
            id
            holdings
          }
        }
      }
    }
  }
}`

const MAKE_DISTRIBUTION_PAYMENT = `mutation($distributionPayment:DistributionPaymentInput!){
  createDistributionPayment(input:{distributionPayment:$distributionPayment}){
    distributionPayment{
      ${Fragment.toString()}
      distributionByDistributionId {
        id
        allocationByAllocationId {
          id
          accountByOwnerId{
            id
            holdings
          }
          roundByRoundId {
            id
            holdings
          }
        }
      }
    }
  }
}`

const DELETE_FIELD = `mutation ($id: UUID!) {
  deleteDistributionPaymentById(input: {id: $id}) {
    distributionPayment{
      ${Fragment.toString()}
      distributionByDistributionId {
        id
        allocationByAllocationId {
          id
          accountByOwnerId{
            id
            holdings
          }
          roundByRoundId {
            id
            holdings
         }
        }
      }
    }
  }
}
`

const PaymentForm = ({
    id,
    resolve,
    distributionId,
    form: {
        amount,
        paidOn,
        validateInput,
        getChanged,
        setLoading,
        loading,
    },
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    const submitValue = {
                        id,
                        distributionPayment: changedInput,
                    }
                    setLoading('create')
                    new Mutation({
                        mutation: UPDATE_DISTRIBUTION_PAYMENT,
                        onSubmit: () => submitValue,
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            } else {
                const submitValue = {
                    distributionPayment: {
                        distributionId,
                        amount: input.amount,
                        paidOn: input.paidOn,
                    },
                }
                setLoading('create')
                new Mutation({
                    mutation: MAKE_DISTRIBUTION_PAYMENT,
                    onSubmit: () => submitValue,
                    onResolve: resolve,
                }).submit()
            }
        }
    }

    const deleteRow = (event) => {
        // TODO This will need a custom cache resolver to update account.holdings
        event.preventDefault()
        setLoading('delete')
        new Mutation({
            mutation: DELETE_FIELD,
            onSubmit: () => ({ id }),
            onResolve: resolve,
            cacheProcess: (cacheData, mutationData, emitter, cache) => {
                cache.remove(mutationData)
                const allocation = mutationData.deleteDistributionPaymentById.distributionPayment.distributionByDistributionId.allocationByAllocationId
                const account = allocation.accountByOwnerId
                const round = allocation.roundByRoundId
                cacheData.Round[round.id].holdings = round.holdings
                cacheData.Account[account.id].holdings = account.holdings
            },
        }).submit()
    }

    if (loading === 'create') {
        return <Row amount={amount.value} paidOn={paidOn.value} />
    } if (loading === 'delete') {
        return <div />
    }

    const prefix = 'vrtl-payment'

    const newDisplayProps = {
        key: `${prefix}-${id}`,
        amount: <Input {...amount} />,
        paidOn: <DateInput {...paidOn} />,
        remove: id && <Delete Delete className={`${prefix}__icon-delete`} onClick={deleteRow} />,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
    }

    return <Row {...newDisplayProps} />
}

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        amount: {
            type: 'number',
            error: 'Payment amount is required',
            required: true,
        },

        paidOn: {
            type: 'date',
            error: 'Paid On is a required field.',
        },
    },
}

export default Form(PaymentForm, formConfig)

import { useEffect } from 'react'
import useFormView from 'hooks/UseFormView'

const ATTESTATION = `I certify the information I provided on and in connection with this form is true and correct to the best of my knowledge. 
I also understand that any false statements or deliberate omissions on this form may subject me to legal actions.`

const attestationConfig = {
    fields: {
        attestation: {
            type: 'checkbox',
            label: ATTESTATION,
            errorMessage: 'Please accept the attestation to proceed.',
            onSubmit: (value) => ((value === false || value === '') ? { value, errorExists: true } : true),
        },
    },
}

const Attestation = ({ emitter, stateHandler }) => {
    const { validateInput, formView: { attestation } } = useFormView(attestationConfig)

    useEffect(() => {
        const handleAttestationInput = () => {
            const { valid, input } = validateInput()
            if (valid) {
                stateHandler.account({ attestation: input.attestation })
            } else {
                stateHandler.error()
            }
        }

        emitter.on('submitInput', handleAttestationInput)
        return () => emitter.removeListener('submitInput', handleAttestationInput)
    })

    return attestation
}

export default Attestation

import Heading from './Heading.js'
import Row from './Row.js'
import Form from './Form.js'
import Fragment from './Fragment.js'
import RowHeading from './RowHeading.js'
import OrderByheader from './OrderByHeader.js'

import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    form: Form,
    heading: Heading,
    rowHeading: RowHeading,
    orderByHeader: OrderByheader,
}

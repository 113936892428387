import React from 'react'

export default ({
    id,
    name,
    email,
    countryByJurisdiction: { alpha3 },
    collapse,
    offset = '',
    ...props
}) => {
    const prefix = 'vrtl-assets-issuer-card'
    return (
        <div className={`${prefix}__container--row`}>
            <div className={`${prefix}__section--3`}>
                {offset}
                {
                    collapse
                && React.cloneElement(collapse, props)
                }
                {name}
            </div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`}>{email}</div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section`}>{alpha3}</div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__icon-offset--2`} />
            <div className={`${prefix}__icon-offset--2`} />
        </div>
    )
}

const selectSortType = (sort) => {
    const map = {
        asc: 'ASC',
        desc: 'DESC',
    }

    return map[sort] || null
}

export { selectSortType }

import React from 'react'
import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import AccountSetupForm from 'components/account/setup'
import Switch from 'common/Switch.js'

import Delv from 'delv/delv-react'

const BREADCRUMBS = [
    {
        active: true,
        display: 'Account Setup',
        link: '/settings/account/setup',
    },
]

const query = (id) => `{
    accountById(id:"${id}"){
        id
        setup
    }
}`

const AccountSetup = ({
    account,
}) => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            <Delv.query query={query(account.id)}>
                <Switch view={({ accountById: { setup } }) => Number(setup)}>
                    <div style={{ margin: '0 auto' }}>
                        <AccountSetupForm />
                    </div>
                    <div style={{ margin: '0 auto' }}>
                        <h2>Thank you for setting up your account!</h2>
                    </div>
                </Switch>
            </Delv.query>
        </Container>
        <Footer />
    </>
)

export default AccountSetup

import * as React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'

const PageLayout = ({ breadcrumbs, children }) => (
    <>
        <Nav />
        <Container>
            {Boolean(breadcrumbs) && <Breadcrumbs items={breadcrumbs} />}
            {children}
        </Container>
        <Footer />
    </>
)

export default PageLayout

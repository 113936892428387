import * as React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ToggleCell = ({ id, api }) => {
    const isExpanded = api.getExpandedDetailPanels().includes(id)
    return (isExpanded ? (
        <KeyboardArrowDownIcon
            id={id}
            color='primary'
            value={isExpanded}
            sx={{ cursor: 'pointer' }}
        />
    ) : (
        <KeyboardArrowRightIcon
            id={id}
            color='primary'
            value={isExpanded}
            sx={{ cursor: 'pointer' }}
        />
    ))
}

export default ToggleCell

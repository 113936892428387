import * as React from 'react'

import FormControl from 'common/FormControl'
import FormControlLabel from 'common/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from 'common/FormLabel'
import Radio from 'common/Radio'
import RadioGroup from 'common/RadioGroup'

const InputRadio = ({
    id: propId,
    'data-testid': testId,
    helperText,
    label,
    name,
    onChange,
    options,
    error,
    defaultValue: propDefault,
    RadioGroupProps = { sx: {} },
    RadioProps = {},
    value,
}) => {
    const id = propId || label?.replace('/ /g', '-')
    const radioGroupStyles = RadioGroupProps.sx || {}
    const defaultValue = propDefault || propDefault === null || propDefault === 0 ? propDefault : options[0].value

    return (
        <FormControl data-testid={testId} error={error}>
            <FormLabel id={id}>{label}</FormLabel>
            <RadioGroup
                aria-labelledby={id}
                name={name}
                defaultValue={defaultValue}
                value={value}
                sx={{ flexDirection: 'row', ...radioGroupStyles }}
                {...RadioGroupProps}
            >
                { options.map((option) => (
                    <FormControlLabel
                        control={<Radio />}
                        label={option.label}
                        key={option.label}
                        onChange={onChange}
                        value={option.value}
                        {...RadioProps}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default InputRadio

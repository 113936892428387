const selectChainSymbol = ({ chain }) => {
    const baseChain = chain.split('-')

    const map = {
        tezos: 'XTZ',
        ethereum: 'ETH',
    }

    return map[baseChain[0]] || undefined
}

export {
    selectChainSymbol,
}

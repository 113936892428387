import React, { useState } from 'react'
import Edit from '@material-ui/icons/Edit'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'

import './NewEditSwitch.scss'

const Switch = ({
    children, canEdit, editIcon, prefix = 'vrtl-new-edit-switch', className, ...props
}) => {
    const [edit, setEdit] = useState(false)
    const [display, form] = children
    let editAllowed
    if (canEdit instanceof Function) {
        editAllowed = canEdit(props)
    } else {
        editAllowed = canEdit
    }

    if (editAllowed) {
        return (
            <>
                {edit
                    ? SafeReact.cloneChildren(form, {
                        className,
                        ...props,
                        resolve: () => setEdit(false),
                    })
                    : SafeReact.cloneChildren(display, {
                        className,
                        ...props,
                        edit: editIcon ? <Edit className={cx(`${prefix}__icon-edit`, className)} onClick={() => setEdit(true)} /> : () => setEdit(true),
                    })}
            </>
        )
    }
    return SafeReact.cloneChildren(display, props)
}

const Trigger = ({ children, edit, ...props }) => SafeReact.cloneChildren(children, { onClick: edit, ...props })

const HOC = (WrappedComponent, WrappedComponent2) => (props) => (
    <Switch {...props}>
        <WrappedComponent />
        <WrappedComponent2 />
    </Switch>
)

export default {
    switch: Switch,
    trigger: Trigger,
    hoc: HOC,
}

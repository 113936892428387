import { Decimal } from 'decimal.js'

import { selectAssetType } from 'selectors/asset-selectors'
import { selectChainSymbol } from 'selectors/chain-selectors'

const selectDistributionRound = ({ investment }) => investment.allocationByAllocationId.roundByRoundId

const selectInvestmentRound = ({ investment }) => {
    const isHolding = investment.__typename === 'Holding'

    if (isHolding) {
        return investment.securityBySecurityId.roundsBySecurityId.nodes[0]
    }

    return investment.allocationByAllocationId.roundByRoundId
}

const selectDistributionPrice = ({ investment, USDID }) => {
    const usdDistributionPrice = investment.distributionPricesByDistributionId?.nodes.find(({ currency }) => currency === USDID)

    if (!usdDistributionPrice) {
        const round = selectInvestmentRound({ investment })
        return round.price
    }

    return usdDistributionPrice.price
}

const selectInvestmentAsset = ({ investment }) => {
    const round = selectInvestmentRound({ investment })

    return round.assetByAssetId
}

const selectDistributionStatus = ({ investment }) => {
    if (investment.status === 'open') {
        return 'Onboarding'
    }

    if (investment.status === 'closed') {
        return 'Pending'
    }

    return investment.status
}

const selectInvestmentStatus = ({ investment }) => {
    const isHolding = investment.__typename === 'Holding'

    if (isHolding) {
        return 'Issued'
    }

    if (investment.status === 'open') {
        return 'Onboarding'
    }

    if (investment.status === 'closed') {
        return 'Pending'
    }

    return investment.status
}

const selectInvestmentTokenization = ({ investment }) => {
    const isHolding = investment.__typename === 'Holding'

    if (!isHolding) {
        return undefined
    }

    const addresses = investment.holdingBlockchainAddressesByHoldingId.nodes
        ?.map((blockchainAddress) => blockchainAddress.blockchainAddressByBlockchainAddressId.chain)
        .filter(Boolean)

    if (!addresses?.length) {
        return undefined
    }

    const chain = addresses.reduce((acc, cur) => (
        acc === selectChainSymbol({ chain: cur }) ? acc : undefined
    ), selectChainSymbol({ chain: addresses[0] }))

    return chain
}

const selectInvestmentPurchasePrice = ({ investment }) => {
    const isHolding = investment.__typename === 'Holding'

    const investmentPurchasePrice = isHolding ? investment.purchasePrice : investment.purchasePrice?.price

    return investmentPurchasePrice || 0
}

const selectHoldingPurchasePrice = ({ investment }) => investment.purchasePrice

const selectDistributionPurchasePrice = ({ investment }) => (investment.distributionPricesByDistributionId?.nodes[0]?.price || 0)

const selectAmount = ({ investment }) => investment.amount

const selectInvestmentInitial = ({ investment }) => {
    const purchasePrice = selectInvestmentPurchasePrice({ investment })
    const amount = selectAmount({ investment })

    const investmentTotal = new Decimal(amount).mul(purchasePrice).toString()

    return investmentTotal
}

const selectDistributionInitial = ({ investment }) => {
    const purchasePrice = selectDistributionPurchasePrice({ investment })
    const amount = selectAmount({ investment })

    const investmentTotal = new Decimal(amount).mul(purchasePrice).toString()

    return investmentTotal
}

const selectDistributionPaidInCapital = ({ investment }) => (investment.paidInCapital || 0)

const selectInvestmentPaidInCapital = ({ investment }) => {
    /*
        Paid in capital is a pre-issuance record of how much an investor has paid for their shares.
        Post-issuance, '0' means the investor has nothing left to pay.
    */
    const isHolding = investment.__typename === 'Holding'
    const paidInCapital = isHolding ? 0 : investment.paidInCapital

    return paidInCapital || 0
}

const selectInvestmentPercentPaid = ({ investment }) => {
    const paid = selectInvestmentPaidInCapital({ investment })
    const total = selectInvestmentInitial({ investment })

    const percentage = new Decimal(paid).div(total).mul(100).toString()

    return percentage
}

const selectLatestPrice = ({ investment, USDID }) => {
    if (investment.__typename === 'Holding') {
        return investment.securityBySecurityId.unitValue
    }

    return selectDistributionPrice({ investment, USDID })
}

const selectInvestmentValue = ({ investment, USDID }) => {
    const price = selectLatestPrice({ investment, USDID })

    const investmentValue = new Decimal(investment.amount).mul(price).toString()

    return investmentValue
}

const selectInvestmentCapitalDeployments = ({ investment }) => {
    const asset = selectInvestmentAsset({ investment })
    const assetType = selectAssetType({ asset })

    if (assetType !== 'fund') {
        return {
            deployed: undefined,
            undeployed: undefined,
        }
    }

    const round = selectInvestmentRound({ investment })
    const paidInCapital = selectInvestmentPaidInCapital({ investment })
    const initialInvestment = selectInvestmentInitial({ investment })
    const totalDeployed = round.totalCapitalDeployed
    const roundValue = round.total * round.price

    const ratio = new Decimal(investment.amount)
        .div(round.total)
        .mul(
            new Decimal(paidInCapital)
                .div(initialInvestment),
        )

    const deployed = totalDeployed ? ratio.mul(totalDeployed) : 0
    const undeployed = totalDeployed ? ratio.mul(roundValue).sub(deployed) : 0

    return {
        deployed,
        undeployed,
    }
}

const selectQualificationActive = ({ investment }) => {
    const qualifications = investment.stepByStepId?.shortSteps?.nodes || []

    qualifications.sort((a, b) => a.order - b.order)

    const activeQualification = qualifications.find((qual) => qual.status !== 'complete')

    return activeQualification
}

const selectQualificationActivityDisplay = ({
    activePosition,
    activeQualification,
    total,
    steps,
    qualificationsComplete,
}) => {
    // Steps are null if if the viewer is not the owner of the qualification.
    // Thus, they are only allowed a generic view into the progress of the qualification.
    if (steps === null) {
        return qualificationsComplete ? 'Finished' : 'Not Finished'
    }

    if (total === 0) {
        return 'No Qualifications'
    }

    if (activePosition === 1) {
        return 'Not Started'
    }

    if (!activeQualification) {
        return 'Finished'
    }

    return `${activePosition} of ${total}`
}

const selectDistributionActiveQualificationInfo = ({ investment }) => {
    const steps = investment.stepByStepId
    const shortSteps = investment.stepByStepId?.shortSteps
    const totalQualifications = shortSteps?.nodes.length
    const { qualificationsComplete } = investment

    const activeQualification = selectQualificationActive({ investment })
    const activePosition = activeQualification?.order
    const qualificationName = activeQualification?.renderAttributes?.title

    const qualificationActivityDisplay = selectQualificationActivityDisplay({
        activePosition,
        activeQualification,
        total: totalQualifications,
        steps,
        qualificationsComplete,
    })

    return {
        qualificationActivityDisplay,
        qualificationName,
    }
}

const selectInvestmentActiveQualificationInfo = ({ investment }) => {
    const isHolding = investment.__typename === 'Holding'
    if (isHolding) {
        return {}
    }

    const steps = investment.stepByStepId
    const shortSteps = investment.stepByStepId?.shortSteps
    const totalQualifications = shortSteps?.nodes.length
    const { qualificationsComplete } = investment

    const activeQualification = selectQualificationActive({ investment })
    const activePosition = activeQualification?.order
    const qualificationName = activeQualification?.renderAttributes?.title

    const qualificationActivityDisplay = selectQualificationActivityDisplay({
        activePosition,
        activeQualification,
        total: totalQualifications,
        steps,
        qualificationsComplete,
    })

    return {
        qualificationActivityDisplay,
        qualificationName,
    }
}

const selectIsTokenized = ({ investment }) => {
    const isTokenized = investment.holdingBlockchainAddressesByHoldingId.nodes.some((node) => (
        node.blockchainAddressByBlockchainAddressId.chain !== 'vertalon-hawknet' && node.status === 'trade'
    ))

    return isTokenized
}

const selectPercentOfSecurityTotal = ({ investment }) => {
    const total = new Decimal(investment.securityBySecurityId.total)
    const percent = total.cmp(0) ? (new Decimal(investment.amount).div(total).mul(100)) : null
    return percent
}

export {
    selectAmount,
    selectDistributionActiveQualificationInfo,
    selectDistributionInitial,
    selectDistributionPaidInCapital,
    selectDistributionPurchasePrice,
    selectDistributionRound,
    selectDistributionStatus,
    selectHoldingPurchasePrice,
    selectInvestmentActiveQualificationInfo,
    selectInvestmentAsset,
    selectInvestmentCapitalDeployments,
    selectInvestmentInitial,
    selectInvestmentPaidInCapital,
    selectInvestmentPercentPaid,
    selectInvestmentPurchasePrice,
    selectInvestmentRound,
    selectInvestmentStatus,
    selectInvestmentTokenization,
    selectInvestmentValue,
    selectIsTokenized,
    selectLatestPrice,
    selectPercentOfSecurityTotal,
}

const multiSelect = (prop, state) => {
    const old = Object.keys(prop)
    const newV = Object.keys(state)
    if (old.length === newV.length) {
        const join = [...old, ...newV]
        const unique = join.filter(
            (item, index) => join.indexOf(item) === index,
        )
        return old.length !== unique.length
    }
    return true
}

const straightCheck = (prop, state) => prop !== state

const always = () => true

export default {
    email: straightCheck,
    link: always,
    string: straightCheck,
    date: straightCheck,
    number: straightCheck,
    boolean: straightCheck,
    dropdown: straightCheck,
    multiSelect,
    blockchainAddress: straightCheck,
    color: straightCheck,
    integer: straightCheck,
    twitterHandle: straightCheck,
    password: straightCheck,
    phone: straightCheck,
    dropdownTypeahead: straightCheck,
    checkbox: straightCheck,
    textArea: straightCheck,
}

const states = [
    {
        label: 'Alabama',
        value: 'Alabama',
        type: 'dropdown',
    },
    {
        label: 'Alaska',
        value: 'Alaska',
        type: 'dropdown',
    },
    {
        label: 'American samoa',
        value: 'American samoa',
        type: 'dropdown',
    },
    {
        label: 'Arizona',
        value: 'Arizona',
        type: 'dropdown',
    },
    {
        label: 'Arkansas',
        value: 'Arkansas',
        type: 'dropdown',
    },
    {
        label: 'California',
        value: 'California',
        type: 'dropdown',
    },
    {
        label: 'Colorado',
        value: 'Colorado',
        type: 'dropdown',
    },
    {
        label: 'Connecticut',
        value: 'Connecticut',
        type: 'dropdown',
    },
    {
        label: 'Delaware',
        value: 'Delaware',
        type: 'dropdown',
    },
    {
        label: 'District of columbia',
        value: 'District of columbia',
        type: 'dropdown',
    },
    {
        label: 'Florida',
        value: 'Florida',
        type: 'dropdown',
    },
    {
        label: 'Georgia',
        value: 'Georgia',
        type: 'dropdown',
    },
    {
        label: 'Guam',
        value: 'Guam',
        type: 'dropdown',
    },
    {
        label: 'Hawaii',
        value: 'Hawaii',
        type: 'dropdown',
    },
    {
        label: 'Idaho',
        value: 'Idaho',
        type: 'dropdown',
    },
    {
        label: 'Illinois',
        value: 'Illinois',
        type: 'dropdown',
    },
    {
        label: 'Indiana',
        value: 'Indiana',
        type: 'dropdown',
    },
    {
        label: 'Iowa',
        value: 'Iowa',
        type: 'dropdown',
    },
    {
        label: 'Kansas',
        value: 'Kansas',
        type: 'dropdown',
    },
    {
        label: 'Kentucky',
        value: 'Kentucky',
        type: 'dropdown',
    },
    {
        label: 'Louisiana',
        value: 'Louisiana',
        type: 'dropdown',
    },
    {
        label: 'Maine',
        value: 'Maine',
        type: 'dropdown',
    },
    {
        label: 'Maryland',
        value: 'Maryland',
        type: 'dropdown',
    },
    {
        label: 'Massachusetts',
        value: 'Massachusetts',
        type: 'dropdown',
    },
    {
        label: 'Michigan',
        value: 'Michigan',
        type: 'dropdown',
    },
    {
        label: 'Minnesota',
        value: 'Minnesota',
        type: 'dropdown',
    },
    {
        label: 'Mississippi',
        value: 'Mississippi',
        type: 'dropdown',
    },
    {
        label: 'Missouri',
        value: 'Missouri',
        type: 'dropdown',
    },
    {
        label: 'Montana',
        value: 'Montana',
        type: 'dropdown',
    },
    {
        label: 'Nebraska',
        value: 'Nebraska',
        type: 'dropdown',
    },
    {
        label: 'Nevada',
        value: 'Nevada',
        type: 'dropdown',
    },
    {
        label: 'New hampshire',
        value: 'New hampshire',
        type: 'dropdown',
    },
    {
        label: 'New jersey',
        value: 'New jersey',
        type: 'dropdown',
    },
    {
        label: 'New mexico',
        value: 'New mexico',
        type: 'dropdown',
    },
    {
        label: 'New york',
        value: 'New york',
        type: 'dropdown',
    },
    {
        label: 'North carolina',
        value: 'North carolina',
        type: 'dropdown',
    },
    {
        label: 'North dakota',
        value: 'North dakota',
        type: 'dropdown',
    },
    {
        label: 'Northern mariana',
        value: 'Northern mariana',
        type: 'dropdown',
    },
    {
        label: 'Ohio',
        value: 'Ohio',
        type: 'dropdown',
    },
    {
        label: 'Oklahoma',
        value: 'Oklahoma',
        type: 'dropdown',
    },
    {
        label: 'Oregon',
        value: 'Oregon',
        type: 'dropdown',
    },
    {
        label: 'Pennsylvania',
        value: 'Pennsylvania',
        type: 'dropdown',
    },
    {
        label: 'Puerto rico',
        value: 'Puerto rico',
        type: 'dropdown',
    },
    {
        label: 'Rhode island',
        value: 'Rhode island',
        type: 'dropdown',
    },
    {
        label: 'South carolina',
        value: 'South carolina',
        type: 'dropdown',
    },
    {
        label: 'South dakota',
        value: 'South dakota',
        type: 'dropdown',
    },
    {
        label: 'Tennessee',
        value: 'Tennessee',
        type: 'dropdown',
    },
    {
        label: 'Texas',
        value: 'Texas',
        type: 'dropdown',
    },
    {
        label: 'Utah',
        value: 'Utah',
        type: 'dropdown',
    },
    {
        label: 'Vermont',
        value: 'Vermont',
        type: 'dropdown',
    },
    {
        label: 'Virginia',
        value: 'Virginia',
        type: 'dropdown',
    },
    {
        label: 'Virgin islands',
        value: 'Virgin islands',
        type: 'dropdown',
    },
    {
        label: 'Washington',
        value: 'Washington',
        type: 'dropdown',
    },
    {
        label: 'West virginia',
        value: 'West virginia',
        type: 'dropdown',
    },
    {
        label: 'Wisconsin',
        value: 'Wisconsin',
        type: 'dropdown',
    },
    {
        label: 'Wyoming',
        value: 'Wyoming',
        type: 'dropdown',
    },
]

export default states

import React from 'react'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation'

import Account from 'components/account/index.js'
import Distribution from 'components/distribution/index.js'

import SafeReact from 'util/SafeReact.js'

const formConfig = {
    fields: {
        distribution: {
            type: 'multiSelect',
        },
    },
}

const IssuanceForm = ({
    children,
    form: {
        distribution,
        validateInput,
    },
    ...props
}) => {
    const ISSUE_DISTRIBUTION = `mutation ($distribution: [UUID!]!) {
  makeTaRequestDistributionIssuances(input: {distributionIds: $distribution}) {
      query{
          allDistributions(filter:{id:{in:$distribution}}){
              nodes{
              issuanceStatus
              ${Distribution.fragment.toString()}
              accountByInvestorId{
                  ${Account.fragment.toString()}
              }
        }
      }
    }
  }
}`

    const handleSubmit = () => {
        const input = validateInput()
        if (!input.hasErrors) {
            new Mutation({
                mutation: ISSUE_DISTRIBUTION,
                onSubmit: () => ({ distribution: Object.keys(input.distribution) }),
            }).submit()
            props.popup.close()
        }
    }

    return (
        <div>
            <h2 className='header'>Issuance</h2>
            {SafeReact.cloneChildren(children, { ...distribution, ...props })}
            <div className='row row--justify-between'>
                <div className='styled-button' onClick={props.popup.close}>Cancel</div>
                <div className='styled-button' onClick={handleSubmit}>Submit</div>
            </div>
        </div>
    )
}

export default Form(IssuanceForm, formConfig)

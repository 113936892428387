import React from 'react'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import cx from 'classnames'

import './styles.scss'

const Square = ({
    isSelected, classOverride, sxOverride, prefix,
}) => (
    <>
        {isSelected && <CropSquareIcon className={cx(`${prefix}--icon-size-xsm`, classOverride)} sx={sxOverride} />}
    </>
)

export default Square

import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import Delv from 'delv/delv-react.js'
import Security from 'components/security/index.js'
import MultiSelect from 'common/MultiSelect.js'
import Document from 'components/document/index.js'
import SecurityDocument from 'components/securityDocument/index.js'
import HoldingDocument from 'components/holdingDocument/index.js'
import Switch from 'common/Switch.js'
import Asset from 'components/asset/index.js'
import Holding from 'components/holding/index.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'
import Collapsible from 'common/collapsible/index.js'
import Account from 'components/account/index.js'
import InvestorDocument from 'components/investorDocument/index.js'
import NoneFound from 'common/NoneFound/index.js'
import Round from 'components/round/index.js'
import QuerySelector from 'common/querySelector/index.js'

import './styles.scss'

const GET_DOCUMENTS = {
    queries: {
        securities: `allSecurities {
            nodes {
              ${Security.fragment.toString()}
              roundsBySecurityId{
                  nodes{
                      ${Round.fragment.toString()}
                      assetByAssetId{
                          ${Asset.fragment.toString()}
                          accountByIssuerId{
                              ${Account.fragment.toString()}
                          }
                      }
                  }
              }
              securityDocumentsBySecurityId {
                nodes {
                  ${SecurityDocument.fragment.toString()}
                  documentByDocumentId {
                    ${Document.fragment.toString()} 
                    documentSharingsByDocumentId{
                        nodes{
                            ${Document.sharing.fragment.toString()}
                        }
                    }
                  }
                }
              }
            }
          }`,
        holdings: `allHoldings {
            nodes {
              ${Holding.fragment.toString()}
              securityBySecurityId {
                  ${Security.fragment.toString()}
                  roundsBySecurityId {
                    nodes {
                      ${Round.fragment.toString()}
                      assetByAssetId {
                        ${Asset.fragment.toString()}
                        accountByIssuerId {
                            ${Account.fragment.toString()}
                        }
                      }
                    }
                  }
              }
              holdingDocumentsByHoldingId {
                nodes {
                  ${HoldingDocument.fragment.toString()}
                  documentByDocumentId {
                    ${Document.fragment.toString()}
                    documentSharingsByDocumentId{
                        nodes{
                            ${Document.sharing.fragment.toString()}
                        }
                    }
                  }
                }
              }
            }
          }`,
    },
    format: ({
        allSecurities,
        allHoldings,
    }) => {
        const securities = []
        if (allSecurities) {
            allSecurities.nodes.forEach((security) => {
                const securityDocuments = security.securityDocumentsBySecurityId.nodes.map(({ documentByDocumentId }) => ({ documentByDocumentId }))
                const round = security.roundsBySecurityId.nodes[0]

                // We check round for the staged transfer case. We may have a holding but no round.
                if (round) {
                    const {
                        name: roundName,
                        assetByAssetId: {
                            name: assetName,
                            accountByIssuerId: {
                                name: issuerName,
                            },
                        },
                    } = round
                    if (securityDocuments.length) {
                        securities.push({
                            assetName, issuerName, roundName, documents: securityDocuments,
                        })
                    }
                }
            })
        }

        const holdings = []
        if (allHoldings) {
            allHoldings.nodes.forEach((holding) => {
                const { amount } = holding
                const holdingDocuments = holding.holdingDocumentsByHoldingId.nodes.map(({ documentByDocumentId }) => ({ documentByDocumentId }))
                const round = holding.securityBySecurityId.roundsBySecurityId.nodes[0]

                // We check round for the staged transfer case. We may have a holding but no round.
                if (round) {
                    const {
                        name: roundName,
                        assetByAssetId: {
                            name: assetName,
                            accountByIssuerId: {
                                name: issuerName,
                            },
                        },
                    } = round
                    if (holdingDocuments.length) {
                        holdings.push({
                            amount, roundName, assetName, issuerName, documents: holdingDocuments,
                        })
                    }
                }
            })
        }
        return { nodes: [...holdings, ...securities] }
    },
}

const BREADCRUMBS = [
    {
        display: 'Documents',
        link: '/investor-documents',
        active: true,
    },
]

const InvestorDocumentsPage = ({
    prefix = 'vrtl-investor-documents-page',
    ...props
}) => (
    <>
        <Nav />
        <Container>
            <MultiSelect>
                <QuerySelector.select {...GET_DOCUMENTS}>
                    <Div className={`${prefix}__bar ${prefix}--justify-between`}>
                        <Breadcrumbs items={BREADCRUMBS} />
                        <Div>
                            <QuerySelector.toggle field='securities' shape='checkMark'>
                                Securities
                            </QuerySelector.toggle>
                            <QuerySelector.toggle field='holdings' shape='checkMark'>
                                Holdings
                            </QuerySelector.toggle>
                        </Div>
                    </Div>
                    <Switch view={(properties) => (properties.noneFound ? 0 : 1)}>
                        <NoneFound size='lg'>No Documents Found.</NoneFound>
                        <Delv.query>
                            <Map {...props} arrayKey='nodes' noneFound={<NoneFound size='lg'>No Documents Found.</NoneFound>}>
                                <Div className={`${prefix}__table`}>
                                    <InvestorDocument.rowHeading
                                        prefix='vrtl-investor-document-row-heading'
                                        startSlot={<div className={`${prefix}__icon-offset`} />}
                                        endSlot={(<div className={`${prefix}__icon-offset--5`} />)}
                                    />
                                    <Collapsible.container className='' isOpen>
                                        <InvestorDocument.row
                                            prefix='vrtl-investor-document-row'
                                            endSlot={(<div className={`${prefix}__icon-offset--5`} />)}
                                        >
                                            <Collapsible.arrow />
                                        </InvestorDocument.row>
                                        <SecurityDocument.rowHeading
                                            startSlot={<div className={`${prefix}__icon-offset`} />}
                                            prefix='vrtl-investor-document-row-heading'
                                        />
                                        <Map arrayKey='documents'>
                                            <SecurityDocument.row
                                                startSlot={<div className={`${prefix}__inline-offset`} />}
                                                prefix='vrtl-investor-document-row'
                                            />
                                        </Map>
                                    </Collapsible.container>
                                </Div>
                            </Map>
                        </Delv.query>
                    </Switch>
                </QuerySelector.select>
            </MultiSelect>
        </Container>
        <Footer />
    </>
)

export default InvestorDocumentsPage

import TypeMap from '../TypeMap.js'

const selectNodeType = ({
    fieldName, root, info,
}) => {
    const fieldType = TypeMap.get(fieldName)

    const isLeafNode = Boolean(info.isLeaf) || fieldType === 'JSON'
    if (isLeafNode) return 'leaf'

    const isNodes = fieldName === 'nodes'
    if (isNodes) return 'nodes'

    const isNull = root === null
    if (isNull) return 'null'

    const isEmpty = Array.isArray(root) && !root.length
    if (isEmpty) return 'empty'

    const isCollection = fieldType?.endsWith('Connection')
    if (isCollection) return 'collection'

    return 'entity'
}

export { selectNodeType }

import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import { Input } from 'common/FormUI.js'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'
import TransferOwnershipRow from './TransferOwnershipRow.js'

import './TransferOwnership.scss'

const TRANSFER_OWNERSHIP = `mutation($email:String!){
  transferOwnership(input:{_email:$email}){
    usersAccounts{
      id
      status
      role
      userByUserId {
        id
        firstName
        lastName
        status
        emailsByUserId {
          nodes {
            id
            email
          }
        }
      }
    }
  }
}`

const formConfig = {
    fields: {
        email: {
            type: 'email',
            error: 'A valid email is required.',
        },
    },
}

const TransferOwnershipForm = ({
    resolve,
    id,
    form: {
        email,
        validateInput,
        getChanged,
    },
}) => {
    const handleSubmit = () => {
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    const returnVal = {
                        id,
                        email: changedInput.email,
                    }
                    new Mutation({
                        mutation: TRANSFER_OWNERSHIP,
                        onSubmit: () => returnVal,
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            }
        }
    }

    const prefix = 'vrtl-transfer-ownership-form'
    const newDisplayProps = {
        email: <Input {...email} />,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
    }

    return <TransferOwnershipRow {...newDisplayProps} />
}

export default Form(TransferOwnershipForm, formConfig)

import * as React from 'react'

import localStorage from 'util/local-storage-utils'

const useLocalStorage = (key, defaultValue, { serialize = JSON.stringify, deserialize = JSON.parse } = {}) => {
    const [state, setState] = React.useState(() => {
        const valueInLocalStorage = localStorage.getItem(key)
        if (valueInLocalStorage) {
            try {
                return deserialize(valueInLocalStorage)
            } catch (error) {
                localStorage.removeItem(key)
            }
        }

        return typeof defaultValue === 'function' ? defaultValue() : defaultValue
    })

    const prevKeyRef = React.useRef(key)

    React.useEffect(() => {
        if (prevKeyRef.current !== key) {
            localStorage.removeItem(key)
        }
        prevKeyRef.current = key
        localStorage.setItem(key, serialize(state))
    }, [key, state])

    return [state, setState]
}

export default useLocalStorage

import GenericForm from 'common/form/GenericForm.js'

import Fragment from './fragment.js'
import Card from './card.js'

const CREATE = ''

const updateQuery = `mutation ($id:UUID!, $patch:AddressPatch!){
  updateAddressById(input:{id:$id, addressPatch:$patch}){
    address{
      ${Fragment.toString()}
    }
  }
}`

const formConfig = {
    fields: {
        country: {
            type: 'dropdownTypeahead',
            error: 'Country is required',
        },
        city: {
            type: 'string',
            error: 'City is required.',
        },
        postalCode: {
            type: 'string',
            error: 'Postal code is required, enter N/A if not applicable.',
            submit: (value) => {
                let validatedPostalCode = value.replace(/\s/g, '')

                if (validatedPostalCode.length === 0) {
                    return {
                        error: true,
                        message: 'Postal/Zip code is required, enter N/A if not applicable.',
                    }
                }

                if (validatedPostalCode.toLowerCase() === 'n/a') {
                    validatedPostalCode = ''
                }

                return validatedPostalCode
            },
        },
        province: {
            type: 'string',
            error: 'State/Province is required.',
        },
        lineOne: {
            type: 'string',
            error: 'Street address is required.',
        },
        lineTwo: {
            type: 'string',
            ignoreError: true,
        },
        lineThree: {
            type: 'string',
            ignoreError: true,
        },
        lineFour: {
            type: 'string',
            ignoreError: true,
        },
    },
}

export { updateQuery }
export { formConfig }

export default GenericForm({
    Display: Card,
    createField: CREATE,
    updateField: updateQuery,
    prefix: 'vrtl-address',
    formConfig,
})

import * as React from 'react'
import InfoIcon from '@mui/icons-material/Info'

import Tooltip from 'common/Tooltip'

import IconCell from './icon-cell.js'

const CellWithTooltip = ({ colDef, tooltip, Icon = InfoIcon }) => {
    const TooptipCell = React.forwardRef(({ children, ...props }, ref) => (
        <div {...props} ref={ref}>
            <IconCell Icon={Icon}>
                {children}
            </IconCell>
        </div>
    ))

    return (
        <Tooltip title={tooltip}>
            <TooptipCell>{colDef.headerName}</TooptipCell>
        </Tooltip>
    )
}

export default CellWithTooltip

import React from 'react'
import { Link } from 'react-router-dom'
import OpenInNew from '@material-ui/icons/OpenInNew'

import Format from 'util/format/Format.js'
import Warning from './Warning.js'
import Error from './Error.js'

export default ({
    id,
    name,
    match,
    updatedOn,
    syncedOn,
    deleted,
    templateUri,
}) => {
    const prefix = 'vrtl-signing-template'
    return (
        <div className={`${prefix}__container`}>
            <Link to={`${match.url}/${id}`} className={`${prefix}__a--container`}>
                <div className={`${prefix}__section`}>
                    {name}
                    {
                        deleted
                            ? <Error position='right top' />
                            : new Date(updatedOn).getTime() > new Date(syncedOn).getTime()
                                ? <Warning position='right top' />
                                : <div className={`${prefix}__icon-offset`} />
                    }
                </div>
                <div className={`${prefix}__section`}>{Format.date(updatedOn, 'MMM DD YYYY hh:mma')}</div>
                <div className={`${prefix}__section`}>{Format.date(syncedOn, 'MMM DD YYYY hh:mma')}</div>
            </Link>
            <a href={templateUri} className={`${prefix}__a`} target='_blank' rel='noopener noreferrer'><OpenInNew className={`${prefix}__icon-link`} /></a>
        </div>
    )
}

import React from 'react'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

export default ({
    children,
    ...props
}) => {
    const prefix = 'vrtl-delegate-order-by'
    return (
        <OrderBy.bar {...props} addClassName={`${prefix} ${prefix}--margin-shrink`}>
            <OrderBy.filter className={`${prefix}__section--2`} field='firstName'>
                First Name
            </OrderBy.filter>
            <OrderBy.filter className={`${prefix}__section--2`} field='lastName'>
                Last Name
            </OrderBy.filter>
            <OrderBy.filter className={`${prefix}__section--3`} field='email'>
                Email
            </OrderBy.filter>
            <OrderBy.dropdown className={`${prefix}__section`} field='dropdownRole'>
                Role
            </OrderBy.dropdown>
            <OrderBy.dropdown className={`${prefix}__section`} field='status'>
                Status
            </OrderBy.dropdown>
            <div className={`${prefix}__icon-offset`} />
            <div className={`${prefix}__icon-offset`} />
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </OrderBy.bar>
    )
}

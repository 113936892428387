import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import { ReactQuery } from 'delv/delv-react'
// import './Dropdown.css'

const TypeaheadOption = (props) => (
    <div className='dropdown-item' onClick={props.onClick} key={props.name}>
        {props.name}
    </div>
)

function TypeaheadOptions(props) {
    let child
    if (props.children) {
        child = (element, onClick) => React.cloneElement(props.children, { element, onClick })
    } else {
        child = (name, onClick) => <TypeaheadOption onClick={onClick} name={name} />
    }
    if (props.options.length) {
        return (
            <div className='dropdown-items'>
                {props.options.map((element) => child(element[props.name], () => {
                    props.select(element)
                }))}
            </div>
        )
    }
    return (
        <div className='dropdown-items'>
            {child('Nothing found', () => {
                props.select({ value: -1, [props.name]: 'Nothing Found' })
            })}
        </div>
    )
}

class Typeahead extends Component {
    constructor(props) {
        super(props)
        this.state = { showPopup: false, input: '' }
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        // eslint-disable-next-line
        this.setState({ [name]: value, error: '' })
    }

    select = (element) => {
        this.setState({ showPopup: false, input: element[this.props.name] })
        this.props.onChange({
            target: {
                name: this.props.name,
                value: element.value,
            },
        })
    }

    closePopup = () => {
        if (this.state.showPopup) {
            this.setState({ showPopup: false })
        }
    }

    openPopup = () => {
        if (!this.state.showPopup) {
            this.setState({ showPopup: true })
        }
    }

    render = () => {
        const trigger = () => (
            <input
                className='styled-input'
                value={this.state.input}
                onChange={this.handleChange}
                name='input'
                autoComplete='off'
            />
        )
        const loading = this.props.children ? (
            React.cloneElement(this.props.children, {
                element: { value: -2, [this.props.name]: 'Loading' },
                onClick: () => {},
            })
        ) : (
            <TypeaheadOption name='Loading' onClick={() => {}} />
        )
        return (
            <Popup
                onClose={this.closePopup}
                onOpen={this.openPopup}
                open={this.state.showPopup}
                trigger={trigger}
                arrow={false}
                offsetX={
                    (0
                        * parseFloat(
                            getComputedStyle(document.documentElement).fontSize,
                        ))
                    / -2
                }
                contentStyle={{
                    width: '38%',
                    boxSizing: 'border-box',
                    boxShadow:
                        '0 4px 40px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    padding: '0px!important',
                    borderRadius: '5px',
                }}
                position='bottom center'
                closeOnDocumentClick
            >
                <ReactQuery
                    query={this.props.query(this.state.input)}
                    format={this.props.format}
                    cacheProcess='query'
                    loading={loading}
                >
                    <TypeaheadOptions
                        select={this.select}
                        name={this.props.name}
                    >
                        {this.props.children}
                    </TypeaheadOptions>
                </ReactQuery>
            </Popup>
        )
    }
}

export default Typeahead

import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default ({
    children,
    ...props
}) => {
    const map = ({
        id,
        status,
        accountByInvestorId: {
            id: accountId,
            contactName,
            name,
            email,
        },
    }) => ({
        id,
        value: {
            status,
            accountByInvestorId: {
                id: accountId,
                contactName,
                name,
                email,
            },
        },
    })
    const prefix = 'vrtl-customer-row'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.select map={map} className={`${prefix}__icon-checkbox`} />
            <OrderBy.filter field={(node) => node.accountByInvestorId.contactName} className={`${prefix}__section`}>
                Contact name
            </OrderBy.filter>
            <OrderBy.filter field={(node) => node.accountByInvestorId.name} className={`${prefix}__section`}>
                Account name
            </OrderBy.filter>
            <OrderBy.filter field={(node) => node.accountByInvestorId.email} className={`${prefix}__section`}>
                Email
            </OrderBy.filter>
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </OrderBy.bar>
    )
}

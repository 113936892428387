import Fragment from './Fragment.js'
import OrderByHeader from './OrderByHeader.js'
import Card from './Card.js'
import Form from './Form.js'

export default {
    card: Card,
    orderByHeader: OrderByHeader,
    fragment: Fragment,
    form: Form,
}

import React from 'react'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'

/*
This component is a utility that allows you to pass a filter function
to the cleaProps property to avoid prop name collision. This is best
expressed with an example:

const SomeComponent = () => {
    const myClickHandler = () => ''
    return (
        <OnClickGiver>
            <Button variant='outlined' disabled={loading}>Cancel</Button>
            <Button onClick={myClickHandler} variant='contained' disabled={loading}>Upload</Button>
        </OnClickGiver>
    )
}

If the OnClickGiver component happens to clone children passing an onClick function, it will override myClickHandler.
*/

const Div = ({
    className = 'div',
    children,
    style,
    cleanProps = (props) => props,
    ...props
}) => (
    <div className={cx(className)} style={style}>
        {SafeReact.cloneChildren(children, cleanProps(props))}
    </div>
)

export default Div

import Row from './Row.js'
import Form from './Form.js'
import RowHeading from './RowHeading.js'
import Error from './Error.js'
import Warning from './Warning.js'
import Fragment from './Fragment.js'

import './styles.scss'

export default {
    row: Row,
    form: Form,
    rowHeading: RowHeading,
    warning: Warning,
    error: Error,
    fragment: Fragment,
}

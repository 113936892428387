import Form from 'common/form/GenericForm.js'

import Fragment from './Fragment.js'
import Row from './Row.js'

const CREATE_FIELD = `mutation($email:String!, $name:String){
  makeCustomer(input:{_email:$email, name:$name}){
    customer{
      ${Fragment.toString()}
    }
  }
}`

const UPDATE_FIELD = `mutation ($id:UUID!, $patch:CustomerPatch!) {
  updateCustomerById(input:{id:$id, customerPatch:$patch}){
    customer{
      ${Fragment.toString()}
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Contact Name is required.',
            default: (props) => (props.accountByInvestorId
                ? props.accountByInvestorId.contactName
                : ''),
            ignoreError: true,
        },
        email: {
            type: 'email',
            error: 'Email is required.',
            default: (props) => (props.accountByInvestorId
                ? props.accountByInvestorId.email
                : ''),
        },
    },
}

const makeComponents = ({ id, defaultFunc, ...props }) => {
    const newFields = defaultFunc(props)
    return {
        id,
        accountByInvestorId: {
            contactName: newFields.name,
            email: props.accountByInvestorId ? props.accountByInvestorId.email : newFields.email,
            name: props.accountByInvestorId ? props.accountByInvestorId.name : 'unknown',
        },
    }
}

export default Form({
    Display: Row,
    updateField: UPDATE_FIELD,
    createField: CREATE_FIELD,
    prefix: 'vrtl-customer-row',
    formConfig,
    makeFormComponents: makeComponents,
    makeLoadingComponents: makeComponents,
})

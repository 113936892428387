import React from 'react'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'

import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Input, Dropdown } from 'common/FormUI.js'

import Row from './Row.js'

const UPDATE_FIELD = `mutation ($id: UUID!, $templateId: UUID!, $signingFieldPatch: SigningFieldPatch!) {
  updateSigningFieldById(input: {id: $id, signingFieldPatch: $signingFieldPatch}) {
    query {
      allSigningFields(condition: {mappedSigningTemplateId: $templateId}) {
        nodes {
          id
          value
          mappingFieldByMapTo {
            id
            field
            description
          }
        }
      }
    }
  }
}
`

const blacklist = ['error', 'not supported', 'template provided']

const valueOptions = ['static', 'custom']

const FieldCardForm = ({
    id,
    name,
    valueVariable,
    roleName,
    description,
    resolve,
    templateId,
    mappingFields: { nodes },
    form: {
        mapTo, value, setLoading, validateInput, loading, getChanged,
    },
    ...props
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (input.value === '') {
            delete input.value
        }
        if (!input.hasErrors) {
            if (valueOptions.includes(input.mapTo) && !input.value) {
                // eslint-disable-next-line
                console.log('bomb')
            } else {
                const changedInput = getChanged(input)
                if (changedInput) {
                    new Mutation({
                        mutation: UPDATE_FIELD,
                        onSubmit: () => {
                            setLoading(true)
                            return { id, templateId, signingFieldPatch: changedInput }
                        },
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            }
        }
    }
    const options = nodes
        .filter((a) => !blacklist.includes(a.field))
        .map((a) => ({ displayValue: a.field, value: a.id }))
    const prefix = 'vrtl-signing-field'

    if (loading) {
        return <Row roleName={roleName} name={name} valueVariable={valueVariable} />
    }
    const mapToDisplayValue = nodes.filter((a) => a.id === mapTo.value)[0] || {}
    const rowProps = {
        roleName,
        name,
        mapTo: (
            <Dropdown
                {...mapTo}
                options={options}
                defaultValue={props.mapTo === 'error' ? props.value : props.mapTo}
            />
        ), // this is tech debt, the form state controller needs to behave better
        value: valueOptions.includes(mapToDisplayValue.field) && <Input {...value} />,
        valueVariable,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
    }

    return <Row {...rowProps} />
}

export default Delv.queryHOC(
    Form(FieldCardForm, {
        fields: {
            mapTo: {
                type: 'dropdownTypeahead',
                error: 'Name is required.',
            },
            value: {
                type: 'string',
                submit: (value, state) => {
                    if (valueOptions.includes(state.mapTo) && value === '') {
                        return {
                            error: true,
                            message: `Value required for ${state.mapTo} field.`,
                        }
                    }
                    return value
                },
            },
        },
    }),
    {
        query: (id) => `{
mappedSigningTemplateById(id:"${id}"){
    id
    mappingFields{
      nodes{
         id
         field
      }
    }
  }
}`,
        args: (props) => props.mappedSigningTemplateId,
        format: (props) => ({ mappingFields: props.mappedSigningTemplateById.mappingFields }),
    },
)

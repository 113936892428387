import * as React from 'react'
import { Decimal } from 'decimal.js'

import Alert from 'common/Alert'
import Box from 'common/Box'
import Button from 'common/Button'
import Card from 'common/Card/index.js'
import CardContent from 'common/CardContent'
import CardHeader from 'common/CardHeader'
import CircularProgress from 'common/CircularProgress'
import Dialog from 'common/Dialog'
import DialogActions from 'common/DialogActions'
import DialogContent from 'common/DialogContent'
import DialogTitle from 'common/DialogTitle'
import FormV2 from 'common/FormV2'
import Link from 'common/Link'
import Snackbar from 'common/Snackbar'
import Typography from 'common/Typography'
import useMutation from 'delv/hooks/use-mutation'
import useToggle from 'hooks/UseToggle.js'
import useFormView from 'hooks/UseFormView'
import PromiseTimeout from 'util/promiseTimeout.js'
import Format from 'util/format/Format.js'

import { INVESTOR_OFFERING_DETAILS_BY_ID } from './queries.js'
import { MAKE_DISTRIBUTION } from './mutations.js'

const InvestInfo = ({ label, value }) => (
    <Box sx={{
        display: 'flex', flexDirection: 'column', flex: 1, mb: 1,
    }}
    >
        <Typography variant='subtitle2' as='strong'>{label}</Typography>
        <Typography variant='body1' as='p'>{value}</Typography>
    </Box>
)

const InvestButton = ({ children, ...props }) => (
    <Button variant='contained' {...props}>{children}</Button>
)

const selectDefaultInvestment = ({ amount, price, type }) => {
    if (type === 'Units') {
        return {
            total: new Decimal(amount).mul(price).toFixed(2).toString(),
            units: new Decimal(amount).toFixed(0).toString(),
        }
    }

    const units = new Decimal(amount).div(price).floor().toFixed(0)
        .toString()
    const total = new Decimal(units).mul(price).toFixed(2).toString()

    return {
        total,
        units,
    }
}

const IOCard = ({
    type,
    amount,
    id,
    issuerName,
    sellerName,
    assetName,
    className: roundName,
    classPrice: price,
    termsUrl,
    mode = 'edit',
    supportEmail,
}) => {
    const isZeroPrice = new Decimal(price).equals(0)
    const { units: defaultUnits, total: defaultTotal } = selectDefaultInvestment({ amount, price, type })
    const fields = {
        units: {
            type: 'integer',
            helperText: 'Must be whole number.',
            defaultValue: defaultUnits,
            onChange: (_key, value, {
                input,
                setInput,
                getValueAndError,
                validators,
            }) => {
                const { value: units, errorExists } = getValueAndError({ resolver: validators.integer, value: validators.noComma(value) })
                const total = value ? new Decimal(units).mul(price).toFixed(2).toString() : '0.00'

                setInput({
                    ...input,
                    units: {
                        value: units,
                        errorExists,
                    },
                    total: {
                        value: total,
                        errorExists: false,
                    },
                })
            },
            onBlur: (_key, value, {
                input,
                setInput,
                getValueAndError,
                validators,
            }) => {
                const noCommaUnits = validators.num(value) || '0'
                const desiredUnits = new Decimal(noCommaUnits).toString()
                const desiredTotal = new Decimal(desiredUnits).mul(price).toFixed(2).toString()

                const { value: units, errorExists } = getValueAndError({ resolver: validators.formatIntegerWithComma, value: desiredUnits })
                const { value: total } = getValueAndError({ resolver: validators.formatNumberWithComma, value: desiredTotal })

                setInput({
                    ...input,
                    units: {
                        value: units,
                        errorExists,
                    },
                    total: {
                        value: total,
                        errorExists: false,
                    },
                })
            },
        },
        ...(!isZeroPrice && {
            total: {
                label: 'Total Investment (USD)',
                type: 'number',
                helperText: 'Total will round down to nearest whole unit.',
                defaultValue: defaultTotal,
                onChange: (_key, value, {
                    input,
                    setInput,
                    getValueAndError,
                    validators,
                }) => {
                    const noCommaTotal = validators.noComma(value)
                    const desiredTotal = validators.decimal(noCommaTotal)
                    const units = new Decimal(desiredTotal).div(price).toFixed(0).toString()
                    const { value: total, errorExists } = getValueAndError({ resolver: validators.number, value: noCommaTotal })

                    setInput({
                        ...input,
                        units: {
                            value: units,
                            errorExists,
                        },
                        total: {
                            value: total,
                            errorExists: false,
                        },
                    })
                },
                onBlur: (_key, value, {
                    input,
                    setInput,
                    getValueAndError,
                    validators,
                }) => {
                    const noCommaTotal = validators.noComma(value)
                    const decimalTotal = validators.decimal(noCommaTotal)
                    const desiredUnits = new Decimal(decimalTotal)
                        .div(price)
                        .floor()
                        .toFixed(0)
                        .toString()
                    const desiredTotal = new Decimal(desiredUnits).mul(price).toFixed(2).toString()

                    const { value: units, errorExists } = getValueAndError({ resolver: validators.formatIntegerWithComma, value: desiredUnits })
                    const { value: total } = getValueAndError({ resolver: validators.formatNumberWithComma, value: desiredTotal })

                    setInput({
                        ...input,
                        units: {
                            value: units,
                            errorExists,
                        },
                        total: {
                            value: total,
                            errorExists: false,
                        },
                    })
                },
            },
        }),
    }

    const { validateInput, ...form } = useFormView({ fields })
    const [investorMakeDistribution, { loading, error }] = useMutation(MAKE_DISTRIBUTION)
    const { toggle: isDialogOpen, disable: closeDialog, enable: openDialog } = useToggle()
    const { toggle: isSnackbarOpen, disable: closeSnackbar, enable: openSnackbar } = useToggle()

    React.useEffect(() => {
        if (error && !isSnackbarOpen) {
            openSnackbar()
        }
    }, [error])

    // Allow for N Precision price display
    const displayPrice = new Decimal(price).dp() > 2
        ? new Decimal(price).toString()
        : new Decimal(price).toFixed(2).toString()
    const isEdit = mode === 'edit'

    const cancelInvestment = () => {
        closeDialog()
        closeSnackbar()
    }

    const makeInvestment = () => {
        const { valid, input } = validateInput()

        if (valid) {
            investorMakeDistribution({
                variables: {
                    offeringId: id,
                    units: input.units,
                },
                onResolve: PromiseTimeout(
                    (res) => {
                        window.location.assign(`/distribution/${res.investorMakeDistribution.distribution.id}`)
                    }, 500,
                ),
            })
        } else {
            openSnackbar()
        }
    }

    return (
        <Card
            elevation={3}
            className='column'
            sx={{
                width: '20rem', minWidth: '20rem', padding: 1, height: 'fit-content',
            }}
        >
            <CardHeader
                title={assetName}
                subheader={roundName}
            />
            <CardContent>
                <InvestInfo label='Issuer' value={issuerName} />
                <InvestInfo label='Seller' value={sellerName} />
                <InvestInfo label='Price per Unit' value={displayPrice} />
                { !isEdit && <InvestInfo label='Units' value={Format.integer(defaultUnits)} /> }
                { (isZeroPrice || !isEdit) && (
                    <InvestInfo label='Total Investment (USD)' value={isZeroPrice ? '0.00' : Format.number(defaultTotal)} />
                )}
                { Boolean(termsUrl) && (
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', flex: 1, mb: 1,
                    }}
                    >
                        <Typography variant='subtitle2' as='strong'>Dataroom</Typography>
                        <Typography variant='body1' as='p'>
                            <Link href={termsUrl} target='_blank' rel='noopener noreferrer'>
                                View
                            </Link>
                        </Typography>
                    </Box>
                )}
                {supportEmail && (
                    <>
                        <Typography variant='subtitle2' as='strong'>{'Questions? Contact the issuer at '}</Typography>
                        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
                    </>
                )}
            </CardContent>
            { isEdit && (
                <FormV2
                    {...form}
                    FormProps={{ sx: { p: 1, gap: 3 } }}
                    endSlot={<InvestButton onClick={openDialog}>Invest</InvestButton>}
                />
            )}
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby='investment-confirmation-dialog'
                aria-describedby='confirming-investment-unit-and-amounts'
            >
                <DialogTitle id='investment-confirmation-title'>
                    {`${assetName}, ${roundName}`}
                </DialogTitle>
                <DialogContent sx={{ minWidth: '20rem' }}>
                    <InvestInfo color='text' label='Price per Unit' value={displayPrice} />
                    <InvestInfo color='text' label='Units' value={Format.integer(form.input.units.value)} />
                    <InvestInfo color='text' label='Total Investment (USD)' value={Format.number(form.input.total?.value || defaultTotal)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelInvestment} variant='outlined' color='cancel'>Cancel</Button>
                    <Button onClick={makeInvestment} variant='contained' disabled={loading} autoFocus sx={{ minWidth: '5.3125rem' }}>
                        {loading ? <CircularProgress size='1.5rem' /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={isSnackbarOpen}
                onClose={closeSnackbar}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div>
                    <Alert sx={{ mt: 9 }} variant='filled' severity='error'>Unable to invest. Please try again.</Alert>
                </div>
            </Snackbar>
        </Card>
    )
}

export default IOCard

export {
    IOCard,
    MAKE_DISTRIBUTION,
    INVESTOR_OFFERING_DETAILS_BY_ID,
}

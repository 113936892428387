import ValidateChange from './validators/change.js'
import ValidateBlur from './validators/blur.js'
import ValidateSubmit from './validators/submit.js'
import HasChanged from './validators/hasChanged.js'

const validators = {
    change: ValidateChange,
    blur: ValidateBlur,
    submit: ValidateSubmit,
}

class Form {
    constructor({ fields, constraints }, props) {
        this.fields = fields
        this.constraints = constraints
        this.props = props
    }

    // TODO clean this up
    verifyField = (name, value, operation, state) => {
        // state os a hack for now
        const {
            type, error, ignoreError, ...customValidators
        } = this.fields[
            name
        ]
        const validator = customValidators[operation] || validators[operation][type]
        const formattedValue = validator(value, state, validators[operation][type]) // current value, current state, and default validator for the type
        if (formattedValue?.error) {
            if (ignoreError) {
                if (!formattedValue.value) { // this is a hack
                    // if something is not required and has a falsy value
                    return {}
                }
            }
            return {
                [name]: value,
                [`${name}Error`]: formattedValue.message || error,
            }
        }
        return {
            [name]: formattedValue,
            [`${name}Error`]: '',
        }
    }

    verify = (state) => {
        let returnVal = {}
        let hasErrors = false
        Object.keys(state).forEach((key) => {
            if (this.fields[key]) {
                const value = state[key]
                const verifiedField = this.verifyField(
                    key,
                    value,
                    'submit',
                    state,
                )
                if (verifiedField[`${key}Error`]) {
                    hasErrors = true
                }
                returnVal = {
                    ...returnVal,
                    ...verifiedField,
                }
            }
        })
        return {
            hasErrors,
            values: returnVal,
        }
    }

    getChanged = (state) => {
        const changedValues = {}
        const formattedProps = this.verify(this.props)
        Object.keys(state).forEach((key) => {
            if (this.fields[key]) {
                const { type, changed } = this.fields[key]
                const stateValue = state[key]
                let propValue = formattedProps.values[key]
                if (type === 'boolean') {
                    propValue = this.props[key]
                }
                if (changed) {
                    if (changed(propValue, stateValue)) {
                        changedValues[key] = stateValue
                    }
                } else if (HasChanged[type](propValue, stateValue)) {
                    changedValues[key] = stateValue
                }
            }
        })
        if (Object.values(changedValues).length > 0) {
            return changedValues
        }
        return false
    }

    verifyChange = ({ target: { value, name } }) => this.verifyField(name, value, 'change')

    verifyBlur = ({ target: { value, name } }) => this.verifyField(name, value, 'blur')

    verifySubmit = (state) => {
        const newState = this.verify(state)
        if (newState.hasErrors) {
            return newState
        }
        const removeError = {}

        Object.keys(newState.values).forEach((key) => {
            if (!key.endsWith('Error')) {
                removeError[key] = newState.values[key]
            }
        })
        return {
            hasErrors: false,
            values: removeError,
        }
    }
}

export default Form

import React from 'react'

const getComponentType = (component) => {
    if (!component) {
        return 'none'
    } if (typeof component === 'string' || typeof component === 'number') {
        return 'string'
    } if (typeof component === 'object') {
        if (component instanceof Array) {
            return 'array'
        } if (component.$$typeof) {
            if (typeof component.type === 'string') {
                return 'html'
            }
            return 'react'
        } if (component instanceof Function) {
            return 'function' // might be obsolete
        }
        return 'object'
    } if (typeof component === 'function') {
        return 'function'
    }
    return 'unknown'
}

const cloneChildren = (
    children,
    props,
    component,
    context = {
        depth: 0,
        path: 'BASE',
    },
) => {
    let mappedChildren = []

    if (!children) {
        return mappedChildren
    }

    const childrenType = getComponentType(children)

    if (childrenType === 'string') {
        mappedChildren.push(children)
    } else if (childrenType === 'html') {
        const tempProps = props && props instanceof Function
            ? props({ child: children, type: childrenType, context })
            : props
        if (!tempProps) {
            mappedChildren.push(children)
        } else {
            const newProps = {}
            const imReallyAnnoyedThatThisHasToBeAVariable = ['onClick', 'style', 'className']
            imReallyAnnoyedThatThisHasToBeAVariable.forEach((key) => {
                if (tempProps[key]) {
                    newProps[key] = tempProps[key]
                }
            })
            mappedChildren.push(React.cloneElement(children, { key: children.key ? children.key : context.path, ...newProps }))
        }
    } else if (childrenType === 'react') {
        const newProps = props instanceof Function
            ? props({ child: children, type: childrenType, context })
            : props
        if (newProps instanceof Object) {
            mappedChildren.push(React.cloneElement(children, { key: children.key ? children.key : context.path, ...newProps }))
        } else {
            mappedChildren.push(children)
        }
    } else if (childrenType === 'array') {
        mappedChildren = children.reduce(
            (agg, child, index) => agg.concat(
                cloneChildren(
                    child,
                    props,
                    null,
                    {
                        index,
                        path: `${index}-${context.path}`,
                        depth: context.depth + 1,
                        data: child,
                        component,
                    },
                ),
            ),
            mappedChildren,
        )
    } else if (childrenType === 'function') {
        mappedChildren = [
            ...mappedChildren,
            ...cloneChildren(children(props), props, component, context),
        ]
    } else if (childrenType === 'object') {
        const comp = component || (context.depth === 1 && context.component)
        if (comp) {
            const newComponent = comp instanceof Function
                ? comp({ child: children, type: childrenType, context })
                : comp

            mappedChildren = [
                ...mappedChildren,
                ...cloneChildren(newComponent, props, null, context),
            ]
        } else {
            mappedChildren.push(
                <div>
                    Attempted to render raw object:
                    <div>
                        {JSON.stringify(children)}
                    </div>
                </div>,
            )
        }
    } else {
        // eslint-disable-next-line
        console.warn('Unknown child type provided')
        mappedChildren.push(children)
    }

    return mappedChildren
}

export default {
    cloneChildren: (a, b, c) => cloneChildren(a, b, c), // lets hide the context
    getComponentType,
}

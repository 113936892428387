import React from 'react'
import Format from 'util/format/Format.js'
import Card from 'common/Card.js'

const data = `id
cliff
periods
automatic
currentPeriod
startsOn
duration
vestingEventsByVestingScheduleId{
  nodes{
    id
    startPeriod
    endPeriod
  }
}`

const distribution = `vestingScheduleByDistributionId {
  ${data}
}
`
const allocation = `allocationsVestingScheduleByAllocationId {
    id
    cliff
    periods
    automatic
    startsOn
    duration
}`

const fragment = {
    data,
    distribution,
    allocation,
}

export { fragment }

const VestingCard = ({ children, header = null, vesting }) => {
    const {
        cliff, duration, startsOn, periods, currentPeriod,
    } = vesting

    const mapChildren = (child) => React.cloneElement(child, { vesting })

    return (
        <>
            {header}

            <Card.card>
                <Card.section className='small-card-section'>
                    <Card.label>CLIFF</Card.label>
                    {cliff}
                    {' '}
                    {duration}
                    {cliff > 1 ? 's' : ''}
                </Card.section>
                <Card.section className='small-card-section'>
                    <Card.label>PERIODS</Card.label>
                    {periods}
                    {' '}
                    {duration}
                    {periods > 1 ? 's' : ''}
                    {currentPeriod != null && (
                        <div className='annotation-timestamp'>
                            Current Period:
                            {' '}
                            {currentPeriod}
                        </div>
                    )}
                </Card.section>
                <Card.section className='small-card-section'>
                    <Card.label>STARTS ON</Card.label>
                    {Format.date(startsOn, 'MMM Do YYYY')}
                </Card.section>
                {React.Children.map(children, mapChildren)}
            </Card.card>
        </>
    )
}

export default VestingCard

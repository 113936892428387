import * as React from 'react'

import Typography from 'common/Typography'
import { selectLocalCurrency } from 'util/currency-utils'

const CurrencyCell = ({ value }) => (
    <Typography variant='body2'>
        {selectLocalCurrency(value)}
    </Typography>
)

export default CurrencyCell

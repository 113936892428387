import GenericForm from 'common/form/GenericForm'
import Card from 'components/managedAccount/Card.js'
import Account from 'components/account/index.js'

import Fragment from './Fragment.js'

const CREATE_ISSUER = `mutation ($name: String!, $email: String!) {
  makeIssuerAccount(input: {companyName: $name, companyEmail: $email}) {
    account {
     ${Account.fragment.toString()}
      assetsByIssuerId{
        nodes{
          id
        }
      }
      managedAccountsByEntityId {
        nodes {
          ${Fragment.toString()}
        }
      }
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is required.',
        },
        email: {
            type: 'email',
            error: 'Email is required.',
        },
    },
}

export default GenericForm({
    Display: Card,
    createField: CREATE_ISSUER,
    prefix: 'vrtl-form-display',
    formConfig,
    makeFormComponents: ({ defaultFunc, ...props }) => {
        const defaultValue = defaultFunc(props)
        return {
            accountByEntityId: defaultValue,
            userByCreatedBy: {},
        }
    },
    makeLoadingComponents: ({ defaultFunc, ...props }) => {
        const defaultValue = defaultFunc(props)

        return {
            accountByEntityId: defaultValue,
            userByCreatedBy: {},
        }
    },
})

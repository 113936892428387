import React, { Component } from 'react'
import axios from 'axios'
import './Test.css'

class GraphqlTest extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({ [name]: value })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        axios
            .post('/api/v2/graphql', { query: this.state.test, variables: {} })
            .then((res) => {
                this.setState({ recived: res.data })
            })
    }

    render = () => (
        <div className='test'>
            <textarea
                className='test-textarea'
                name='test'
                value={this.state.test}
                onChange={this.handleChange}
            />
            <div>
                <button onClick={this.handleSubmit} type='button'>send</button>
            </div>
            <div>{JSON.stringify(this.state.recived)}</div>
        </div>
    )
}

export default GraphqlTest

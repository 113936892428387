import * as React from 'react'
import { useParams } from 'react-router-dom'
import QueryString from 'query-string'

import { Decimal } from 'decimal.js'
import Box from 'common/Box'
import Breadcrumbs from 'common/Breadcrumbs'
import CircularProgress from 'common/CircularProgress'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import IOCard, { INVESTOR_OFFERING_DETAILS_BY_ID } from 'components/investorOfferingDetailsById'
import useQuery from 'delv/hooks/use-query'

const selectType = (queryString) => {
    if (!queryString.type) {
        return 'Units'
    }

    const type = queryString.type instanceof Array
        ? queryString.type[0]
        : queryString.type

    return type === 'USD' ? 'USD' : 'Units'
}

const selectAmount = (queryString, type) => {
    const DEFAULT_AMOUNT = '100.00'
    if (!queryString.amount) {
        return DEFAULT_AMOUNT
    }

    const amount = queryString.amount instanceof Array
        ? queryString.amount[0]
        : queryString.amount

    if (Number.isNaN(Number(amount)) || amount === true) {
        return DEFAULT_AMOUNT
    }

    if (type === 'Units') {
        return new Decimal(amount).abs().floor().toString()
    }

    return new Decimal(amount).abs().toString()
}

const InvestorCommitment = (props) => {
    // eslint-disable-next-line camelcase
    const { offering_id } = useParams()
    const { data, loading } = useQuery(INVESTOR_OFFERING_DETAILS_BY_ID, {
        // eslint-disable-next-line camelcase
        args: `(id: "${offering_id}")`,
    })

    const BREADCRUMBS = [{ active: true, display: 'Investor Onboarding', link: '#' }]

    const queryString = QueryString.parse(props.location.search)
    const type = selectType(queryString)
    const amount = selectAmount(queryString, type)

    const cardProps = {
        amount,
        type,
        ...data?.investorOfferingDetailsById,
    }

    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                { !data?.investorOfferingDetailsById || loading
                    ? <CircularProgress color='primary' />
                    : (
                        <Box sx={{ py: 4, margin: 'auto' }}>
                            <IOCard {...cardProps} />
                        </Box>
                    )}
            </Container>
            <Footer />
        </>
    )
}

export default InvestorCommitment

export {
    selectAmount,
}

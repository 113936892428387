import React from 'react'
import useToggle from 'hooks/UseToggle.js'

const ViewToggle = ({
    ToggleIcon, defaultValue = false, invertDisplay = false, children, label,
}) => {
    const { toggle, swap } = useToggle(defaultValue)

    if (!ToggleIcon) {
        // eslint-disable-next-line
        console.error('Must provide Toggle to Toggleable component')
    }

    const displayChildren = invertDisplay ? !toggle : toggle

    return (
        <>
            <ToggleIcon onChange={swap} value={toggle} label={label} />
            {displayChildren && children}
        </>
    )
}

export default ViewToggle

import Delv from './delv.js'
import WSEvents from './WSQueries.js'

const allowedTypes = Object.keys(WSEvents)

// this needs to be reworked to allow custom actions to be taken on websocket subscription events

class DelvWebSocket {
    constructor(url) {
        this.url = url || `wss://${window.location.host}/wss/v1/`
        this.ready = false
        this.subscribedTo = {}
        this.queryIds = {} // this is really a short term hack
        this.queue = []
        this.hackyQueries = Object.create(null)
    }

    connect = () => {
        if (!this.ws) {
            this.ws = new WebSocket(this.url)
            this.ws.onopen = this.onOpen
            this.ws.onmessage = this.onMessage
            this.ws.onclose = this.onClose
            this.connectionStart = true
        }
    }

    disconnect = () => {
        if (this.ws) {
            this.ws.close()
        }
    }

    subscribe = ({ type, ids, queryId }) => {
        if (!this.connectionStart) {
            this.connect()
        }
        if (allowedTypes.includes(type)) {
            if (this.ready) {
                let newId = false
                if (this.subscribedTo[type]) {
                    const currentIds = this.subscribedTo[type]
                    ids.forEach((id) => {
                        if (!currentIds.includes(id)) {
                            newId = true
                            currentIds.push(id)
                        }
                    })
                } else {
                    newId = true
                    this.subscribedTo[type] = ids
                }
                if (newId) {
                    this.queryIds[ids[0]] = queryId || ids[0]
                    this.ws.send(
                        JSON.stringify({
                            type: 'subscribe',
                            table: type,
                            ids,
                        }),
                    )
                }
            } else {
                this.queue.push({
                    method: 'subscribe',
                    data: {
                        type,
                        ids,
                        queryId,
                    },
                })
            }
        }
    }

    unsubscribe = ({ type, ids, queryId }) => {
        if (this.ready) {
            if (this.subscribedTo[type]) {
                const newSubcribedTo = this.subscribedTo[type].filter(
                    (item) => !ids.includes(item),
                )
                if (newSubcribedTo.length !== this.subscribedTo.length) {
                    this.subscribedTo = newSubcribedTo
                    this.queryIds[ids[0]] = null
                    this.ws.send(
                        JSON.stringify({
                            type: 'unsubscribe',
                            table: type,
                            ids,
                        }),
                    )
                }
            }
        } else {
            this.queue.push({
                method: 'unsubscribe',
                data: {
                    type,
                    ids,
                    queryId,
                },
            })
        }
    }

    onOpen = () => {
        this.ready = true
        this.queue.forEach((item) => {
            switch (item.method) {
            case 'subscribe':
                this.subscribe(item.data)
                break
            case 'unsubscribe':
                this.unsubscribe(item.data)
                break
            default:
                break
            }
        })
    }

    onMessage = (message) => {
        const data = JSON.parse(message.data)
        const queryInfo = WSEvents[data.type]
        Delv.query({
            query: queryInfo.query(this.queryIds[data.id]),
            networkPolicy: 'network-only',
            cacheProcess: queryInfo.cacheProcess || 'default',
            onFetch: () => {},
            onResolve: (res) => {
                if (queryInfo.onResolve) {
                    return queryInfo.onResolve({
                        data: res, type: data.type, ws: this, subscribedId: data.id,
                    })
                }

                return undefined
            },
            onError: () => {},
        })
        if (this.hackyQueries[data.id]) {
            Delv.query(this.hackyQueries[data.id])
            this.hackyQueries[data.id] = undefined
        }
    }

    onClose = () => {
        this.ready = false
    }

    addQuery = ({ id, query, delvConfig }) => {
        this.hackyQueries[id] = delvConfig || { // delv.query requires all of these values
            query,
            networkPolicy: 'network-only',
            cacheProcess: 'default',
            onFetch: () => {},
            onResolve: () => {},
            onError: () => {},
        }
    }
}

export default new DelvWebSocket()

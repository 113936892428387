import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
name
email
slug
url
twitterHandle
contactName
supportEmail
setup
type`)

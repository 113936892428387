export default `id
 mappedSigningTemplateId
 name
 value
 valueVariable
 signingRoleBySigningRoleId {
   id
   name
 }
 mappingFieldByMapTo {
   id
   field
   description
 }`

import React from 'react'

import './styles.scss'

export default ({
    name,
}) => {
    const prefix = 'vrtl-asset-heading'
    return (
        <div className={`${prefix}__header-lg`}>
            {name}
        </div>
    )
}

import React from 'react'
import CheckMark from './CheckMark.js'
import Square from './Square.js'
import Circle from './Circle.js'

const iconMap = (
    shape,
    ...props
) => {
    const map = {
        default: <Circle {...props} />,
        square: <Square {...props} />,
        circle: <Circle {...props} />,
        checkMark: <CheckMark {...props} />,
    }

    return shape ? map[shape] : map.default
}

export default iconMap

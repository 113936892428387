import typeMap from './accountConfig.js'

const selectConfigs = (type) => {
    const {
        account,
        party,
        Component,
        format,
        addressFields,
        mailingFields,
    } = typeMap[type] || typeMap.Individual
    return {
        account,
        Component,
        format,
        party,
        addressFields,
        mailingFields,
    }
}

export default selectConfigs

import TypeMap from '../TypeMap.js'

const entityResolver = ({
    cache, fieldName, root, args, keyConflict,
}) => {
    const fieldType = TypeMap.get(fieldName)
    if (!args) {
        const idFromRoot = keyConflict.get(fieldName) ? root[fieldName] : root[fieldType]
        if (!idFromRoot) {
            return null
        }
        const entity = cache[fieldType][idFromRoot]

        return entity
    }

    const value = Object.values(args)[0]

    const nodeByPrimaryKey = cache[fieldType][value]
    if (nodeByPrimaryKey) {
        return nodeByPrimaryKey
    }

    const key = Object.keys(args)[0]
    // eslint-disable-next-line eqeqeq
    const nodeByUniqueKey = Object.values(cache[fieldType]).filter((node) => node[key] == value)
    if (nodeByUniqueKey.length === 1) {
        return nodeByUniqueKey[0]
    }

    return null
}

export { entityResolver }

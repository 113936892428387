import React from 'react'
import Card from 'common/Card.js'

const AssetCard = ({ name }) => (
    <Card.container>
        <div>
            <Card.card>
                <Card.section>
                    <Card.label>ASSET</Card.label>
                    {name}
                </Card.section>
            </Card.card>
        </div>
    </Card.container>
)

export default AssetCard

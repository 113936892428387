import React, { Component } from 'react'
import './Test.css'

class WSTest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            test: `{
"type":"subscribe",

"table":"blockchain_registrations",

"ids":[1,2,3,4]
}`,
            recived: '',
            url: `wss://${window.location.host}/wss/v1/`,
            connected: false,
        }
        this.count = 0
    }

    connect = () => {
        this.ws = new WebSocket(this.state.url)
        this.ws.onopen = () => {
            this.setState({ connected: true })
        }
        this.ws.onmessage = (event) => {
            this.setState({ recived: event.data + this.count++ })
        }
        this.ws.onclose = () => {
            this.setState({ connected: false })
            this.ws = null
        }
    }

    disconnect = () => {
        this.ws.close()
    }

    kill = () => {
        this.ws = null
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({ [name]: value })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.ws.send(this.state.test)
    }

    render = () => {
        const style = this.state.connected ? { backgroundColor: 'green' } : {}
        return (
            <div className='test'>
                <div>
                    <input
                        name='url'
                        value={this.state.url}
                        onChange={this.handleChange}
                    />
                    <button style={style} onClick={this.connect} type='button'>
                        connect
                    </button>
                    <button onClick={this.disconnect} type='button'>disconnect</button>
                    <button onClick={this.kill} type='button'>kill</button>
                </div>
                <textarea
                    className='test-textarea'
                    name='test'
                    value={this.state.test}
                    onChange={this.handleChange}
                />
                <div>
                    <button onClick={this.handleSubmit} type='button'>send</button>
                </div>
                <div>{this.state.recived}</div>
            </div>
        )
    }
}

export default WSTest

import Fragment from 'delv/Fragment.js'

const PartyFragment = new Fragment(`
id
role
usCitizen
isPrimary
dateOfBirth
`)

export default PartyFragment

import * as React from 'react'
import Inbox from '@mui/icons-material/Inbox'
import { grey } from '@mui/material/colors'

import Box from 'common/Box'
import Card from 'common/Card/index.js'
import Typography from 'common/Typography'
import { isSm } from 'constants/media-queries'

const NoneFoundCardSx = {
    maxWidth: '824px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    py: 8,
    px: 2,
    gap: 2,
    mx: 8,
    [isSm]: {
        mx: 2,
    },
}

const PageNoneFound = ({ children = 'None Found.' }) => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card sx={NoneFoundCardSx}>
            <Inbox data-testid='inbox-icon' sx={{ fontSize: 96, color: grey[600], [isSm]: { fontSize: 64 } }} />
            <Typography variant='h5' align='center'>{children}</Typography>
        </Card>
    </Box>
)

export default PageNoneFound

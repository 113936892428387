import * as React from 'react'

import Box from 'common/Box'

const Form = ({
    formView, FormProps = {}, endSlot,
}) => {
    const formStyles = FormProps.sx || {}
    const keys = Object.keys(formView)

    return (
        <Box
            sx={{
                display: 'flex', flexDirection: 'column', gap: 2, py: 2, ...formStyles,
            }}
        >
            {keys.map((key) => React.cloneElement(formView[key], { key }))}
            {endSlot}
        </Box>
    )
}

export default Form

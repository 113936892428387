import React from 'react'

export default ({
    startSlot,
    endSlot,
    offset,
    prefix = 'vrtl-asset-card',
}) => (
    <div className={`${prefix}__container--heading`}>
        {startSlot}
        <div className={`${prefix}__section--3`}>
            {offset}
            Asset Name
        </div>
        <div className={`${prefix}__section--2`}>Type</div>
        <div className={`${prefix}__section--2`}>Category</div>
        <div className={`${prefix}__section--2`}>Authorized Total</div>
        <div className={`${prefix}__section`}>Status</div>
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__section--2`} />
        <div className={`${prefix}__icon-offset--2`} />
        <div className={`${prefix}__icon-offset--2`} />
        {endSlot}
    </div>
)

import React from 'react'
import Done from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'

import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Dropdown, Input } from 'common/FormUI.js'

import Row from './Row.js'

const UPDATE_ROLE_MAPPING = `
mutation($id:UUID!, $patch:SigningRolePatch!){
  updateSigningRoleById(input:{id:$id, signingRolePatch:$patch}){
    signingRole{
      id
      mapTo
      toName
      toEmail
      mappingRoleByMapTo{
        id
        description
      }
    }
  }
}`

const formConfig = {
    fields: {
        mapTo: {
            name: 'mapTo',
            type: 'dropdown',
            error: 'Mapping required.',
            submit: (value) => {
                if (value === 'not mapped') {
                    return {
                        error: true,
                        message: 'Mapping required.',
                    }
                }
                return value
            },
        },
        toEmail: {
            name: 'toEmail',
            type: 'email',
            error: 'Valid email required',
            submit: (value, state, validator) => {
                if (state.mapTo === 'static') {
                    if (value === '') {
                        return {
                            error: true,
                            message: 'To email required for static field.',
                        }
                    }
                    return validator(value, state)
                }
                return ''
            },
        },
        toName: {
            name: 'toName',
            type: 'string',
            submit: (value, state, validator) => {
                if (state.mapTo === 'static') {
                    if (value === '') {
                        return {
                            error: true,
                            message: 'To name required for static field.',
                        }
                    }
                    return validator(value)
                }
                return ''
            },
        },
    },
}

const delvConfig = {
    query: `{
        allMappingRoles{
            nodes{
                id
            }
        }
    }`,
    format: (data) => {
        const blacklist = ['not mapped']

        return {
            options: data.allMappingRoles.nodes.map(({ id }) => ({ value: id, mapTo: id })).filter(({ value }) => !blacklist.includes(value)),
        }
    },
}

const FormComponent = ({
    form: {
        toEmail,
        toName,
        mapTo,
        validateInput,
        getChanged,
        loading,
        setLoading,
    },
    id,
    name,
    options,
    resolve,
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (input.toEmail === '') {
            delete input.toEmail
        }
        if (input.toName === '') {
            delete input.toName
        }
        if (!input.hasErrors) {
            const changedInput = getChanged(input)
            if (changedInput) {
                new Mutation({
                    mutation: UPDATE_ROLE_MAPPING,
                    onSubmit: () => {
                        setLoading(true)
                        return { id, patch: changedInput }
                    },
                    onResolve: resolve,
                }).submit()
            } else {
                resolve()
            }
        }
    }

    const prefix = 'vrtl-signing-role'
    if (loading) {
        return <Row name={name} mapTo='updating' toName='updating' toEmail='updating' />
    }

    const rowProps = {
        name,
        mapTo: <Dropdown {...mapTo} options={options} />,
        toName: mapTo.value === 'static' ? <Input {...toName} /> : 'N/A',
        toEmail: mapTo.value === 'static' ? <Input {...toEmail} /> : 'N/A',
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
    }
    return <Row {...rowProps} />
}

export default Delv.queryHOC(Form(FormComponent, formConfig), delvConfig)

import React, { Component } from 'react'

function ViewSwitch(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props)
            this.state = { UI: props.defaultUI ? props.defaultUI : 0 }
            this.history = []
        }

        componentDidUpdate = (prevProps) => {
            if (this.props.defaultUI !== prevProps.defaultUI) {
                this.setUI(this.props.defaultUI)
            }
        }

        next = () => {
            if (!this.props.viewAmount || this.state.UI + 1 < this.props.viewAmount) {
                this.history.push(this.state.UI)
                // eslint-disable-next-line
                this.setState({ UI: this.state.UI + 1 })
            }
        }

        back = () => {
            const oldUI = this.history.pop()
            this.setState({ UI: oldUI })
        }

        setUI = (UI) => {
            this.history.push(this.state.UI)
            this.setState({ UI })
        }

        render = () => {
            const viewSwitch = {
                next: this.next,
                back: this.back,
                setUI: this.setUI,
                UI: this.state.UI,
            }
            return (
                <WrappedComponent
                    viewSwitch={viewSwitch}
                    {...this.props}
                />
            )
        }
    }
}

export default ViewSwitch

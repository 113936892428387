import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
name
opensOn
closesOn
status
total
termsUrl
price
assetId
`)

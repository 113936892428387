/**
 * The base implementation of `assignValue` and `assignMergeValue` without
 * value checks.
 *
 * @private
 * @param {Object} object The object to modify.
 * @param {string} key The key of the property to assign.
 * @param {*} value The value to assign.
 */
function baseAssignValue(object, key, value) {
  if (key == '__proto__') {
          //skipping to avoid __proto__ pollution
          console.log('Skipping object value assignment from dashCopy baseAssignValue. If you are stuggling with an odd error, check here')
          return
  } else {
    object[key] = value
  }
}

export default baseAssignValue

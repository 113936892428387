import * as React from 'react'
import * as DateFns from 'date-fns'

import Typography from 'common/Typography'

const DateCell = ({ value }) => (
    <Typography variant='body2'>
        {DateFns.format(new Date(value), 'MM/dd/yyyy')}
    </Typography>
)

export default DateCell

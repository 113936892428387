import React from 'react'
import Edit from '@material-ui/icons/Edit'

import Format from 'util/format/Format.js'
import IconHelper from 'common/IconHelper.js'

export default ({
    id,
    name,
    authorizedTotal,
    status,
    children,
    assetTypeByType: { type, accountingType },
    edit,
    cancel,
    submit,
    collapse,
    startSlot,
    endSlot,
    offset,
    ...props
}) => {
    const prefix = 'vrtl-asset-card'
    return (
        <div className={`${prefix}__container--row`}>
            {startSlot}
            <div className={`${prefix}__section--3`}>
                {offset}
                {
                    collapse && React.cloneElement(collapse, props)
                }
                {name}
            </div>
            <div className={`${prefix}__section--2`}>{type}</div>
            <div className={`${prefix}__section--2 ${prefix}--pascal`}>{accountingType}</div>
            <div className={`${prefix}__section--2`}>{typeof authorizedTotal === 'string' ? Format.number(authorizedTotal) : authorizedTotal}</div>
            <div className={`${prefix}__section ${prefix}--pascal`}>{status}</div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            {endSlot}
            {
                IconHelper([[cancel, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />], false, submit, false], prefix)
            }
        </div>
    )
}

import React, { useState } from 'react'
import cx from 'classnames'
import SafeReact from 'util/SafeReact.js'

const CollapsibleContainer = ({
    children,
    className = 'card-container',
    addClassName = '',
    isOpen = false,
    open: propOpen,
    setOpen: propSetOpen,
    ...props
}) => {
    const [o, setO] = useState(isOpen)
    let open = o
    let setOpen = setO

    if (typeof propOpen === 'boolean') {
        open = propOpen
        setOpen = propSetOpen
    }

    const childrens = open ? children : children[0]

    return (
        <div className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(childrens, { open, setOpen, ...props })}
        </div>
    )
}

export default CollapsibleContainer

import * as React from 'react'

import Paper from 'common/Paper'

import './styles.scss'

const Pinstripe = ({ sx, ...props }) => (
    <Paper className='vrtl-pinstripe' square sx={{ backgroundColor: 'primary.main', ...sx }} {...props} />
)

export default Pinstripe

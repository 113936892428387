import React from 'react'
import Format from 'util/format/Format.js'
import {
    Page,
    Text,
    View,
    Image,
    Document,
} from '@react-pdf/renderer'
import styles from './styles.js'

const Template = ({
    type,
    assetName,
    legalName,
    issuerName,
    totalAmount,
    holdings,
}) => {
    const MapHoldings = (props) => (
        props.holdings.map(({
            roundName,
            issuedOn,
            amount,
        }) => (
            <View style={styles.row}>
                <Text style={styles.cell}>{roundName}</Text>
                <Text style={styles.cell}>{Format.date(issuedOn)}</Text>
                <Text style={styles.cell}>{Format.number(amount)}</Text>
            </View>
        ))
    )

    const currentDate = new Date()

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.container}>
                    <Text style={styles.title_page}>Proof Of Issuance</Text>
                    <View style={styles.logo_container}>
                        <Image style={styles.logo_container} src={`${window.location.origin}/content/images/Vertalo-640x200-8.png`} />
                    </View>
                </View>
                <View style={styles.container_row}>
                    <Text style={styles.title}>
                        {`Statement Date: ${Format.date(currentDate)}`}
                    </Text>
                </View>
                <View style={styles.container_column}>
                    <Text style={styles.title}>Please print or save this document for your records.</Text>
                </View>
                <View style={styles.container_box}>
                    <Text style={[styles.title_colored, styles.center, styles.colorbg]}>Asset Details</Text>
                    <Text style={styles.header}>
                        {`Share Holder Name: ${legalName}`}
                    </Text>
                    <Text style={styles.header}>
                        {`Issuer Name: ${issuerName}`}
                    </Text>
                    <Text style={styles.header}>
                        {`Asset Name: ${assetName}`}
                    </Text>
                    <Text style={styles.header}>
                        {`Asset Type: ${type}`}
                    </Text>
                    <Text style={styles.header}>
                        {`Total Shares: ${Format.number(totalAmount)}`}
                    </Text>
                </View>
                <View style={styles.table}>
                    <Text style={[styles.title_colored, styles.center, styles.colorbg]}>Holdings Details</Text>
                    <View style={styles.row}>
                        <Text style={styles.header_cell}>Round Name</Text>
                        <Text style={styles.header_cell}>Issued On</Text>
                        <Text style={styles.header_cell}>Total Units</Text>
                    </View>
                    <MapHoldings holdings={holdings} />
                </View>
                <View fixed style={[styles.footer, styles.center]}>
                    <Text>For assistance you may contact Vertalo Inc, the Transfer Agent for the above listed security.</Text>
                    <Text>E-Mail: transfers@vertalo.com | website: www.vertalo.com</Text>
                </View>
            </Page>
        </Document>
    )
}

export default Template

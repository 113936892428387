const TokenFragment = `
    id
    name
    type
    symbol
    status
    precision
    coinbaseBuyPriceUSD
    coinbaseSellPriceUSD
    coinbaseSpotPriceUSD
`

export default TokenFragment

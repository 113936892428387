import React from 'react'
import SafeReact from 'util/SafeReact.js'

const Row = ({
    prefix = 'vrtl-investor-security-row',
    issuerName,
    assetName,
    roundName,
    endSlot,
    startSlot,
    children,
    ...props
}) => (
    <div className={`${prefix}__row`}>
        {SafeReact.cloneChildren(children, props)}
        {startSlot}
        <div className={`${prefix}__section--2`}>
            {issuerName}
        </div>
        <div className={`${prefix}__section`}>
            {assetName}
        </div>
        <div className={`${prefix}__section`}>
            {roundName}
        </div>
        {endSlot}
    </div>
)

export default Row

import AccountFragment from 'components/account/Fragment.js'
import UserFragment from 'components/user/Fragment.js'
import CountryFragment from 'components/country/fragment.js'
import AddressFragment from 'components/address/fragment.js'
import PartyFragment from 'components/party/fragment.js'

const AccountSetupFragment = `
  ${AccountFragment.toString()}
  taxShort
  countryByJurisdiction {
    ${CountryFragment.toString()}
  }
  accountAddressesByAccountId {
    nodes {
      id
      type
      addressByAddressId {
        ${AddressFragment.toString()}
      }
    }
  }
  partiesByAccountId {
    nodes {
      ${PartyFragment.toString()}
      taxShort
      userByUserId {
        ${UserFragment.toString()}
      }
      partyAddressesByPartyId {
        nodes {
          id
          type
          addressByAddressId {
            ${AddressFragment.toString()}
          }
        }
      }
    }
  }
  usersAccountsByAccountId {
    nodes {
      id
      role
      userByUserId {
        ${UserFragment.toString()}
      }
    }
  }
`

export default AccountSetupFragment

import React from 'react'
import GridChildProvider from 'common/grid/GridChildProvider.js'
import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation'
import Process from 'process/Process.js'
import Div from 'common/Div.js'
import { Link } from 'react-router-dom'

import './Qualifications.scss'
import NoneFound from 'common/NoneFound/index.js'

const GET_QUALIFICATIONS = (types) => `{
  allIntegrations(filter: {type:{in:${types}}}) {
    nodes {
      id
      type
      service
      description
      accountIntegrationByIntegrationId {
        id
        ${Process.fragment}
      }
      integrationPartnerByPartnerId {
        id
        name
        logo
        url
      }
    }
  }
}`

const CREATE_VENDOR_INTEGRATION = `mutation ($integrationId: UUID!) {
  createAccountIntegration(input: {accountIntegration: {integrationId: $integrationId}}) {
    accountIntegration {
      id
      integrationByIntegrationId {
        id
      }
      ${Process.fragment}
    }
  }
}`

const addIntegration = (integrationId) => {
    new Mutation({
        mutation: CREATE_VENDOR_INTEGRATION,
        onSubmit: () => ({ integrationId }),
    }).submit()
}

const VENDOR_INTEGRATION_MAP = {
    Signing: '/docusign/templates',
    'Payment processing': '/north-capital/issuers',
}

const STEPLESS_INTEGRATIONS = {
    'Information Gathering': '/onboarding-questions',
}

const IntegrationCard = ({
    id,
    service,
    description = '',
    accountIntegrationByIntegrationId: accountIntegration,
    integrationPartnerByPartnerId: { logo },
    integrationTypes,
}) => {
    const proc = accountIntegration ? accountIntegration.stepByStepId : undefined
    const prefix = 'vrtl-qualifications-card'
    return (
        <div className={`${prefix}__container`}>
            <Process.process proc={proc} refetch={GET_QUALIFICATIONS(integrationTypes)}>
                <div className={`${prefix}__logo-container`}>
                    <img src={logo} className={`${prefix}__logo`} />
                </div>
                <Div className='vrtl-process-short-steps'>
                    {!accountIntegration ? (
                        <div className={`${prefix}__text`}>{description}</div>
                    ) : proc && proc.status !== 'complete' ? (
                        [
                            <Process.info />,
                            <Process.shortSteps>
                                <Process.shortStep />
                            </Process.shortSteps>,
                        ]
                    ) : (
                        <div className={`${prefix}__text`}>{description}</div>
                    )}
                </Div>
                <Div className={`${prefix}__footer`}>
                    {!accountIntegration ? (
                        STEPLESS_INTEGRATIONS[service] ? (
                            <Link
                                className={`${prefix}__button`}
                                to={STEPLESS_INTEGRATIONS[service]}
                            >
                                Manage
                            </Link>
                        ) : (
                            <div className={`${prefix}__button`} onClick={() => addIntegration(id)}>
                                Add
                            </div>
                        )
                    ) : proc && proc.status === 'complete' ? (
                        VENDOR_INTEGRATION_MAP[service] && (
                            <Link
                                className={`${prefix}__button`}
                                to={VENDOR_INTEGRATION_MAP[service]}
                            >
                                Manage
                            </Link>
                        )
                    ) : (
                        <Process.currentStep />
                    )}
                </Div>
            </Process.process>
        </div>
    )
}

export default (props) => {
    const args = props.isInvestor ? '["portfolio"]' : '["compliance", "signing", "VertaloIO", "payment"]'

    return (
        <div className='flex-1'>
            <Delv.query query={GET_QUALIFICATIONS(args)}>
                <GridChildProvider
                    itemsPer={3}
                    arrayKey='allIntegrations.nodes'
                    noneFound={<NoneFound size='lg'>No integrations found.</NoneFound>}
                >
                    <IntegrationCard integrationTypes={args} accountId={props.accountId} />
                </GridChildProvider>
            </Delv.query>
        </div>
    )
}

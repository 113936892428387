import * as React from 'react'

const useArgs = (options) => {
    const [args, setArgs] = React.useState({
        filter: {
            items: [],
        },
        sort: {
            items: [],
        },
        pagination: {
            page: options?.pagination?.page || 0,
            pageSize: options?.pagination?.pageSize || 50,
            offset: options?.pagination?.offset || 0,
        },
        ...options?.args,
    })
    const constantArgs = {
        ...options?.args,
        filter: {
            ...args.filter,
            ...options?.constantArgs?.filter,
        },
        pagination: {
            ...args.pagination,
            ...options?.constantArgs?.pagination,
        },
        sort: {
            ...args.sort,
            ...options?.constantArgs?.sort,
        },
    }

    const setFilter = ({ filter, items }) => {
        const filteredArgs = {
            ...args,
            filter,
        }
        const filteredNewItems = items.filter(({ value }) => Boolean(value))
        const filteredArgItems = args.filter.items.filter(({ value }) => Boolean(value))
        const matchFromArgs = filteredArgItems.filter((argItem) => (
            filteredNewItems.some((newItem) => (
                newItem.columnField === argItem.columnField && newItem.value === argItem.value && newItem.operatorValue === argItem.operatorValue
            ))
        ))

        if (matchFromArgs.length === filteredNewItems.length) {
            filteredArgs.filter.items = items
            setArgs(filteredArgs)
            return
        }

        filteredArgs.pagination.offset = options?.pagination?.offset || 0
        filteredArgs.pagination.pageSize = options?.pagination?.pageSize || 50
        filteredArgs.pagination.page = options?.pagination?.page || 0
        filteredArgs.filter.items = items
        setArgs(filteredArgs)
    }

    const setSort = ({ items, key, value }) => {
        const sortedArgs = {
            ...args,
            sort: {
                [key]: {
                    fieldName: key,
                    sort: value === '' ? null : value,
                },
            },
        }

        sortedArgs.pagination.offset = options?.pagination?.offset || 0
        sortedArgs.pagination.pageSize = options?.pagination?.pageSize || 50
        sortedArgs.pagination.page = options?.pagination?.page || 0
        sortedArgs.sort.items = items

        setArgs(sortedArgs)
    }

    const loadMore = ({ totalCount }) => {
        const desiredOffset = args.pagination.offset + args.pagination.pageSize
        const newOffset = desiredOffset < totalCount ? desiredOffset : totalCount

        if (newOffset < totalCount) {
            setArgs({
                ...args,
                pagination: {
                    ...args.pagination,
                    page: args.pagination.page + 1,
                    offset: newOffset,
                },
            })
        }
    }

    const loadAll = ({ totalCount }) => {
        setArgs({
            ...args,
            pagination: {
                ...args.pagination,
                offset: 0,
                pageSize: totalCount,
            },
        })
    }
    return {
        args: constantArgs,
        setArgs,
        loadAll,
        loadMore,
        setFilter,
        setSort,
    }
}

export default useArgs

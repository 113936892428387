import * as React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import Divider from 'common/Divider'
import List from 'common/List'
import ListItem from 'common/ListItem'
import ListItemText from 'common/ListItemText'
import ListItemIcon from 'common/ListItemIcon'
import Link from 'common/Link/index'
import {
    GOOGLE_HELP_DOC,
    INVESTOR_SUPPORT_CENTER,
    VENDOR_SUPPORT_CENTER,
    PRIVACY_POLICY,
    TERMS,
} from 'constants/links'
import { getAccountType } from 'util/account-utils'
import useUsersData from 'hooks/UseUsersData'

import './styles.scss'

const SupportLink = ({ prefix, getUsersData }) => {
    const selectSupportLink = (accountType) => {
        const map = {
            investor: INVESTOR_SUPPORT_CENTER,
            broker: VENDOR_SUPPORT_CENTER,
            issuer: VENDOR_SUPPORT_CENTER,
            agent: VENDOR_SUPPORT_CENTER,
            ats: VENDOR_SUPPORT_CENTER,
            admin: GOOGLE_HELP_DOC,
        }

        return map[accountType] || INVESTOR_SUPPORT_CENTER
    }

    const accountType = getAccountType(getUsersData)
    const supportLink = selectSupportLink(accountType)

    return (
        <ListItem className={`${prefix}__list-item`}>
            <Link className={`${prefix}__list-link`} href={supportLink} target='_blank' rel='noopener noreferrer'>
                Support Center
                <ListItemIcon className={`${prefix}__list-link-icon`}>
                    <OpenInNewIcon color='primary' data-testid='footer-support-icon' />
                </ListItemIcon>
            </Link>
        </ListItem>
    )
}

const Footer = ({
    prefix = 'vrtl-footer',
}) => {
    const { data } = useUsersData()

    return (
        <footer data-testid='footer' className={prefix}>
            <Divider />
            <List className={`${prefix}__list`}>
                <ListItem className={`${prefix}__list-item`}>
                    <ListItemText className={`${prefix}__list-link`}>
                        {`Vertalo ©${new Date().getFullYear()}`}
                    </ListItemText>
                </ListItem>
                <ListItem className={`${prefix}__list-item`}>
                    <Link className={`${prefix}__list-link`} href={TERMS}>
                        Terms
                    </Link>
                </ListItem>
                <ListItem className={`${prefix}__list-item`}>
                    <Link className={`${prefix}__list-link`} href={PRIVACY_POLICY}>
                        Privacy Policy
                    </Link>
                </ListItem>
                <SupportLink prefix={prefix} getUsersData={data?.getUsersData} />
            </List>
        </footer>
    )
}

export default Footer

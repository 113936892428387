import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
name
description
errorMessage
onboardingQuestionTypeByType{
  id:type
  type
  regex
}`)

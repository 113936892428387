import React from 'react'
import Format from 'util/format/Format.js'
import './AnnotationCard.scss'

const data = `id
annotation
createdOn`

const distribution = `distributionAnnotationsByDistributionId{
  nodes{
    ${data}
  }
}`
const allocation = `allocationAnnotationsByAllocationId{
  nodes{
    ${data}
  }
}`

const fragment = {
    data,
    distribution,
    allocation,
}

export { fragment }

export default ({ annotation, createdOn }) => (
    <div className='card-container card'>
        <div className='card-section'>
            <div className='card-label'>NOTE</div>
            <div className='annotation-text-container'>{annotation}</div>
            <div className='annotation-timestamp'>
                Created On:
                {' '}
                {Format.date(createdOn)}
            </div>
        </div>
    </div>
)

import React from 'react'
import Format from 'util/format/Format.js'
import Card from 'common/Card.js'

export default ({
    edit, allocation: {
        name, opensOn, closesOn, status,
    },
}) => (
    <div>
        <Card.card>
            <Card.section>
                <Card.label>NAME</Card.label>
                {name}
            </Card.section>
            <Card.section>
                <Card.label>STATUS</Card.label>
                {status}
            </Card.section>
            <Card.section>
                <Card.label>OPENS ON</Card.label>
                {Format.date(opensOn, 'MMM Do YYYY')}
            </Card.section>
            <Card.section>
                <Card.label>CLOSES ON</Card.label>
                {Format.date(closesOn, 'MMM Do YYYY')}
            </Card.section>
            {edit}
        </Card.card>
    </div>
)

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import SettingsIcon from '@mui/icons-material/Settings'
import WorkIcon from '@mui/icons-material/Work'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import FolderIcon from '@mui/icons-material/Folder'
import ContactsIcon from '@mui/icons-material/Contacts'
import GroupsIcon from '@mui/icons-material/Groups'
import StorageIcon from '@mui/icons-material/Storage'
import CorporateFare from '@mui/icons-material/CorporateFare'
import Dashboard from '@mui/icons-material/Dashboard'
import DonutLarge from '@mui/icons-material/DonutLarge'
import Receipt from '@mui/icons-material/Receipt'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const GOOGLE_HELP_DOC = 'https://docs.google.com/document/d/148p0uvuPqi-9599qSAMEBz2NF048c1GtlWGAMcr2wOk/edit?usp=sharing'
// eslint-disable-next-line max-len
const INVESTOR_SUPPORT_CENTER = 'https://vertalo-org.myfreshworks.com/login/auth/sso?client_id=41441690836303948&redirect_uri=https%3A%2F%2Finvestors.support.vertalo.com%2Ffreshid%2Fcustomer_authorize_callback'
// VENDOR_SUPPORT_CENTER routes to an issuer-specific freshdesk page.
// We're using it as the default for all roles except investor until we have a freshdesk page per role.
// eslint-disable-next-line max-len
const VENDOR_SUPPORT_CENTER = 'https://vertalo-org.myfreshworks.com/login/auth/sso?client_id=41441690836303948&redirect_uri=https%3A%2F%2Fclients.support.vertalo.com%2Ffreshid%2Fcustomer_authorize_callback'

const PRIVACY_POLICY = 'https://www.vertalo.com/privacy-policy'
const TERMS = 'https://www.vertalo.com/terms-of-service'

const USER_OPTIONS = [
    {
        Icon: SwapHorizIcon,
        href: '/authenticate/app-user',
        text: 'Switch Role',
    },
    {
        Icon: SettingsIcon,
        to: '/settings/account',
        text: 'Settings',
    },
    {
        Icon: HelpOutlineIcon,
        href: VENDOR_SUPPORT_CENTER,
        text: 'Support',
    },
    {
        Icon: ExitToAppIcon,
        href: '/authenticate/logout',
        onClick: () => {
            localStorage.removeItem('account')
        },
        text: 'Logout',
    },
]

const DROPDOWN_LINKS = {
    admin: [
        {
            Icon: SwapHorizIcon,
            href: '/authenticate/app-user',
            text: 'Switch Role',
        },
        {
            Icon: HelpOutlineIcon,
            href: GOOGLE_HELP_DOC,
            text: 'Support',
        },
        {
            Icon: ExitToAppIcon,
            href: '/authenticate/logout',
            onClick: () => {
                localStorage.removeItem('account')
            },
            text: 'Logout',
        },
    ],
    investor: [
        {
            Icon: SwapHorizIcon,
            href: '/authenticate/app-user',
            text: 'Switch Role',
        },
        {
            Icon: SettingsIcon,
            to: '/settings/account',
            text: 'Settings',
        },
        {
            Icon: HelpOutlineIcon,
            href: INVESTOR_SUPPORT_CENTER,
            text: 'Support',
        },
        {
            Icon: ExitToAppIcon,
            href: '/authenticate/logout',
            onClick: () => {
                localStorage.removeItem('account')
            },
            text: 'Logout',
        },
    ],
}

const NAV_LINKS = {
    admin: [
        {
            Icon: AppRegistrationIcon,
            to: '/admin/forms',
            text: 'Forms',
            highlightOn: ['/forms'],
        },
        {
            Icon: ArticleOutlinedIcon,
            to: '/admin/reports',
            text: 'Reports',
            highlightOn: ['/reports'],
        },
        {
            Icon: StorageIcon,
            to: '/admin/types',
            text: 'Types',
            highlightOn: ['/types'],
        },
    ],
    agent: [
        {
            Icon: GroupsIcon,
            to: '/distributions',
            text: 'Distributions',
            highlightOn: ['/distributions'],
        },
        {
            Icon: ContactsIcon,
            to: '/contacts',
            text: 'Contacts',
            highlightOn: ['/contacts'],
        },
    ],
    ats: [
        {
            Icon: Receipt,
            to: '/securities',
            text: 'Securities',
            highlightOn: ['/securities'],
        },
        {
            Icon: ContactsIcon,
            to: '/contacts',
            text: 'Contacts',
            highlightOn: ['/contacts'],
        },
    ],
    investor: [
        {
            Icon: WorkIcon,
            to: '/portfolio',
            text: 'Portfolio',
            highlightOn: ['/portfolio'],
        },
        {
            Icon: AccountBalanceWalletIcon,
            to: '/wallet',
            text: 'Wallet',
            highlightOn: ['/wallet'],
        },
        {
            Icon: FolderIcon,
            to: '/investor-documents',
            text: 'Documents',
            highlightOn: ['/investor-documents'],
        },
    ],
    issuer: [
        {
            Icon: Dashboard,
            to: '/dashboard',
            text: 'Dashboard',
            highlightOn: ['/dashboard'],
        },
        {
            Icon: DonutLarge,
            to: '/assets',
            text: 'Assets',
            highlightOn: ['/assets'],
        },
        {
            Icon: Receipt,
            to: '/securities',
            text: 'Securities',
            highlightOn: ['/securities'],
        },
        {
            Icon: CorporateFare,
            to: '/capitalization',
            text: 'Capitalization',
            highlightOn: ['/capitalization'],
        },
        {
            Icon: ArticleOutlinedIcon,
            to: '/reports',
            text: 'Reports',
            highlightOn: ['/reports'],
        },
        {
            Icon: ContactsIcon,
            to: '/contacts',
            text: 'Contacts',
            highlightOn: ['/contacts'],
        },
    ],
    broker: [
        {
            Icon: Dashboard,
            to: '/dashboard',
            text: 'Dashboard',
            highlightOn: ['/dashboard'],
        },
        {
            Icon: DonutLarge,
            to: '/assets',
            text: 'Assets',
            highlightOn: ['/assets'],
        },
        {
            Icon: CorporateFare,
            to: '/capitalization',
            text: 'Capitalization',
            highlightOn: ['/capitalization'],
        },
        {
            Icon: ArticleOutlinedIcon,
            to: '/reports',
            text: 'Reports',
            highlightOn: ['/reports'],
        },
        {
            Icon: ContactsIcon,
            to: '/contacts',
            text: 'Contacts',
            highlightOn: ['/contacts'],
        },
    ],
}

export {
    GOOGLE_HELP_DOC,
    INVESTOR_SUPPORT_CENTER,
    VENDOR_SUPPORT_CENTER,
    DROPDOWN_LINKS,
    USER_OPTIONS,
    NAV_LINKS,
    PRIVACY_POLICY,
    TERMS,
}

import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MaterialLink from '@mui/material/Link'

const LinkBehavior = React.forwardRef((props, ref) => (
    props.href
        ? <a href={props.href} {...props}>{props.children}</a>
        : <RouterLink ref={ref} {...props} />
))

const Link = (props) => (
    <MaterialLink component={LinkBehavior} {...props} />
)

export default Link

import * as React from 'react'

import AutoComplete from 'common/Autocomplete'
import TextField from 'common/TextField'
import CircularProgress from 'common/CircularProgress'

const DropdownTypeahead = ({
    id: propId,
    'data-testid': testId,
    label: propLabel,
    loading,
    onChange,
    options,
    value,
    helperText,
    error,
    onBlur,
    required,
    name,
    fullWidth,
    ...props
}) => {
    const id = propId || propLabel?.replace('/ /g', '-')
    const [inputValue, setInputValue] = React.useState('')
    const label = `${propLabel}${required ? ' *' : ''}`

    React.useEffect(() => {
        if (value === '') {
            setInputValue('')
        }
    }, [value])

    const handleChange = (_e, val) => {
        const event = {
            target: {
                value: val || '',
                name,
            },
        }

        onChange(event)
        setInputValue(val?.label || '')
    }

    const handleInputBlur = (e) => {
        const desiredValue = e.target.value
        const newValue = options.find((opt) => opt.value === desiredValue || opt.label === desiredValue)

        const event = {
            target: {
                value: newValue || '',
                name,
            },
        }

        onBlur(event)
        setInputValue(newValue?.label || '')
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const filterOptions = (opts) => opts.filter(
        (opt) => opt.label.toLowerCase().includes(inputValue.toLowerCase()) || opt.value.toLowerCase().includes(inputValue.toLowerCase()),
    )

    return (
        <AutoComplete
            data-testid={testId}
            id={id}
            disablePortal
            options={options}
            value={value}
            onChange={handleChange}
            openOnFocus
            filterOptions={filterOptions}
            fullWidth={fullWidth}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    onBlur={handleInputBlur}
                    helperText={helperText}
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    // eslint-disable-next-line
                    inputProps={{
                        ...params.inputProps,
                        onChange: handleInputChange,
                        value: inputValue,
                        // chrome doesn't support the value 'off', but any string will disable autoComplete
                        autoComplete: 'ijustwantsomesleep',
                    }}
                />
            )}
            {...props}
        />
    )
}

export default DropdownTypeahead

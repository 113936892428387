import React from 'react'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import { Link } from 'react-router-dom'

export default ({ children, url = '/' }) => {
    const [title, body] = children
    return (
        <div className='dashboard-section'>
            <Link to={url}>
                <div className='dashboard-section-header'>
                    <div className='flex-1'>
                        <div className='dashboard-section-header-text'>
                            {title}
                        </div>
                    </div>
                    <div className='dashboard-section-arrow'>
                        <RightArrow />
                    </div>
                </div>
            </Link>
            <div className='dashboard-section-body'>{body}</div>
        </div>
    )
}

import gql from 'graphql-tag'

class QueryManager {
    constructor() {
        this.queries = {}
    }

    addTypename = (query) => query.replace(/{\s*(\n)/g, '{\n__typename\n')

    normalizeQuery = (query, variables) => {
        const ast = gql`
            ${query}
        `
        const normalized = ast.definitions[0].name && `__${ast.definitions[0].name.value}`
        return (
            normalized
            || `${query}${JSON.stringify(variables)}`.replace(/(\s)+/g, '')
        )
    }

    add = (query, variables) => {
        const normalized = this.normalizeQuery(query, variables)
        if (!this.includes(null, null, normalized)) {
            const id = normalized.substring(0, 2) === '__'
                ? normalized
                : Math.random().toString(36).substr(2, 9)
            this.queries[normalized] = {
                promise: null,
                id,
            }
            return id
        }
        return this.queries[normalized].id
    }

    includes = (query, variables, normalized) => this.queries[normalized || this.normalizeQuery(query, variables)]

    getPromise = (query, variables) => {
        const normalized = this.normalizeQuery(query, variables)
        if (this.includes(null, null, normalized)) {
            return this.queries[normalized].promise
        }
        return null
    }

    setPromise = (query, variables, promise) => {
        const normalized = this.normalizeQuery(query, variables)
        promise.finally(() => {
            this.queries[normalized].promise = null
        })
        this.queries[normalized].promise = promise
    }

    remove = (queryName, hack) => {
        const query = `__${queryName}`

        if (hack) {
            Object.keys(this.queries).forEach((key) => {
                if (key.startsWith(query)) {
                    this.remove(key.replace(/^__/, ''))
                }
            })
        } else if (this.queries[query] && !this.queries[query].promise) {
            this.queries[query] = null
        }
    }
}

export default QueryManager

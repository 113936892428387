import React from 'react'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'
import { Date, Input, Dropdown } from 'common/FormUI.js'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation'
import Security from 'components/security/index.js'
import Fragment from './Fragment.js'
import Row from './Row.js'
import TrancheFragment from './TrancheFragment.js'

/* eslint-disable max-len */
const MAKE_ROUND = `mutation ($assetId:UUID!, $name: String!, $termsUrl:String!, $price:BigFloat, $opensOn:Datetime!, $closesOn:Datetime!, $status: String, $total:BigFloat!) {
    makeRound(input: {assetId: $assetId, name: $name, termsUrl: $termsUrl, price: $price, opensOn: $opensOn, closesOn: $closesOn, status:$status, total: $total}) {
      round {
        ${Fragment.toString()}
        securityBySecurityId {
            ${Security.fragment.toString()}
            securityListingBySecurityId{
                id
                accountByAtsId{
                  id
                  name
                  company
                }
              }
        }
        tokenByTokenId {
          id
          name
          total
          precision
          capTablesByTokenId {
            nodes {
              id
            }
          }
        }
        brokerAllocationsByRoundId {
          nodes {
            id
            brokerId
          }
        }
        assetByAssetId {
          id
          name
        }
        allocationsByRoundId {
          nodes {
            id
            managedUnits
            accountByOwnerId {
              id
            }
          }
        }
      }
    }
  }`
/* eslint-enable max-len */

const MAKE_TRANCHE = `mutation($input: MakeTrancheInput!) {
  makeTranche(input: $input){
    round{
      ${Fragment.toString()}
      securityBySecurityId {
        ${Security.fragment.toString()}
        securityListingBySecurityId{
            id
            accountByAtsId{
              id
              name
              company
            }
          }
      }
      trancheBondDatumByRoundId{
        ${TrancheFragment.toString()}
      }
      tokenByTokenId {
        id
        name
        total
        precision
        capTablesByTokenId {
          nodes {
            id
          }
        }
      }
      brokerAllocationsByRoundId { 
        nodes {
          id
          brokerId
        }
      }
      assetByAssetId { 
        id
        name
      }
      allocationsByRoundId {
        nodes {
          id
          managedUnits
          accountByOwnerId {
            id
          }
        }
      }
    }
  }
}`

const UPDATE_TRANCH = {
    query: `  updateTrancheBondDatumByRoundId(input: {roundId: $roundId, trancheBondDatumPatch:{coupon:$coupon, term:$term}}){
        trancheBondDatum{
            ${TrancheFragment.toString()}
        }
    }`,
    args: '$term: BigFloat, $coupon: BigFloat,',
}

const UPDATE_ROUND = {
    // eslint-disable-next-line
    query: `updateRoundById(input: {id: $roundId, roundPatch: {name: $name, termsUrl: $termsUrl, price: $price, opensOn: $opensOn, closesOn: $closesOn, status: $status, total: $total}}) {
        round {
          id
          name
          price
          opensOn
          closesOn
          termsUrl
          status
          total
        }
      }`,
    args: '$name: String, $termsUrl: String, $price: BigFloat, $opensOn: Datetime, $closesOn: Datetime, $status: String, $total: BigFloat, ',
}

const UPDATE_SECURITY = {
    query: `updateSecurityById(input: {id: $securityId, securityPatch: {unitValue: $unitValue}}) {
        security {
            id
            unitValue
        }
    }`,
    args: '$securityId: UUID!, $unitValue: BigFloat!',
}

const makeMutation = (formInput, idMap) => {
    const roundHasChanged = (input) => (Object.keys(input).length - (Number(!!input.coupon) + Number(!!input.unitValue) + Number(!!input.term)) > 0)
    const trancheHasChanged = (input) => input.coupon || input.term
    const priceHasChanged = (input) => !!input.unitValue

    const fragments = {
        args: '',
        query: '',
        variables: {},
    }

    if (trancheHasChanged(formInput)) {
        const term = formInput?.term
        const coupon = formInput?.coupon

        fragments.args += UPDATE_TRANCH.args
        fragments.query += UPDATE_TRANCH.query
        fragments.variables = { term, coupon }
    }

    if (roundHasChanged(formInput)) {
        const filteredRound = Object.keys(formInput)
            .filter((key) => (key !== 'term' && key !== 'coupon'))
            .reduce((accum, curr) => {
                // eslint-disable-next-line
                accum[curr] = formInput[curr]
                return accum
            }, {})

        fragments.args += UPDATE_ROUND.args
        fragments.query += UPDATE_ROUND.query
        fragments.variables = { ...fragments.variables, ...filteredRound }
    }

    if (priceHasChanged(formInput)) {
        fragments.args += UPDATE_SECURITY.args
        fragments.query += UPDATE_SECURITY.query
        fragments.variables = {
            ...fragments.variables,
            unitValue: formInput.unitValue,
            securityId: idMap.securityId,
        }
    }

    if (trancheHasChanged(formInput) || roundHasChanged(formInput)) {
        fragments.args += '$roundId: UUID!,'
        fragments.variables = { ...fragments.variables, roundId: idMap.roundId }
    }

    const mutation = {
        query: `mutation(${fragments.args}){
            ${fragments.query}
        }`,
        variables: fragments.variables,
    }

    return mutation
}

const STATUS = [
    { status: 'Drafted', value: 'drafted' },
    { status: 'Active', value: 'active' },
    { status: 'Closed', value: 'closed' },
    { status: 'Stale', value: 'stale' },
]

const RoundForm = ({
    id,
    assetId,
    resolve,
    type,
    atsName,
    displayProps = {},
    form: {
        name,
        opensOn,
        closesOn,
        termsUrl,
        price,
        total,
        status,
        validateInput,
        getChanged,
        term,
        coupon,
        unitValue,
        nodeType,
    },
    ...props
}) => {
    const assetType = type.toLowerCase()
    const handleSubmit = () => {
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    const mutation = makeMutation(changedInput, { roundId: id, securityId: props.securityBySecurityId.id })
                    new Mutation({
                        mutation: mutation.query,
                        onSubmit: () => mutation.variables,
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            } else {
                // eslint-disable-next-line
                const { term, coupon, name, opensOn, closesOn, termsUrl, price, total, status, } = input

                const formInput = assetType === 'debt'
                    ? {
                        name, opensOn, closesOn, termsUrl, price, total, status, term, coupon,
                    }
                    : {
                        name, opensOn, closesOn, termsUrl, price, total, status,
                    }

                new Mutation({
                    mutation: assetType === 'debt' ? MAKE_TRANCHE : MAKE_ROUND,
                    onSubmit: () => (assetType === 'debt' ? ({ input: { ...formInput, assetId } }) : ({ ...formInput, assetId })),
                    onResolve: resolve,
                }).submit()
            }
        }
    }
    const prefix = 'vrtl-round-card'
    const roundDisplayProps = {
        ...props,
        ...displayProps,
        name: <Input {...name} />,
        opensOn: <Date testId='round_openson' {...opensOn} />,
        closesOn: <Date testId='round_closeson' {...closesOn} />,
        termsUrl: <Input {...termsUrl} />,
        price: <Input {...price} />,
        status: <Dropdown options={STATUS} {...status} />,
        total: <Input {...total} />,
        cancel: id ? (
            <Cancel id={nodeType.value.cancel.id} className={`${prefix}__icon-cancel`} onClick={resolve} />
        ) : (
            <Delete Delete className={`${prefix}__icon-delete`} onClick={resolve} />
        ),
        submit: <Done id={nodeType.value.submit.id} className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        unitValue: id ? <Input {...unitValue} /> : 'N/A',
        atsName: <div className={`${prefix}__section--2`}>{atsName}</div>,
    }

    const trancheDisplayProps = {
        ...props,
        ...displayProps,
        name: <Input {...name} />,
        opensOn: <Date testId={opensOn.id} {...opensOn} />,
        closesOn: <Date testId={closesOn.id} {...closesOn} />,
        termsUrl: <Input {...termsUrl} />,
        price: <Input {...price} />,
        status: <Dropdown options={STATUS} {...status} />,
        total: <Input {...total} />,
        cancel: id ? (
            <Cancel {...nodeType.value.cancel.id} className={`${prefix}__icon-cancel`} onClick={resolve} />
        ) : (
            <Delete Delete className={`${prefix}__icon-delete`} onClick={resolve} />
        ),
        submit: <Done {...nodeType.value.submit.id} className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        term: <Input {...term} />,
        coupon: (
            <div className={`${prefix}--center row ${prefix}--padding-right-sm`}>
                <Input {...coupon} />
                %
            </div>
        ),
        unitValue: id ? <Input {...unitValue} /> : 'N/A',
        atsName: <div className={`${prefix}__section--2`}>{atsName}</div>,

    }
    return assetType === 'debt' ? <Row {...trancheDisplayProps} /> : <Row {...roundDisplayProps} />
}

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is a required field.',
        },
        opensOn: {
            type: 'date',
            error: 'Opens on requires a valid date.',
        },
        closesOn: {
            type: 'date',
            error: 'Closes on required a valid date.',
        },
        termsUrl: {
            type: 'link',
            error: 'Dataroom Url is required',
        },
        price: {
            type: 'number',
            error: 'Price is required.',
        },
        total: {
            type: 'number',
            error: 'Total is required.',
        },
        status: {
            type: 'dropdown',
            error: 'Status is required.',
        },
        coupon: {
            type: 'number',
            error: 'Coupon is required',
            skipRender: ({ type }) => type.toLowerCase() !== 'debt',
        },
        term: {
            type: 'number',
            error: 'Term is required',
            skipRender: ({ type }) => type.toLowerCase() !== 'debt',
        },
        unitValue: {
            type: 'number',
        },
    },
    nodeType: 'round',
}

export default Form(RoundForm, formConfig)

import Fragment from './Fragment.js'
import InvestorAccount from './InvestorAccount.js'
import Card from './Card.js'
import Setup from './setup/index.js'

const Account = {
    fragment: Fragment,
    card: Card,
    investorAccount: InvestorAccount,
    setup: Setup,
}

export default Account

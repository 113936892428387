import React from 'react'

import Delv from 'delv/delv-react'
import Card from 'common/Card.js'
import Div from 'common/Div.js'
import Map from 'common/Map.js'
import NoneFound from 'common/NoneFound/index.js'
import Popup from 'common/Popup.js'

import Process from 'process/Process.js'
import MappedTemplate from './mappedTemplate/index.js'

const GET_MAPPED_TEMPLATE = (id) => `{
  allPaymentInstruments(condition:{paymentIssuerId:"${id}"}){
    nodes{
      ${MappedTemplate.fragment}
    }
  }
}`

const Right = ({ match }) => (
    <div className='vrtl-signing-templates__container--templates'>
        <div className='gray-header'>Offerings</div>
        <MappedTemplate.rowHeading />
        <Div className='buyers-container'>
            <Div className='order-by-inner'>
                <Delv.query query={GET_MAPPED_TEMPLATE(match.params.issuer_id)}>
                    <Map
                        arrayKey='allPaymentInstruments.nodes'
                        noneFound={<NoneFound>No templates found.</NoneFound>}
                    >
                        <MappedTemplate.row match={match} />
                    </Map>
                </Delv.query>
            </Div>
        </Div>
    </div>
)

const Left = ({
    paymentIssuerById: {
        name,
        stepByStepId,
    },
}) => (
    <div className='vrtl-signing-left__section'>
        <div className='row--justify-between'>
            <div className='vrtl-signing__header'>Issuer</div>
            <div className='vrtl-signing-template' />
        </div>
        <Card.section>
            <Card.label>Issuer name</Card.label>
            {name}
        </Card.section>
        {stepByStepId.status !== 'complete' && (
            <Card.section>
                <Card.label>Mapping process</Card.label>
                <Process.process proc={stepByStepId}>
                    <Div className='vrtl-process-short-steps'>
                        <Process.shortSteps>
                            <Process.shortStep />
                        </Process.shortSteps>
                    </Div>
                    <Div className='vrtl-step-card__footer'>
                        <Popup.popup>
                            <Popup.inner>
                                <Popup.close />
                                <Process.currentStep />
                                <div />
                            </Popup.inner>
                            <Popup.trigger>Next step</Popup.trigger>
                        </Popup.popup>
                    </Div>
                </Process.process>
            </Card.section>
        )}
    </div>
)

Left.delvConfig = {
    query: (id) => `{
      paymentIssuerById(id:"${id}"){
        id
        name
        ${Process.fragment}
      }
  }`,
    args: (props) => props.match.params.issuer_id,
    networkPolicy: 'network-once',
}

export default {
    right: Delv.queryHOC(Right, Left.delvConfig),
    left: Delv.queryHOC(Left, Left.delvConfig),
}

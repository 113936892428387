import React from 'react'
import Mutation from 'delv/Mutation'
import './styles.scss'

const DEPLOY_CONTRACT = `mutation ($contractId:UUID!) {
  deployContract(input:{_contractId: $contractId}) {
    contract{
      id
      status
      tokenByTokenId{
        id
        status
      }
    }
  }
}`

export default ({ contractId, popup }) => {
    const deploy = () => {
        new Mutation({
            mutation: DEPLOY_CONTRACT,
            onSubmit: () => ({ contractId }),
            onResolve: popup.close,
        }).submit()
    }
    return (
        <div className='deploy-token'>
            <h2 className='header'>Deploy Token?</h2>
            <div className='deploy-token-button-container'>
                <div className='styled-button' onClick={deploy}>
                    Yes
                </div>
                <div className='styled-button' onClick={popup.close}>
                    No
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import Dialog from 'common/Dialog/index.js'
import DialogContent from 'common/DialogContent/index.js'
import DialogContentText from 'common/DialogContentText/index.js'
import Link from 'common/Link/index.js'
import DialogTitle from 'common/DialogTitle/index.js'
import Button from 'common/Button/index.js'

import Upload from 'common/Upload.js'
import Alert from '@mui/material/Alert'
import Snackbar from 'common/Snackbar/index.js'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Box from 'common/Box/index.js'
import Div from 'common/Div.js'

const Trigger = ({ onClick, icon }) => (icon
    ? <AddCircleIcon onClick={onClick} color='success' sx={{ cursor: 'pointer' }} />
    : <div onClick={onClick} className='card-form-trigger'>+ Import</div>)

const ModalUpload = ({
    instructions,
    extensions = '*',
    exampleFileHref,
    onUploadFinish,
    heading,
    urlType,
    onClick,
    icon,
    modalSize = 'sm',
    multiSelect,
}) => {
    const [isOpen, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [notificationIsOpen, setNotificationIsOpen] = useState(false)
    const [error, setError] = useState(undefined)

    const stateHandler = {
        setError: (message) => setError(message),
        clearError: () => setError(undefined),
        setSuccess: () => {
            setOpen(false)
            setNotificationIsOpen(true)
        },
        setLoading: () => setLoading(true),
        clearLoading: () => setLoading(false),
        closeModal: () => {
            // Only modals that upload documents use MultiSelect
            if (multiSelect) {
                multiSelect.clear()
            }
            setOpen(false)
            setError(undefined)
        },
        closeNotification: () => setNotificationIsOpen(false),
        open: () => setOpen(true),
    }

    return (
        <>
            <Trigger onClick={stateHandler.open} icon={icon} />
            <Dialog open={isOpen} fullWidth maxWidth={modalSize}>
                <DialogTitle>{heading}</DialogTitle>
                <DialogContent>
                    {error && <Alert sx={{ mb: '2' }} severity='error'>{error}</Alert>}
                    <DialogContentText>{instructions}</DialogContentText>
                    {
                        Boolean(exampleFileHref) && (
                            <Link href={exampleFileHref} download>
                                Example File Download
                            </Link>
                        )
                    }
                </DialogContent>
                <Box sx={{ pb: 3, pl: 2.5, pr: 2.5 }}>
                    <Upload.upload>
                        <Upload.dropzone extensions={extensions} />
                        <Upload.files>
                            <Upload.file loading={loading} />
                        </Upload.files>
                        <Upload.button
                            loading={loading}
                            setLoading={setLoading}
                            urlType={urlType}
                            handler={stateHandler}
                            onUploadFinish={onUploadFinish}
                            onClick={onClick}
                        >
                            <Div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                                <Div cleanProps={({ onClick: notOnClick, ...otherProps }) => otherProps}>
                                    <Button onClick={stateHandler.closeModal} variant='outlined' disabled={loading}>Cancel</Button>
                                </Div>
                                <Button onClick={onClick} variant='contained' disabled={loading}>Upload</Button>
                            </Div>
                        </Upload.button>
                    </Upload.upload>
                </Box>
            </Dialog>
            <Snackbar
                open={notificationIsOpen}
                autoHideDuration={6000}
                onClose={stateHandler.closeNotification}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <div><Alert variant='filled' severity='success'>File Successfully Uploaded!</Alert></div>
            </Snackbar>
        </>
    )
}

export default ModalUpload

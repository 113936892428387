import * as React from 'react'

import SubNav from 'common/SubNav/index.js'
import PageLayout from 'layouts/page-layout'

const SubNavLayout = ({ breadcrumbs, children, tabs }) => (
    <PageLayout breadcrumbs={breadcrumbs}>
        {Boolean(tabs) && <SubNav tabs={tabs} />}
        {children}
    </PageLayout>
)

export default SubNavLayout

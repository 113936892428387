const MAKE_DISTRIBUTION = `mutation($offeringId: UUID!, $units: BigFloat!) {
    investorMakeDistribution(input: { offeringId: $offeringId, amount: $units }) {
        distribution{
            id
            amount
        }
    }
}`

export {
    MAKE_DISTRIBUTION,
}

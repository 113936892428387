import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default ({ children, ...props }) => {
    const prefix = 'vrtl-sent-documents'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                field name
            </OrderBy.filter>
            <OrderBy.dropdown field='type' className={`${prefix}__section`}>
                type
            </OrderBy.dropdown>
            <OrderBy.filter field='description' className={`${prefix}__section`}>
                description
            </OrderBy.filter>
            <OrderBy.filter field='errorMessage' className={`${prefix}__section`}>
                error
            </OrderBy.filter>
            <div className={`${prefix}__icon-offset`} />

            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </OrderBy.bar>
    )
}

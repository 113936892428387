import * as React from 'react'

import Mutation from '../Mutation.js'

const useMutation = (mutation) => {
    const [data, setData] = React.useState(undefined)
    const [error, setError] = React.useState(undefined)
    const [loading, setLoading] = React.useState(undefined)

    const mutationCallback = ({
        variables,
        onResolve,
    }) => {
        setLoading(true)
        setError(undefined)
        setData(undefined)

        new Mutation({
            mutation,
            onResolve: (res) => {
                setData(res)
                setLoading(false)
                if (onResolve) {
                    onResolve(res)
                }
            },
            onError: (err) => {
                setError(err)
                setLoading(false)
            },
            onSubmit: () => variables,
        }).submit()
    }

    const result = {
        data,
        error,
        loading,
    }

    return [mutationCallback, result]
}

export default useMutation

import { selectIdsFromCollection } from '../selectors/node-id-selectors.js'

const nodesResolver = ({
    cache, context, filterCacheByIds, filterCache,
}) => {
    const fieldType = context.parent.__typename

    if (!cache[fieldType]) {
        return []
    }

    const nodeIds = context.parent.collection
        ? selectIdsFromCollection({
            args: context.parent.args,
            collection: context.parent.collection,
            context,
            filterCache,
        })
        : []

    let nodes = filterCacheByIds(fieldType, nodeIds)

    if (context.parent.args) {
        const makeObject = ((acc, item) => {
            acc[item.id] = item
            return acc
        })
        nodes = nodes.reduce(makeObject, {})
        const result = filterCache(nodes, context.parent.args, context)
        nodes = result.data && result.sort
            ? result.sort(Object.values(result.data))
            : Object.values(result)
    }

    return nodes
}

export { nodesResolver }

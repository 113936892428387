import React from 'react'
import CreateIcon from '@material-ui/icons/Create'
import AddCircle from '@material-ui/icons/AddCircle'

import Div from 'common/Div.js'
import EditSwitch from 'common/EditSwitch.js'
import NewEditSwitch from 'common/NewEditSwitch.js'
import Popup from 'common/Popup.js'
import Qualification from 'qualification/index.js'
import AllocationQualificationsForm from 'qualification/AllocationQualificationsForm.js'
import AnnotationForm from 'annotation/AnnotationForm.js'
import AnnotationCard from 'annotation/AnnotationCard.js'
import VestingCard from 'vesting/VestingCard.js'
import VestingForm from 'vesting/VestingForm.js'
import EditVestingForm from 'vesting/EditVestingForm.js'
import EditAllocationForm from 'allocations/EditAllocationForm.js'
import Delv from 'delv/delv-react'
import Map from 'common/Map.js'
import NoneFound from 'common/NoneFound/index.js'

import AllocationCard from './AllocationCard.js'
import { EditAllocation } from './queries.js'
import './AllocationEditor.scss'

const VestingCardWrapper = (props) => {
    const { vesting, canEdit } = props

    const defaultVestingSchedulePopup = (
        <Popup.popup>
            <Popup.inner>
                <VestingForm type='allocation' allocationId={props.allocationId} />
            </Popup.inner>

            <Popup.trigger className='vrtl-allocation-editor__heading-action'>
                <AddCircle className='vrtl-allocation-editor__add' />
            </Popup.trigger>
        </Popup.popup>
    )

    const vestingCardHeader = (
        <Div className='vrtl-allocation-editor__heading'>
            <div className='vrtl-allocation-editor__heading-text'>Allocation Vesting Schedule</div>
            {!vesting && canEdit && defaultVestingSchedulePopup}
        </Div>
    )

    const vestingCard = (
        <VestingCard>
            <Popup.popup>
                <Popup.inner>
                    <EditVestingForm type='allocation' vesting={vesting} />
                </Popup.inner>

                <Popup.trigger className='clickable'>
                    <CreateIcon className='action-icon' />
                </Popup.trigger>
            </Popup.popup>
        </VestingCard>
    )

    const noVesting = (
        <div className='row'>
            <div className='none-found margin'>No Default Vesting Schedule</div>
        </div>
    )

    return (
        <Div className='paddingbox flex-1' {...props}>
            {vestingCardHeader}
            {vesting ? vestingCard : noVesting}
        </Div>
    )
}

const AllocationEditor = (props) => {
    const {
        allocationId, accountId, allocation,
    } = props
    const canEdit = props.canEdit && ['drafted', 'open', 'active', 'closed'].includes(allocation.status)
    const annotationPopup = (
        <Popup.popup>
            <Popup.inner>
                <AnnotationForm type='allocation' allocationId={allocationId} />
            </Popup.inner>
            <Popup.trigger className='vrtl-allocation-editor__heading-action'>
                <AddCircle className='vrtl-allocation-editor__add' />
            </Popup.trigger>
        </Popup.popup>
    )

    const conditionalAnnotationPopup = canEdit ? annotationPopup : <></>

    return (
        <div className='distribute-allocation-form-container'>
            <div className='paddingbox'>
                <EditSwitch allocation={allocation} allocationId={allocation.id} canEdit={canEdit}>
                    <AllocationCard />
                    <EditAllocationForm />
                </EditSwitch>
            </div>
            <div className='paddingbox flex-1'>
                <div className='vrtl-allocation-editor__heading'>
                    <div className='vrtl-allocation-editor__heading-text'>Allocation Qualifications</div>
                </div>

                <Qualification.rowHeading>
                    {canEdit && (
                        <Popup.popup>
                            <Popup.inner>
                                <AllocationQualificationsForm accountId={accountId} allocationId={allocationId} />
                            </Popup.inner>
                        </Popup.popup>
                    )}
                </Qualification.rowHeading>

                <Map
                    qualifications={props.qualifications}
                    arrayKey='qualifications.nodes'
                    noneFound={<NoneFound>No qualifications found.</NoneFound>}
                >
                    <NewEditSwitch.switch canEdit={canEdit}>
                        <Qualification.row />
                        <Qualification.form />
                    </NewEditSwitch.switch>
                </Map>
            </div>

            <VestingCardWrapper canEdit={canEdit} vesting={props.vesting} allocationId={props.allocationId} />

            <div className='paddingbox flex-1'>
                <div className='vrtl-allocation-editor__heading'>
                    <div className='vrtl-allocation-editor__heading-text'>Allocation Notes</div>
                    {conditionalAnnotationPopup}
                </div>
                <Map annotations={props.annotations} arrayKey='annotations.nodes' noneFound={<NoneFound>No notes found.</NoneFound>}>
                    <AnnotationCard />
                </Map>
            </div>
        </div>
    )
}

export default Delv.queryHOC(AllocationEditor, EditAllocation)

import React, { useEffect } from 'react'
import Box from 'common/Box'
import useFormView from 'hooks/UseFormView'
import { isSm } from 'constants/media-queries'

const AccountForm = ({ fields, stateHandler, emitter }) => {
    const {
        validateInput,
        formView: {
            accountName,
            formationDate,
            taxId,
        },
    } = useFormView(fields)

    useEffect(() => {
        const submitAccountInput = () => {
            const { valid, input } = validateInput()
            if (valid) {
                stateHandler.account(input)
            } else {
                stateHandler.error(true)
            }
        }

        emitter.on('submitInput', submitAccountInput)
        return () => emitter.removeListener('submitInput', submitAccountInput)
    })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {accountName}
            <Box sx={{ display: 'flex', gap: 4, [isSm]: { flexDirection: 'column' } }}>
                {taxId}
                {formationDate}
            </Box>
        </Box>
    )
}

export default AccountForm

export default (cb, timeout) => {
    const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, timeout)
    })
    return (data) => {
        timeoutPromise.then(() => {
            cb(data)
        })
    }
}

import React from 'react'

export default (props) => (
    <div className='selectable-card'>
        <div className='card-container'>
            <div className='card'>
                <div className='card-section'>
                    <div className='card-label'>NAME</div>
                    <div>{props.name}</div>
                </div>
                <div className='card-section' />
                <div className='card-section' />
            </div>
        </div>
    </div>
)

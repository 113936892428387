import React from 'react'
import Card from 'common/Card.js'
import Format from 'util/format/Format.js'

export default ({
    managedUnits,
    name,
    status,
}) => (
    <Card.card>
        <Card.section>
            <Card.label>DISTRIBUTOR</Card.label>
            {name}
        </Card.section>
        <Card.section>
            <Card.label>UNITS UNDER MANAGEMENT</Card.label>
            {Format.number(managedUnits)}
        </Card.section>
        <Card.section>
            <Card.label>STATUS</Card.label>
            {status}
        </Card.section>
    </Card.card>
)

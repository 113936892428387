import React, { useEffect, useMemo } from 'react'
import Box from 'common/Box'
import useFormView from 'hooks/UseFormView'
import ViewToggle from 'common/ViewToggle'
import InputCheckbox from 'common/InputCheckbox'
import Dropdown from 'common/Dropdown'
import countryFragment from 'components/country/fragment.js'
import { isMd } from 'constants/media-queries'
import useQuery from 'delv/hooks/use-query.js'
import states from './states.js'
import MailingAddress from './MailingAddress.js'

const COUNTRIES = `{
    allCountries{
      nodes{
        ${countryFragment}
      }
    }
  }`

const Address = ({
    addressFields,
    mailingFields,
    stateHandler,
    emitter,
    field,
    index,
    id,
}) => {
    // Hack to change helper text. Remove on Refactor
    if (field === 'parties') {
        addressFields.fields.lineOne.label = 'Party Address Line 1'
        addressFields.fields.lineTwo.label = 'Party Address Line 2'
    }

    const { data } = useQuery(COUNTRIES)
    const USA = useMemo(() => data?.allCountries.nodes.find((e) => e.name === 'United States of America (the)'), [data])

    const {
        validateInput,
        formView: {
            lineOne,
            lineTwo,
            city,
            province,
            postalCode,
            country,
        },
        input,
        config,
    } = useFormView(addressFields)

    useEffect(() => {
        const submitAddressInput = () => {
            const { valid, input: addressInput } = validateInput()
            if (valid) {
                stateHandler.address(field, index, { ...addressInput, type: 'primary' })
            } else {
                stateHandler.error()
            }
        }

        emitter.on('submitInput', submitAddressInput, { id })
        return () => emitter.removeListener('submitInput', submitAddressInput, { id })
    })
    return (
        <>
            <Box sx={{
                gap: 4, display: 'flex', flexDirection: 'column', width: '100%',
            }}
            >
                <Box sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
                    {lineOne}
                    {lineTwo}
                    {city}
                </Box>
                <Box sx={{ gap: 4, display: 'flex', [isMd]: { flexDirection: 'column' } }}>
                    {country}
                    {
                        input.country.value.value === USA?.id ? (
                            <Dropdown name='province' label='State' options={states} fullWidth required onChange={config.province.onChange} />
                        ) : (
                            province
                        )
                    }
                    {postalCode}
                </Box>
            </Box>
            <ViewToggle defaultValue invertDisplay label='Mailing address same as primary' ToggleIcon={InputCheckbox}>
                <MailingAddress USA={USA} id={id + 1} fields={mailingFields} field={field} index={index} emitter={emitter} stateHandler={stateHandler} />
            </ViewToggle>
        </>
    )
}

export default Address

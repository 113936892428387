import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import { Input, Date } from 'common/FormUI.js'

// TODO:
// this query needs to be cleaned up a lot

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is a required field.',
        },
        opensOn: {
            type: 'date',
            default: (props) => props.assetClass.opensOn,
            error: 'Opens On is a required field.',
        },
        closesOn: {
            type: 'date',
            default: (props) => props.assetClass.closesOn,
            error: 'Closes On is a required field.',
        },
    },
}

const AllocationForm = Form(
    ({
        assetClass, popup, form: {
            name, opensOn, closesOn, validateInput,
        },
    }) => {
        const CREATE_ALLOCATION = `mutation ($allocation: AllocationInput!) {
          createAllocation(input: {allocation:$allocation}) {
            allocation {
              id
              name
              closesOn
              roundByRoundId {
                id
                name
                holdings
                managedUnits
                termsUrl
                opensOn
                closesOn
                price
                total
                status
                allocationsByRoundId {
                    nodes {
                        id
                        name
                        status
                        opensOn
                        closesOn
                        managedUnits
                        accountByOwnerId {
                            id
                            name
                        }
                        distributionsByAllocationId{
                          nodes{
                            id
                            amount
                            status
                            accountByInvestorId{
                              id
                              contactName
                              email
                            }
                          }
                        }
                    }
                }
                assetByAssetId{
                  id
                  name
                }
                brokerAllocationsByRoundId {
                  nodes {
                    id
                    managedUnits
                    name
                    status
                  }
                }
              }
            }
          }
        }`

        const handleSubmit = () => {
            const input = validateInput()

            if (!input.hasErrors) {
                const {
                    opensOn: assetOpensOn,
                    closesOn: assetClosesOn,
                } = assetClass
                const returnVal = {
                    allocation: {
                        name: input.name,
                        opensOn: assetOpensOn,
                        closesOn: assetClosesOn,
                        roundId: assetClass.id,
                    },
                }
                new Mutation({
                    mutation: CREATE_ALLOCATION,
                    onSubmit: () => returnVal,
                    onResolve: () => popup.close(),
                }).submit()
            }
        }

        return (
            <form className='vrtl-round-allocation__add-form'>
                <h2 className='qualifications-form-title'>Create Allocation</h2>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>NAME</div>
                        <div className='asset-form-input-container'>
                            <Input {...name} />
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>OPENS ON</div>
                        <div className='asset-form-input-container'>
                            <Date {...opensOn} />
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>CLOSES ON</div>
                        <div className='asset-form-input-container'>
                            <Date {...closesOn} />
                        </div>
                    </div>
                </div>

                <div className='card row--justify-between'>
                    <div className='styled-button' onClick={popup.close}>
                        Cancel
                    </div>
                    <div className='styled-button' onClick={handleSubmit}>
                        Submit
                    </div>
                </div>
            </form>
        )
    },
    formConfig,
)

export default AllocationForm

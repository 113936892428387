const doNothing = (input) => input

const email = (input) => input.trim()

const number = (input) => input.replace(/[^0-9.,]/g, '')

const integer = (input) => input.replace(/[^0-9,]/g, '')

export default {
    email,
    link: doNothing,
    string: doNothing,
    date: doNothing,
    number,
    boolean: doNothing,
    dropdown: doNothing,
    multiSelect: doNothing,
    blockchainAddress: doNothing,
    color: doNothing,
    integer,
    twitterHandle: doNothing,
    password: doNothing,
    phone: doNothing,
    dropdownTypeahead: doNothing,
    checkbox: doNothing,
    textArea: doNothing,
}

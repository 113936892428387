import React from 'react'
import Form from 'common/form/Form.js'
import Card from 'common/Card.js'
import { Dropdown, Button } from 'common/FormUI.js'
import Mutation from 'delv/Mutation.js'

const STATUS = [
    {
        status: 'Drafted',
        value: 'drafted',
    },
    {
        status: 'Open',
        value: 'open',
    },
    {
        status: 'Closed',
        value: 'closed',
    },
]

const formConfig = {
    fields: {
        status: {
            type: 'dropdown',
            error: 'Please select a status.',
        },
    },
}

const createFragement = (status, distributionId) => `
  m${distributionId.replace(/\-/g, '_')}:updateDistributionById(input:{id: "${distributionId}", distributionPatch:{status:"${status}"}}){
      distribution{
        id
        status
        amount
      }
  }
`
const BulkStatusUpdate = ({
    form: { status, validateInput, error },
    multiSelect,
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const distributions = multiSelect.getValues()
        const input = validateInput()

        // @Form-Validator This error handling will be replaced with Form validator update. Currently no support for custom errors.
        const errors = []

        if (!input.hasErrors) {
            // eslint-disable-next-line
            const _status = input.status
            let mutation = ''
            distributions.forEach(
                ({ distributionId, status: distributionStatus, contactName }) => {
                    if (distributionStatus === 'locked') {
                        errors.push(`Cannot update status on locked distribution: ${contactName}`, <br />)
                    } else if (distributionStatus !== _status) {
                        mutation += createFragement(
                            _status,
                            distributionId,
                        )
                    }
                },
            )

            if (errors.length) {
                error.set(errors)
            }
            if (mutation) {
                mutation = `mutation {
                    ${mutation}
                }`
                new Mutation({
                    mutation,
                    onSubmit: () => ({}),
                    onResolve: () => {
                        multiSelect.select([])
                    },
                }).submit()
            }
        }
    }
    return (
        <Card.container>
            <Card.card>
                <Card.section>
                    {error.error && <div className='error'>{error.error}</div>}
                    <Card.label>Status</Card.label>
                    <Dropdown options={STATUS} width='7rem' {...status} />
                </Card.section>
                <Card.section>
                    <Button onClick={handleSubmit}>Submit</Button>
                </Card.section>
            </Card.card>
        </Card.container>
    )
}

export default Form(BulkStatusUpdate, formConfig)

import React from 'react'
import Done from '@material-ui/icons/CheckCircle'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'

import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Dropdown } from 'common/FormUI.js'

import Row from './Row.js'

const UPDATE_DISTRIBUTION_QUALIFICATION = `mutation ($id: UUID!, $executeOrder: Int!) {
  updateDistributionQualificationOrder(input: {_order: $executeOrder, distributionQualificationId: $id}) {
    distribution {
      id
      distributionQualificationsByDistributionId {
        nodes {
          id
          executeOrder
        }
      }
    }
  }
}`

const UPDATE_ALLOCATION_QUALIFICATION = `mutation ($id: UUID!, $executeOrder:Int!) {
  updateAllocationQualificationById(input: {id: $id, allocationQualificationPatch:{executeOrder:$executeOrder}}) {
    allocationByAllocationId{
      id
      allocationQualificationsByAllocationId{
        nodes{
          id
          executeOrder
        }
      }
    }
  }
}`

const DELETE_DISTRIBUTION_QUALIFICATION = `mutation ($id: UUID!) {
  deleteDistributionQualificationById(input: {id: $id}) {
    distributionQualification {
      id
    }
  }
}`

const DELETE_ALLOCATION_QUALIFICATION = `mutation ($id: UUID!) {
  deleteAllocationQualificationById(input:{id:$id}) {
    allocationQualification {
      id
    }
  }
}`

const QualificationOrderForm = ({
    id,
    distribution,
    resolve,
    form: {
        executeOrder, setLoading, validateInput, loading, getChanged,
    },
    key,
    ...props
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const changedInput = getChanged(input)
            if (changedInput) {
                new Mutation({
                    mutation: distribution ? UPDATE_DISTRIBUTION_QUALIFICATION : UPDATE_ALLOCATION_QUALIFICATION,
                    onSubmit: () => {
                        setLoading('create')
                        return {
                            id,
                            executeOrder: changedInput.executeOrder,
                        }
                    },
                    onResolve: resolve,
                }).submit()
            } else {
                resolve()
            }
        } else {
            resolve()
        }
    }

    const deleteRow = (event) => {
        event.preventDefault()
        new Mutation({
            mutation: distribution ? DELETE_DISTRIBUTION_QUALIFICATION : DELETE_ALLOCATION_QUALIFICATION,
            onSubmit: () => {
                setLoading('delete')
                return {
                    id,
                }
            },
            onResolve: resolve,
            cacheProcess: 'delete',
        }).submit()
    }

    if (loading === 'create') {
        return <Row {...props} executeOrder={executeOrder.value} />
    }
    if (loading === 'delete') {
        return <div />
    }

    const options = props.qualifications.nodes.map((node, index) => ({
        value: index + 1,
        executeOrder: index + 1,
    }))

    const prefix = 'vrtl-custom-mapping-fields'
    const rowProps = {
        ...props,
        executeOrder: <Dropdown {...executeOrder} options={options} />,
        remove: id && <Delete className={`${prefix}__icon-delete`} onClick={deleteRow} />,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        key: `form-${id}`,
    }

    return <Row {...rowProps} />
}
const formConfig = {
    fields: {
        executeOrder: {
            type: 'dropdown',
        },
    },
}

export default Form(QualificationOrderForm, formConfig)

import * as React from 'react'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'

import {
    CurrencyCell,
    IconCell,
    NumberCell,
    PercentCell,
    ToggleCell,
    TooltipCell,
} from 'common/DataGridCell'
import SupportEmailIcon from 'common/SupportEmailIcon'
import Link from 'common/Link'
import Typography from 'common/Typography'

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
}

const rightAlignColumnDefaults = {
    ...columnDefaults,
    align: 'right',
}

const numberColumnDefaults = {
    ...rightAlignColumnDefaults,
    renderCell: NumberCell,
}

const currencyColumnDefaults = {
    ...rightAlignColumnDefaults,
    renderCell: CurrencyCell,
}

const percentColumnDefaults = {
    ...rightAlignColumnDefaults,
    renderCell: PercentCell,
}

const columns = [
    {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        hideable: false,
        headerName: 'Toggle',
        renderHeader: () => <div />,
        renderCell: ToggleCell,
        disableColumnMenu: true,
    },
    {
        ...columnDefaults,
        field: 'asset',
        headerName: 'Asset',
        renderCell: ({ value }) => (
            <Typography color='primary' variant='subtitle2'>
                {value}
            </Typography>
        ),
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'issuer',
        headerName: 'Issuer',
        renderCell: ({ value, row }) => {
            if (row.issuerSupportEmail) {
                return (
                    <IconCell Icon={(props) => <SupportEmailIcon email={row.issuerSupportEmail} {...props} />}>
                        {value}
                    </IconCell>
                )
            }

            return value
        },
        minWidth: 175,
    },
    {
        ...columnDefaults,
        field: 'round',
        headerName: 'Rounds',
        renderCell: ({ value, row }) => {
            if (Number.isNaN(value)) {
                return (
                    <Link href={row.termsUrl} rel='noopener noreferrer' target='_blank' color='primary'>
                        {value}
                    </Link>
                )
            }

            return value
        },
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'tokenized',
        headerName: 'Tokenized',
    },
    {
        ...columnDefaults,
        field: 'status',
        headerName: 'Status',
        renderCell: ({ value, row }) => {
            if (row.__typename === 'Holding') {
                return (
                    <Link to={`/holding/${row.id}`} sx={{ textTransform: 'capitalize' }}>
                        {value}
                    </Link>
                )
            }

            if (row.__typename === 'Distribution') {
                return (
                    <Link to={`/distribution/${row.id}`} sx={{ textTransform: 'capitalize' }}>
                        {value}
                    </Link>
                )
            }

            return null
        },
    },
    {
        ...columnDefaults,
        field: 'progress',
        headerName: 'Progress',
    },
    {
        ...columnDefaults,
        field: 'nextStep',
        headerName: 'Next Step',
    },
    {
        ...columnDefaults,
        field: 'type',
        headerName: 'Type',
        cellClassName: 'capitalize',
    },
    {
        ...columnDefaults,
        field: 'category',
        headerName: 'Category',
        cellClassName: 'capitalize',
    },
    {
        ...numberColumnDefaults,
        field: 'units',
        headerName: 'Units',
    },
    {
        ...currencyColumnDefaults,
        field: 'value',
        headerName: 'Value',
        renderHeader: (props) => <TooltipCell tooltip='Latest value of investment' {...props} />,
    },
    {
        ...currencyColumnDefaults,
        field: 'investment',
        headerName: 'Investment',
        renderHeader: (props) => <TooltipCell tooltip='Initial amount invested' {...props} />,
    },
    {
        ...currencyColumnDefaults,
        field: 'purchasePrice',
        headerName: 'Cost Basis',
    },
    {
        ...currencyColumnDefaults,
        field: 'latestPrice',
        headerName: 'Latest Price',
    },
    {
        ...currencyColumnDefaults,
        field: 'committedCapital',
        headerName: 'Committed Capital',
    },
    {
        ...currencyColumnDefaults,
        field: 'paidInCapital',
        headerName: 'Paid in Capital',
    },
    {
        ...percentColumnDefaults,
        field: 'percentPaid',
        headerName: 'Percent Paid',
    },
    {
        ...currencyColumnDefaults,
        field: 'deployedCapital',
        headerName: 'Deployed Capital',
    },
    {
        ...currencyColumnDefaults,
        field: 'undeployedCapital',
        headerName: 'Undeployed Capital',
    },
    {
        ...currencyColumnDefaults,
        field: 'paidOutDividends',
        headerName: 'Paid Out Dividends',
    },
    {
        ...percentColumnDefaults,
        field: 'coupon',
        headerName: 'Yield',
    },
    {
        ...numberColumnDefaults,
        field: 'term',
        headerName: 'Term',
        renderHeader: (props) => <TooltipCell tooltip='Years to maturity' {...props} />,
    },
]

const columnVisibilityModel = {
    __detail_panel_toggle__: true,
    asset: true,
    issuer: true,
    round: true,
    tokenized: true,
    status: true,
    progress: false,
    stage: false,
    type: false,
    category: false,
    units: true,
    value: true,
    investment: true,
    purchasePrice: true,
    latestPrice: false,
    committedCapital: false,
    paidInCapital: false,
    percentPaid: false,
    deployedCapital: false,
    undeployedCapital: false,
    paidOutDividends: false,
    coupon: false,
    term: false,
}

export {
    columns,
    columnVisibilityModel,
}

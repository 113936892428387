import React from 'react'
import Mutation from 'delv/Mutation'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Document from 'components/document/index.js'
import Edit from '@material-ui/icons/Edit'
import Holding from 'components/holding/index.js'
import HoldingDocument from 'components/holdingDocument/index.js'
import SecurityDocument from 'components/securityDocument/index.js'
import Security from 'components/security/index.js'
import DelvWebSocket from 'delv/websocket.js'
import stringTranformers from 'util/stringTranformers.js'
import MultiSelect from 'common/MultiSelect.js'
import Row from './Row.js'
import Upload from './Upload.js'

const DocumentForm = ({
    prefix = 'vrtl-document-row',
    documentByDocumentId,
    id,
    docType,
    canEdit,
    edit,
    resolve: setEdit,
    remove,
    ...props
}) => {
    const makeNames = () => {
        const docTypeFragments = {
            holding: {
                node: Holding.fragment.toString(),
                document: HoldingDocument.fragment.toString(),
            },
            security: {
                node: Security.fragment.toString(),
                document: SecurityDocument.fragment.toString(),
            },
        }

        const upperDocType = stringTranformers.capitalize(docType)
        const nodeMutationName = `make${upperDocType}Document`
        const nodeDocByNodeDocId = `${docType}DocumentBy${upperDocType}DocumentId`
        return {
            upperDocType, nodeMutationName, nodeDocByNodeDocId, docTypeFragments,
        }
    }

    const makeUpload = () => {
        const {
            upperDocType, nodeMutationName, nodeDocByNodeDocId, docTypeFragments,
        } = makeNames(docType)

        const UPLOAD_DOCUMENT = `mutation($name: String!, $id: UUID!, $extension: String!, $shareWith: [String]!) {
            make${upperDocType}Document(input: {name: $name, ${docType}Id: $id, extension: $extension, shareWith: $shareWith}) {
                id:documentId
                uploadInfo
                ${docType}DocumentBy${upperDocType}DocumentId{
                    ${docTypeFragments[docType].document}
                    ${docType}By${upperDocType}Id{
                        ${docTypeFragments[docType].node}
                    }
                    documentByDocumentId{
                        ${Document.fragment.toString()}
                        documentSharingsByDocumentId {
                            nodes{
                                ${Document.sharing.fragment.toString()}
                            }
                        }
                    }
                }
            }
        }`

        const handleSubmit = (file, multiSelect) => {
            const body = file.name.split(/\./)
            const extension = body.pop()
            const name = body.join('.')
            const shareWithOptions = multiSelect.getValues()

            return new Promise((resolve, reject) => {
                new Mutation({
                    mutation: UPLOAD_DOCUMENT,
                    onSubmit: () => ({
                        name, extension, id, shareWith: shareWithOptions,
                    }),
                    onResolve: (res) => {
                        // Multiselect does not unmount after submitting the form, so clear its state for next upload
                        multiSelect.clear()
                        DelvWebSocket.subscribe({ type: 'documents', ids: [res[nodeMutationName][nodeDocByNodeDocId].documentId] })
                        resolve(res[nodeMutationName].uploadInfo)
                    },
                    cacheProcess: (_, data, emitter, cache) => {
                        const nodeData = data[nodeMutationName][nodeDocByNodeDocId]

                        cache.cacheByType({ nodeDocByNodeDocId: nodeData })
                    },
                    onError: (err) => reject(err),
                }).submit()
            })
        }

        return {
            ...props, docType, handleSubmit,
        }
    }

    const makeDelete = () => {
        const { upperDocType, docTypeFragments } = makeNames(docType)

        const DELETE_DOCUMENT = `mutation ($id: UUID!) {
            delete${upperDocType}Document(input: {${docType}DocumentId: $id}) {
                ${docType}Document {
                    ${docTypeFragments[docType].document}
                    ${docType}By${upperDocType}Id {
                    ${docTypeFragments[docType].node}
                    }
                }
            }
        }`
        const handleSubmit = () => {
            new Mutation({
                mutation: DELETE_DOCUMENT,
                onSubmit: () => ({ id }),
                onResolve: setEdit,
                cacheProcess: 'delete',
            }).submit()
        }
        const newDisplayProps = {
            documentByDocumentId,
            edit: edit ? (
                <Edit className={`${prefix}__icon-edit`} onClick={edit} />
            ) : <Cancel className={`${prefix}__icon-cancel no-margin`} onClick={setEdit} />,
            remove: edit ? null : <Delete Delete className={`${prefix}__icon-delete no-margin`} onClick={handleSubmit} />,
            handleSubmit,
            ...props,
        }

        return newDisplayProps
    }

    return documentByDocumentId?.id ? (
        <Row {...makeDelete()} />
    ) : (
        <MultiSelect><Upload {...makeUpload()} /></MultiSelect>
    )
}

export default DocumentForm

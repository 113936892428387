import Row from './Row.js'
import RowHeading from './RowHeading.js'
import Form from './Form.js'

import './styles.scss'

export default {
    row: Row,
    rowHeading: RowHeading,
    form: Form,
}

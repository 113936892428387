import React from 'react'
import './Grid.css'
import cx from 'classnames'

const chunk = (list, chunkSize, className = 'grid-item flex-1') => { // className is kinda hacky
    if (!list.length) {
        return []
    }

    const theChunkSize = !chunkSize ? 4 : chunkSize

    while (list.length % theChunkSize !== 0) {
        // eslint-disable-next-line
        list = [...list, '']
    }

    let i
    let j
    let t
    const chunks = []
    for (i = 0, j = list.length; i < j; i += theChunkSize) {
        /* eslint-disable react/no-array-index-key */
        t = list.slice(i, i + theChunkSize).map((item, index) => (
            <div className={className} key={index}>
                {item}
            </div>
        ))
        /* eslint-enable react/no-array-index-key */
        chunks.push(t)
    }
    return chunks
}

const Grid = (props) => (
    <>
        {/* eslint-disable react/no-array-index-key */}
        {chunk(props.children, props.itemsPer, props.itemClassName).map((row, index) => (
            <div
                className={cx({ 'grid-column': props.column, 'grid-row': !props.column })}
                key={index}
            >
                {row}
            </div>
        ))}
        {/* eslint-enable react/no-array-index-key */}
    </>
)

export default Grid

import React from 'react'

import { Link } from 'react-router-dom'
import Format from 'util/format/Format.js'
import Edit from '@material-ui/icons/Edit'
import IconHelper from 'common/IconHelper.js'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { isIssuer, isBroker } from 'util/permissions.js'

export default ({
    id,
    name,
    opensOn,
    closesOn,
    termsUrl,
    price,
    status,
    total,
    edit,
    cancel,
    submit,
    term,
    coupon,
    entityPage,
    prefix = 'vrtl-round-card',
    type,
    unitValue,
    atsName,
    startSlot,
    endSlot,
    offset,
    ...props
}) => {
    const isTranche = props.type?.toLowerCase() === 'debt' || (term && coupon)
    const roundLink = <Link className='row' to={`/asset-class/${id}/manage`}><OpenInNew className={`${prefix}__icon-open-in-new`} /></Link>
    const securityLink = <Link to={`/security/${props.securityBySecurityId?.id}`}><OpenInNew className={`${prefix}__icon-open-in-new`} /></Link>
    const canLinkSecurity = entityPage && !isBroker(props)
    const canLinkRound = !entityPage
    return (
        <div className={`${prefix}__container--row`}>
            {startSlot}
            <div className={`${prefix}__section--3`}>
                {offset}
                {name}
            </div>
            <div className={`${prefix}__section--2`}>{typeof opensOn === 'string' ? Format.date(opensOn, 'MMM DD YYYY') : opensOn}</div>
            <div className={`${prefix}__section--2`}>{typeof closesOn === 'string' ? Format.date(closesOn, 'MMM DD YYYY') : closesOn}</div>
            <div className={`${prefix}__section--2`}>{typeof total === 'string' ? Format.number(total) : total}</div>
            <div className={`${prefix}__section ${prefix}--pascal`}>{status}</div>
            <div className={`${prefix}__section--2`}>{typeof price === 'string' ? `$${Format.number(price)}` : price}</div>
            <div className={`${prefix}__section--2`}>{typeof unitValue === 'string' ? `$${Format.number(unitValue)}` : unitValue}</div>
            {
                isIssuer(props)
                    ? (
                        <div className={`${prefix}__section--2`}>{atsName || 'Unlisted'}</div>
                    ) : (
                        <div className={`${prefix}__section--2`} />
                    )
            }
            {
                isTranche ? (
                    <>
                        <div className={`${prefix}__section--2`}>{typeof term === 'string' ? Format.number(term) : term}</div>
                        <div className={`${prefix}__section--2`}>{typeof coupon === 'string' ? Format.percent(coupon, 100) : coupon}</div>
                    </>
                ) : (
                    <>
                        <div className={`${prefix}__section--2`} />
                        <div className={`${prefix}__section--2`} />
                    </>
                )
            }
            <div className={`${prefix}__section--2 ${prefix}--overflow`}>{termsUrl}</div>
            {canLinkSecurity && <div className={`${prefix}__section--2 ${prefix}--overflow`}>{securityLink}</div>}
            {
                IconHelper([
                    [cancel, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />],
                    false,
                    [submit, canLinkRound && roundLink],
                ], prefix)
            }
            <div className={`${prefix}__icon-offset`} />
            {endSlot}
        </div>
    )
}

import DivFactory from './DivFactory.js'

import './Tombstone.scss'

export default {
    tombstone: DivFactory('tombstone'),
    header: DivFactory('tombstone-header'),
    section: DivFactory('tombstone-section'),
    row: DivFactory('tombstone-row'),
    item: DivFactory('tombstone-item'),
    right: DivFactory('tombstone-item-right'),
    splitRow: DivFactory('tombstone-split-row'),
}

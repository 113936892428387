import React from 'react'

export default ({ offset = '' }) => {
    const prefix = 'vrtl-assets-issuer-card'
    return (
        <div className={`${prefix}__container--heading`}>
            <div className={`${prefix}__section--3`}>
                {offset}
                Issuer Name
            </div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`}>Issuer Email</div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section`}>Jurisdiction</div>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__icon-offset--2`} />
            <div className={`${prefix}__icon-offset--2`} />
        </div>
    )
}

import React, { Component } from 'react'
import Typeahead from 'common/typeahead/Typeahead'
import Mutation from 'delv/Mutation'
import './BrokerAllocationForm.css'

const CREATE_BROKER_ALLOCATION = `mutation($brokerAllocation:BrokerAllocationInput!){
  createBrokerAllocation(input:{brokerAllocation:$brokerAllocation}){
    brokerAllocation{
      id
      managedUnits
      name
      status
      roundByRoundId{
        id
      }
    }
  }
}`

const BROKER_TYPEAHEAD = (name) => `{
  brokerTypeahead(accountName:"${name}"){
    nodes{
      id
      name
    }
  }
}`

class BrokerAllocationForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
        }
        this.mutation = new Mutation({
            mutation: CREATE_BROKER_ALLOCATION,
            onSubmit: this.handleSubmit,
            onResolve: props.popup.close,
        })
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        this.setState({ [name]: value })
    }

    validateInput = () => {
        if (!this.state.broker) {
            // eslint-disable-next-line
            this.setState({ error: 'A broker is required.' })
        } else {
            return true
        }

        return false
    }

    handleSubmit = () => {
        if (this.validateInput()) {
            return {
                brokerAllocation: {
                    roundId: this.props.roundId,
                    brokerId: this.state.broker,
                },
            }
        }

        return undefined
    }

    formatTypeahead = (data) => ({
        options: data.brokerTypeahead.nodes.map((item) => ({
            broker: item.name,
            value: item.id,
        })),
    })

    render = () => (
        <div className='broker-allocation-form'>
            <div className='broker-allocation-form-heading'>
                Select A Distributor
            </div>
            <div className='broker-allocation-form-section'>
                <div>
                    <div className='card-label'>DISTRIBUTOR</div>
                    <Typeahead
                        query={BROKER_TYPEAHEAD}
                        name='broker'
                        format={this.formatTypeahead}
                        onChange={this.handleChange}
                        value={this.state.name}
                    />
                </div>
                <div className='broker-allocation-form-section'>
                    <div onClick={this.mutation.submit} className='styled-button broker-allocation-form-buttons'>
                        Submit
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BrokerAllocationForm

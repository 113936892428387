import React from 'react'
import NetworkMessage from 'common/NetworkMessage'

const DEFAULT_MESSAGE = 'Success!'

const NetworkSuccess = ({ message, AlertProps }) => (
    <NetworkMessage message={message ?? DEFAULT_MESSAGE} severity='success' {...AlertProps} />
)

export default NetworkSuccess

import * as React from 'react'
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import RestoreIcon from '@mui/icons-material/Restore'

import Box from 'common/Box'
import Button from 'common/Button'
import { isMd } from 'constants/media-queries'

const gridToolbarContainerSx = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    mb: 1,
    [isMd]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
}

const DataGridToolbarButtonSx = {
    fontSize: '0.8125rem',
}

const DataGridToolbar = ({
    columns = true,
    density = true,
    filter,
    export: exportTable,
    collapseRows,
    resetGrid,
    children,
    csvOptions,
    printOptions,
}) => (
    <GridToolbarContainer sx={gridToolbarContainerSx}>
        <Box>
            {Boolean(columns) && <GridToolbarColumnsButton />}
            {Boolean(filter) && <GridToolbarFilterButton />}
            {Boolean(density) && <GridToolbarDensitySelector />}
            {Boolean(exportTable) && <GridToolbarExport printOptions={printOptions} csvOptions={csvOptions} />}
            {Boolean(collapseRows) && (
                <Button
                    onClick={collapseRows}
                    startIcon={<UnfoldLessIcon />}
                    sx={DataGridToolbarButtonSx}
                >
                    Collapse
                </Button>
            )}
            {Boolean(resetGrid) && (
                <Button
                    onClick={resetGrid}
                    startIcon={<RestoreIcon />}
                    sx={DataGridToolbarButtonSx}
                >
                    Reset
                </Button>
            )}
        </Box>
        {children}
    </GridToolbarContainer>
)

export default DataGridToolbar

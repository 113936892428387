import React from 'react'
import objectPath from 'object-path'

import SafeReact from 'util/SafeReact.js'

import Grid from './Grid.js'

const GridChildProvider = ({
    arrayKey,
    children,
    itemsPer,
    column,
    noneFound,
    ...props
}) => {
    const render = objectPath.get(props, arrayKey)

    if (render && render.length) {
        return (
            <Grid itemsPer={itemsPer} column={column}>
                {SafeReact.cloneChildren(
                    render,
                    ({ context: { data, index } }) => ({
                        key: data.id,
                        index,
                        ...data,
                    }),
                    children,
                )}
            </Grid>
        )
    }
    return noneFound || null
}

export default GridChildProvider

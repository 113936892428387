import React from 'react'
import HoverToolTip from 'common/HoverToolTip'
import { isIssuer } from 'util/permissions.js'

export default ({
    prefix = 'vrtl-security-row',
    endSlot,
    startSlot,
    entityPage,
    ...props
}) => {
    const isAnIssuer = isIssuer(props)

    const LinkDisplay = () => {
        if (isAnIssuer && entityPage) {
            return <div className={`${prefix}__section--2`}>Round</div>
        }

        return <div className={`${prefix}__icon-offset`} />
    }
    return (
        <div className={`${prefix}__container--heading`}>
            {startSlot}
            <div className={`${prefix}__section--3`}>Name</div>
            <div className={`${prefix}__section--2`}>Ticker Symbol</div>
            <HoverToolTip position='top center'>
                <div className={`${prefix}__section--2 ${prefix}--hover-highlight`}>Latest Value</div>
                Last Price x Units. Last price is the most recent price per unit recorded for the security.
            </HoverToolTip>
            <div className={`${prefix}__section--2`}>Latest Price</div>
            <div className={`${prefix}__section--2`}>Total Units</div>
            {
                isAnIssuer ? (
                    <>
                        <div className={`${prefix}__section--2`}>Asset Name</div>
                        <div className={`${prefix}__section--2`}>Asset Type</div>
                    </>
                ) : (
                    <div className={`${prefix}__section--2`}>Issuer</div>
                )
            }
            <LinkDisplay />
            {endSlot}
        </div>
    )
}

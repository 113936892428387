import React from 'react'
import cx from 'classnames'

import './loading.scss'

import Logo from '../../logos/ColorBug.js'

export default ({ className = 'loading-pulse-container', addClassName = '' }) => (
    <div className={cx(className, addClassName)}>
        <Logo className='loading-pulse' />
        <div className='loading-text'>Loading</div>
    </div>
)

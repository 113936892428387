import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'

import ErrorIcon from '@material-ui/icons/Error'

export default ({
    position,
    width,
}) => (
    <HoverToolTip position={position} width={width}>
        <ErrorIcon
            className='field-edit-icon'
            style={{ color: 'red' }}
        />
        <div>
            Template has been deleted, new mapped templates cannot be created from this template.
        </div>
    </HoverToolTip>
)

import React from 'react'
import Decimal from 'decimal.js'

import Format from 'util/format/Format.js'

const CapitalSummary = (props) => {
    const { assetClass } = props

    const totalDeployed = assetClass.capitalDeploymentsByRoundId.nodes
        .reduce(
            (total, deployment) => Decimal.add(deployment.amount, total),
            0,
        )

    const expected = Decimal.sub(assetClass.holdings, totalDeployed)

    return (
        <div className='vrtl-capital-summary__container'>
            <div className='vrtl-capital-summary__heading'>
                <div className='vrtl-capital-summary__heading-text'>
                    Recent Capital Summary
                </div>
            </div>

            <div className='vrtl-capital-summary__header'>
                <div className='vrtl-capital-summary__column'>
                    Total Paid In Capital
                </div>

                <div className='vrtl-capital-summary__column'>
                    Deployed Capital
                </div>

                <div className='vrtl-capital-summary__column'>
                    Remaining Capital
                </div>

                <div className='vrtl-capital-summary__column vrtl-capital-summary__column--2' />

                <div className='vrtl-capital-summary__column vrtl-capital-summary__column--shrink' />
            </div>

            <div className='vrtl-capital-summary__row'>
                <div className='vrtl-capital-summary__column'>
                    $
                    {Format.number(assetClass.holdings)}
                </div>

                <div className='vrtl-capital-summary__column'>
                    $
                    {Format.number(totalDeployed)}
                </div>

                <div className='vrtl-capital-summary__column'>
                    $
                    {Format.number(expected)}
                </div>

                <div className='vrtl-capital-summary__column vrtl-capital-summary__column--2' />

                <div className='vrtl-capital-summary__column vrtl-capital-summary__column--shrink' />
            </div>
        </div>
    )
}

export default CapitalSummary

import React from 'react'
import Div from 'common/Div.js'
import Popup from 'common/Popup.js'
import Tombstone from 'common/Tombstone.js'
import Map from 'common/Map.js'
import OrderBy from 'common/OrderBy.js'
import Card from 'common/Card.js'
import DelvWebSocket from 'delv/websocket'
import Format from 'util/format/Format.js'
import { CheckBox } from 'common/FormUI.js'
import TokenizeViewSwitch from 'tokenize/TokenizeViewSwitch.js'
import TokenForm from 'token/TokenForm.js'
import TokenOverview from 'tokenize/Two.js'
import DeployToken from 'deployToken/index.js'
import MintViewSwitch from 'token/MintViewSwitch.js'
import Ledger from 'components/ledger/index.js'
import Delv from 'delv/delv-react'
import Account from 'components/account/index.js'
import Security from 'components/security/index.js'
import Holding from 'components/holding/index.js'
import NoneFound from 'common/NoneFound/index.js'
import { getBlockExplorerUrl } from 'util/blockchain'

import InvestorCard from './InvestorCard.js'
import { TokenizeQuery } from './queries.js'
import './token.scss'

const STATUS = {
    system_only: 'Not Tokenized',
    burning: 'Burning',
    not_deployed: 'Not Deployed',
    deploying: 'Deploying',
    deployed: 'Deployed',
    issuing: 'Issuing',
    issued: 'Issued',
}

const getDisplayStatus = (status) => STATUS[status] || status

const DistributionCard = ({
    name, units, onClick, isSelected,
}) => (
    <Card.card>
        <CheckBox value={isSelected} onChange={onClick} />
        <Card.section>
            <Card.label>Name</Card.label>
            {name}
        </Card.section>
        <Card.section />
        <Card.section>
            <Card.label>Units</Card.label>
            {Format.number(units)}
        </Card.section>
    </Card.card>
)

const Token = ({
    klass, orderByData, account, userAccount: { role },
}) => {
    const { tokenByTokenId: token } = klass
    const isIssuer = account.type === 'issuer'
    const canEdit = role !== 'account_viewer' && isIssuer

    if (token.contract && token.status === 'deploying') {
        DelvWebSocket.connect()
        DelvWebSocket.subscribe({
            type: 'contracts',
            ids: [token.contract.id],
        })
    }

    return (
        <div className='row flex-1'>
            <div className='flex-1'>
                <div className='padded-card-container'>
                    <div className='token-info-header'>
                        <h2>Token Information</h2>
                        <div className='vrtl-token-buttons'>
                            <Card.section className=''>
                                {isIssuer && token.contract && token.contract.status === 'not_deployed' ? (
                                    <Popup.popup>
                                        <Popup.inner>
                                            <Popup.close />
                                            <TokenizeViewSwitch token={token} roundId={klass.id}>
                                                <TokenForm />
                                                <TokenOverview />
                                            </TokenizeViewSwitch>
                                        </Popup.inner>
                                        <Popup.trigger addClassName='card-form-trigger'>Edit</Popup.trigger>
                                    </Popup.popup>
                                ) : token.contract && isIssuer && (token.contract.status === 'deployed' || token.contract.status === 'issued') ? (
                                    <div className='card-form-trigger styled-button'>Swap Chain</div>
                                ) : (
                                    <div />
                                )}
                            </Card.section>
                            {
                                // this is all the top header, the inline to the title part
                                canEdit ? (
                                    !token.contract ? (
                                        <Popup.popup>
                                            <Popup.inner>
                                                <Popup.close />
                                                <TokenizeViewSwitch token={token} roundId={klass.id}>
                                                    <TokenForm />
                                                    <TokenOverview />
                                                </TokenizeViewSwitch>
                                            </Popup.inner>
                                            <Popup.trigger>Setup Token</Popup.trigger>
                                        </Popup.popup>
                                    ) : token.status === 'not_deployed' ? (
                                        <Popup.popup>
                                            <Popup.inner>
                                                <DeployToken contractId={token.contract.id} />
                                            </Popup.inner>
                                            <Popup.trigger addClassName='card-form-trigger'>Deploy</Popup.trigger>
                                        </Popup.popup>
                                    ) : token.contract && (token.contract.status === 'deployed' || token.contract.status === 'issued') ? (
                                        <Popup.popup>
                                            <Popup.inner>
                                                <Popup.close />
                                                <MintViewSwitch assetClassId={klass.id}>
                                                    <Div>
                                                        <>
                                                            <h2 className='header'>Mint</h2>
                                                        </>
                                                        <Card.card>
                                                            <Card.section>
                                                                <Card.label>NAME</Card.label>
                                                                {token.name}
                                                            </Card.section>
                                                            <Card.section>
                                                                <Card.label>SYMBOL</Card.label>
                                                                {token.symbol}
                                                            </Card.section>
                                                            <Card.section>
                                                                <Card.label>CHAIN</Card.label>
                                                                {token.contract.chain}
                                                            </Card.section>
                                                        </Card.card>
                                                        <Delv.query {...TokenizeQuery} roundId={klass.id}>
                                                            <OrderBy.order arrayKey='capTables'>
                                                                <OrderBy.bar>
                                                                    <OrderBy.select
                                                                        map={(data) => ({ id: data.id, value: data })}
                                                                        className='icon-checkbox'
                                                                    />
                                                                    <OrderBy.filter className='flex-2' field='name'>
                                                                        Name
                                                                    </OrderBy.filter>
                                                                    <OrderBy.sort field='units'>Units</OrderBy.sort>
                                                                </OrderBy.bar>
                                                                <Div className='vrtl-token-distributions'>
                                                                    <Div className='order-by-inner'>
                                                                        <Map
                                                                            arrayKey='_orderBy.capTables'
                                                                            noneFound={<NoneFound>No holdings found.</NoneFound>}
                                                                        >
                                                                            <DistributionCard />
                                                                        </Map>
                                                                    </Div>
                                                                </Div>
                                                            </OrderBy.order>
                                                        </Delv.query>
                                                    </Div>
                                                    <Div />
                                                </MintViewSwitch>
                                            </Popup.inner>
                                            <Popup.trigger addClassName='card-form-trigger'>Mint</Popup.trigger>
                                        </Popup.popup>
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )
                            }
                        </div>
                    </div>
                    <Card.card>
                        {token.contract ? (
                            <Card.section>
                                <Card.label>Name</Card.label>
                                <Tombstone.item>{token.name}</Tombstone.item>
                            </Card.section>
                        ) : (
                            <Card.section />
                        )}
                        {token.contract ? (
                            <Card.section>
                                <Card.label>Symbol</Card.label>
                                <Tombstone.item>{token.symbol}</Tombstone.item>
                            </Card.section>
                        ) : (
                            <Card.section />
                        )}
                        {token.contract ? (
                            <Card.section>
                                <Card.label>Chain</Card.label>
                                <Tombstone.item>{token.contract.chain}</Tombstone.item>
                            </Card.section>
                        ) : (
                            <Card.section />
                        )}
                        <Card.section>
                            <Card.label>Status</Card.label>
                            <Tombstone.item>{getDisplayStatus(token.contract ? token.contract.status : token.status)}</Tombstone.item>
                        </Card.section>
                        {token.contract
                        && (token.contract.status === 'deployed'
                            || token.contract.status === 'issuing'
                            || token.contract.status === 'issued') ? (
                                <Card.section>
                                    <Card.label>Contract address</Card.label>
                                    <Tombstone.item>
                                        <a
                                            href={getBlockExplorerUrl(token.contract.contractAddress, token.contract.chain)}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {Format.blockchainAddress(token.contract.contractAddress)}
                                        </a>
                                    </Tombstone.item>
                                </Card.section>
                            ) : (
                                <Card.section />
                            )}
                    </Card.card>
                </div>
            </div>

            <div className='flex-1 column'>
                <div className='vrtl-round-incoming-cap__container'>
                    <div className='row--justify-between'>
                        <div className='vrtl-round-incoming-cap__heading'>
                            <div className='vrtl-round-incoming-cap__heading-text'>Stakeholder Ledger</div>
                        </div>
                    </div>
                    <OrderBy.order dropdownFields={['status']} orderByData={orderByData}>
                        <OrderBy.bar>
                            <OrderBy.filter field='name' className='center-row flex-2'>
                                Name
                            </OrderBy.filter>
                            <OrderBy.sort field='amount' className='center-row flex-1'>
                                Units
                            </OrderBy.sort>
                            <div className='flex-1' />
                            <OrderBy.dropdown field='status' width='5rem'>
                                Status
                            </OrderBy.dropdown>
                        </OrderBy.bar>
                        <Div className='buyers-container'>
                            <Div className='order-by-inner'>
                                <Map arrayKey='_orderBy.chain'>
                                    <InvestorCard prefix='vrtl-token-chain' />
                                </Map>
                                <Map arrayKey='_orderBy.holding'>
                                    <InvestorCard prefix='vrtl-token-holding' />
                                </Map>
                                <Map arrayKey='_orderBy.distribution'>
                                    <InvestorCard prefix='vrtl-token-distribution' />
                                </Map>
                            </Div>
                        </Div>
                    </OrderBy.order>
                </div>
            </div>
        </div>
    )
}

const GET_TOKEN = {
    query: (id) => `{
  roundById(id: "${id}") {
    id
    name
    holdings
    managedUnits
    termsUrl
    opensOn
    closesOn
    price
    total
    status
    tokenByTokenId {
      id
      name
      symbol
      total
      status
      contractsByTokenId {
        nodes {
          id
          status
          chain
          contractAddress
          integrationByCustodianId {
            id
            integrationPartnerByPartnerId {
              id
              name
            }
          }
          integrationByIssuancePlatformId {
            id
            integrationPartnerByPartnerId {
              id
              name
            }
          }
        }
      }
    }
    securityBySecurityId{
      ${Security.fragment.toString()}
      holdingsBySecurityId{
        nodes{
          ${Holding.fragment.toString()}
          accountByInvestorId{
            ${Account.fragment.toString()}
          }
          holdingBlockchainAddressesByHoldingId(condition:{status:"trade"}){
              nodes{
            id
            status
            blockchainAddressByBlockchainAddressId{
                id
                address
                chain
                ledgersByBlockchainAddressId {
                  nodes {
                    ${Ledger.fragment.toString()}
                    transactionByTransactionId {
                      id
                      chainData
                    }
                  }
                }
              }
          }
          }
        }
      }
    }
    allocationsByRoundId {
      nodes {
        id
        distributionsByAllocationId {
          nodes {
            id
            amount
            status
            accountByInvestorId {
              id
              contactName
              email
            }
          }
        }
      }
    }
  }
}
`,
    args: (props) => props.match.params.round_id,
    format: (data) => {
        const capTables = {
            distribution: [],
            holding: [],
            chain: [],
        }

        const klass = data.roundById
        if (data.error) {
            throw data.error
        }
        const { allocationsByRoundId, tokenByTokenId, securityBySecurityId } = klass
        if (allocationsByRoundId && allocationsByRoundId.nodes) {
            allocationsByRoundId.nodes.forEach(({ distributionsByAllocationId }) => {
                if (distributionsByAllocationId && distributionsByAllocationId.nodes) {
                    distributionsByAllocationId.nodes.forEach((distribution) => {
                        // eslint-disable-next-line
                        if (distribution.status != 'locked') {
                            capTables.distribution.push({
                                id: distribution.id,
                                amount: distribution.amount,
                                status: 'not issued',
                                name: distribution.accountByInvestorId.contactName,
                            })
                        }
                    })
                }
            })
        }

        if (securityBySecurityId) {
            securityBySecurityId.holdingsBySecurityId.nodes.forEach((holding) => {
                // eslint-disable-next-line
                if (holding.amount != 0) {
                    const chain = holding.holdingBlockchainAddressesByHoldingId.nodes[0].blockchainAddressByBlockchainAddressId.chain
                    if (chain === 'vertalon-hawknet') {
                        capTables.holding.push({
                            id: holding.id,
                            amount: holding.amount,
                            status: 'issued',
                            name: holding.accountByInvestorId.contactName,
                        })
                    } else {
                        const chainData = holding.holdingBlockchainAddressesByHoldingId.nodes[0].blockchainAddressByBlockchainAddressId
                            .ledgersByBlockchainAddressId?.nodes[0]?.transactionByTransactionId?.chainData
                        capTables.chain.push({
                            id: holding.id,
                            amount: holding.amount,
                            status: chain,
                            name: holding.accountByInvestorId.contactName,
                            chainData,
                        })
                    }
                }
            })
        }

        const token = tokenByTokenId

        token.contract = token.contractsByTokenId.nodes[0]

        return {
            klass,
            token,
            orderByData: capTables,
        }
    },
}

export default {
    query: GET_TOKEN,
    component: Token,
}

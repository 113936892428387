import React from 'react'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

export default ({
    children,
    ...props
}) => {
    const prefix = 'vrtl-asset-order-by-header'

    return (
        <OrderBy.bar className={`${prefix}__bar`} {...props}>
            <OrderBy.filter className={`${prefix}__section--3`} field='name'>
                <div className='inline-offset' />
                Asset Name
            </OrderBy.filter>
            <OrderBy.dropdown className={`${prefix}__section--2`} field='type'>
                Type
            </OrderBy.dropdown>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <OrderBy.dropdown className={`${prefix}__section`} field='status'>
                Status
            </OrderBy.dropdown>
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__section--2`} />
            <div className={`${prefix}__icon-offset`} />
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__add-icon` })
                    : <div className={`${prefix}__icon-offset`} />
            }
            <div className={`${prefix}__icon-offset`} />
        </OrderBy.bar>
    )
}

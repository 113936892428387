import * as React from 'react'

import Nav from 'common/Nav'
import Breadcrumbs from 'common/Breadcrumbs'
import Footer from 'common/Footer'
import Container from 'common/Container'
import Page from 'common/Page.js'
import Delv from 'delv/delv-react.js'
import SafeReact from 'util/SafeReact.js'
import CapitalManagement from './CapitalManagement.js'
import { RoundPage, CapitalDeployments } from './queries.js'
import Manage from './Manage.js'
import Token from './Token.js'

import './styles.scss'

const RoundWrapper = ({ children, ...props }) => {
    const klass = props.klass
    const asset = klass.assetByAssetId

    const BREADCRUMBS = [
        {
            display: 'Assets',
            link: '/assets',
        },
        {
            display: asset.name,
            link: '/assets',
        },
        {
            display: klass.name,
            link: '#',
            active: true,
        },
    ]
    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                {SafeReact.cloneChildren(children, props)}
            </Container>
            <Footer />
        </>
    )
}

export default Page({
    Base: Delv.queryHOC(RoundWrapper, RoundPage),
    views: {
        manage: {
            component: Manage,
            navLabel: 'Round Management',
            allowedRoles: ['issuer', 'broker'],
        },
        capital: {
            component: Delv.queryHOC(CapitalManagement, CapitalDeployments),
            navLabel: 'Capital Management',
            allowedRoles: ['issuer'],
        },
        token: {
            component: Delv.queryHOC(Token.component, Token.query),
            navLabel: 'Token Management',
            allowedRoles: ['issuer'],
        },
    },
    defaultView: 'manage',
})

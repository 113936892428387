import * as React from 'react'

import Stepper from 'common/Stepper'
import Step from 'common/Step'
import StepButton from 'common/StepButton'

const StepsStepper = ({
    steps,
    getCompleted,
    getLabel,
    getKey,
    activeStep,
    setActiveStep,
    orientation = 'horizontal',
}) => (
    <Stepper orientation={orientation} nonLinear activeStep={activeStep}>
        { steps.map((step, index) => {
            const label = getLabel ? getLabel(step) : step.label
            const completed = getCompleted ? getCompleted(step) : step.completed
            const key = getKey ? getKey(step) : label
            const active = index === activeStep
            const stepProps = {
                active,
                completed,
                key,
            }

            return (
                <Step {...stepProps}>
                    <StepButton color='inherit' onClick={() => setActiveStep(index)}>
                        {label}
                    </StepButton>
                </Step>
            )
        })}
    </Stepper>
)

export default StepsStepper

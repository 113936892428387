import React from 'react'

import Qualifications from 'account/Qualifications.js'
import SubNavLayout from 'layouts/sub-nav-layout.js'
import { integrationBreadcrumbs, selectSettingsTabs } from 'constants/settings'

const IntegrationSettings = ({ account }) => {
    const isInvestor = account.type === 'investor'
    const tabs = selectSettingsTabs(account.type)

    return (
        <SubNavLayout breadcrumbs={integrationBreadcrumbs} tabs={tabs}>
            <Qualifications isInvestor={isInvestor} accountId={account.id} />
        </SubNavLayout>
    )
}

export default IntegrationSettings

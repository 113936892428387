import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'

import WarningIcon from '@material-ui/icons/Warning'

export default ({ position, width }) => (
    <HoverToolTip position={position} width={width}>
        <WarningIcon className='field-edit-icon' style={{ color: 'orange' }} />
        <div>
            Change detected in the template, mapped templates created before this change will not reflect the change made. To use the new
            version of this template create a new mapped template.
        </div>
    </HoverToolTip>
)

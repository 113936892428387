import React from 'react'

import HelpIcon from '@material-ui/icons/Help'
import HoverToolTip from 'common/HoverToolTip'

export default () => {
    const prefix = 'vrtl-custom-mapping-fields'
    return (
        <HoverToolTip>
            <HelpIcon className={`${prefix}__icon-help`} />
            <div>
                These fields can be used when mapping signing templates for use as a qualification. To use the field set map to custom and
                then enter the name of a field found in the value box.
            </div>
        </HoverToolTip>
    )
}

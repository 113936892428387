import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'
import Card from 'common/Card.js'
import Div from 'common/Div.js'

import './styles.scss'

export default ({
    id,
    name,
    active,
    locked,
    edit,
    cancel,
    submit,
    remove,
    children,
}) => {
    const prefix = 'vrtl-onboarding-question-set-row'
    if (id && children) {
        return (
            <Card.collapsible className=''>
                <Div className={`${prefix}__container`}>
                    <Card.arrow />
                    <div className={`${prefix}__section`}>{name}</div>
                    <div className={`${prefix}__section`}>{typeof active === 'boolean' ? active ? 'Active' : 'Not Active' : active}</div>
                    <div className={`${prefix}__section`}>{typeof locked === 'boolean' ? locked ? 'Locked' : 'Not locked' : locked}</div>
                    {
                        IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
                    }
                </Div>
                {
                    React.cloneElement(children, { questionSetId: id, locked })
                }
            </Card.collapsible>
        )
    }
    return (
        <div className={`${prefix}__container`}>
            <div className='icon-offset' />
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{typeof active === 'boolean' ? active ? 'Active' : 'Not Active' : active}</div>
            <div className={`${prefix}__section`}>{typeof locked === 'boolean' ? locked ? 'Locked' : 'Not Locked' : locked}</div>
            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

import React from 'react'
import Format from 'util/format/Format.js'

const data = `id
price
currencyByCurrency {
  id
  code
  name
}`

const distribution = `distributionPricesByDistributionId {
  nodes {
      ${data}
  }
}`

const fragment = {
    data,
    distribution,
}
export { fragment }

export default ({ price, currencyByCurrency: { code } }) => (
    <div className='card card-container'>
        <div className='small-card-section'>
            <div className='card-label'>PRICE PER UNIT</div>
            {Format.number(price)}
        </div>
        <div className='small-card-section'>
            <div className='card-label'>CURRENCY</div>
            {code}
        </div>
    </div>
)

import React from 'react'
import OpenInNew from '@material-ui/icons/OpenInNew'
import AddBox from '@material-ui/icons/AddBox'
import { Link } from 'react-router-dom'

import Popup from 'common/Popup.js'
import Format from 'util/format/Format.js'
import Delv from 'delv/delv-react'
import Map from 'common/Map.js'
import NoneFound from 'common/NoneFound/index.js'

import AllocationForm from './AllocationForm.js'
import { AllocationsQuery } from './queries.js'

const AllocationEntry = (props) => {
    const {
        id,
        name,
        status,
        opensOn,
        closesOn,
        accountByOwnerId: { name: creator },
    } = props

    return (
        <div className='vrtl-round-allocation__row'>
            <div className='vrtl-round-allocation__column'>{name}</div>

            <div className='vrtl-round-allocation__column'>{Format.date(opensOn)}</div>

            <div className='vrtl-round-allocation__column'>{Format.date(closesOn)}</div>

            <div className='vrtl-round-allocation__column'>{status}</div>

            <div className='vrtl-round-allocation__column vrtl-round-allocation__column--2'>
                {creator}
            </div>
            <div className='vrtl-round-allocation__column vrtl-round-allocation__column--shrink'>
                <Link to={`/distribute-allocation/${id}/allocation`}>
                    <OpenInNew className='dropdown-arrow' />
                </Link>
            </div>
        </div>
    )
}

const AllocationsCard = (props) => {
    const { assetClass, roundId } = props

    const entries = (
        <Delv.query {...AllocationsQuery} roundId={roundId}>
            <Map
                arrayKey='allocations.nodes'
                noneFound={<NoneFound>No allocations found.</NoneFound>}
            >
                <AllocationEntry currentAccount={props.currentAccount} />
            </Map>
        </Delv.query>
    )

    const popup = (
        <Popup.popup>
            <Popup.inner>
                <AllocationForm assetClass={assetClass} />
            </Popup.inner>

            <Popup.trigger className='vrtl-round-allocation__add'>
                <AddBox />
            </Popup.trigger>
        </Popup.popup>
    )

    return (
        <div className='vrtl-round-allocation__container'>
            <div className='vrtl-round-allocation__heading'>
                <div className='vrtl-round-allocation__heading-text'>Allocations</div>
            </div>

            <div className='vrtl-round-allocation__frame'>
                <div className='vrtl-round-allocation__frame-item' />
            </div>

            <div className='vrtl-round-allocation__header'>
                <div className='vrtl-round-allocation__column'>Name</div>

                <div className='vrtl-round-allocation__column'>Opens On</div>

                <div className='vrtl-round-allocation__column'>Closes On</div>

                <div className='vrtl-round-allocation__column'>Status</div>

                <div className='vrtl-round-allocation__column vrtl-round-allocation__column--2'>
                    Created By
                </div>

                <div className='vrtl-round-allocation__column vrtl-round-allocation__column--shrink'>
                    {popup}
                </div>
            </div>

            {entries}
        </div>
    )
}

export default AllocationsCard

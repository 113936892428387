import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default (props) => {
    const prefix = 'vrtl-signing-role'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                Role
            </OrderBy.filter>
            <OrderBy.dropdown field='mapTo' className={`${prefix}__section`}>
                Map to
            </OrderBy.dropdown>
            <OrderBy.dropdown field='type' className={`${prefix}__section`}>
                Type
            </OrderBy.dropdown>
            <OrderBy.filter field='toName' className={`${prefix}__section`}>
                Name
            </OrderBy.filter>
            <OrderBy.filter field='toEmail' className={`${prefix}__section`}>
                Email
            </OrderBy.filter>
            <div className={`${prefix}__icon-offset--2`} />
        </OrderBy.bar>
    )
}

import React from 'react'
import './Two.scss'

const Two = ({ form: { name, chain, symbol } }) => (
    <>
        <h2 className='header'>Setup Token</h2>
        <div className='token-container'>
            <div className='card-section'>
                <div className='card-label'>NAME</div>
                {name.value}
            </div>

            <div className='card-section'>
                <div className='card-label'>SYMBOL</div>
                {symbol.value}
            </div>

            <div className='card-section'>
                <div className='card-label'>CHAIN</div>
                {chain.value}
            </div>
        </div>
    </>
)
export default Two

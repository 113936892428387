import * as React from 'react'
import Box from 'common/Box'
import Snackbar from 'common/Snackbar'
import NetworkError from 'common/NetworkError'
import NetworkSuccess from 'common/NetworkSuccess'
import UseToggle from 'hooks/UseToggle'

const NetworkResponse = ({
    error, errorMessage, data, successMessage, SnackbarProps = {}, AlertProps = {}, onClose,
}) => {
    const { toggle, swap } = UseToggle()
    React.useEffect(() => {
        if (error || data) {
            swap()
        }
    }, [error, data])

    const onCloseWrapper = () => {
        if (onClose) {
            onClose()
        }
        swap()
    }

    return (
        <Snackbar
            open={toggle}
            onClose={onCloseWrapper}
            autoHideDuration={6000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            {...SnackbarProps}
        >
            <div>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {Boolean(error) && <NetworkError message={errorMessage} {...AlertProps} />}
                    {!error && Boolean(data) && <NetworkSuccess message={successMessage} {...AlertProps} />}
                </Box>
            </div>
        </Snackbar>
    )
}

export default NetworkResponse

const makeKey = (cb, id) => `${id ?? ''}${cb.name}${cb.toLocaleString()}`

const EventEmitter = () => {
    const data = { events: {} }

    const emit = (event, context) => Object.values(data.events[event]).map((cb) => cb(context))

    const on = (event, cb, { id } = {}) => {
        if (!data.events[event]) {
            data.events[event] = {}
        }
        if (!data.events[event][[makeKey(cb, id)]]) {
            data.events[event][[makeKey(cb, id)]] = cb
        }
    }

    const removeListener = (event, cb, { id } = {}) => {
        delete data.events[event][[makeKey(cb, id)]]
    }

    return {
        emit,
        on,
        removeListener,
    }
}

export default EventEmitter

import React from 'react'
import { CheckBox } from 'common/FormUI.js'
import Format from 'util/format/Format.js'

const Row = ({
    legalName,
    contactName,
    totalAmount,
    onClick,
    isSelected,
    prefix = 'vrtl-poi-card',
}) => (
    <div className={`${prefix}__container`}>
        <CheckBox value={isSelected} onChange={onClick} />
        <div className={`${prefix}__section--no-min`}>
            <div className={`${prefix}__label`}>
                Contact Name
            </div>
            {contactName}
        </div>
        <div className={`${prefix}__section--no-min`} />
        <div className={`${prefix}__section--no-min`}>
            <div className={`${prefix}__label`}>
                Account Name
            </div>
            {legalName}
        </div>
        <div className={`${prefix}__section--no-min`} />
        <div className={`${prefix}__section--no-min`}>
            <div className={`${prefix}__label`}>
                Total Units
            </div>
            {Format.number(totalAmount)}
        </div>

    </div>
)

export default Row

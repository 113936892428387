import * as React from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import Box from 'common/Box'
import Divider from 'common/Divider'
import ListItem from 'common/ListItem'
import ListItemIcon from 'common/ListItemIcon'
import Link from 'common/Link/index'
import Menu from 'common/Menu'
import NavLinks from 'common/Nav/Links'
import NavOptionsTrigger from 'common/Nav/Options/Trigger'
import Typography from 'common/Typography'
import useTheme from 'hooks/UseTheme'
import { NAV_LINKS, DROPDOWN_LINKS, USER_OPTIONS } from 'constants/links.js'
import { getAccountName, getAccountType } from 'util/account-utils.js'

import './styles.scss'

const NavOptionsFooter = ({ accountType, getUsersData, prefix = 'vrtl-options-dropdown-footer' }) => {
    const accountName = getAccountName(getUsersData)

    return (
        <ListItem className={prefix}>
            <ListItemIcon className={`${prefix}__icon`}>
                <AccountBoxIcon color='primary' sx={{ mr: 1.5 }} />
            </ListItemIcon>
            <Box className={`${prefix}__box`}>
                <Typography className={`${prefix}__box-account-name`}>{accountName}</Typography>
                <Typography className={`${prefix}__box-account-type`}>{accountType}</Typography>
            </Box>
        </ListItem>
    )
}

const selectDropdownLinks = (accountType) => DROPDOWN_LINKS[accountType] || USER_OPTIONS

const NavOptions = ({ prefix = 'vrtl-nav-options', getUsersData }) => {
    const [anchorEl, setAnchorEl] = React.useState(undefined)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const theme = useTheme()
    const primary = theme.palette.primary.main

    const accountType = getAccountType(getUsersData)

    return (
        <div className={prefix} data-testid='nav-options'>
            { accountType
                ? (
                    <>
                        <NavOptionsTrigger
                            id='nav-trigger'
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            open={open}
                            getUsersData={getUsersData}
                        />
                        <Menu
                            id='nav-options-dropdown'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            component='div'
                            square='true'
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            PaperProps={{ sx: { border: `1px solid ${primary}`, borderRadius: '0 0 0.25rem 0.25rem' } }}
                            PopoverClasses={{
                                paper: 'vrtl-options-dropdown__paper',
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'nav-trigger',
                                className: 'vrtl-options-dropdown',
                            }}
                        >
                            <NavLinks
                                getUsersData={getUsersData}
                                links={NAV_LINKS}
                                prefix='vrtl-options-dropdown-links'
                                linkPrefix='vrtl-options-dropdown-nav'
                            />
                            <NavLinks
                                getUsersData={getUsersData}
                                links={selectDropdownLinks(accountType)}
                                prefix='vrtl-options-dropdown-links'
                                linkPrefix='vrtl-options-dropdown-option'
                            />
                            <Divider sx={{ my: 0, mx: 1 }} component='li' />
                            <NavOptionsFooter accountType={accountType} getUsersData={getUsersData} />
                        </Menu>
                    </>
                ) : (
                    <Box className='vrtl-nav-options-logout' sx={{ gap: 1 }}>
                        <ExitToAppIcon color='primary' />
                        <Link className='text text--white' href='/authenticate/logout'>
                            Logout
                        </Link>
                    </Box>
                )}
        </div>
    )
}

export default NavOptions

import Row from './Row.js'
import OrderByHeader from './OrderByHeader.js'
import Fragment from './Fragment.js'
import Form from './Form.js'

import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    orderByHeader: OrderByHeader,
    form: Form,
}

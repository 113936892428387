import React from 'react'
import Mutation from 'delv/Mutation'
import { TextArea } from 'common/FormUI.js'
import Form from 'common/form/Form'
import './BulkAnnotationForm.scss'

// eslint-disable-next-line max-len
const createFragment = (id, annotation) => `a${id.replace(/\-/g, '_')}:createDistributionAnnotation(input: {distributionAnnotation: { distributionId: "${id}", annotation: "${annotation}"}}) {
        distributionAnnotation {
          id
          annotation
          createdOn
          distributionId
          distributionByDistributionId {
            id
          }
        }
      }
    `

const BulkAnnotationForm = ({
    form: { annotation, validateInput }, multiSelect,
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        const distributions = multiSelect.getValues()
        if (!input.hasErrors) {
            const { annotation: inputAnnotation } = input
            let mutation = ''
            distributions.forEach(({ distributionId }) => {
                mutation += createFragment(distributionId, inputAnnotation)
            })
            if (mutation) {
                mutation = `mutation {
                    ${mutation}
                }`
                new Mutation({
                    mutation,
                    onSubmit: () => ({}),
                    onResolve: () => {
                        multiSelect.select([])
                    },
                }).submit()
            }
        }
    }

    return (
        <div className='bulk-annotation-form'>
            <form className='card'>
                <div className='bulk-annotation-form-section'>
                    <div className='bulk-annotation-card-label'>NOTE:</div>
                    {annotation.error ? <div className='error'>{annotation.error}</div> : <div className='error' />}
                    <TextArea style={{ minHeight: '10em' }} {...annotation} />
                </div>
            </form>
            <div className='bulk-form-button-container'>
                <div className='bulk-form-button' onClick={handleSubmit}>
                    Save
                </div>
            </div>
        </div>
    )
}

const formConfig = {
    fields: {
        annotation: {
            type: 'string',
            error: 'Note is required',
        },
    },
}

export default Form(BulkAnnotationForm, formConfig)

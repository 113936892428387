import React from 'react'

export default ({
    children,
    ...props
}) => {
    const prefix = 'vrtl-payment'
    return (
        <div className={`${prefix}__heading-container`}>
            <div className={`${prefix}__section--2`}>amount (USD)</div>
            <div className={`${prefix}__section--2`}>status</div>
            <div className={`${prefix}__section--3`}>paid on</div>

            <div className={`${prefix}__icon-offset--2`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </div>
    )
}

import objectPath from 'object-path'

const config = {
    canEditDefault: {
        allowed: ['owner', 'admin'],
    },
}

const roles = {
    account_admin: 'admin',
    account_owner: 'owner',
    account_viewer: 'viewer',
}

const isIssuer = (props) => (
    Array.isArray(props.getUsersData.account) ? props.getUsersData.account[0].type === 'issuer' : props.getUsersData.account.type === 'issuer'
)

const isBroker = (props) => props.getUsersData.account[0].type === 'broker'

const isInvestor = (props) => props.getUsersData.account[0].type === 'investor'

const canEditDefault = (props) => {
    if (!isIssuer(props)) {
        return false
    }

    const currentRole = roles[props.getUsersData.userAccount[0].role]

    return config.canEditDefault.allowed.some((role) => role === currentRole)
}

const isUploader = (props, uploadedBy = 'documentByDocumentId.uploadedBy') => {
    const uploader = uploadedBy.includes('.') ? objectPath.get(props, uploadedBy) : uploadedBy
    return uploader === props.account.id
}

const canEditDocument = (props) => isUploader(props) && roles[props.account.role] !== 'viewer'

export {
    isBroker, isIssuer, isInvestor, canEditDefault, isUploader, canEditDocument,
}

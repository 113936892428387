import React from 'react'
import AddCircle from '@material-ui/icons/AddCircle'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import MultiSelect from 'common/MultiSelect.js'
import Div from 'common/Div.js'
import NewEditSwitch from 'common/NewEditSwitch.js'
import Switch from 'common/Switch.js'
import Popup from 'common/Popup.js'
import ViewAppend from 'common/ViewAppend.js'
import Qualification from 'qualification/index.js'
import DistributionQualificationsForm from 'qualification/DistributionQualificationsForm.js'
import AnnotationForm from 'annotation/AnnotationForm.js'
import AnnotationCard from 'annotation/AnnotationCard.js'
import PriceCard from 'price/PriceCard.js'
import PriceForm from 'price/PriceForm.js'
import Payment from 'payment/index.js'
import VestingCard from 'vesting/VestingCard.js'
import VestingForm from 'vesting/VestingForm.js'
import EditVestingForm from 'vesting/EditVestingForm.js'
import InstancedAccountInformation from 'components/instancedAccountInformation/index.js'
import Account from 'components/account/index.js'
import CustomMappingField from 'customMappingField/index.js'
import AllocationLink from 'components/allocationLink/index.js'
import Summary from 'capitalization/Summary.js'
import Delv from 'delv/delv-react'
import Customer from 'customer/index.js'
import ImportAllocationForm from './ImportAllocationForm.js'

import Email from './Email.js'
import AddCustomersForm from './AddCustomersForm.js'
import AddInvestorForm from './AddInvestorForm.js'
import Buyer from './Buyer/index.js'
import FundraisingNav from './FundraisingNav.js'
import { EditDistribution, AllocationCapTable, GetCurrencies } from './queries.js'
import BulkStatusUpdateForm from './BulkStatusUpdateForm.js'
import BulkAnnotationForm from './BulkAnnotationForm.js'
import AllocationEditor from './AllocationEditor.js'
import './styles.scss'
import './Left.scss'

export default Delv.queryHOC(
    ({ account: { id: accountId }, userAccount: { role }, ...props }) => {
        const canEdit = role !== 'account_viewer' && accountId === props.allocationById.ownerId
        const allocationActive = props.allocationById.status === 'active'
        const allocationId = props.match.params.allocation_id
        const isOwner = accountId === props.allocationById.ownerId

        const allocation = <AllocationEditor canEdit={canEdit} accountId={accountId} allocationId={allocationId} vesting={props.vesting} />

        const distributionNotLocked = (multiSelect) => {
            const key = Object.keys(multiSelect.selected)
            const distribution = multiSelect.selected[key]
            return distribution.status !== 'locked'
        }

        const inviteFromContacts = (
            <MultiSelect>
                <Delv.query
                    query={Customer.fragment.nest('allCustomers').nest('query').toString()}
                    format={(data) => ({ orderByData: data.allCustomers.nodes })}
                    allocationId={allocationId}
                >
                    <OrderBy.order sort={(a, b) => a.accountByInvestorId.contactName.localeCompare(b.accountByInvestorId.contactName)}>
                        {canEdit ? <AddCustomersForm /> : <div>Operation not allowed</div>}
                        <Customer.orderByHeader />
                        <Div className='buyers-container'>
                            <Div className='order-by-inner'>
                                <Map arrayKey='_orderBy.data'>
                                    <Customer.row />
                                </Map>
                            </Div>
                        </Div>
                    </OrderBy.order>
                </Delv.query>
            </MultiSelect>
        )

        const invite = canEdit ? <AddInvestorForm allocationId={allocationId} /> : <div>Operation not allowed</div>

        const investors = (
            <Switch view={({ multiSelect }) => (multiSelect.length() <= 1 ? multiSelect.length() : 2)}>
                <>
                    <div className='none-found'> Select a distribution.</div>
                </>

                <Delv.query
                    key='investors'
                    query={(id) => InstancedAccountInformation.fragment
                        .nest('instancedAccountInformationsByAccountId')
                        .merge(Account.fragment)
                        .nest('accountById', { id })
                        .nest('query')
                        .toString()}
                    args={({ multiSelect }) => {
                        const id = multiSelect.getValues()[0].accountId
                        return id
                    }}
                    format={(data) => {
                        const { accountById } = data
                        const { instancedAccountInformationsByAccountId } = data.accountById

                        return {
                            accountById,
                            instancedAccountInformationsByAccountId,
                        }
                    }}
                >
                    <Div>
                        <Div className='row'>
                            <Account.investorAccount />
                        </Div>
                        <Div className='tombstone flex-1'>
                            <h3 className='header'>Onboarding Information</h3>
                            <InstancedAccountInformation.rowHeading />
                            <Map
                                arrayKey='instancedAccountInformationsByAccountId.nodes'
                                noneFound={<div className='none-found'>No questions answered</div>}
                            >
                                <InstancedAccountInformation.row prefix='vrtl-investor-row' />
                            </Map>
                        </Div>
                    </Div>
                </Delv.query>
                <>
                    <div className='none-found'>Select a single item; to operate on multiple items use Bulk operations.</div>
                </>
            </Switch>
        )

        const distribution = (
            <Switch view={({ multiSelect }) => (multiSelect.length() <= 1 ? multiSelect.length() : 2)}>
                <>
                    <div className='none-found'>Select a distribution.</div>
                </>
                <Delv.query key='edit-distribution' {...EditDistribution} allocationId={allocationId}>
                    <Switch view={({ incomplete }) => (incomplete ? 1 : 0)}>
                        <Div className='column flex-1'>
                            <Div className='row flex-1'>
                                <Div className='paddingbox flex-1'>
                                    <Div className='vrtl-distribution-editor__heading'>
                                        <div className='vrtl-distribution-editor__heading-text'>Required Qualifications</div>
                                    </Div>
                                    <Qualification.rowHeading>
                                        {canEdit && (
                                            <Popup.popup>
                                                <Popup.inner>
                                                    <DistributionQualificationsForm accountId={accountId} />
                                                </Popup.inner>
                                            </Popup.popup>
                                        )}
                                    </Qualification.rowHeading>
                                    <Map arrayKey='qualifications.nodes' noneFound={<div className='none-found'>No Qualifications</div>}>
                                        <NewEditSwitch.switch canEdit={canEdit}>
                                            <Qualification.row />
                                            <Qualification.form distribution />
                                        </NewEditSwitch.switch>
                                    </Map>
                                </Div>
                                <Div className='paddingbox flex-1'>
                                    <ViewAppend.container target canEdit={canEdit}>
                                        <Div className='vrtl-distribution-editor__heading'>
                                            <div className='vrtl-distribution-editor__heading-text'>Custom mapping fields</div>
                                            <CustomMappingField.help />
                                        </Div>

                                        <Delv.query
                                            query={({ distributionId }) => `{\n${Delv.nest('allDistributionCustomFields', CustomMappingField.fragment, {
                                                distributionId,
                                            })}}`}
                                        >
                                            <Div>
                                                <Switch view={({ multiSelect }) => (distributionNotLocked(multiSelect) ? 0 : 1)}>
                                                    <CustomMappingField.rowHeading>
                                                        <ViewAppend.icon append={<CustomMappingField.form />}>
                                                            <AddCircle />
                                                        </ViewAppend.icon>
                                                    </CustomMappingField.rowHeading>
                                                    <CustomMappingField.rowHeading />
                                                </Switch>
                                                <ViewAppend.target />
                                                <Map
                                                    arrayKey='allDistributionCustomFields.nodes'
                                                    noneFound={<div className='none-found'>No custom mapping fields</div>}
                                                >
                                                    <NewEditSwitch.switch canEdit={({ multiSelect }) => distributionNotLocked(multiSelect)}>
                                                        <CustomMappingField.row />
                                                        <CustomMappingField.form isEdit />
                                                    </NewEditSwitch.switch>
                                                </Map>
                                            </Div>
                                        </Delv.query>
                                    </ViewAppend.container>
                                </Div>
                            </Div>
                            <Div className='row flex-1'>
                                <Div className='paddingbox flex-1'>
                                    <Div className='vrtl-distribution-editor__heading'>
                                        <div className='vrtl-distribution-editor__heading-text'>Price</div>
                                        {canEdit ? (
                                            <Switch view={({ multiSelect }) => (distributionNotLocked(multiSelect) ? 0 : 1)}>
                                                <Delv.query {...GetCurrencies}>
                                                    <Popup.popup>
                                                        <Popup.inner>
                                                            <PriceForm />
                                                        </Popup.inner>
                                                        <Popup.trigger className='vrtl-distribution-editor__heading-action'>
                                                            <AddCircle className='vrtl-distribution-editor__add' />
                                                        </Popup.trigger>
                                                    </Popup.popup>
                                                </Delv.query>
                                                <></>
                                            </Switch>
                                        ) : (
                                            <></>
                                        )}
                                    </Div>
                                    <Map arrayKey='prices.nodes' noneFound={<div className='none-found'>No Price</div>}>
                                        <PriceCard />
                                    </Map>
                                </Div>
                                <Div className='paddingbox flex-1'>
                                    <ViewAppend.container canEdit={canEdit}>
                                        <Div className='vrtl-distribution-editor__heading'>
                                            <div className='vrtl-distribution-editor__heading-text'>Payments</div>
                                        </Div>
                                        <Payment.rowHeading>
                                            <ViewAppend.icon append={<Payment.form />}>
                                                <AddCircle />
                                            </ViewAppend.icon>
                                        </Payment.rowHeading>
                                        {isOwner ? (
                                            <Map arrayKey='payments.nodes' noneFound={<div className='none-found'>No Payments</div>}>
                                                <NewEditSwitch.switch canEdit={canEdit}>
                                                    <Payment.row />
                                                    <Payment.form />
                                                </NewEditSwitch.switch>
                                            </Map>
                                        ) : (
                                            <div className='none-found'>No Payments</div>
                                        )}
                                    </ViewAppend.container>
                                </Div>
                            </Div>
                            <Div className='paddingbox flex-1'>
                                <Switch view={({ vesting }) => (vesting ? 1 : 0)}>
                                    <Div>
                                        <Div className='vrtl-distribution-editor__heading'>
                                            <div className='vrtl-distribution-editor__heading-text'>Vesting Schedule</div>
                                            {canEdit ? (
                                                <Popup.popup>
                                                    <Popup.inner>
                                                        <VestingForm type='distribution' />
                                                    </Popup.inner>
                                                    <Popup.trigger className='vrtl-distribution-editor__heading-action'>
                                                        <AddCircle className='vrtl-distribution-editor__add' />
                                                    </Popup.trigger>
                                                </Popup.popup>
                                            ) : (
                                                <></>
                                            )}
                                        </Div>
                                        <div className='none-found'>No Vesting Schedule</div>
                                    </Div>
                                    <VestingCard>
                                        {canEdit ? (
                                            <Popup.popup>
                                                <Popup.inner>
                                                    <EditVestingForm type='distribution' />
                                                </Popup.inner>
                                                <Popup.trigger addClassName='edit-vesting-trigger'>Edit</Popup.trigger>
                                            </Popup.popup>
                                        ) : (
                                            <div />
                                        )}
                                    </VestingCard>
                                </Switch>
                            </Div>
                            <Div className='paddingbox flex-1'>
                                <Div className='vrtl-distribution-editor__heading'>
                                    <div className='vrtl-distribution-editor__heading-text'>Distribution Notes</div>
                                    {canEdit ? (
                                        <Popup.popup>
                                            <Popup.inner>
                                                <AnnotationForm type='distribution' />
                                            </Popup.inner>
                                            <Popup.trigger className='vrtl-distribution-editor__heading-action'>
                                                <AddCircle className='vrtl-distribution-editor__add' />
                                            </Popup.trigger>
                                        </Popup.popup>
                                    ) : (
                                        <></>
                                    )}
                                </Div>
                                <Map arrayKey='annotations.nodes' noneFound={<div className='none-found'>No Notes</div>}>
                                    <AnnotationCard />
                                </Map>
                            </Div>
                        </Div>
                        <>
                            This investment does not have a complete record, this often happens when a cap table is imported from the
                            blockchain.
                        </>
                    </Switch>
                </Delv.query>
                <>
                    <div className='none-found'>Select a single item; to operate on multiple items use Bulk operations.</div>
                </>
            </Switch>
        )

        const bulkUpdateStatus = canEdit ? <BulkStatusUpdateForm /> : <div>Bulk operations cannot be performed by account viewers.</div>

        const bulkAnnotate = canEdit ? <BulkAnnotationForm /> : <div>Bulk operations cannot be performed by account viewers.</div>

        const offerWall = [
            <h2 className='buyer-form-header'>Agent Links</h2>,
            <ViewAppend.container target canEdit={canEdit && allocationActive}>
                <AllocationLink.rowHeading>
                    <ViewAppend.icon append={<AllocationLink.form allocationId={allocationId} />}>
                        <AddCircle />
                    </ViewAppend.icon>
                </AllocationLink.rowHeading>
                <ViewAppend.target />
                <Delv.query query={AllocationLink.fragment.nest('allAllocationOfferingLinks', { allocationId }).nest('query').toString()}>
                    <Map arrayKey='allAllocationOfferingLinks.nodes'>
                        <NewEditSwitch.switch canEdit={canEdit}>
                            <AllocationLink.row />
                            <AllocationLink.form />
                        </NewEditSwitch.switch>
                    </Map>
                </Delv.query>
            </ViewAppend.container>,
        ]

        const emails = <Email allocationId={allocationId} canEdit={canEdit} />

        const tabs = {
            allocation: {
                title: 'Allocation',
                element: allocation,
            },
            invite: {
                title: 'Invitation',
                element: invite,
            },
            investors: {
                title: 'Investors',
                element: investors,
            },
            distribution: {
                title: 'Distribution',
                element: distribution,
            },
            'bulk-invite': {
                title: 'Bulk Invite',
                element: inviteFromContacts,
            },
            'bulk-update': {
                title: 'Bulk Update',
                element: bulkUpdateStatus,
            },
            'bulk-annotate': {
                title: 'Bulk Annotate',
                element: bulkAnnotate,
            },
            links: {
                title: 'Links',
                element: offerWall,
            },
            email: {
                title: 'Emails',
                element: emails,
            },
        }

        const view = props.match.params.view

        const allocationData = props.allocationById
        const klass = allocationData.roundByRoundId
        const asset = klass.assetByAssetId

        const BREADCRUMBS = [
            {
                display: 'Assets',
                link: '/assets',
            },
            {
                display: asset.name,
                link: '/assets',
            },
            {
                display: klass.name,
                link: `/asset-class/${klass.id}/manage`,
            },
            {
                display: allocationData.name,
                link: '#',
                active: true,
            },
        ]

        return (
            <>
                <Nav />
                <Container>
                    <Breadcrumbs items={BREADCRUMBS} />
                    <FundraisingNav tabs={tabs} match={props.match} />
                    <MultiSelect>
                        <Div className='distribute-allocation'>
                            <Div className='distribute-allocation-left'>
                                <Div className='row flex-1'>
                                    <Div className='flex-1 column'>
                                        <Div className='distribute-allocation-left-main'>
                                            {tabs[view] ? tabs[view].element : tabs.allocation.element}
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>

                            <Div className='distribute-allocation-right'>
                                <Delv.query query={AllocationCapTable.query(allocationId)} format={AllocationCapTable.format}>
                                    <Div className='column flex-1'>
                                        <Summary>
                                            {canEdit && <ImportAllocationForm allocationId={allocationId} />}
                                        </Summary>
                                        <OrderBy.order dropdownFields={['status']}>
                                            <Buyer.orderByHeader />
                                            <Div className='buyers-container'>
                                                <Div className='order-by-inner'>
                                                    <Map arrayKey='_orderBy.data'>
                                                        <NewEditSwitch.switch canEdit={canEdit}>
                                                            <Buyer.row>{isOwner ? <Buyer.qualifications /> : null}</Buyer.row>
                                                            <Buyer.form />
                                                        </NewEditSwitch.switch>
                                                    </Map>
                                                </Div>
                                            </Div>
                                        </OrderBy.order>
                                    </Div>
                                </Delv.query>
                            </Div>
                        </Div>
                    </MultiSelect>
                </Container>
                <Footer />
            </>
        )
    },
    {
        query: (allocationId) => `{
          allocationById(id:"${allocationId}"){
            id
            name
            status
            ownerId
            roundByRoundId{
              id
              name
              assetByAssetId{
                id
                name
              }
          }
          }
      }`,
        args: (props) => props.match.params.allocation_id,
    },
)

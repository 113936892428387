import numeral from 'numeral'
import moment from 'moment'
import Decimal from 'decimal.js'

const date = (d, format) => moment(d).format(format || 'MMMM Do YYYY')

const integer = (num, value) => (value ? numeral(num).value() : numeral(num).format('0,0'))

const number = (num, value) => (value ? numeral(num).value() : numeral(num).format('0,0.00[00000]'))

const numberFixed = (num, value) => (value ? numeral(num).value() : numeral(num).format('0,0.00'))

const numeric = (num, value) => number(num, value)

const percent = (one, two) => numeral(one / two).format('0.00[0]%')

const DOMEvent = (name, value) => ({ target: { name, value } })

const remToPx = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize)

const scaledPercent = (value, scale) => {
    /*
        Description:
            This function exposes a safe way to scale a value without rounding
            prematurely. For front end use only.

        Usage:
            scaledPercent(.50, 100) => '50.00%'
            scaledPercent(.38, 100) => '38.00%'
    */
    if (typeof scale !== 'number') {
        throw new Error('Scale must be an integer value.')
    }

    return numeral(Decimal.mul(scale, value)).format('0.00[0]%')
}

const truncate = (string) => `${string.substring(0, 20)}...`

const blockchainAddress = (address) => truncate(address, 15)

const ifString = (func, ...args) => {
    if (typeof args[0] === 'string') {
        if (func.length === 1) {
            return func(args[0])
        } if (func.length === 2) {
            return func(args[0], args[1])
        } if (func.length === 3) {
            return func(args[0], args[1], args[2])
        }
    }

    return args[0]
}

export default {
    numeric,
    integer,
    number,
    numberFixed,
    date,
    percent,
    DOMEvent,
    blockchainAddress,
    remToPx,
    truncate,
    ifString,
    scaledPercent,
}

import React, { useState, useRef } from 'react'
import Mutation from 'delv/Mutation'
import CancelIcon from '@mui/icons-material/Cancel'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'
import axios from 'axios'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LinearProgress from 'common/LinearProgress/index.js'
import Grid from 'common/Grid/index.js'

import './Upload.scss'

const MAKE_FILE_LOCATION = `mutation($name: String!){
    makeFileLocation(fileName: $name)
}`

const GET_FILE_LOCATION = (file) => new Promise((resolve, reject) => {
    new Mutation({
        mutation: MAKE_FILE_LOCATION,
        onSubmit: () => ({ name: file.name }),
        onResolve: (res) => resolve(res.makeFileLocation),
        onError: reject,
    }).submit()
})

const Upload = ({
    children,
    multi,
    uploadUrl = 'api/v2/graphql',
    ...props
}) => {
    const [files, setFiles] = useState([])
    const addFile = (addFiles) => {
        const file = addFiles[0]
        if (!file) {
            return
        }
        const lastModified = file.lastModified
        const name = file.name
        if (multi) {
            setFiles([
                {
                    file,
                    name,
                    id: name + lastModified,
                },
                ...addFiles,
            ])
        } else {
            setFiles([
                {
                    file,
                    name,
                    id: name + lastModified,
                },
            ])
        }
    }

    const removeFile = (id) => {
        setFiles(files.filter((file) => file.id !== id))
    }

    const clearFiles = () => setFiles([])

    const upload = {
        clearFiles,
        addFile,
        removeFile,
        uploadUrl,
        files,
    }

    return SafeReact.cloneChildren(children, { ...upload, ...props })
}

const Dropzone = ({
    addFile, extensions, children, ...props
}) => {
    const ref = useRef(null)
    const [drag, setDrag] = useState(false)

    const openFileDialog = () => ref.current.click()

    const onDragOver = (event) => {
        event.preventDefault()
        if (!drag) {
            setDrag(true)
        }
    }

    const onDragLeave = (event) => {
        event.preventDefault()
        if (drag) {
            setDrag(false)
        }
    }

    const onDrop = (event) => {
        const files = event.dataTransfer.files
        addFile(files)
        onDragLeave(event)
    }

    const handleChange = (event) => {
        const files = event.target.files
        addFile(files)
        // eslint-disable-next-line
        event.target.value = null
    }

    const dropZoneDisplay = children ? SafeReact.cloneChildren(children, props) : <CloudUploadIcon className='vrtl-upload__upload-icon' />
    return (
        <div
            className={cx('vrtl-upload__dropzone', { 'vrtl-upload__dropdown-hover': drag })}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={openFileDialog}
        >
            <input
                ref={ref}
                className='vrtl-upload__hacky-file-input'
                type='file'
                accept={extensions}
                onChange={handleChange}
            />
            {dropZoneDisplay}
            <div className='vrtl-upload__dropzone--text'>{`Supports: ${extensions.replace(/(,|\.)/g, ' ').toUpperCase()}`}</div>
            <div className='vrtl-upload__dropzone--text'>Size Limit: 100MB</div>

        </div>
    )
}

const Files = ({
    className = 'vrtl-upload__container--files',
    files,
    removeFile,
    children,
}) => (
    <div className={cx(className)}>
        {files.map((file, index) => SafeReact.cloneChildren(children, {
            key: index,
            ...file,
            removeFile,
        }))}
    </div>
)

const LoadingBar = ({ loading }) => {
    if (loading) {
        return <LinearProgress />
    }
    return (
        <div className='vrtl-upload__progress-bar'>
            <div className='vrtl-upload__progress' style={{ width: '100%' }} />
        </div>
    )
}

const File = ({
    name,
    id,
    removeFile,
    loading,
}) => (
    <Grid spacing={0.5} sx={{ alignItems: 'center' }} container>
        <Grid item>
            {name}
        </Grid>
        <Grid xs item>
            <LoadingBar loading={loading} />
        </Grid>
        <Grid item>
            <CancelIcon sx={{ cursor: 'pointer' }} color='error' onClick={() => removeFile(id)} />
        </Grid>
    </Grid>
)

const UploadButton = ({
    clearFiles,
    files,
    onClick = GET_FILE_LOCATION,
    onUploadFinish,
    urlType,
    handler,
    children,
}) => {
    const loadFiles = () => {
        const file = files[0]
        if (file) {
            handler.setLoading()
            handler.clearError()
            if (urlType === 'signedUrl') {
                // We let the webhook handle errors for SURL uploads, so we just drop the modal in all cases
                onClick(file)
                    .then(({ fields: additionalHeaders, url: uploadUrl }) => {
                        const formData = new FormData()

                        // AWS signed URL protocol requires we pass headers into formData, not on the
                        // headers key of request object, and append the actual file as the last element.
                        Object.keys(additionalHeaders).forEach((key) => {
                            formData.append(key, additionalHeaders[key])
                        })
                        formData.append('file', file.file)
                        return axios.post(uploadUrl, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                    })
                    .finally(() => {
                        clearFiles()
                        handler.clearLoading()
                        handler.closeModal()
                    })
            } else {
                onClick(file)
                    .then((uploadUrl) => {
                        const formData = new FormData()
                        formData.append('file', file.file, file.name)

                        return axios.post(uploadUrl, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                            .catch((error) => {
                                if (error.response.status === 413) {
                                    handler.setError('Error: File is larger than 100MB')
                                } else {
                                    handler.setError('Unknown error occurred.')
                                }
                            })
                    })
                    .catch(() => false)
                    .then((res) => {
                        if (res) {
                            if (onUploadFinish) {
                                onUploadFinish(res)
                                    .catch((error) => {
                                        handler.setError(error[0].message)
                                        return false
                                    })
                                    .then((response) => response && handler.setSuccess())
                            } else {
                                handler.setSuccess()
                            }
                        }
                    })
                    .finally(() => {
                        handler.clearLoading()
                        clearFiles()
                    })
            }
        }
    }

    return SafeReact.cloneChildren(children, { onClick: loadFiles })
}

export default {
    upload: Upload,
    dropzone: Dropzone,
    files: Files,
    file: File,
    button: UploadButton,
}

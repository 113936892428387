import React from 'react'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

export default (props) => {
    const prefix = 'vrtl-issuer-card'

    return (
        <OrderBy.bar addClassName={`${prefix}--margin`} {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                Issuer
            </OrderBy.filter>
        </OrderBy.bar>
    )
}

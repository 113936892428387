import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form.js'
import { Input, Date as DateInput, Dropdown } from 'common/FormUI.js'

const CREATE_CAPITAL_DEPLOYMENT = `
    mutation ($capitalDeploymentInput: CreateCapitalDeploymentInput!) {
        createCapitalDeployment(input: $capitalDeploymentInput) {
            capitalDeployment {
                id
                paidOn
                amount
                note
                currencyByCurrency {
                    id
                    code
                }
                roundByRoundId {
                    id
                }
            }
        }
    }
`

const formConfig = {
    fields: {
        paidOn: {
            type: 'date',
            default: () => new Date(),
            error: 'Paid On is a required field.',
        },

        amount: {
            type: 'number',
            error: 'Amount is a required field.',
        },

        note: {
            type: 'string',
            error: 'Note is a required field.',
        },

        currency: {
            type: 'dropdown',
            default: 1,
        },
    },
}

const CapitalDeploymentForm = Form(
    ({
        roundId, popup, form: {
            paidOn, amount, note, currency, validateInput,
        },
    }) => {
        const handleSubmit = () => {
            const input = validateInput()

            if (!input.hasErrors) {
                const {
                    currency: unusedCurrency,
                    ...filteredInput
                } = input
                const returnVal = {
                    capitalDeploymentInput: {
                        capitalDeployment: {
                            ...filteredInput,
                            roundId,
                        },
                    },
                }

                new Mutation({
                    mutation: CREATE_CAPITAL_DEPLOYMENT,
                    onSubmit: () => returnVal,
                    onResolve: () => popup.close(),
                }).submit()
            }
        }

        return (
            <form className='vrtl-round-allocation__add-form'>
                <h2 className='qualifications-form-title'>Create Deployment</h2>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>PAID ON</div>
                        <div className='asset-form-input-container'>
                            <DateInput {...paidOn} />
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>AMOUNT</div>
                        <div className='asset-form-input-container'>
                            <Input {...amount} />
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>NOTE</div>
                        <div className='asset-form-input-container'>
                            <Input {...note} />
                        </div>
                    </div>
                </div>

                <div className='card'>
                    <div className='card-section'>
                        <div className='card-label'>CURRENCY</div>
                        <div className='asset-form-input-container'>
                            <Dropdown
                                options={[{ currency: 'USD', value: 1 }]}
                                width='5rem'
                                {...currency}
                            />
                        </div>
                    </div>
                </div>

                <div className='card row--justify-between'>
                    <div className='styled-button' onClick={popup.close}>
                        Cancel
                    </div>
                    <div className='styled-button' onClick={handleSubmit}>
                        Submit
                    </div>
                </div>
            </form>
        )
    },
    formConfig,
)

export default CapitalDeploymentForm

import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import NewEditSwitch from 'common/NewEditSwitch.js'
import SecurityDocument from 'components/securityDocument/index.js'
import Div from 'common/Div.js'
import Map from 'common/Map.js'
import Document from 'components/document/index.js'
import Account from 'components/account/index.js'
import Delv from 'delv/delv-react'
import OrderBy from 'common/OrderBy.js'
import Security from 'components/security/index.js'
import { canEditDocument } from 'util/permissions.js'
import Round from 'components/round/index.js'
import Asset from 'components/asset/index.js'
import NoneFound from '../common/NoneFound/index.js'

import './styles.scss'

const SecurityBreadcrumbs = ({ name }) => {
    const BREADCRUMBS = [
        {
            display: 'Securities',
            link: '/securities',
        },
        {
            active: true,
            display: name,
            link: '#',
        },
    ]
    return <Breadcrumbs items={BREADCRUMBS} />
}

const SecurityPage = ({
    prefix = 'vrtl-security-entity',
    ...props
}) => {
    const GET_SECURITY = {
        query: (id) => `{
            securityById(id: "${id}") {
              ${Security.fragment.toString()}
              accountByIssuerId {
                  ${Account.fragment.toString()}
              }
              roundsBySecurityId {
                  nodes{
                      ${Round.fragment.toString()}
                      assetByAssetId {
                        ${Asset.fragment.toString()}
                      }
                  }
              }
              securityDocumentsBySecurityId {
                nodes {
                  ${SecurityDocument.fragment.toString()}
                  documentByDocumentId {
                    ${Document.fragment.toString()}
                    documentSharingsByDocumentId {
                        nodes{
                            ${Document.sharing.fragment.toString()}
                        }
                    }
                  }
                }
              }
            }
          }`,
        args: (properties) => properties.match.params.security_id,
        format: ({
            securityById: {
                securityDocumentsBySecurityId,
                ...security
            },
        }) => {
            const inbound = []
            const outbound = []
            securityDocumentsBySecurityId.nodes.forEach((doc) => {
                doc.name = doc.documentByDocumentId.name
                doc.createdOn = doc.documentByDocumentId.createdOn
                if (doc.documentByDocumentId.uploadedBy === props.account.id) {
                    outbound.push(doc)
                } else {
                    inbound.push(doc)
                }
            })
            return {
                ...security, orderByData: { outbound, inbound },
            }
        },
    }

    return (
        <>
            <Nav />
            <Container>
                <Delv.query {...props} {...GET_SECURITY}>
                    <Div className={`${prefix}__container`}>
                        <SecurityBreadcrumbs />
                        <Div className={`${prefix}__card`}>
                            <Security.card entityPage />
                        </Div>
                        <Div className='row'>
                            <Div className={`${prefix}__container--table`}>
                                <div className={`${prefix}__header`}>Documents Shared With Me</div>
                                <Div className={`${prefix}__table`}>
                                    <OrderBy.order>
                                        <Document.orderByheader sharedVia>
                                            <div className={`${prefix}__icon-offset`} />
                                        </Document.orderByheader>
                                        <Map
                                            arrayKey='_orderBy.inbound'
                                            noneFound={<NoneFound>No documents found</NoneFound>}
                                            {...props}
                                        >
                                            <NewEditSwitch.switch
                                                editIcon
                                                canEdit={(properties) => canEditDocument(properties)}
                                                editClassOverride='no-margin'
                                            >
                                                <SecurityDocument.row />
                                                <SecurityDocument.form />
                                            </NewEditSwitch.switch>
                                        </Map>
                                    </OrderBy.order>
                                </Div>
                            </Div>
                            <Div className={`${prefix}__container--table`}>
                                <div className={`${prefix}__header`}>Documents Shared By Me</div>
                                <Div className={`${prefix}__table`}>
                                    <OrderBy.order>
                                        <Document.orderByheader>
                                            <SecurityDocument.form />
                                        </Document.orderByheader>
                                        <Map
                                            arrayKey='_orderBy.outbound'
                                            noneFound={<NoneFound>No documents found</NoneFound>}
                                            {...props}
                                        >
                                            <NewEditSwitch.switch
                                                editIcon
                                                canEdit={(properties) => canEditDocument(properties)}
                                                className='no-margin'
                                            >
                                                <SecurityDocument.row />
                                                <SecurityDocument.form />
                                            </NewEditSwitch.switch>
                                        </Map>
                                    </OrderBy.order>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Delv.query>
            </Container>
            <Footer />
        </>
    )
}

export default SecurityPage

import React from 'react'
import Map from 'common/Map.js'
import Delv from 'delv/delv-react.js'
import Account from 'components/account/index.js'
import Security from 'components/security/index.js'
import Div from 'common/Div.js'
import Orderby from 'common/OrderBy.js'
import NoneFound from 'common/NoneFound/index.js'
import OrderByHeader from './OrderByHeader.js'

import './styles.scss'

const SecuritiesPage = ({
    prefix = 'vrtl-securities-page',
    ...props
}) => {
    const ATS_VIEW = {
        query: `{
            allSecurities{
              nodes{
                ${Security.fragment.toString()}
                accountByIssuerId {
                    ${Account.fragment.toString()}
                }
              }
            }
          }`,
        format: ({ allSecurities: nodes }) => ({ orderByData: nodes }),
    }
    return (
        <Delv.query {...props} {...ATS_VIEW}>
            <Orderby.order>
                <OrderByHeader />
                <Div className={`${prefix}__table`}>
                    <Security.rowHeading />
                    <Map arrayKey='_orderBy.nodes' noneFound={<NoneFound>No securities found.</NoneFound>}>
                        <Security.row />
                    </Map>
                </Div>
            </Orderby.order>
        </Delv.query>
    )
}

export default SecuritiesPage

import React from 'react'
import { Link } from 'react-router-dom'

import Nav from 'common/Nav'
import Footer from 'common/Footer'
import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'

import Template from './Template.js'
import Templates from './Templates.js'
import MappedTemplates from './MappedTemplates.js'
import Document from './Document.js'
import SendTestTemplate from './SendTestTemplate.js'

const Signing = (props) => {
    const [blank, base, sub, ...other] = props.match.url.split('/')
    let backLink
    if (sub === 'templates') {
        other.pop()
        backLink = [blank, base, sub, ...other].join('/')
    } else if (sub === 'test') {
        backLink = [blank, base, 'templates', ...other].join('/')
    }
    const issuerId = props.match.params.issuer_id
    const instrumentId = props.match.params.instrument_id

    const BREADCRUMBS = [
        {
            active: true,
            display: 'North Capital',
            link: '#',
        },
    ]

    return (
        <>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                <div className='vrtl-signing-templates__page'>
                    <div className='vrtl-signing-templates__left'>
                        <div className='vrtl-signing-templates__left-body'>
                            <div className='order-by-inner'>
                                { sub === 'issuers' && <Templates.left {...props} /> }
                                { issuerId && <Template.left {...props} />}
                                { instrumentId && <MappedTemplates.left {...props} canEdit />}
                            </div>
                        </div>
                        {props.match.params.template_id && <Link className='vrtl-signing-template__back' to={backLink}>Back</Link>}
                    </div>
                    <div className='vrtl-signing-templates__right'>
                        {
                            sub === 'issuers'
                                ? props.match.params.payment_id ? <Document.right {...props} />
                                    : props.match.params.instrument_id ? <MappedTemplates.right {...props} />
                                        : props.match.params.issuer_id ? <Template.right {...props} />
                                            : <Templates.right {...props} />
                                : <SendTestTemplate {...props} />
                        }
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    )
}

export default Signing

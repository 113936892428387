import React from 'react'
import cx from 'classnames'
import MUIChip from '@mui/material/Chip'

import SafeReact from 'util/SafeReact'
import iconMap from 'common/Icons/index.js'

import './styles.scss'

const Chip = ({
    mui,
    ...params
}) => {
    if (mui) {
        return <MUIChip {...params} />
    }

    const {
        onClick,
        isSelected = false,
        className,
        children,
        color,
        prefix = 'vtlo-chip',
        shape,
        shapeOverride,
        ...props
    } = params

    return (
        <div className={cx(`${prefix}__chip`, `${prefix}__chip--${color}`)} onClick={onClick}>
            <div className={`${prefix}__shape_container`}>
                {SafeReact.cloneChildren(iconMap(shape, shapeOverride), { ...props, isSelected })}
            </div>
            <div className={cx(`${prefix}__label`, `${prefix}__label--${color}`)}>
                {SafeReact.cloneChildren(children, props)}
            </div>
        </div>
    )
}

export default Chip

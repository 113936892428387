import { Decimal } from 'decimal.js'

import {
    selectInvestmentRound,
    selectInvestmentStatus,
    selectInvestmentTokenization,
    selectInvestmentPurchasePrice,
    selectInvestmentInitial,
    selectInvestmentPercentPaid,
    selectLatestPrice,
    selectInvestmentValue,
    selectInvestmentPaidInCapital,
    selectInvestmentCapitalDeployments,
    selectInvestmentActiveQualificationInfo,
} from 'selectors/investment-selectors'
import {
    selectAssetType,
    selectAssetCategory,
} from 'selectors/asset-selectors'

const compareSubColumns = ({ oldValue, newValue }) => {
    if (oldValue === undefined) {
        return newValue
    }

    return oldValue === newValue ? oldValue : null
}

const format = (res) => {
    const assetMap = {}
    const investmentMap = {}
    const investments = [...(res.allDistributions?.nodes || []), ...(res.allHoldings?.nodes || [])]
    const USDID = res.allCurrencies.nodes[0].id

    investments.forEach((investment) => {
        const round = selectInvestmentRound({ investment })
        if (!round) {
            return
        }

        const trancheTerm = round.trancheBondDatumByRoundId?.term
        const trancheCoupon = round.trancheBondDatumByRoundId?.coupon

        const investmentStatus = selectInvestmentStatus({ investment })
        const investmentPurchasePrice = selectInvestmentPurchasePrice({ investment })
        const investmentInitial = selectInvestmentInitial({ investment })
        const investmentValue = selectInvestmentValue({ investment, USDID })
        const investmentPaidInCapital = selectInvestmentPaidInCapital({ investment })
        const investmentComittedCapital = investmentInitial
        const investmentPercentPaid = selectInvestmentPercentPaid({ investment })
        const investmentLatestPrice = selectLatestPrice({ investment, USDID })
        const investmentTokenized = selectInvestmentTokenization({ investment })
        const {
            deployed: investmentDeployedCapital,
            undeployed: investmentUndeployedCapital,
        } = selectInvestmentCapitalDeployments({ investment })

        const asset = round.assetByAssetId
        const assetType = selectAssetType({ asset })
        const assetCategory = selectAssetCategory({ asset })
        const assetStatus = compareSubColumns({ oldValue: assetMap[asset.id]?.status, newValue: investmentStatus })
        const assetTokenization = compareSubColumns({ oldValue: assetMap[asset.id]?.tokenized, newValue: investmentTokenized })

        const { qualificationActivityDisplay, qualificationName } = selectInvestmentActiveQualificationInfo({ investment })

        investmentMap[investment.id] = {
            id: investment.id,
            round: round.name,
            assetId: asset.id,
            tokenized: investmentTokenized,
            units: investment.amount,
            termsUrl: round.termsUrl,
            status: investmentStatus,
            purchasePrice: investmentPurchasePrice,
            investment: investmentInitial,
            value: investmentValue,
            committedCapital: investmentComittedCapital,
            paidInCapital: investmentPaidInCapital,
            term: trancheTerm,
            coupon: trancheCoupon,
            latestPrice: investmentLatestPrice,
            paidOutDividends: 0,
            percentPaid: investmentPercentPaid,
            deployedCapital: investmentDeployedCapital,
            undeployedCapital: investmentUndeployedCapital,
            progress: qualificationActivityDisplay,
            nextStep: qualificationName,
            __typename: investment.__typename,
        }

        assetMap[asset.id] = {
            id: asset?.id,
            type: assetType,
            category: assetCategory,
            asset: asset.name,
            issuer: asset.accountByIssuerId.name,
            issuerSupportEmail: asset.accountByIssuerId.supportEmail,
            round: assetMap[asset.id]?.round + 1 || 1,
            tokenized: assetTokenization,
            status: assetStatus,
            units: new Decimal(assetMap[asset.id]?.units || 0).add(investment.amount).toString(),
            purchasePrice: new Decimal(assetMap[asset.id]?.purchasePrice || 0).add(investmentPurchasePrice || 0).toString(),
            investment: new Decimal(assetMap[asset.id]?.investment || 0).add(investmentInitial || 0).toString(),
            value: new Decimal(assetMap[asset.id]?.value || 0).add(investmentValue || 0).toString(),
            committedCapital: new Decimal(assetMap[asset.id]?.committedCapital || 0).add(investmentComittedCapital || 0).toString(),
            paidInCapital: new Decimal(assetMap[asset.id]?.paidInCapital || 0).add(investmentPaidInCapital || 0).toString(),
            deployedCapital: investmentDeployedCapital
                ? new Decimal(assetMap[asset.id]?.deployedCapital || 0).add(investmentDeployedCapital).toString()
                : assetMap[asset.id]?.deployedCapital,
            undeployedCapital: investmentUndeployedCapital
                ? new Decimal(assetMap[asset.id]?.undeployedCapital || 0).add(investmentUndeployedCapital).toString()
                : assetMap[asset.id]?.undeployedCapital,
            paidOutDividends: 0,
        }
    })

    const nestedRows = Object.values(investmentMap)
    const rows = Object.values(assetMap).map((asset) => {
        const roundsByAsset = nestedRows.filter(({ assetId }) => assetId === asset.id)
        const purchasePrice = asset.purchasePrice && new Decimal(asset.purchasePrice).dividedBy(roundsByAsset.length).toString()

        return ({
            ...asset,
            purchasePrice,
            rows: roundsByAsset,
        })
    })

    return { rows }
}

export default format

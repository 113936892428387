import React from 'react'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import { Link } from 'react-router-dom'

export default ({ text, label, url = '/' }) => (
    <Link className='dashboard-header-item' to={url}>
        <div className='flex-1' />
        <div className='flex-10'>
            <div className='dashboard-header-text'>{text}</div>
            <div className='dashboard-label'>{label}</div>
        </div>
        <div className='dashboard-header-arrow'>
            <RightArrow />
        </div>
    </Link>
)

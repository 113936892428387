import * as React from 'react'
import { Decimal } from 'decimal.js'

import Box from 'common/Box'
import Breadcrumbs from 'common/Breadcrumbs'
import CircularProgress from 'common/CircularProgress'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Grid from 'common/GridV2'
import Nav from 'common/Nav'
import { PageNoneFound } from 'common/NoneFound'
import { TOKENS_QUERY } from 'components/token/queries'
import TokenCard from 'components/token/card'
import useQuery from 'delv/hooks/use-query'
import Format from 'util/format/Format.js'

const formatTokens = ({ allTokens }) => {
    const notHawknetOrZeroAmount = ({ amount, blockchainAddressByBlockchainAddressId: blockchainAddress }) => (
        !(blockchainAddress.chain === 'vertalon-hawknet' || !new Decimal(amount).isPos())
    )

    const sumCapTableAmount = (capTables, precision) => {
        const addAmount = (accum, { amount }) => accum.add(Format.numeric(amount, precision, true))
        return capTables.reduce(addAmount, new Decimal(0))
    }

    const unnestBlockNumber = ({ blockchainAddressByBlockchainAddressId }) => ({
        ...blockchainAddressByBlockchainAddressId,
        blockNumber: blockchainAddressByBlockchainAddressId.ledgersByBlockchainAddressId.nodes[0]?.transactionByTransactionId?.chainData?.block_number,
    })

    const filteredTokens = allTokens.nodes.reduce((accum, currentToken) => {
        const {
            contractsByTokenId,
            capTablesByTokenId,
            roundByTokenId,
        } = currentToken

        const activeBlockchainAddresses = capTablesByTokenId.nodes.filter(notHawknetOrZeroAmount).map(unnestBlockNumber)

        if (!activeBlockchainAddresses.length) {
            return accum
        }

        const tokenData = {
            ...currentToken,
            activeBlockchainAddresses,
            chain: activeBlockchainAddresses[0].chain,
            contractAddress: contractsByTokenId.nodes[0].contractAddress,
            issuer: roundByTokenId?.assetByAssetId.accountByIssuerId.name,
            total: sumCapTableAmount(capTablesByTokenId.nodes, currentToken.precision),
        }

        accum.push(tokenData)

        return accum
    }, [])

    return filteredTokens
}

const Layout = ({ children }) => (
    <>
        <Nav />
        <Container>
            {children}
        </Container>
        <Footer />
    </>
)

const Wallet = () => {
    const { data: tokens, loading } = useQuery(TOKENS_QUERY, {
        args: '(filter: { status: { equalTo: "deployed" }, capTablesByTokenIdExist: true })',
        format: formatTokens,
    })

    const BREADCRUMBS = [
        {
            display: 'Wallet',
            link: '/wallet',
            active: true,
        },
    ]

    if (loading) {
        return (
            <Layout>
                <Breadcrumbs items={BREADCRUMBS} />
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, marginBottom: 3,
                }}
                >
                    <CircularProgress size={64} />
                </Box>
            </Layout>
        )
    }

    if (tokens?.length) {
        return (
            <Layout>
                <Breadcrumbs items={BREADCRUMBS} />
                <Grid container spacing={4}>
                    {tokens.map((token) => (
                        <Grid key={token.id} item lg={3} md={4} sm={6} xs={12} sx={{ width: '100%' }}>
                            <TokenCard token={token} />
                        </Grid>
                    ))}
                </Grid>
            </Layout>
        )
    }

    return (
        <Layout>
            <Breadcrumbs items={BREADCRUMBS} />
            <PageNoneFound>No tokens found in your wallet.</PageNoneFound>
        </Layout>
    )
}

export default Wallet

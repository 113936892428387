import React from 'react'
import { grey } from '@mui/material/colors'
import { DataGridPro } from '@mui/x-data-grid-pro'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import { GridNoneFound } from 'common/NoneFound'
import {
    getColumnSize,
    removeReactFromColumns,
    addReactToColumns,
    syncVisibilityModel,
} from 'common/DataGrid/utils/column-utils'
import Account from 'components/account/index.js'
import format from 'components/portfolio/portfolio-format'
import PortfolioDataGridToolbar from 'components/portfolio/portfolio-data-grid-toolbar'
import {
    columns as initialColumns,
    columnVisibilityModel as initialColumnVisibilityModel,
} from 'components/portfolio/portfolio-columns'
import Round from 'components/round/index.js'
import Security from 'components/security/index.js'
import useQuery from 'delv/hooks/use-query.js'
import StepFragment from 'fragments/step-fragment'
import ShortStepFragment from 'fragments/short-step-fragment'
import DistributionPriceFragment from 'fragments/distribution-price-fragment'
import CurrencyFragment from 'fragments/currency-fragment'
import useLocalStorage from 'hooks/use-local-storage'
import PageLayout from 'layouts/page-layout.js'

const distributionsQuery = (args) => `allDistributions(filter: {status: ${args.statusFilter}}) {
    nodes {
        __typename
        id
        status
        amount
        paidInCapital
        distributionPricesByDistributionId  {
            nodes {
                ${DistributionPriceFragment}
            }
        }
        purchasePrice {
            id
            price
        }
        stepByStepId {
            ${StepFragment}
            shortSteps {
                nodes {
                    ${ShortStepFragment}
                }
            }
        }
        allocationByAllocationId {
            id
            roundByRoundId {
                unitValue
                totalCapitalDeployed
                ${Round.fragment.toString()}
                securityBySecurityId {
                    ${Security.fragment.toString()}
                }
                trancheBondDatumByRoundId{
                    ${Round.tranche.fragment.toString()}
                }
                assetByAssetId {
                    id
                    name
                    assetTypeByType {
                        type
                        accountingType
                    }
                    accountByIssuerId {
                        ${Account.fragment.toString()}
                    }
                }
            }
        }
    }
}`

const holdingsQuery = `allHoldings(filter: {amount: {notEqualTo: "0"}}){
    nodes {
        __typename
        id
        amount
        purchasePrice
        securityBySecurityId {
            ${Security.fragment.toString()}
            roundsBySecurityId {
                nodes {
                    id
                    name
                    unitValue
                    price
                    total
                    totalCapitalDeployed
                    termsUrl
                    trancheBondDatumByRoundId{
                    ${Round.tranche.fragment.toString()}
                    }
                    assetByAssetId {
                        id
                        name
                        assetTypeByType {
                            type
                            accountingType
                        }
                        accountByIssuerId {
                            ${Account.fragment.toString()}
                        }
                    }
                }
            }
        }
        holdingBlockchainAddressesByHoldingId(condition: {status: "trade"}) {
            nodes {
                id
                status
                blockchainAddressByBlockchainAddressId {
                    id
                    chain
                    address
                }
            }
        }
    }
}
`

const BREADCRUMBS = [
    {
        display: 'Portfolio',
        link: '/portfolio',
        active: true,
    },
]

const DataGridNestedSx = {
    paddingLeft: '52px',
    backgroundColor: `${grey[200]}`,
    borderRadius: '0',
    '& .MuiDataGrid-virtualScroller': {
        marginTop: '0 !important',
        height: 'auto !important',
        overflowX: 'visible',
        overflowY: 'hidden !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        display: 'none !important',
    },
    '& .MuiDataGrid-cell': {
        borderBottom: '0 !important',
    },
    '& .MuiDataGrid-row': {
        borderBottom: '1px solid white !important',
    },
    '& .Mui-selected': {
        background: 'transparent',
    },
}

const currencyQuery = `
    allCurrencies(condition: { code: "USD" }) {
        nodes {
            ${CurrencyFragment}
        }
    }
`

const getQuery = (querySelection) => {
    const queries = [currencyQuery]
    if (querySelection.issued) {
        queries.push(holdingsQuery)
    }
    if (querySelection.onboarding || querySelection.pending) {
        const args = {}

        if (querySelection.onboarding && querySelection.pending) {
            args.statusFilter = '{ notEqualTo: "locked" }'
        } else if (querySelection.onboarding) {
            args.statusFilter = '{ equalTo: "open" }'
        } else if (querySelection.pending) {
            args.statusFilter = '{ equalTo: "closed" }'
        }

        queries.push(distributionsQuery(args))
    }

    return queries.length ? `{${queries.join('\n')}}` : undefined
}

const PortfolioPage = () => {
    const [querySelection, setQuerySelection] = React.useReducer((a, b) => ({ ...a, ...b }), { issued: true, onboarding: true, pending: true })
    const {
        data,
        loading,
    } = useQuery(getQuery(querySelection), {
        format,
    })

    const [density, setDensity] = useLocalStorage('row-density', 'compact')
    const [columns, setColumns] = useLocalStorage('portfolio-columns', initialColumns, {
        serialize: removeReactFromColumns,
        deserialize: addReactToColumns(initialColumns),
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage('portfolio-column-visibility', initialColumnVisibilityModel, {
        deserialize: syncVisibilityModel(initialColumnVisibilityModel),
    })
    const [expandedRows, setExpandedRows] = React.useState([])

    const rows = data?.rows || []

    React.useEffect(() => {
        if (rows.length) {
            setExpandedRows(rows.map(({ id }) => id))
        }
    }, [rows])

    const NestedTable = React.useCallback(({ row }) => {
        const nestedColVisibility = { ...columnVisibilityModel }
        delete nestedColVisibility.__detail_panel_toggle__

        return (
            <DataGridPro
                rows={row.rows}
                columns={columns.slice(1)}
                hideFooter
                scrollbarSize={0}
                density={density}
                columnVisibilityModel={nestedColVisibility}
                sx={DataGridNestedSx}
            />
        )
    }, [columns, density, columnVisibilityModel])

    const toggleQuerySelection = (key) => setQuerySelection({ [key]: !querySelection[key] })
    const collapseRows = () => setExpandedRows([])
    const resetGrid = () => {
        setColumns(initialColumns)
        setColumnVisibilityModel(initialColumnVisibilityModel)
    }

    return (
        <PageLayout breadcrumbs={BREADCRUMBS}>
            <DataGridPro
                autoHeight
                disableColumnResize={false}
                disableColumnFilter
                disableColumnPinning
                onColumnOrderChange={(e) => {
                    const target = e.targetIndex
                    const old = e.oldIndex
                    const newColumns = [...columns]
                    newColumns[target] = columns[old]
                    newColumns[old] = columns[target]
                    setColumns(newColumns)
                }}
                rows={rows}
                columns={columns}
                loading={loading}
                getDetailPanelContent={NestedTable}
                rowThreshold={0}
                getDetailPanelHeight={({ row }) => getColumnSize(density) * (row.rows?.length) || 212}
                density={density}
                onColumnWidthChange={(e) => {
                    const newColumns = [...columns].map((col) => (
                        col.field === e.colDef.field
                            ? ({
                                ...col,
                                flex: undefined,
                                width: e.colDef.width,
                            }) : col
                    ))
                    setColumns(newColumns)
                }}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onStateChange={(params) => {
                    if (params.density.value !== density) {
                        setDensity(params.density.value)
                    }
                }}
                sx={{
                    border: 'none',
                    backgroundColor: '#ffffff',
                    '& .Mui-selected': {
                        background: 'transparent !important',
                    },
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                    Footer: DataGridFooter,
                    NoRowsOverlay: GridNoneFound,
                    Toolbar: PortfolioDataGridToolbar,
                }}
                componentsProps={{
                    noRowsOverlay: {
                        children: 'No Investments Found',
                    },
                    toolbar: {
                        querySelection,
                        toggleQuerySelection,
                        collapseRows,
                        resetGrid,
                    },
                    footer: {
                        rows,
                        totalCount: rows.length,
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
                detailPanelExpandedRowIds={expandedRows}
                onDetailPanelExpandedRowIdsChange={setExpandedRows}
            />
        </PageLayout>
    )
}

export default PortfolioPage

import * as React from 'react'
import MUITooltip from '@mui/material/Tooltip'

// Tooltip will always have a div as a child so that no matter what, the child is capable of holding a ref.
const Tooltip = ({ children, placement = 'top-start', ...props }) => (
    <MUITooltip placement={placement} arrow {...props}>
        <div>
            {children}
        </div>
    </MUITooltip>
)

export default Tooltip

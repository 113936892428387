import React from 'react'

import Mutation from 'delv/Mutation'
import { Input, Dropdown } from 'common/FormUI.js'
import Form from 'common/form/Form'

import './PriceForm.scss'

const MAKE_DISTRIBUTION_PRICE = `mutation($distributionPrice:DistributionPriceInput!){
  createDistributionPrice(input:{distributionPrice:$distributionPrice}){
    distributionPrice{
      id
      price
      distributionByDistributionId{
        id
      }
      currencyByCurrency{
        id
        code
        name
      }
    }
  }
}`

const isDuplicateCurrency = (formInput, distributionPrices) => {
    const currencies = {}
    distributionPrices.nodes.forEach(({ currencyByCurrency: { id } }) => {
        currencies[id] = id
    })

    if (currencies[formInput.currency]) {
        return true
    }
    return false
}

const PriceForm = ({
    distributionId,
    popup,
    form: {
        price,
        currency,
        validateInput,
        error,
    },
    prices,
    prefix = 'vrtl-price-card',
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()

        if (isDuplicateCurrency(input, prices)) {
            error.set('Multiple prices of the same currency is not supported.')
            return
        }

        if (!input.hasErrors) {
            const submitValue = {
                distributionPrice: {
                    distributionId,
                    price: input.price,
                    currency: input.currency,
                },
            }
            new Mutation({
                mutation: MAKE_DISTRIBUTION_PRICE,
                onSubmit: () => submitValue,
                onResolve: popup.close,
            }).submit()
        }
    }

    return (
        <div>
            <div className={`${prefix}--error ${prefix}--padding-left`}>
                {error.error}
            </div>
            <form className='price-form-card'>
                <div className='price-ppu-container'>
                    <div className='card-label'>PRICE PER UNIT</div>
                    <Input {...price} />
                </div>
                <div>
                    <div className='card-label'>CURRENCY</div>
                    <Dropdown {...currency} width='5rem' />
                </div>
            </form>
            <div className='price-form-button-container'>
                <div className='price-form-button' onClick={popup.close}>
                    Cancel
                </div>
                <div className='price-form-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </div>
    )
}

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        currency: {
            type: 'dropdown',
            options: ({ currencies }) => currencies,
            error: 'Currency is required.',
        },
        price: {
            type: 'number',
            error: 'Price per unit is required',
        },
    },
}

PriceForm.fragment = `distributionPricesByDistributionId {
      nodes {
        id
        currencyByCurrency{
          id
          code
          name
        }
      }
    }`

export default Form(PriceForm, formConfig)

import Delv from './delv.js'

class Mutation {
    constructor({
        mutation,
        networkPolicy,
        onFetch,
        onSubmit,
        onResolve,
        onError,
        refetchQueries,
        cacheProcess,
    }) {
        this.mutation = mutation
        this.networkPolicy = networkPolicy
        this.onSubmit = onSubmit
        this.fetch = onFetch
        this.error = onError
        this.resolve = onResolve
        this.cacheProcess = cacheProcess
        this.refetchQueries = refetchQueries || []
    }

    submit = (event) => {
        this.variables = this.onSubmit(event)
        if (this.variables) {
            this.query()
        }
    }

    onFetch = (promise, data) => {
        if (this.fetch) {
            this.fetch(promise, data)
        }
    }

    onError = (error) => {
        if (this.error) {
            this.error(error)
        }
    }

    onResolve = (data) => {
        if (this.resolve) {
            this.resolve(data)
        }
        this.refetchQueries.forEach((query) => {
            Delv.query({
                query: query.query,
                networkPolicy: query.networkPolicy || 'network-only',
                cacheProcess: query.cacheProcess || 'default',
                variables: {},
                onResolve: () => {},
                onFetch: () => {},
            })
        })
    }

    removeListeners = () => {
        this.submit = null
        this.fetch = null
        this.resolve = null
    }

    query = () => {
        Delv.query({
            query: this.mutation,
            variables: this.variables,
            networkPolicy: this.networkPolicy || 'network-only',
            cacheProcess: this.cacheProcess || 'default',
            onFetch: this.onFetch,
            onResolve: this.onResolve,
            onError: this.onError,
        })
    }
}

export default Mutation

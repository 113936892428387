import React from 'react'

import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'

const Transactions = () => (
    <>
        <Nav />
        <Container>
            <p>This feature is not available</p>
        </Container>
        <Footer />
    </>
)

export default Transactions

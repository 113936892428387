import React from 'react'
import { Link } from 'react-router-dom'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Format from 'util/format/Format.js'
import NumberUI from 'common/NumberUI.js'
import HoverToolTip from 'common/HoverToolTip'
import { Decimal } from 'decimal.js'
import { isIssuer } from 'util/permissions.js'

export default ({
    prefix = 'vrtl-security-card',
    id,
    endSlot,
    startSlot,
    unitValue,
    total,
    name,
    ticker,
    roundsBySecurityId,
    entityPage,
    accountByIssuerId,
    ...props
}) => {
    const latestValue = Format.number(new Decimal(unitValue).mul(total))
    const assetName = roundsBySecurityId?.nodes[0]?.assetByAssetId.name
    const assetType = roundsBySecurityId?.nodes[0]?.assetByAssetId.type
    const isAnIssuer = isIssuer(props)
    const LinkDisplay = () => {
        if (entityPage) {
            if (isAnIssuer) {
                return (
                    <div className={`${prefix}__section`}>
                        <div className={`${prefix}__heading`}>Round</div>
                        <Link className={`${prefix}__value ${prefix}__link`} to={`/asset-class/${roundsBySecurityId.nodes[0].id}/manage`}>
                            <OpenInNew className={`${prefix}__icon-open-in-new ${prefix}__section no-margin`} />
                        </Link>
                    </div>
                )
            }
            return <div className={`${prefix}__icon-offset`} />
        }
        return (
            <Link className={`${prefix}__link`} to={`/security/${id}`}>
                <OpenInNew className={`${prefix}__icon-open-in-new`} />
            </Link>
        )
    }

    return (
        <div className={`${prefix}__container`}>
            {startSlot}
            <div className={`${prefix}__row`}>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>Name</div>
                    <div className={`${prefix}__value`}>{name}</div>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>Ticker Symbol</div>
                    <div className={`${prefix}__value`}>{ticker || 'N/A'}</div>
                </div>
                {
                    isAnIssuer ? (
                        <>
                            <div className={`${prefix}__section`}>
                                <div className={`${prefix}__heading`}>Asset Name</div>
                                <div className={`${prefix}__value`}>{assetName}</div>
                            </div>
                            <div className={`${prefix}__section`}>
                                <div className={`${prefix}__heading`}>Asset Type</div>
                                <div className={`${prefix}__value`}>{assetType}</div>
                            </div>
                        </>
                    ) : (
                        <div className={`${prefix}__section`}>
                            <div className={`${prefix}__heading`}>Issuer</div>
                            <div className={`${prefix}__value`}>{accountByIssuerId.name}</div>
                        </div>
                    )
                }
                <div className={`${prefix}__section`}>
                    <HoverToolTip width='fit-content' position='top left' arrow={false}>
                        <div className={`${prefix}--hover-highlight ${prefix}__heading`}>
                            Latest Value
                        </div>
                        <div className='popup-contents'>
                            Last Price x Units. Last price is the most recent price per unit recorded for the security.
                        </div>
                    </HoverToolTip>
                    <div className={`${prefix}__value`}><NumberUI prefix='$' value={latestValue} /></div>
                </div>
                <div className={`${prefix}__section`}>
                    <HoverToolTip width='fit-content' position='top left' arrow={false}>
                        <div className={`${prefix}__heading`}>Latest Price</div>
                        <div className='popup-contents'>$ / Unit</div>
                    </HoverToolTip>
                    <div className={`${prefix}__value`}><NumberUI prefix='$' value={unitValue} /></div>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__heading`}>Total Units</div>
                    <div className={`${prefix}__value`}>{Format.number(total)}</div>
                </div>
                <LinkDisplay />
            </div>
            {endSlot}
        </div>
    )
}

import Format from 'util/format/Format.js'

const CapByStakeholder = {
    query: `{
      allAssets {
        nodes {
          id
          name
          roundsByAssetId {
            nodes {
              id
              name
              tokenByTokenId {
                id
                precision
                capTablesByTokenId {
                  nodes {
                    id
                    amount
                    blockchainAddressByBlockchainAddressId {
                      id
                      chain
                      address
                      accountsBlockchainAddressesByBlockchainAddressId {
                        nodes {
                          id
                          name
                          accountByAccountId {
                            id
                            contactName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    format: (data) => {
        const capTables = []
        let total = 0
        let investors = 0
        data.allAssets.nodes.forEach((asset) => {
            asset.roundsByAssetId.nodes.forEach((klass) => {
                const precision = klass.tokenByTokenId.precision
                klass.tokenByTokenId.capTablesByTokenId.nodes.forEach(
                    (capTable) => {
                        const {
                            id,
                            amount,
                            blockchainAddressByBlockchainAddressId: {
                                chain,
                                address,
                                accountsBlockchainAddressesByBlockchainAddressId,
                            },
                        } = capTable
                        let acba = accountsBlockchainAddressesByBlockchainAddressId
                        let investor = Format.blockchainAddress(address)
                        let status = chain === 'vertalon-hawknet'
                            ? 'Unknown'
                            : 'On chain'
                        if (acba && acba.nodes[0]) {
                            acba = acba.nodes[0]
                            if (chain === 'vertalon-hawknet') {
                                status = acba.name
                            }
                            if (acba.accountByAccountId) {
                                investor = acba.accountByAccountId.contactName
                            }
                        }
                        if (
                            // eslint-disable-next-line
                            amount != 0
                            && !amount.startsWith('-')
                            && status !== 'allocation'
                        ) {
                            const numberAmount = Format.numeric(
                                amount,
                                precision,
                                true,
                            )
                            investors++
                            total += numberAmount
                            capTables.push({
                                id,
                                amount: numberAmount,
                                status,
                                name: investor,
                                asset: asset.name,
                            })
                        }
                    },
                )
            })
        })
        return {
            summary: {
                investors: {
                    label: 'STAKEHOLDERS',
                    value: investors,
                },
                total: {
                    label: 'UNITS',
                    value: Format.number(total),
                },
            },
            orderByData: capTables.map((ct) => {
                // eslint-disable-next-line
                ct.percentage = Format.percent(ct.amount, total)
                return ct
            }),
        }
    },
}
export { CapByStakeholder }

const SOPByStakeholder = {
    query: `{
      allAssets {
        nodes {
          id
          name
          holdings
          roundsByAssetId {
            nodes {
              id
              name
              allocationsByRoundId {
                nodes {
                  id
                  accountByOwnerId {
                    id
                    name
                  }
                  distributionsByAllocationId {
                    nodes {
                      id
                      accountByInvestorId {
                        id
                        contactName
                      }
                      distributionPaymentsByDistributionId {
                        nodes {
                          id
                          amount
                          paidOn
                          currencyByCurrency {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }`,
    format: (data) => {
        const payments = []
        let total = 0
        let investors = 0
        data.allAssets.nodes.forEach((asset) => {
            asset.roundsByAssetId.nodes.forEach((klass) => {
                klass.allocationsByRoundId.nodes.forEach((allocation) => {
                    allocation.distributionsByAllocationId.nodes.forEach(
                        (distribution) => {
                            const investor = distribution.accountByInvestorId
                            distribution.distributionPaymentsByDistributionId.nodes.forEach(
                                ({
                                    id,
                                    amount,
                                    paidOn,
                                    currencyByCurrency: { code },
                                }) => {
                                    investors++
                                    total += Format.number(amount, true)
                                    payments.push({
                                        id,
                                        name: investor.contactName,
                                        asset: asset.name,
                                        amount,
                                        paidOn,
                                        currency: code,
                                    })
                                },
                            )
                        },
                    )
                })
            })
        })
        return {
            summary: {
                investors: {
                    label: 'STAKEHOLDERS',
                    value: investors,
                },
                total: {
                    label: 'CAPITAL RAISED',
                    value: `$${Format.number(total)}`,
                },
            },
            orderByData: payments.map((payment) => {
                // eslint-disable-next-line
                payment.percentage = Format.percent(payment.amount, total)
                return payment
            }),
        }
    },
}

export { SOPByStakeholder }

import React from 'react'
import Box from 'common/Box'
import useMutation from 'delv/hooks/use-mutation'
import Divider from 'common/Divider'
import Button from 'common/Button'
import NetworkResponse from 'common/NetworkResponse'
import Typography from 'common/Typography/index.js'
import ViewAppend from 'common/ViewAppend'
import useQuery from 'delv/hooks/use-query.js'
import useFormView from 'hooks/UseFormView'
import Attestation from 'components/account/setup/Attestation.js'
import PartyForm from 'components/account/setup/Party.js'
import AppendParty from 'components/account/setup/AppendParty.js'
import Address from 'components/account/setup/Address.js'
import selectEntity from 'components/account/setup/selectEntity'
import Div from 'common/Div.js'
import EventEmitter from 'common/utils/EventEmitter.js'
import LoadingButton from 'common/LoadingButton.js'
import fragment from './fragment.js'

const INVESTOR_ACCOUNT_TYPES = `{
    allInvestorAccountTypes(orderBy:TYPE_ASC){
      nodes{
        id: nodeId
        type
      }
    }
  }`

/* eslint-disable max-len */
const SETUP_ACCOUNT = `mutation ($accountName: String!, $attestation: Boolean!, $taxId: String!, $type: String!, $formationDate: Datetime!, $addresses: [SetupAccountAddressInput!]!, $parties: [SetupAccountPartiesInput!]!) {
    setupAccount(input: {accountName: $accountName, attestation: $attestation, taxId: $taxId, type: $type, formationDate: $formationDate, addresses: $addresses, parties: $parties}) {
        ${fragment.toString()}
    }
}`
/* eslint-enable max-len */

const emitter = EventEmitter()

const formatAccountTypes = (accountData) => (accountData?.allInvestorAccountTypes.nodes.map((e) => ({
    label: e.type,
    value: e.type,
}))
)

const data = {
    account: {},
    parties: {},
    error: false,
}

const reset = () => {
    data.account = {}
    data.parties = {}
    data.error = false
}

// Should select render from config in refactor
const EventWrapper = () => {
    const [setupAccount, { loading, error: mutationError }] = useMutation(SETUP_ACCOUNT)
    const { data: formattedAccountTypes } = useQuery(INVESTOR_ACCOUNT_TYPES, { format: formatAccountTypes })

    if (mutationError) {
        reset()
    }

    const accountTypeConfig = {
        fields: {
            type: {
                type: 'dropdown',
                label: 'Investor Type',
                options: formattedAccountTypes,
            },
        },
    }
    const stateHandler = {
        account: (input) => {
            data.account = { ...data.account, ...input }
        },
        error: () => {
            data.error = true
        },
        party: (index, input) => {
            if (!data.parties[index]) {
                data.parties[index] = {}
            }

            data.parties[index] = {
                ...data.parties[index],
                ...input,
            }
        },
        address: (field, index, input) => {
            const handleParty = () => {
                if (!data.parties[index]) {
                    data.parties[index] = {}
                }

                if (!data.parties[index].addresses) {
                    data.parties[index] = {
                        ...data.parties[index],
                        addresses: [],
                    }
                }
                data.parties[index].addresses.push(input)
            }

            const handleAccount = () => {
                if (!data.account.addresses) {
                    data.account.addresses = []
                }

                data.account.addresses.push(input)
            }

            if (field === 'parties') {
                handleParty()
            } else {
                handleAccount()
            }
        },
        formatData: () => {
            Object.values(data.parties).forEach((party) => {
                if (party.addresses.length === 1) {
                    party.addresses.push({ ...party.addresses[0], type: 'mailing' })
                }
            })

            if (data.account.addresses?.length === 1) {
                data.account.addresses.push({ ...data.account.addresses[0], type: 'mailing' })
            }
        },
    }

    const { validateInput, formView: { type }, input: { type: { value: typeValue } } } = useFormView(accountTypeConfig)

    const {
        Component: Account,
        account: accountProps,
        format,
        party,
        addressFields,
        mailingFields,
    } = selectEntity(typeValue)

    const handleSubmit = () => {
        emitter.emit('submitInput')
        const { valid, input } = validateInput()
        if (valid) {
            stateHandler.account(input)
        } else {
            stateHandler.error()
        }

        if (!data.error) {
            if (format) {
                format(data)
            }

            stateHandler.formatData()

            const dedupAddresses = (arr) => {
                const seen = {}
                return arr.filter((a) => {
                    if (seen[a.type]) {
                        return false
                    }
                    seen[a.type] = a
                    return true
                })
            }

            Object.values(data.parties).forEach((p) => {
                p.addresses = dedupAddresses(p.addresses)
            })

            data.account.addresses = dedupAddresses(data.account.addresses)

            setupAccount({
                variables: {
                    parties: Object.values(data.parties),
                    ...data.account,
                },
            })
        } else {
            reset()
        }
    }

    const notIndividual = (accountType) => {
        if (!formattedAccountTypes) {
            return undefined
        }
        const filteredTypes = formattedAccountTypes.filter((i) => !['Individual', 'Sole Proprietorship'].includes(i.value))

        return filteredTypes.some((e) => e.value === accountType)
    }

    const appendParty = <AppendParty mailingFields={mailingFields} addressFields={addressFields} stateHandler={stateHandler} emitter={emitter} />

    const getErrorMessage = (errorObject) => {
        if (!errorObject) {
            return undefined
        }

        const recognizedErrors = {
            1300: '1300',
            1301: '1301',
            1302: '1302',
            1303: '1303',
            1304: '1304',
            1310: '1310',
            1311: '1311',
            1312: '1312',
            1313: '1313',
            1314: '1314',
            1315: '1315',
            1316: '1316',
            1317: '1317',
            1318: '1318',
        }

        const code = errorObject[0].message.slice(0, 4)
        if (recognizedErrors[code]) {
            return errorObject[0].message.slice(4)
        }
        return undefined
    }

    const errorMessage = getErrorMessage(mutationError)
    const title = 'Account Setup'
    const instructions = 'Please fill the following form to set up your investor account and add any parties to this account.'

    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 4, maxWidth: '60rem',
        }}
        >
            <Box data-testid='step-header' sx={{ pb: 2 }}>
                <Typography variant='h4' as='h1' sx={{ mb: 2 }}>{title}</Typography>
                <Typography variant='body1' sx={{ my: 1 }}>
                    <strong>Instructions: </strong>
                    {instructions}
                </Typography>
            </Box>
            {type}
            {
                typeValue && (
                    <>
                        <Account {...party} fields={accountProps} emitter={emitter} stateHandler={stateHandler} />
                        <Address
                            id='account'
                            field='account'
                            emitter={emitter}
                            stateHandler={stateHandler}
                            addressFields={addressFields}
                            mailingFields={mailingFields}
                        />
                        <Divider />
                        {
                            notIndividual(typeValue) && (
                                <>
                                    <Box>
                                        <Typography variant='h5' as='h1' sx={{ my: 2 }}>Add Parties</Typography>
                                        <Typography variant='body1' sx={{ my: 1 }}>
                                            <strong>Instructions: </strong>
                                            {party.instructions}
                                        </Typography>
                                    </Box>
                                    <PartyForm {...party} id='primary' isPrimary index='primary' emitter={emitter} stateHandler={stateHandler} />
                                    <Address
                                        addressFields={party?.fields.addressFields ? party.fields.addressFields : addressFields}
                                        mailingFields={mailingFields}
                                        id='primaryAddress'
                                        field='parties'
                                        index='primary'
                                        emitter={emitter}
                                        stateHandler={stateHandler}
                                    />
                                    <ViewAppend.container {...party} target canEdit>
                                        <ViewAppend.target />
                                        <ViewAppend.icon append={appendParty}>
                                            <Div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <Button variant='outlined'>{`Add ${party.label}`}</Button>
                                            </Div>
                                        </ViewAppend.icon>
                                    </ViewAppend.container>
                                </>
                            )
                        }
                        <Attestation emitter={emitter} stateHandler={stateHandler} />
                    </>
                )
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton loading={loading} disabled={!typeValue} variant='contained' onClick={handleSubmit}>Submit</LoadingButton>
            </Box>
            <NetworkResponse errorMessage={errorMessage} severity='error' error={!!mutationError} />
        </Box>
    )
}

export default EventWrapper

import { useState } from 'react'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'

const ViewAppend = ({
    children,
    className,
    cleanProps = (props) => props,
    target,
    canEdit,
    ...props
}) => {
    const [appended, setAppended] = useState([])

    let editAllowed
    if (canEdit instanceof Function) {
        editAllowed = canEdit(props)
    } else {
        editAllowed = canEdit
    }

    const append = (element) => {
        const id = window.util.generateId()
        setAppended([
            ...appended,
            {
                id,
                element,
            },
        ])
    }

    const remove = (id) => setAppended(appended.filter((a) => a.id !== id))

    const targets = SafeReact.cloneChildren(
        appended,
        ({ context: { data, index } }) => ({
            ...cleanProps(props),
            key: data.id,
            resolve: () => remove(data.id),
            index,
        }),
        ({ child }) => child.element,
    )

    const newProps = cleanProps(props)

    if (editAllowed) {
        newProps.viewAppend = {
            append,
            length: appended.length,
            target: target ? targets : undefined,
        }
    }

    const newChildren = SafeReact.cloneChildren(children, newProps)

    return target
        ? newChildren
        : [...newChildren, ...targets]
}

const AppendIcon = ({
    children, viewAppend, append, className,
}) => {
    if (viewAppend && viewAppend.append) {
        return SafeReact.cloneChildren(children, {
            onClick: () => viewAppend.append(append),
            className: cx(children.props.className, className),
        })
    }
    return SafeReact.cloneChildren(children, {
        className,
        style: { visibility: 'hidden' },
    })
}

const NoneFound = ({ children, viewAppend }) => {
    if (viewAppend && viewAppend.length) {
        return ''
    }
    return children
}

const ViewAppendTarget = ({ viewAppend }) => {
    if (viewAppend && viewAppend.target) {
        return viewAppend.target
    }
    return ''
}

export default {
    container: ViewAppend,
    icon: AppendIcon,
    noneFound: NoneFound,
    target: ViewAppendTarget,
}

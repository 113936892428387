import React from 'react'
import CopyToClipboard from 'common/CopyToClipboard.js'
import IconHelper from 'common/IconHelper.js'
import ContentCopy from 'icons/ContentCopy.js'
import Edit from '@material-ui/icons/Edit'

const Row = ({
    name,
    authorizedLink,
    unauthorizedLink,
    agentId,
    accountByAgentId,
    edit,
    submit,
    cancel,
}) => {
    const prefix = 'vrtl-allocation-link'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--3`}>
                {name}
            </div>
            <div className={`${prefix}__section--4`}>
                {agentId || (accountByAgentId ? accountByAgentId.name : '') }
            </div>
            <div className={`${prefix}__section--2`}>
                {
                    unauthorizedLink && !submit
                && (
                    <CopyToClipboard text={unauthorizedLink}>
                        <div className={`${prefix}__copy-content`}>
                            <div className={`${prefix}__copy-content--text`}>Copy</div>
                            <ContentCopy className={`${prefix}__icon-copy`} />
                        </div>
                    </CopyToClipboard>
                )
                }
            </div>
            <div className={`${prefix}__section--2`}>
                {
                    authorizedLink && !submit
                && (
                    <CopyToClipboard text={authorizedLink}>
                        <div className={`${prefix}__copy-content`}>
                            <div className={`${prefix}__copy-content--text`}>Copy</div>
                            <ContentCopy className={`${prefix}__icon-copy`} />
                        </div>
                    </CopyToClipboard>
                )
                }
            </div>
            {
                IconHelper([
                    edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />,
                    cancel,
                    submit,
                ], prefix)
            }

        </div>
    )
}

export default Row

import React, { useState } from 'react'
import QueryString from 'query-string'
import { Link } from 'react-router-dom'

import Container from 'common/Container'
import Nav from 'common/Nav'
import Breadcrumbs from 'common/Breadcrumbs'
import Footer from 'common/Footer'
import Loading from 'common/loading/index.js'
import GridChildProvider from 'common/grid/GridChildProvider.js'
import Delv from 'delv/delv-react.js'
import Mutation from 'delv/Mutation'

import DelegateRole from './DelegateRole.js'
import './AccountRole.scss'

const GET_USER_ACCOUNTS = `{
  allUsers {
    nodes {
      id
      pendingTermsOfService {
        id
        type
        agreement
      }
      usersAccountsByUserId {
        nodes {
          id
          role
          accountByAccountId {
            id
            name
            slug
            type
          }
        }
      }
    }
  }
}`

const ACCEPT_TERMS = `mutation ($id: UUID!) {
  acceptTerms(input: {termsId: $id}) {
    termsOfService {
      id
    }
  }
}`

const AccountRoleBody = ({
    allUsers: {
        nodes: [{ pendingTermsOfService, usersAccountsByUserId }],
    },
    location: { search },
}) => {
    const [viewState, setViewState] = useState('default')
    const acceptTerms = () => {
        new Mutation({
            mutation: ACCEPT_TERMS,
            onSubmit: () => ({ id: pendingTermsOfService.id }),
            onResolve: () => {
                window.location.assign(`/authenticate/terms-of-service${search || ''}`)
            },
            cacheProcess: 'delete',
        }).submit()
        setViewState('loading')
    }

    if (viewState === 'loading') {
        return (
            <div className='flex-1'>
                <Loading.fullPage />
            </div>
        )
    }

    if (pendingTermsOfService) {
        const BREADCRUMBS = [
            {
                display: 'Terms of Service',
                route: '#',
            },
        ]
        if (pendingTermsOfService.type === 'html') {
            return (
                <Container>
                    <Breadcrumbs items={BREADCRUMBS} />
                    <div className='vrtl-terms__container'>
                        {/* eslint-disable-next-line */}
                        <div dangerouslySetInnerHTML={{ __html: pendingTermsOfService.agreement }} />
                    </div>
                    <div className='vrtl-terms-action__container'>
                        <div className='styled-button' onClick={acceptTerms}>
                            I AGREE
                        </div>
                    </div>
                </Container>
            )
        }
        return (
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                <div className='vrtl-terms__container'>
                    <div>{pendingTermsOfService.agreement}</div>
                </div>
                <div className='vrtl-terms-action__container'>
                    <div className='styled-button' onClick={acceptTerms}>
                        I AGREE
                    </div>
                </div>
            </Container>
        )
    }

    let sorted = usersAccountsByUserId.nodes.sort((a, b) => a.accountByAccountId.name.localeCompare(b.accountByAccountId.name))

    if (search) {
        const { role } = QueryString.parse(search)
        if (role) {
            const roles = role.split(',')
            sorted = sorted.filter((a) => roles.includes(a.accountByAccountId.type))
        }
    }

    const prefix = 'vrtl-account-role'
    const BREADCRUMBS = [
        {
            display: 'Select Account',
            route: '#',
        },
    ]

    return (
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            <div className={`${prefix}__body`}>
                <div className={`${prefix}__left`} />
                <div className={`${prefix}__delegates-body`}>
                    <div className='order-by-inner'>
                        <div className='grid-row'>
                            <div className='grid-item flex-1' />

                            <Link to='/account/claim' className={`${prefix}__claim-link`}>
                                <div className='grid-item flex-1'>
                                    <div className='delegate-role delegate-claim'>
                                        <div className='delegate-role-role delegate-role-claim'>
                                            Don't see your account? Click here to claim!
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <div className='grid-item flex-1' />
                        </div>
                        <GridChildProvider itemsPer={3} itemClassName='grid-item' arrayKey='sorted.nodes' sorted={{ nodes: sorted }}>
                            <DelegateRole search={search} />
                        </GridChildProvider>
                    </div>
                </div>
                <div className={`${prefix}__right`} />
            </div>
        </Container>
    )
}

const AccountRole = (props) => (
    <>
        <Nav />
        <Delv.query
            query={GET_USER_ACCOUNTS}
            loading={(
                <div className='flex-1'>
                    <Loading.fullPage />
                </div>
            )}
        >
            <AccountRoleBody {...props} />
        </Delv.query>
        <Footer />
    </>
)
export default AccountRole

import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'

export default ({
    name,
    value,
    edit,
    submit,
    remove,
    cancel,
}) => {
    const prefix = 'vrtl-custom-mapping-fields'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{value}</div>
            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

import cloneDeep from './functions/cloneDeep.js'
import mergeWith from './functions/mergeWith.js'
import pickBy from './functions/pickBy.js'
import union from './functions/union.js'
import isEqual from './functions/isEqual.js'

export default {
    cloneDeep,
    mergeWith,
    pickBy,
    union,
    isEqual,
}
import * as React from 'react'

import Logo from 'common/Logo/index.js'

import './styles.scss'

const NavLogo = ({ prefix = 'vrtl-nav-logo', baseUrl, logo = 'color' }) => (window.logoUrl
    ? <Logo prefix={prefix} href={window.logoUrl} logo={logo} />
    : <Logo prefix={prefix} to={baseUrl} logo={logo} />)

export default NavLogo

import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SafeReact from '../util/SafeReact.js'

export default (props) => {
    const [isCopied, setIsCopied] = useState(false)

    let timer

    const closeModal = () => {
        /* istanbul ignore next */
        timer = setTimeout(() => {
            setIsCopied(false)
        }, 1000)
    }

    React.useEffect(() => {
        if (isCopied) {
            closeModal()
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [isCopied])

    const showAndClosePopup = () => {
        setIsCopied(true)
    }

    return (
        <div>
            {isCopied
                ? 'Copied!'
                : (
                    <CopyToClipboard text={props.text} onCopy={showAndClosePopup} style={{ cursor: 'pointer' }}>
                        {SafeReact.cloneChildren(props.children)[0]}
                    </CopyToClipboard>
                )}
        </div>
    )
}

import * as React from 'react'
import GetApp from '@material-ui/icons/GetApp'

import './ReportsDownload.scss'

const ReportDownloadRow = (props) => {
    const {
        name,
        url,
    } = props

    return (
        <div className='vrtl-report-download__row'>
            <div className='vrtl-report-download__column'>
                {name}
            </div>

            <div className='vrtl-report-download__column vrtl-report-download__column--shrink'>
                <div className='vrtl-report-download__download'>
                    <a href={url}><GetApp style={{ color: 'gray' }} /></a>
                </div>
            </div>
        </div>
    )
}

const ReportsDownloadCard = (props) => {
    const { reports } = props

    const rows = reports.map(({ name, url }) => (
        <ReportDownloadRow name={name} url={url} />
    ))

    const conditionalRows = rows.length === 0 ? (
        <div className='none-found'>No Reports Available</div>
    ) : (
        rows
    )

    const header = (
        <div className='vrtl-report-download__header'>
            <div className='vrtl-report-download__column'>Report Name</div>
        </div>
    )

    return (
        <div className='vrtl-report-download__container' style={{ flex: '0' }}>
            <div className='vrtl-report-download__heading'>
                <div className='vrtl-report-download__heading-text'>
                    Download Reports
                </div>
            </div>

            {header}

            {conditionalRows}
        </div>
    )
}

const ReportsDownload = (props) => (
    <div className='vrtl-reports-download__container'>
        <div className='vrtl-reports-download__column'>
            <ReportsDownloadCard {...props} />
        </div>
        <div className='flex-1' />
    </div>
)

export default ReportsDownload

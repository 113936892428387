import * as React from 'react'

import Button from 'common/Button'
import Link from 'common/Link'
import Box from 'common/Box'
import Typography from 'common/Typography'

import StepForm from './step-form.js'
import StepEmpty from './step-empty.js'
import StepIframe from './step-iframe.js'
import AsyncIframeStep from './step-async-iframe.js'

const ProcessingStep = () => <Typography variant='h5'>Step is processing.</Typography>
const CompleteStep = () => <Typography variant='h5'>Step Complete!</Typography>
const RetryLimitStep = () => <Typography variant='h5'>Retry limit reached. Please contact your issuer for assistance.</Typography>

const CompletedStep = () => (
    <Box sx={{ py: 2 }}>
        <Typography variant='h3' as='h1' sx={{ mb: 2 }}>Congratulations!</Typography>
        <Typography variant='h5' as='h2' sx={{ mb: 4 }}>
            {'You have completed all of your investment qualifications. For updates watch your inbox or visit your '}
            <Link to='/portfolio'>portfolio</Link>
            .
        </Typography>
    </Box>
)

const InactiveStep = ({ currentStep, goToCurrentStep }) => {
    const currentStepTitle = currentStep.renderAttributes?.progressBarTitle || 'Current Step'

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography>
                {`This is not the active step. Please continue the onboarding process with ${currentStepTitle}.`}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='contained' onClick={goToCurrentStep}>
                    Go to Active Step
                </Button>
            </Box>
        </Box>
    )
}

const LinkStep = ({ schema }) => (
    <Link href={schema?.link || 'https://www.vertalo.com/'} target='_blank' rel='noopener noreferrer'>
        Continue
    </Link>
)

const ButtonStep = ({ completeStep, variables, schema }) => {
    const onClick = () => {
        completeStep({
            variables: {
                blob: {},
                ...variables,
            },
        })
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClick} variant='contained'>{schema.text || 'Continue'}</Button>
        </Box>
    )
}

const StepDisplay = ({
    activeStep,
    currentStep,
    goToCurrentStep,
    completeStep,
    variables,
    openSnackbar,
}) => {
    // This must be the first check. Completed messaging should always show, no matter what step is selected.
    if (currentStep.status === 'completed') {
        return <CompletedStep />
    }

    // If steps is not current, should always display inactive step
    if (activeStep.id !== currentStep.id) {
        return <InactiveStep currentStep={currentStep} goToCurrentStep={goToCurrentStep} />
    }

    // Status messaging as priority over step content.
    const status = activeStep.status

    const stepStatusMap = {
        processing: ProcessingStep,
        complete: CompleteStep,
        retry_limit: RetryLimitStep,
    }

    if (stepStatusMap[status]) {
        const StatusComponent = stepStatusMap[status]

        return <StatusComponent />
    }

    if (status === 'failed') {
        return (
            <Typography variant='h5'>
                {
                    currentStep.outputData.error
                        ? currentStep.outputData.error
                        : 'Failed to complete step. Please contact your issuer for assistance.'
                }
            </Typography>
        )
    }

    // Types of steps we support
    const type = currentStep.type
    const schema = currentStep.schema
    if (type === 'custom') {
        const { Component } = currentStep
        return <Component />
    }

    if (type === 'link') {
        return <LinkStep schema={schema} />
    }

    if (type === 'iframe') {
        return <StepIframe currentStep={currentStep} />
    }

    if (type === 'async_iframe') {
        return <AsyncIframeStep className='vrtl-process-iframe' currentStep={currentStep} />
    }

    if (type === 'button') {
        return <ButtonStep completeStep={completeStep} schema={schema} variables={variables} />
    }

    if (type === 'form' || type === 'encrypted_form') {
        return (
            <StepForm
                completeStep={completeStep}
                variables={variables}
                openSnackbar={openSnackbar}
                {...currentStep}
            />
        )
    }

    return <StepEmpty />
}

export default StepDisplay

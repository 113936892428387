const referenceTypeConflicts = {
    accountByOwnerId: 'allocationsByOwnerId',
    allocationsByOwnerId: 'accountByOwnerId',
    customerByInvestorId: 'accountByInvestorId',
    customersByVendorId: 'accountByVendorId',
    accountByOwnedBy: 'stepsByOwnedBy',
    accountByExecuteBy: 'stepsByExecuteBy',
    integrationByCustodianId: 'contractsByCustodianId',
    integrationByIssuancePlatformId: 'contractsByIssuancePlatformId',
    contractsByCustodianId: 'integrationByCustodianId',
    contractsByIssuancePlatformId: 'integrationByIssuancePlatformId',
}

export { referenceTypeConflicts }

import React from 'react'
import { isUploader } from 'util/permissions.js'

import './styles.scss'

export default ({
    prefix = 'vrtl-document-row',
    endSlot,
    startSlot,
    ...props
}) => (
    <div className={`${prefix}__heading`}>
        {startSlot}
        <div className={`${prefix}__section--2`}>
            File Name
        </div>
        {
            isUploader(props) ? (
                <>
                    <div className={`${prefix}__section`}>
                        Upload Status
                    </div>
                    <div className={`${prefix}__section`}>
                        Shared With
                    </div>
                </>
            ) : (
                <div className={`${prefix}__section`}>
                    Shared Via
                </div>
            )
        }
        <div className={`${prefix}__section`}>
            Uploaded On
        </div>
        {endSlot}
        <div className={`${prefix}__icon-offset--5`} />
    </div>
)

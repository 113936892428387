import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'
import { CheckBox } from 'common/FormUI.js'

export default ({
    id,
    status,
    accountByInvestorId: {
        id: accountId,
        contactName,
        name,
        email,
    },
    multiSelect,
    offset,
    edit,
    submit,
    cancel,
}) => {
    const multiSelectArgs = {
        id,
        value: {
            status,
            accountByInvestorId: {
                id: accountId,
                contactName,
                name,
                email,
            },
        },
    }
    const prefix = 'vrtl-customer-row'
    return (
        <div className={`${prefix}__container`}>
            {
                multiSelect
            && (
                <CheckBox
                    value={multiSelect.includes(id)}
                    className={`${prefix}__icon-checkbox`}
                    onClick={() => multiSelect.select(multiSelectArgs)}
                />
            )
            }
            {offset}
            <div className={`${prefix}__section`}>{contactName}</div>
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{email}</div>
            {
                IconHelper([cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
name
status
authorizedTotal
createdOn
issuerId
type
assetTypeByType{
  type
  accountingType
}`)

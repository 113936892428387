import React from 'react'
import Add from '@material-ui/icons/AddCircle'

import Delv from 'delv/delv-react'
import NewEditSwitch from 'common/NewEditSwitch.js'
import ViewAppend from 'common/ViewAppend.js'
import Popup from 'common/Popup.js'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'
import Delegate from 'components/delegate/index.js'
import './Account.scss'

import TransferOwnershipForm from './transferOwnership/TransferOwnershipForm.js'

const GET_USERS_ACCOUNTS = `{
  allUsersAccounts {
    nodes {
      id
      status
      role
      userByUserId {
        id
        firstName
        lastName
        status
        emailsByUserId {
          nodes {
            id
            email
          }
        }
      }
    }
  }
}`

const roleDictionary = {
    account_owner: 'Owner',
    account_admin: 'Admin',
    account_viewer: 'Viewer',
}

const formatUserAccounts = (data, props) => {
    // eslint-disable-next-line
    data.orderByData = data.allUsersAccounts.nodes.map((node) => {
        const dropdownRole = roleDictionary[node.role]
        const { firstName, lastName } = node.userByUserId
        const email = node.userByUserId.emailsByUserId.nodes[0].email

        // eslint-disable-next-line
        node.canEdit = props.canEdit && node.role !== 'account_owner'
        const flattenedNode = {
            email, firstName, lastName, dropdownRole, ...node,
        }

        return flattenedNode
    })

    // eslint-disable-next-line
    data.orderByData.sort((a, b) => { return a.role === 'account_owner' ? -1 : b.role === 'account_owner' ? 1 : 0 })
    return data
}

const Delegates = ({
    canEdit,
    isOwner,
    account,
    user,
    prefix = 'vrtl-account-delegates',
}) => (
    <div className={prefix}>
        <ViewAppend.container target canEdit={canEdit}>
            <div className='row--justify-between'>
                {
                    isOwner
                        && (
                            <Popup.popup>
                                <Popup.inner>
                                    <Popup.close />
                                    <TransferOwnershipForm id={user.id} />
                                </Popup.inner>
                                <Popup.trigger className='vrtl-transfer-ownership__button'>Transfer Ownership</Popup.trigger>
                            </Popup.popup>
                        )
                }
            </div>
            <Delv.query
                query={GET_USERS_ACCOUNTS}
                format={formatUserAccounts}
                key='delegate'
                canEdit={canEdit}
            >
                <Div>
                    <OrderBy.order dropdownFields={['dropdownRole', 'status']}>
                        <Delegate.orderByHeader>
                            <ViewAppend.icon append={<Delegate.form />}>
                                <Add />
                            </ViewAppend.icon>
                        </Delegate.orderByHeader>
                        <ViewAppend.target />
                        <Map arrayKey='_orderBy.data'>
                            <NewEditSwitch.switch canEdit editIcon>
                                <Delegate.card />
                                <Delegate.form {...account} />
                            </NewEditSwitch.switch>
                        </Map>
                    </OrderBy.order>
                </Div>
            </Delv.query>
        </ViewAppend.container>
    </div>
)

export default Delegates

import React from 'react'
import './styles.scss'

export default ({
    field,
    value,
    prefix = 'vrtl-investor-row',
}) => (
    <div className={`${prefix}__container`}>
        <div className={`${prefix}__section`}>{field}</div>
        <div className={`${prefix}__section`}>{value}</div>
    </div>
)

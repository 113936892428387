/* eslint-disable max-len, camelcase */
import React, { useState } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import LoopIcon from '@material-ui/icons/Loop'
import RestoreIcon from '@material-ui/icons/Restore'

import HoverToolTip from 'common/HoverToolTip.js'
import Mutation from 'delv/Mutation'
import DelvWebSocket from 'delv/websocket'
import Loading from 'common/loading/index.js'
import ViewSwitch from 'common/NewViewSwitch.js'
import AsyncIframe from 'components/steps/step-async-iframe.js'

import IntegrationRequirementForm from './IntegrationRequirementForm.js'
import './Process.scss'
// process is really a context provider, but as a component because that is easier

const COMPLETE_STEP = `mutation ($blob: JSON!, $id: UUID!) {
  completeStep(input: {blob: $blob, _stepId: $id}) {
    step {
      id
      status
    }
  }
}`

const LEVEL_COMPLETE_STEP = (
    level,
) => `mutation ($blob: JSON!, $parentStepId: UUID!, $childStepId:UUID!) {
  complete${level}Step(input: {blob: $blob, stepId: $childStepId, ${level.toLowerCase()}StepId:$parentStepId}) {
     ${level.toLowerCase()}{
        id
        stepByStepId{
          id
          status
        }
     }
  }
}`

const CurrentStepForm = ({
    p_currentStep, p_id, level, onResolve, refetch,
}) => {
    const [loading, setLoading] = useState(false)
    if (!p_currentStep || !p_currentStep.id) {
        return <div>Process complete!</div>
    }

    const mutation = level ? LEVEL_COMPLETE_STEP(level) : COMPLETE_STEP
    const queryArgs = level
        ? { parentStepId: p_id, childStepId: p_currentStep.id }
        : { id: p_currentStep.id }

    const onStepSubmit = () => setLoading(true)

    const onStepResolve = () => {
        setLoading(false)
        if (onResolve) {
            onResolve()
        }
    }

    const {
        id, status, type, schema,
    } = p_currentStep
    if (loading) {
        return <Loading.dots />
    }
    if (status === 'processing') {
        return <div>Processing</div>
    }
    if (status === 'complete') {
        return <div>Step complete!</div>
    }
    if (status === 'failed') {
        return <div>Failed!</div>
    }
    if (status === 'retry_limit') {
        return <div>Retry limit reached.</div>
    }
    if (type === 'form' || type === 'encrypted_form') {
        return (
            <IntegrationRequirementForm
                mutation={mutation}
                queryArgs={queryArgs}
                onSubmit={onStepSubmit}
                onResolve={onStepResolve}
                refetch={refetch}
                setLoading={setLoading}
                {...p_currentStep}
            />
        )
    }
    if (type === 'button') {
        const onClick = () => {
            new Mutation({
                mutation,
                onSubmit: () => ({
                    ...queryArgs,
                    blob: {},
                }),
                onResolve: onStepResolve,
                refetch,
            }).submit()
            onStepSubmit()
        }
        return (
            <div className='vrtl-step-card--action-btn' onClick={onClick}>
                {schema.text}
            </div>
        )
    }
    if (type === 'link') {
        return (
            <a href={schema.link || 'google.com'} target='_blank' rel='noopener noreferrer'>
                Continue
            </a>
        )
    }
    if (type === 'iframe' || type === 'async_iframe') {
        return type === 'iframe' ? (
            <iframe className='vrtl-process-iframe' src={schema.link} key={id} />
        ) : (
            <AsyncIframe className='vrtl-process-iframe' step={p_currentStep} />
        )
    }

    return <div />
}

const statusIconMap = {
    // not_started:<Brightness1Icon className={`vrtl-step-card__requirement-icon--not_started`} />,
    not_started: (
        <RadioButtonUncheckedIcon className='vrtl-step-card__requirement-icon--not_started' />
    ),
    in_progress: <LoopIcon className='vrtl-step-card__requirement-icon--in_progress' />,
    processing: <LoopIcon className='vrtl-step-card__requirement-icon--in_progress' />,
    failed: <ErrorOutlineIcon className='vrtl-step-card__requirement-icon--failed' />,
    complete: <CheckCircleIcon className='vrtl-step-card__requirement-icon--complete' />,
    restart: <RestoreIcon className='vrtl-step-card__requirement-icon--restart' />,
    retry: <RestoreIcon className='vrtl-step-card__requirement-icon--retry' />,
    retry_limit: <ErrorOutlineIcon className='vrtl-step-card__requirement-icon--retry_limit' />,
    current_step_on: (
        <ArrowRightIcon className='vrtl-step-card__requirement-icon--current_step_on' />
    ),
    current_step_off: (
        <ArrowRightIcon className='vrtl-step-card__requirement-icon--current_step_off' />
    ),
}

const ProcessProgressBarItem = ({
    renderAttributes,
    status,
    order,
    isCurrentStep,
    onClick,
    isSelected,
}) => (
    <div className='vrtl-step-card__progress-bar-item' onClick={onClick}>
        <div className='vrtl-step-card__progress-bar-item-icon-container'>
            <div className={`vrtl-step-number ${isCurrentStep ? 'active' : null}`}>{order}</div>
            <div className='vrtl-step-card__progress-bar-item-status-icon'>
                {statusIconMap[status]}
            </div>
        </div>
        <div className={`vrtl-step-card__progress-bar-item-name ${isSelected ? 'selected' : null}`}>
            {renderAttributes.progressBarTitle}
        </div>
    </div>
)

const ProcessProgressBar = ({ p_shortSteps, p_currentStep, viewSwitch }) => (
    <div className='vrtl-step-card__progress-bar'>
        <div
            className='vrtl-step-card__progress-bar-fill'
            style={{
                width: `${
                    ((p_currentStep.order ? p_currentStep.order - 0.5 : p_shortSteps.length)
                        / p_shortSteps.length)
                    * 100
                }%`,
            }}
        />
        <div className='vrtl-step-card__progress-bar-container'>
            {p_shortSteps.map((node) => {
                const onClick = () => viewSwitch.setUI(node.order - 1)
                return (
                    <ProcessProgressBarItem
                        {...node}
                        onClick={onClick}
                        p_currentStep={p_currentStep}
                        isSelected={viewSwitch.UI === node.order - 1}
                    />
                )
            })}
        </div>
    </div>
)

const ProcessShortStep = ({
    p_shortStep: {
        status, isCurrentStep, renderAttributes, id,
    },
}) => {
    let current_step
    if (isCurrentStep) {
        current_step = statusIconMap.current_step_on
    } else {
        current_step = statusIconMap.current_step_off
    }

    return (
        <div className='vrtl-step-card__requirement-row' key={id}>
            {current_step}
            {statusIconMap[status]}
            <div>{renderAttributes.title}</div>
        </div>
    )
}

const ProcessShortSteps = ({
    p_id, p_shortSteps, p_currentStep, children,
}) => {
    if (!p_id) {
        return <div />
    }
    const currentStepId = p_currentStep.id

    const shortSteps = p_shortSteps
        .sort((a, b) => a.order - b.order)
        .map((step) => {
            if (step.id === currentStepId) {
                // eslint-disable-next-line
                step.isCurrentStep = true
            }
            return React.cloneElement(children, { p_shortStep: step, p_id, key: step.id })
        })
    return (
        <div className='column'>
            <div>Steps:</div>
            {shortSteps}
        </div>
    )
}

const ProcessStep = ({
    viewSwitch, p_shortSteps, p_currentStep, level, p_id,
}) => {
    const currentStep = p_shortSteps[viewSwitch.UI].isCurrentStep
        ? {
            ...p_currentStep,
            isCurrentStep: true,
            renderAttributes: p_shortSteps[viewSwitch.UI].renderAttributes,
        }
        : p_shortSteps[viewSwitch.UI]
    const prefix = 'vrtl-process-step'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__header`}>
                {p_shortSteps[viewSwitch.UI].order}
                .
                {currentStep.renderAttributes.title}
            </div>
            <div className={`${prefix}__body`}>
                <div className={`${prefix}__body--left`}>
                    <div className='gray-header'>Instructions</div>
                    <div className={`${prefix}__instructions`}>
                        {currentStep.renderAttributes.instructions}
                    </div>
                    <div className='gray-header'>About</div>
                    <div className={`${prefix}__instructions`}>
                        {currentStep.renderAttributes.about}
                    </div>
                </div>
                <div className={`${prefix}__body--right`}>
                    {currentStep.isCurrentStep ? (
                        <CurrentStepForm p_id={p_id} level={level} p_currentStep={p_currentStep} />
                    ) : (
                        <div className={`${prefix}__inactive`}>
                            <div className={`${prefix}__inactive--text`}>
                                {p_currentStep.renderAttributes
                                    ? `This is not the current active step, please select "${p_currentStep.renderAttributes.progressBarTitle}" to continue the onboarding process`
                                    : 'Process complete!'}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const statusDisplayMap = {
    not_started: 'Not started.',
    in_progress: 'In progress.',
    processing: 'Processing.',
    failed: (
        <div className='row'>
            <ErrorIcon className='vrtl-step-card__requirement-icon--failed' />
            <div>Failed</div>
        </div>
    ),
    complete: 'Complete!',
    restart: (
        <div className='row'>
            <ErrorIcon className='vrtl-step-card__requirement-icon--failed' />
            <div>Retry available.</div>
        </div>
    ),
}

const Status = ({ p_status, p_currentStep }) => {
    if (
        p_currentStep
        && (p_status === 'failed' || p_status === 'restart')
        && p_currentStep.outputData
        && p_currentStep.outputData.error
    ) {
        return (
            <HoverToolTip position='bottom left'>
                <div className='vrtl-step-card__requirement-row cursor'>
                    <div>{statusDisplayMap[p_status]}</div>
                </div>
                <div>{p_currentStep.outputData.error}</div>
            </HoverToolTip>
        )
    }
    return <div>{statusDisplayMap[p_status]}</div>
}

const ProcessInfo = ({ p_shortSteps, viewSwitch }) => {
    const p_info = p_shortSteps[viewSwitch.UI]
    if (p_info) {
        const { name, message } = p_info.renderAttributes

        return (
            <div className='vrtl-process-info'>
                <div className='vrtl-process-name'>{name}</div>
                <div className='vrtl-process-message'>{message}</div>
            </div>
        )
    }
    return <div />
}

const Process = ViewSwitch(({
    proc, children, viewSwitch, refetch,
}) => {
    const mapChildren = (props) => (child) => {
        if (!child) {
            return <div />
        }
        if (typeof child.type === 'string') {
            return child
        }
        return React.cloneElement(child, { ...props })
    }

    if (proc) {
        if (proc.currentStep) {
            DelvWebSocket.connect()
            DelvWebSocket.subscribe({ type: 'steps', ids: [proc.currentStep.id], queryId: proc.id })
            if (refetch) {
                DelvWebSocket.addQuery({ id: proc.currentStep.id, query: refetch })
            }
        }
        const currentStep = proc.currentStep ? proc.currentStep : { status: 'complete' }
        const shortSteps = proc.shortSteps.nodes
            .sort((a, b) => a.order - b.order)
            .map((step) => {
                let isCurrentStep = step.isCurrentStep

                if (proc.currentStep && step.id === proc.currentStep.id) {
                    currentStep.order = step.order
                    currentStep.renderAttributes = step.renderAttributes
                    isCurrentStep = true
                }
                return {
                    ...step,
                    isCurrentStep,
                    p_id: proc.id,
                    key: step.id,
                }
            })

        const formattedProcess = {
            p_status: currentStep.status,
            p_currentStep: currentStep,
            p_shortSteps: shortSteps,
            p_id: proc.id,
            viewSwitch,
        }
        return React.Children.map(children, mapChildren(formattedProcess))
    }
    return children
})

const FullProcess = ({ proc, level }) => {
    if (proc.shortSteps.nodes.length === 0) {
        return <div />
    }

    const currentStep = proc.currentStep ? proc.currentStep : { status: 'complete' }
    const shortSteps = [
        ...proc.shortSteps.nodes.filter((step) => step.id === currentStep.id),
        { order: 1 },
    ]

    return (
        <Process proc={proc} defaultUI={shortSteps[0].order - 1}>
            <ProcessProgressBar />
            <ProcessStep level={level} />
        </Process>
    )
}

export default {
    process: Process,
    shortSteps: ProcessShortSteps,
    shortStep: ProcessShortStep,
    currentStep: CurrentStepForm,
    status: Status,
    info: ProcessInfo,
    fullProcess: FullProcess,
    progressBar: ProcessProgressBar,
    step: ProcessStep,
    fragment: `stepByStepId {
      id
      status
      shortSteps{
        nodes{
          id
          renderAttributes
          status
          order
        }
      }
      currentStep{
        id
        schema
        status
        type
        outputData
        dynamicData
      }
    }`,
}
/* eslint-enable max-len, camelcase */

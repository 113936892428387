import React from 'react'
import Format from 'util/format/Format.js'
import { Link } from 'react-router-dom'

export default (props) => {
    const transactions = props.ledgersByTransactionId.nodes
    return (
        <Link to={`/transaction/${props.id}`} className='selectable-card'>
            <div className='investor-messages'>
                <div className='investor-message'>
                    <div className='flex-1'>
                        {Format.blockchainAddress(props.id)}
                    </div>
                    <div className='flex-1'>{transactions.length}</div>
                </div>
            </div>
        </Link>
    )
}

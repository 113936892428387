import React from 'react'

import Breadcrumbs from 'common/Breadcrumbs/index.js'
import Container from 'common/Container/index.js'
import Footer from 'common/Footer/index.js'
import Nav from 'common/Nav/index.js'
import Delv from 'delv/delv-react'
import ViewAppend from 'common/ViewAppend.js'
import ManagedAccount from 'components/managedAccount/index.js'
import Account from 'components/account/index.js'
import Round from 'components/round/index.js'
import Asset from 'components/asset/index.js'
import BrokerAllocation from 'components/brokerAllocation/index.js'
import Issuer from 'components/issuer/index.js'
import Map from 'common/Map.js'
import OrderBy from 'common/OrderBy.js'
import Div from 'common/Div.js'
import Add from '@material-ui/icons/AddCircle'

import './styles.scss'

const QUERY = {
    query: `{
    allAccounts(condition:{type:"issuer"}){
      nodes{
        ${Account.fragment.toString()}
        assetsByIssuerId{
          nodes{
            ${Asset.fragment.toString()}
            roundsByAssetId{
              nodes{
                ${Round.fragment.toString()}
                brokerAllocationsByRoundId{
                  nodes{
                    ${BrokerAllocation.fragment.toString()}
                  }
                }
              }
            }
          }
        }
      }
    }
  }`,
    format: (data) => ({
        orderByData: data.allAccounts.nodes,
        ...data,
    }),
}

const GET_ENTITIES = {
    query: `{
    allManagedAccounts{
      nodes{
        ${ManagedAccount.fragment.toString()}
      }
    }
  }`,
    format: (data) => ({
        orderByData: data.allManagedAccounts.nodes,
        ...data,
    }),
}

const BREADCRUMBS = [
    {
        display: 'Dashboard',
        link: '/dashboard',
    },
    {
        active: true,
        display: 'Issuers',
        link: '/issuers',
    },
]

const Issuers = () => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            <div className='row flex-1'>
                <div className='flex-1'>
                    <h2 className='issuer-header'>Invitations to manage round</h2>
                    <Delv.query {...QUERY}>
                        <OrderBy.order>
                            <Issuer.orderByHeader />
                            <Map arrayKey='_orderBy.data' data='allAccounts'>
                                <BrokerAllocation.managedRounds />
                            </Map>
                        </OrderBy.order>
                    </Delv.query>
                </div>
                <div className='vrtl-issuers-page--left'>
                    <h2 className='issuer-header'>Fully managed accounts</h2>
                    <ViewAppend.container target canEdit>
                        <Delv.query {...GET_ENTITIES}>
                            <OrderBy.order>
                                <ManagedAccount.orderByHeader>
                                    <ViewAppend.icon append={<ManagedAccount.form />}>
                                        <Add />
                                    </ViewAppend.icon>
                                </ManagedAccount.orderByHeader>
                                <Div className='buyers-container'>
                                    <Div className='order-by-inner'>
                                        <ViewAppend.target />
                                        <Map arrayKey='_orderBy.data' data='allManagedAccounts'>
                                            <ManagedAccount.card />
                                        </Map>
                                    </Div>
                                </Div>
                            </OrderBy.order>
                        </Delv.query>
                    </ViewAppend.container>
                </div>
            </div>
        </Container>
        <Footer />
    </>
)

export default Issuers

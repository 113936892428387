import GenericForm from 'common/form/GenericForm.js'
import Row from './Row.js'

import Fragment from './Fragment.js'

const MAKE_ASSET_CLASS = `mutation($name:String!, $total:BigFloat!, $symbol:String!){
  createSecurity(input:{security:{name:$name, total:$total, symbol:$symbol}}){
    security{
      ${Fragment.toString()}
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is a required field.',
        },
        total: {
            type: 'number',
            error: 'Total is required.',
        },
    },
}

export default GenericForm({
    Display: Row,
    createField: MAKE_ASSET_CLASS,
    prefix: 'vrtl-security-row',
    formConfig,
})

// updateField:updateQuery,

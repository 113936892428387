import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
amount
paidOn
status
currencyByCurrency{
  id
  code
  name
}`)

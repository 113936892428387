import Row from './Row.js'
import RowHeading from './RowHeading.js'
import Fragment from './Fragment.js'

import './styles.scss'

export default {
    row: Row,
    rowHeading: RowHeading,
    fragment: Fragment,
}

import React from 'react'

import Delv from 'delv/delv-react'

import GenericForm from 'common/form/GenericForm.js'
import { Dropdown } from 'common/FormUI.js'

import Row from './Row.js'
import Fragment from './Fragment.js'

const nameTypeahead = {
    query: `{
  allVendorAgents{
    nodes{
      id
      agentId
      name
    }
  }
}`,
    cachePolicy: 'query',
    format: ({ allVendorAgents }) => {
        const options = allVendorAgents.nodes.map(({ agentId, name }) => ({ displayValue: name, value: agentId }))
        return { options }
    },
}

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is required.',
        },
        agentId: {
            type: 'dropdownTypeahead',
            error: 'Agent Email is required.',
            customUI: ({ value }) => (
                <Delv.query {...nameTypeahead}>
                    <Dropdown {...value} />
                </Delv.query>
            ),
            skipRenderOn: ({ props }) => props?.id,
            ignoreError: true,
        },
    },
}

const CREATE_ALLOCATION_LINK = `mutation ($allocationId: UUID!, $name: String!, $agentId: UUID) {
  makeAllocationOfferingLink(input: {_allocationId: $allocationId, _name: $name, _agentId: $agentId}) {
    allocationOfferingLink {
      ${Fragment.toString()}
    }
  }
}
`

export default GenericForm({
    Display: Row,
    createField: CREATE_ALLOCATION_LINK,
    prefix: 'vrtl-allocation-link',
    formConfig,
    createArgs: (input, props) => ({
        ...input,
        allocationId: props.allocationId,
    }),
})

import React from 'react'
import Format from 'util/format/Format.js'
import { Link } from 'react-router-dom'
import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'
import './styles.scss'

export default ({
    accountByEntityId: {
        id,
        type,
        name,
        email,
    },
    userByCreatedBy: {
        firstName,
        lastName,
    },
    createdOn,
    edit,
    cancel,
    submit,
}) => {
    const prefix = 'vrtl-managed-account-row'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--2`}>
                {
                    id
                        ? (
                            <Link to={`/${type}/${id}`}>
                                {name}
                            </Link>
                        )
                        : name
                }

            </div>
            <div className={`${prefix}__section--3`}>
                {email}
            </div>
            <div className={`${prefix}__section--2`}>
                {firstName}
                {' '}
                {lastName}
            </div>
            <div className={`${prefix}__section--2`}>
                {createdOn ? Format.date(createdOn) : ''}
            </div>
            {
                IconHelper([
                    [edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />, cancel],
                    submit,
                ], prefix)
            }
        </div>
    )
}

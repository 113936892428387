import React from 'react'
import Mutation from 'delv/Mutation.js'
import Fragment from 'components/brokerAllocation/Fragment.js'

import './styles.scss'

const UPDATE_BROKER_ALLOCATION = `mutation($id:UUID!, $status:String!){
  updateBrokerAllocationById(input:{id:$id, brokerAllocationPatch:{status:$status}}){
    brokerAllocation{
      ${Fragment.toString()}
    }
  }
}`

// this card is not reusable, only appears in one place so thats okay until UI refactor
export default ({
    name,
    status,
    id,
}) => {
    const handleSubmit = (submitId, submitStatus) => {
        new Mutation({
            mutation: UPDATE_BROKER_ALLOCATION,
            onSubmit: () => ({
                id: submitId,
                status: submitStatus,
            }),
        }).submit()
    }

    const prefix = 'vrtl-broker-allocation-card'

    return (
        <div className={`${prefix}__container--border`}>
            <div className={`${prefix}__section ${prefix}--padding-bottom ${prefix}--center-text`}>
                <div className={`${prefix}__label`}>
                    Round:
                    {name}
                </div>
                <div className={`${prefix}__label`}>
                    Status:
                    {status}
                </div>
            </div>
            {status === 'sent' && (
                <div className={`${prefix}__container--button`}>
                    <div
                        className='slim-button styled-button'
                        onClick={() => handleSubmit(id, 'rejected')}
                    >
                        Reject
                    </div>
                    <div className={`${prefix}--padding-left`} />
                    <div
                        className='slim-button styled-button'
                        onClick={() => handleSubmit(id, 'accepted')}
                    >
                        Accept
                    </div>
                </div>
            )}
        </div>
    )
}

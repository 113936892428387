import React from 'react'
import Mutation from 'delv/Mutation'
import { fragment as qualificationFragment } from 'qualification/QualificationCard.js'
import ModalUpload from 'common/ModalUpload/index.js'
import Distribution from 'components/distribution/index.js'
import Account from 'components/account/index.js'

const BULK_UPLOAD = `mutation ($fileName: String!, $allocationId: UUID!) {
  bulkAllocation(fileName: $fileName, allocationId: $allocationId) {
    id
    distributionsByAllocationId{
      nodes{
        ${Distribution.fragment.toString()}
        ${qualificationFragment.distribution}
        accountByInvestorId{
          ${Account.fragment.toString()}
        }
      }
    }
    roundByRoundId {
      id
      tokenByTokenId {
        id
        capTablesByTokenId {
          nodes {
            id
            amount
            blockchainAddressByBlockchainAddressId {
              id
              chain
              address
            }
          }
        }
      }
    }
  }
}`

export default ({ allocationId }) => {
    const importCapTable = (res) => {
        const fileName = res.data.fileName
        return new Promise((resolve, reject) => {
            new Mutation({
                mutation: BULK_UPLOAD,
                onFetch: (promise, data) => {
                    data.queryManager.remove('roundPage', true)
                },
                onSubmit: () => ({ fileName, allocationId }),
                onError: (response) => {
                    reject(response)
                },
                onResolve: (response) => resolve(response),
            }).submit()
        })
    }

    const heading = 'Import Allocation'
    const exampleFileHref = '/api/v2/example/download/allocation-cap-table.csv'
    const extensions = '.csv'
    const instructions = <p>Numbers must not contain commas.</p>

    return (
        <ModalUpload
            instructions={instructions}
            extensions={extensions}
            exampleFileHref={exampleFileHref}
            onUploadFinish={importCapTable}
            heading={heading}
            modalSize='md'
        />
    )
}

import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'

import WarningIcon from '@material-ui/icons/Warning'

export default ({ position, width }) => (
    <HoverToolTip position={position} width={width}>
        <WarningIcon className='field-edit-icon' style={{ color: 'orange' }} />
        <div>
            Change detected in the template, changes that modify tab labels, or that add/remove tabs
            may cause the template to fail to send, or send incorrectly.
        </div>
    </HoverToolTip>
)

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
status
amount
locked
accountByInvestorId{
  id
  contactName
  name
}`)

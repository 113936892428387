const selectFilterType = (operator) => {
    const map = {
        contains: 'includesInsensitive',
        equals: 'equalTo',
        is: 'equalTo',
        onOrAfter: 'greaterThanOrEqualTo',
        after: 'greaterThan',
        onOrBefore: 'lessThanOrEqualTo',
        before: 'lessThan',
        '=': 'equalTo',
        '!=': 'notEqualTo',
        '>': 'greaterThan',
        '>=': 'greaterThanOrEqualTo',
        '<': 'lessThan',
        '<=': 'lessThanOrEqualTo',
    }

    return map[operator]
}

const selectFilter = ({ value, operatorValue }) => {
    if (operatorValue === 'is') {
        if (value === 'true') {
            return true
        }
        if (value === 'false') {
            return false
        }

        return null
    }

    if (value === '') {
        return null
    }

    return value
}

const getFilterFromRoute = ({ query }) => {
    const fieldName = query.get('type')
    const id = query.get('id')

    if (!fieldName && !id) {
        return {}
    }

    const filter = {
        items: [{
            columnField: fieldName,
            operatorValue: 'equals',
            value: id,
            id: `${fieldName}-${id}-equals`,
        }],
        [fieldName]: {
            equalTo: {
                fieldName,
                filter: id,
                type: 'equalTo',
            },
        },
    }

    return { filter }
}

export {
    selectFilter,
    selectFilterType,
    getFilterFromRoute,
}

import React from 'react'

import OrderBy from 'common/OrderBy.js'
import HoverToolTip from 'common/HoverToolTip.js'
import HelpIcon from '@material-ui/icons/Help'

export default (props) => {
    const prefix = 'vrtl-signing-field'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.dropdown field='roleName' className={`${prefix}__section--2`}>
                Role Name
            </OrderBy.dropdown>
            <OrderBy.filter field='name' className={`${prefix}__section--2`}>
                Name
            </OrderBy.filter>
            <OrderBy.dropdown field='mapTo' className={`${prefix}__section--2`} width='10rem'>
                Map to
            </OrderBy.dropdown>
            <OrderBy.filter field='value' className={`${prefix}__section--2`}>
                Value
            </OrderBy.filter>
            <OrderBy.filter field='valueVariable' className={`${prefix}__section--3`}>
                Data label
                <HoverToolTip position='bottom left'>
                    <HelpIcon />
                    <div>All fields that share the same data label and template modify the same value in the sent document.</div>
                </HoverToolTip>
            </OrderBy.filter>
            <div className={`${prefix}__icon-offset--2`} />
        </OrderBy.bar>
    )
}

import React from 'react'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import cx from 'classnames'

import './styles.scss'

const Circle = ({
    isSelected, prefix = 'vrtl-shape-map', classOverride, sxOverride,
}) => (
    <>
        {isSelected && <CircleOutlinedIcon className={cx(`${prefix}--icon-size-xsm`, classOverride)} sx={sxOverride} />}
    </>
)

export default Circle

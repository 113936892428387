const generateChainAddressMap = {
    'ethereum-rinkeby': (address) => `https://rinkeby.etherscan.io/address/${address}#tokentxns`,
    'ethereum-mainnet': (address) => `https://etherscan.io/address/${address}#tokentxns`,
    'tezos-mainnet': (address) => `https://tzstats.com/${address}`,
}

const getBlockExplorerUrl = (address, chain) => {
    const fn = generateChainAddressMap[chain]

    return fn ? fn(address) : undefined
}

export {
    getBlockExplorerUrl,
}

import React from 'react'

import HoverToolTip from 'common/HoverToolTip.js'

import ErrorIcon from '@material-ui/icons/Error'

export default ({ position, width }) => (
    <HoverToolTip position={position} width={width}>
        <ErrorIcon className='field-edit-icon' style={{ color: 'red' }} />
        <div>
            Mapped template has been deleted. Template usage disabled. In flight qualifications using this mapped template have been marked
            as failed and will need to be added under a new mapped template.
        </div>
    </HoverToolTip>
)

import React from 'react'
import Card from 'common/Card.js'
import { Input, Dropdown } from 'common/FormUI.js'

import 'tokenize/Tokenize.css'

// The commented elements are chains we supported in the past. We're keeping them here to ease toggling their availability in the UI
const chains = [
    // {
    //     chain: 'Ethereum Rinkeby',
    //     value: 'ethereum-rinkeby',
    // },
    // {
    //     chain: 'Ethereum Mainnet',
    //     value: 'ethereum-mainnet',
    // },
    {
        chain: 'Tezos Mainnet (public)',
        value: 'tezos-mainnet',
    },
    {
        chain: 'Ethereum Vertalo (private)',
        value: 'ethereum-vertalo',
    },
    // {
    //     chain: 'Ethereum Ganache (private)',
    //     value: 'ethereum-ganache',
    // },
    // , {
    //     chain: 'Ethereum Kovan',
    //     value: 'ethereum-kovan'
    // }
]

export default ({ form: { name, symbol, chain } }) => (
    <>
        <h2 className='header'>Setup Token</h2>
        <Card.card>
            <Card.section>
                <Card.label>TOKEN NAME</Card.label>
                <Input addClassName='token-form-input' {...name} />
            </Card.section>
            <Card.section>
                <Card.label>TOKEN SYMBOL</Card.label>
                <Input addClassName='token-form-input' {...symbol} />
            </Card.section>
            <Card.section>
                <Card.label>CHAIN</Card.label>
                <Dropdown options={chains} {...chain} />
            </Card.section>
        </Card.card>
    </>
)

import numeral from 'numeral'

const doNothing = (input) => input

const email = (input) => {
    const trimmedInput = input.trim()

    if (!trimmedInput || !trimmedInput.match(/^.+@.+\..+$/)) {
        return { error: true, value: trimmedInput }
    }

    return trimmedInput
}

const link = (input) => {
    if (input.startsWith('https://')) {
        return input
    }

    if (input.startsWith('http://')) {
        return input.replace('http', 'https')
    }

    return `https://${input}`
}

const string = (input) => {
    if (!input) {
        return { error: true }
    }
    return input
}

const number = (input) => {
    if (!input) {
        return { error: true, value: input }
    }
    return numeral(input).format('0.00[0000000000000000]')
}

const integer = (input) => {
    if (!input) {
        return { error: true, value: input }
    }
    return numeral(input).value()
}

const date = (input) => {
    const theDate = new Date(input)
    if (!input || `${theDate}` === 'Invalid Date') {
        return { error: true, value: input }
    }
    return theDate.toISOString()
}
const blockchainAddress = (input) => {
    const formatted = input.match(/^(0x)?[0-9a-fA-F]{40}$/g)
    if (!formatted || formatted.length !== 1) {
        return { error: true, value: input }
    }
    return formatted[0]
}

const dropdown = (input) => {
    if (input === '' || (!input && input !== 0)) {
        return { error: true, value: input }
    }
    return input
}

const multiSelect = (input) => {
    if (!input || Object.values(input).length === 0) {
        return { error: true, value: input }
    }
    return input
}

const boolean = (input) => !!input

const twitterHandle = (input) => {
    const formatted = input.match(/^[A-z0-9\_]{1,15}$/)
    if (!formatted) {
        return { error: true, value: input }
    }
    return input
}

const phone = (input) => {
    const formatted = input.match(/^\+[1-9]\d{1,14}$/)

    return !formatted
        ? { error: true, value: input }
        : input
}

export default {
    email,
    link,
    string,
    date,
    number,
    boolean,
    dropdown,
    multiSelect,
    blockchainAddress,
    color: doNothing,
    integer,
    twitterHandle,
    password: doNothing,
    phone,
    dropdownTypeahead: dropdown,
    checkbox: boolean,
    textArea: string,
}

import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import './FundraisingNav.scss'

export default (props) => {
    const { tabs, match } = props

    const tabBars = Object.keys(tabs).map((key) => {
        const tab = tabs[key]

        const className = cx('vrtl-fundraising-nav__tab', {
            'vrtl-fundraising-nav__tab--active': key === match.params.view,
        })

        return (
            <Link to={`/distribute-allocation/${match.params.allocation_id}/${key}`} className={className}>
                {tab.title}
            </Link>
        )
    })

    return <div className='vrtl-fundraising-nav__container'>{tabBars}</div>
}

import React from 'react'
import Done from '@material-ui/icons/AddCircle'

import Delv from 'delv/delv-react'
import Format from 'util/format/Format.js'
import Card from 'common/Card.js'
import Div from 'common/Div.js'
import ViewAppend from 'common/ViewAppend.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Map from 'common/Map.js'
import NoneFound from 'common/NoneFound/index.js'

import MappedTemplate from './mappedTemplate/index.js'
import Template from './template/index.js'

const GET_MAPPED_TEMPLATE = (id) => `{
  allMappedSigningTemplates(condition:{signingTemplateId:"${id}"}){
    nodes{
      ${MappedTemplate.fragment}
    }
  }
}`

const Right = ({ match, signingTemplateById: { updatedOn, deleted, name } }) => {
    const warning = {
        updatedOn,
        deleted,
        name,
        position: 'bottom center',
        width: '25rem',
    }
    return (
        <div className='vrtl-signing-templates__container--templates'>
            <ViewAppend.container target canEdit={!deleted}>
                <div className='gray-header'>Mapped templates</div>
                <MappedTemplate.rowHeading>
                    <ViewAppend.icon
                        append={(
                            <MappedTemplate.form
                                signingTemplateId={match.params.template_id}
                                Display={<MappedTemplate.row />}
                            />
                        )}
                    >
                        <Done className='' style={{ color: '#1eda1a' }} />
                    </ViewAppend.icon>
                </MappedTemplate.rowHeading>
                <Div className='buyers-container'>
                    <Div className='order-by-inner'>
                        <ViewAppend.target />
                        <Delv.query query={GET_MAPPED_TEMPLATE(match.params.template_id)}>
                            <Map
                                arrayKey='allMappedSigningTemplates.nodes'
                                noneFound={<NoneFound>No templates found.</NoneFound>}
                            >
                                <EditSwitch.switch
                                    canEdit={({ deleted: del }) => !del && !warning.deleted}
                                >
                                    <MappedTemplate.row match={match} parentInfo={warning} />
                                    <MappedTemplate.form
                                        match={match}
                                        signingTemplateId={match.params.template_id}
                                        Display={<MappedTemplate.row match={match} />}
                                    />
                                </EditSwitch.switch>
                            </Map>
                        </Delv.query>
                    </Div>
                </Div>
            </ViewAppend.container>
        </div>
    )
}

const Left = ({
    signingTemplateById: {
        name, description, deleted, updatedOn, syncedOn,
    },
}) => (
    <div className='vrtl-signing-left__section'>
        <div className='row--justify-between'>
            <div className='vrtl-signing__header'>Template</div>
            {deleted ? (
                <Template.error position='left top' />
            ) : new Date(updatedOn).getTime() > new Date(syncedOn).getTime() ? (
                <Template.warning position='left top' />
            ) : (
                <div className='vrtl-signing-template' />
            )}
        </div>
        <Card.section>
            <Card.label>Template name</Card.label>
            {name}
            {' '}
            {deleted && '(Deleted)'}
        </Card.section>
        <Card.section>
            <Card.label>Template description</Card.label>
            {description || 'N/A'}
        </Card.section>
        <Card.section>
            <Card.label>Updated on</Card.label>
            {Format.date(updatedOn, 'MMM DD YYYY hh:mma')}
        </Card.section>
        <Card.section>
            <Card.label>Synced on</Card.label>
            {Format.date(syncedOn, 'MMM DD YYYY hh:mma')}
        </Card.section>
    </div>
)

Left.delvConfig = {
    query: (id) => `{
      signingTemplateById(id:"${id}"){
        id
        name
        description
        updatedOn
        syncedOn
        deleted
      }
  }`,
    args: (props) => props.match.params.template_id,
    networkPolicy: 'network-once',
}

export default {
    right: Delv.queryHOC(Right, Left.delvConfig),
    left: Delv.queryHOC(Left, Left.delvConfig),
}

import React from 'react'
import Delv from 'delv/delv-react.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'
import QuestionSets from 'onboardingQuestions/questionSets/index.js'
import VendorQualification from 'components/vendorQualification/index.js'
import './styles.scss'
import { CheckBox } from 'common/FormUI.js'

const OptionSelectCard = ({
    className = 'option-select-option',
    children,
    multiSelect,
    id,
    name,
}) => {
    const value = multiSelect.includes(id)
    const onClick = () => multiSelect.select({ id, value: { id, name } })
    return (
        <div className={className} onClick={onClick}>
            <CheckBox value={value} />
            {children}
        </div>
    )
}

const qualifications = {
    query: (id) => `{
          allVendorQualifications(condition:{accountId:"${id}", active:true}){
            nodes{
                ${VendorQualification.fragment.toString()}
              onboardingQuestionSetByVendorQualificationId{
                ${QuestionSets.fragment.toString()}
              }
            }
          }
      }`,
    format: (data) => {
        const options = data.allVendorQualifications.nodes.map((item) => ({
            id: item.id,
            children: item.name,
        }))
        return { data: options }
    },
}

export default ({
    accountId, multiSelect, handleSubmit, popup,
}) => (
    <div className='qualifications-form'>
        <h2 className='qualifications-form-title'>Qualifications</h2>
        <div className='qualifications-form-description'>
            Select the new qualifications.
        </div>
        <div className='qualifications-form-scroll'>
            <Delv.query
                query={qualifications.query(accountId)}
                format={qualifications.format}
            >
                <Div className='order-by-inner'>
                    <Map>
                        <OptionSelectCard multiSelect={multiSelect} />
                    </Map>
                </Div>
            </Delv.query>
        </div>
        <div className='allocation-space-between'>
            <div
                className='styled-button allocation-form-button'
                onClick={popup.close}
            >
                Cancel
            </div>
            <div
                className='styled-button allocation-form-button'
                onClick={() => handleSubmit(multiSelect.selected)}
            >
                Submit
            </div>
        </div>
    </div>
)

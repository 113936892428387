import React from 'react'
import Button from 'common/Button'
import CircularProgress from 'common/CircularProgress'

const LoadingButton = ({
    label, loading, disabled, children, sx = {}, ...props
}) => (
    <Button variant='contained' {...props} disabled={disabled} sx={{ minWidth: '5.3125rem', ...sx }}>
        {loading ? <CircularProgress size='1.5rem' /> : children}
    </Button>
)

export default LoadingButton

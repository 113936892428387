import React from 'react'
import HoverToolTip from 'common/HoverToolTip.js'
import Done from '@material-ui/icons/Done'
import Cancel from '@material-ui/icons/Cancel'
import RemoveCircle from '@material-ui/icons/RemoveCircle'

export default ({ distribution }) => {
    let distributionQ = distribution.distributionQualificationsByDistributionId
    if (distributionQ) {
        distributionQ = distributionQ.nodes
    } else {
        distributionQ = []
    }

    const status = {
        qualified: 0,
        rejected: 0,
        unknown: 0,
    }

    const qualifications = (distributionQ.length) ? distributionQ.map(
        ({
            id,
            stepByStepId: {
                status: qualificationStatus,
            },
            vendorQualificationByVendorQualificationId: {
                name,
            },
        }) => {
            let statusIcon = ''
            if (qualificationStatus === 'complete') {
                statusIcon = <Done className='buyer-qualified' />
                status.qualified++
            } else if (qualificationStatus === 'failed') {
                statusIcon = <Cancel className='buyer-rejected' />
                status.rejected++
            } else {
                statusIcon = <RemoveCircle className='buyer-unknown' />
                status.unknown++
            }
            return (
                <div className='qualification-item' key={id}>
                    {name}
                    {' '}
                    {statusIcon}
                </div>
            )
        },
    ) : <div className='none-found'>No qualifications set.</div>

    let icon = <Done className='buyer-qualified' />

    if (status.rejected) {
        icon = <Cancel className='buyer-rejected' />
    } else if (status.unknown) {
        icon = <RemoveCircle className='buyer-unknown' />
    }

    return (
        <HoverToolTip width='15rem'>
            <div>{icon}</div>
            <div>{qualifications}</div>
        </HoverToolTip>
    )
}

import * as React from 'react'

import List from 'common/List'
import ListItem from 'common/ListItem'
import ListItemIcon from 'common/ListItemIcon'
import ListItemText from 'common/ListItemText'
import Link from 'common/Link'
import Typography from 'common/Typography'
import { getAccountType } from 'util/account-utils.js'

import { getPathName } from './utils.js'
import './styles.scss'

const NavLink = ({
    prefix, Icon, highlightOn, href, pathName, text, to, onClick,
}) => {
    const underline = highlightOn?.includes(pathName) ? 'always' : 'none'

    return (
        <ListItem className={prefix} dense>
            <Link className={`${prefix}__link`} href={href} onClick={onClick} to={to} underline={underline}>
                {
                    Boolean(Icon) && (
                        <ListItemIcon className={`${prefix}__icon`}>
                            <Icon sx={{ mr: 1.5 }} color='primary' />
                        </ListItemIcon>
                    )
                }
                <ListItemText>
                    <Typography variant='body1'>{text}</Typography>
                </ListItemText>
            </Link>
        </ListItem>
    )
}

const NavLinks = ({
    prefix = 'vrtl-nav-links', linkPrefix = 'vrtl-nav-link', links = [], getUsersData,
}) => {
    const accountType = getAccountType(getUsersData)
    const pathName = getPathName({ accountType })

    if (!Array.isArray(links)) {
        // eslint-disable-next-line no-param-reassign
        links = accountType && links && links[accountType] ? links[accountType] : []
    }

    return (
        <List className={prefix} data-testid='nav-links'>
            { links.map((link) => <NavLink key={link.text} pathName={pathName} prefix={linkPrefix} {...link} />) }
        </List>
    )
}

export default NavLinks

import React from 'react'

import SubNavLayout from 'layouts/sub-nav-layout.js'
import { delegateBreadcrumbs, selectSettingsTabs } from 'constants/settings'
import Delegates from 'account/Delegates.js'

const DelegateSettings = ({
    account,
    userAccount,
    user,
}) => {
    const canEdit = userAccount.role !== 'account_viewer'
    const isOwner = userAccount.role === 'account_owner'
    const tabs = selectSettingsTabs(account.type)

    return (
        <SubNavLayout breadcrumbs={delegateBreadcrumbs} tabs={tabs}>
            <Delegates account={account} canEdit={canEdit} isOwner={isOwner} user={user} />
        </SubNavLayout>
    )
}

export default DelegateSettings

import React from 'react'
import OrderBy from 'common/OrderBy.js'

import './styles.scss'

export default ({
    children,
    ...props
}) => {
    const prefix = 'vrtl-entity-order-by'
    const getAccountName = (node) => node.accountByEntityId.name

    const getFirstName = (node) => node.userByCreatedBy.firstName

    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field={getAccountName} className={`${prefix}__section--2`}>
                Name
            </OrderBy.filter>
            <OrderBy.filter field={({ accountByEntityId: { email } }) => email} className={`${prefix}__section--3`}>
                Email
            </OrderBy.filter>
            <OrderBy.filter field={getFirstName} className={`${prefix}__section--2`}>
                Created By
            </OrderBy.filter>
            <OrderBy.sort date field='createdOn' className={`${prefix}__section--2`}>
                Created On
            </OrderBy.sort>
            <div className={`${prefix}__icon-offset`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
        </OrderBy.bar>
    )
}

import React from 'react'

import { StatelessMultiSelect } from 'common/MultiSelect.js'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation.js'

import QualificationsForm from './QualificationsForm.js'

const createFragment = (
    aId,
    qId,
) => `q${qId.replace(/-/g, '')}:createAllocationQualification(input: {allocationQualification: {allocationId:"${aId}", vendorQualificationId:"${qId}"}}) {
    allocationQualification {
      id
      allocationId
      executeOrder
      vendorQualificationByVendorQualificationId {
        id
      }
      allocationByAllocationId {
        id
      }
    }
  }
  `

const formConfig = {
    fields: {
        qualification: {
            type: 'multiSelect',
            default: (props) => {
                const { qualifications } = props
                const data = {}
                if (qualifications) {
                    qualifications.nodes.forEach(
                        ({
                            vendorQualificationByVendorQualificationId: { id },
                        }) => {
                            data[id] = { id }
                        },
                    )
                }
                return data
            },
        },
    },
}

export default Form(
    ({
        accountId,
        qualifications,
        allocationId,
        popup,
        form: { qualification },
    }) => {
        const handleSubmit = (qual) => {
            const old = []
            let add = Object.keys(qual)
            if (qualifications) {
                qualifications.nodes.forEach(
                    ({ vendorQualificationByVendorQualificationId: { id } }) => {
                        old.push(id)
                    },
                )
            }
            add = add.filter((q) => !old.includes(q))
            let mutation = ''
            if (add.length > 0) {
                add.forEach((node) => {
                    mutation += createFragment(allocationId, node)
                })
            }
            if (mutation) {
                mutation = `mutation {
                ${mutation}
            }`
                new Mutation({
                    mutation,
                    onSubmit: () => ({}),
                    onResolve: popup.close,
                }).submit()
            } else {
                popup.close()
            }
        }

        return (
            <div className='qualifications-conatiner'>
                <StatelessMultiSelect {...qualification}>
                    <QualificationsForm
                        handleSubmit={handleSubmit}
                        accountId={accountId}
                        popup={popup}
                    />
                </StatelessMultiSelect>
            </div>
        )
    },
    formConfig,
)

import Delv from 'delv/delv-react'

class Fragment {
    constructor(fragment) {
        this.fragment = fragment
    }

    nest = (nestUnder, args, overrideArgs) => new Fragment(Delv.nest(nestUnder, this.fragment, args, overrideArgs))

    merge = (fragment) => new Fragment(`${this.fragment}\n${fragment instanceof Fragment ? fragment.toString() : fragment}`)

    toString = () => this.fragment
}

export default Fragment

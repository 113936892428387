import * as React from 'react'
import Inbox from '@mui/icons-material/Inbox'

import GridNoneFound from './grid-none-found.js'
import PageNoneFound from './page-none-found.js'

import './styles.scss'

const NONE_FOUND_MESSAGE = 'None Found.'
const PREFIX = 'vrtl-none-found'

// This method of using NoneFound is deprecated. See deprecation message at EOF
const NoneFoundLg = ({ children }) => (
    <div className={`${PREFIX}__wrapper`}>
        <div className={`${PREFIX}__container ${PREFIX}__container--lg ${PREFIX}__container--col`}>
            <Inbox className={`${PREFIX}__icon`} data-testid='inbox-icon' style={{ fontSize: '8rem' }} />
            <p className={`${PREFIX}__text ${PREFIX}__text--lg`}>{children}</p>
        </div>
    </div>
)

// This method of using NoneFound is deprecated. See deprecation message at EOF
const NoneFoundSm = ({ children }) => (
    <div className={`${PREFIX}__container`}>
        <Inbox className={`${PREFIX}__icon`} data-testid='inbox-icon' />
        <p className={`${PREFIX}__text`}>{children}</p>
    </div>
)

// This method of using NoneFound is deprecated. See deprecation message at EOF
const map = {
    lg: NoneFoundLg,
    sm: NoneFoundSm,
}

// This method of using NoneFound is deprecated. See deprecation message at EOF
const NoneFound = ({ children = NONE_FOUND_MESSAGE, size = 'sm' }) => {
    const Component = map[size]

    return <Component>{children}</Component>
}

// Default export should not be used for NoneFound.
// A name spaced export should be chosen based on usage context.
// If a none found does not exist for a needed context:
//  1. Create none found component at ./xxx-none-found.js
//  2. Export as name spaced module XxxNoneFound
export default NoneFound

export {
    GridNoneFound,
    PageNoneFound,
}

import * as React from 'react'
import CircularProgress from 'common/CircularProgress'
import Box from 'common/Box'
import Snackbar from 'common/Snackbar'
import Alert from 'common/Alert'
import useToggle from 'hooks/UseToggle'
import useMutation from 'delv/hooks/use-mutation'

import StepHeader from './step-header.js'
import StepDisplay from './step-display.js'

const COMPLETE_STEP = `mutation ($blob: JSON!, $id: UUID!) {
    completeStep(input: {blob: $blob, _stepId: $id}) {
      step {
        id
        status
      }
    }
  }`

const LEVEL_COMPLETE_STEP = (level) => `mutation ($blob: JSON!, $parentStepId: UUID!, $childStepId:UUID!) {
    complete${level}Step(input: {blob: $blob, stepId: $childStepId, ${level.toLowerCase()}StepId:$parentStepId}) {
       ${level.toLowerCase()}{
            id
            stepByStepId{
                id
                status
            }
       }
    }
}`

const StepBody = ({
    activeStep,
    currentStep,
    id,
    isCurrentStep,
    goToCurrentStep,
    level,
}) => {
    const mutation = level ? LEVEL_COMPLETE_STEP(level) : COMPLETE_STEP
    const [completeStep, { error }] = useMutation(mutation)
    const { toggle: isSnackbarOpen, disable: closeSnackbar, enable: openSnackbar } = useToggle()
    const { toggle: loading, disable: disableLoading, enable: enableLoading } = useToggle()

    React.useEffect(() => {
        if (error) {
            openSnackbar()
            disableLoading()
        }
    }, [error])

    // VERY DELICATE.
    // Ensures activeStep is equal to current step after web socket changes.
    React.useEffect(() => {
        if (currentStep.id !== activeStep.id) {
            disableLoading()
        }
    }, [currentStep, activeStep])

    // Handles loading state for asynchronous integrations.
    React.useEffect(() => {
        disableLoading()
    }, [activeStep.status])

    const variables = level
        ? { parentStepId: id, childStepId: activeStep.id }
        : { id: activeStep.id }

    const props = {
        activeStep,
        currentStep,
        isCurrentStep,
        goToCurrentStep,
        completeStep: (opts) => {
            enableLoading()
            completeStep(opts)
        },
        variables,
        openSnackbar,
    }

    return (
        <>
            <StepHeader
                activeStep={activeStep}
                currentStep={currentStep}
                isCurrentStep={isCurrentStep}
            />
            { loading
                ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <StepDisplay {...props} />
                )}
            <Snackbar
                open={isSnackbarOpen}
                onClose={closeSnackbar}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <div>
                    <Alert sx={{ mt: 9 }} variant='filled' severity='error'>Error. Please try again.</Alert>
                </div>
            </Snackbar>
        </>
    )
}

export default StepBody

import React from 'react'
import Box from 'common/Box'
import Steps from 'components/steps'
import Delv from 'delv/delv-react'
import Process from 'process/Process.js'
import Round from 'components/round/index.js'
import IOCard from 'components/investorOfferingDetailsById'
import AccountFragment from 'components/account/Fragment.js'
import AccountSetup from 'components/account/setup'
import { isMd, isSm } from 'constants/media-queries'
import PageLayout from 'layouts/page-layout'
import './styles.scss'

const DistributionQuery = {
    query: (id) => `{
      distributionById(id: "${id}") {
        id
        amount
        accountByInvestorId{
            ${AccountFragment.toString()}
        }
        ${Process.fragment}
        purchasePrice {
            id
            price
        }
        allocationByAllocationId {
          id
          accountByOwnerId{
            ${AccountFragment.toString()}
          }
          roundByRoundId {
            ${Round.fragment.toString()}
            unitValue
            trancheBondDatumByRoundId{
                ${Round.tranche.fragment.toString()}
            }
            assetByAssetId {
              id
              name
              type
              accountByIssuerId{
                id
                name
              }
            }
          }
        }
        distributionQualificationsByDistributionId {
          nodes {
            id
            integrationByIntegrationId {
              id
              service
              integrationPartnerByPartnerId {
                id
                name
                logo
              }
            }
          }
        }
      }
    }`,
    args: (props) => props.match.params.distribution_id,
}

const Distribution = (props) => {
    const {
        distributionById: {
            id,
            amount,
            accountByInvestorId,
            allocationByAllocationId: {
                accountByOwnerId: { name: sellerName },
                roundByRoundId: {
                    name: roundName,
                    termsUrl,
                    assetByAssetId: {
                        name: assetName,
                        accountByIssuerId: { name: issuerName },
                    },
                },
            },
            purchasePrice: {
                price,
            },
        },
    } = props

    const BREADCRUMBS = [
        {
            display: 'Investor Onboarding',
            link: '#',
        },
    ]

    const cardData = {
        type: 'Units',
        mode: 'view',
        id,
        issuerName,
        sellerName,
        assetName,
        className: roundName,
        classPrice: price,
        termsUrl,
        amount,
    }

    const containerSx = {
        display: 'flex',
        gap: 4,
        width: '100%',
        [isMd]: {
            flexWrap: 'wrap',
        },
        [isSm]: {
            justifyContent: 'center',
        },
    }

    return (
        <PageLayout breadcrumbs={BREADCRUMBS}>
            <Box sx={containerSx}>
                <IOCard {...cardData} />
                <Box sx={{ flex: 1 }}>
                    {
                        accountByInvestorId.setup ? (
                            <Steps level='Distribution' proc={props.distributionById.stepByStepId} />
                        ) : (
                            <AccountSetup />
                        )
                    }
                </Box>
            </Box>
        </PageLayout>
    )
}

export default Delv.queryHOC(Distribution, DistributionQuery)

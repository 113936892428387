import React from 'react'
import Div from 'common/Div.js'
import { Link } from 'react-router-dom'
import Map from 'common/Map.js'
import Collapsible from 'common/collapsible/index.js'
import Asset from 'components/asset/index.js'
import BrokerAllocation from 'components/brokerAllocation/index.js'
import GridChildProvider from 'common/grid/GridChildProvider.js'

import './styles.scss'

export default ({
    name,
    id,
    type,
    assetsByIssuerId,
}) => {
    let total = 0
    let approved = 0

    const assets = assetsByIssuerId.nodes.map((asset) => ({
        name: asset.name,
        id: asset.id,
        classes: {
            nodes: asset.roundsByAssetId.nodes.map((klass) => {
                const brokerAllocation = klass.brokerAllocationsByRoundId.nodes[0]
                total++
                if (brokerAllocation.status === 'accepted') {
                    approved++
                }
                return {
                    id: brokerAllocation.id,
                    status: brokerAllocation.status,
                    name: klass.name,
                }
            }),
        },
    }))

    if (total === 0) {
        return <div />
    }

    const prefix = 'vrtl-managed-rounds-card'

    return (
        <Collapsible.container className=''>
            <Div className={`${prefix}__container ${prefix}--border`}>
                <Collapsible.arrow prefix={prefix} />
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>
                        ISSUER
                    </div>
                    <Link to={`/${type}/${id}`}>{name}</Link>
                </div>
                <div className={`${prefix}__section`}>
                    <div className={`${prefix}__label`}>
                        ROUNDS PENDING
                    </div>
                    {`${approved} of ${total} accepted`}
                </div>
                <div className={`${prefix}__section`} />
            </Div>

            <Div>
                <Map data={assets}>
                    <Div className={`${prefix}__container ${prefix}--col ${prefix}--border-bottom`}>
                        <Asset.heading />
                        <GridChildProvider itemsPer={3} arrayKey='classes.nodes'>
                            <BrokerAllocation.card />
                        </GridChildProvider>
                    </Div>
                </Map>
            </Div>
        </Collapsible.container>
    )
}

import React from 'react'
import Alert from 'common/Alert'

const NetworkMessage = ({ message, severity, AlertProps = {} }) => (
    <Alert severity={severity} variant='filled' {...AlertProps}>
        {message}
    </Alert>
)

export default NetworkMessage

import * as React from 'react'

import FormConfig from 'common/FormV2/config'
import * as validators from 'common/FormV2/validators'
import { camelCaseToTitleCase } from 'util/format/string-format'

const selectValue = (e) => e.target.value

const selectKey = (e) => e.target.name

const selectDefaultValue = ({ fields, key }) => {
    if (fields[key].defaultValue || fields[key].defaultValue === null || fields[key].defaultValue === 0) {
        return fields[key].defaultValue
    }

    return FormConfig[fields[key].type]?.defaultValue || ''
}

const defaultFormValues = ({ fields, keys }) => {
    const formState = keys.reduce((input, key) => {
        const defaultValue = selectDefaultValue({ fields, key })

        input[key] = {
            value: defaultValue,
            errorExists: false,
        }

        return input
    }, {})

    return formState
}

const createFormConfig = ({
    fields,
    input,
    onBlur,
    onChange,
    keys,
}) => (
    keys.reduce((config, key) => {
        // Remove unnused fields
        const {
            type: _type,
            errorMessage: _errorMessage,
            ...configValues
        } = fields[key]

        config[key] = { ...configValues }
        config[key].name = fields[key].name || key
        config[key].__typename = fields[key].type
        // Default all fields to required
        config[key].required = !(fields[key].required === false)
        // Custom prop or happy defaults
        const fieldDefault = fields[key].defaultValue
        config[key].defaultValue = fieldDefault || fieldDefault === null || fieldDefault === 0 ? fieldDefault : FormConfig[fields[key].type]?.defaultValue
        config[key].label = fields[key].label || camelCaseToTitleCase(config[key].name)
        // Use foundational form functions
        config[key].onChange = onChange
        config[key].onBlur = onBlur
        // Merge information from input state
        config[key].value = input[key].value
        config[key].error = input[key].errorExists
        config[key].helperText = input[key].errorExists && fields[key].errorMessage ? fields[key].errorMessage : fields[key].helperText

        return config
    }, {})
)

const getValueAndError = ({ required, resolver, value: desiredValue }) => {
    const validatedValue = resolver(desiredValue)
    const value = validatedValue?.value !== undefined && validatedValue?.label === undefined ? validatedValue.value : validatedValue
    const errorExists = !required && (value === '' || value === undefined)
        ? false
        : Boolean(validatedValue?.errorExists)

    return { value, errorExists }
}

const useForm = ({ fields }) => {
    const keys = React.useMemo(() => Object.keys(fields), [fields])
    const defaultInput = React.useCallback(() => defaultFormValues({ fields, keys }), [])
    const [input, setInput] = React.useState(defaultInput)
    const onChange = (e) => {
        const desiredValue = selectValue(e)
        const key = selectKey(e)
        const defaultResolver = FormConfig[fields[key].type].onChange

        if (fields[key].onChange) {
            fields[key].onChange(key, desiredValue, {
                input,
                setInput,
                getValueAndError,
                defaultResolver,
                fields,
                validators,
            })
        } else {
            const { value, errorExists } = getValueAndError({
                resolver: defaultResolver,
                value: desiredValue,
                required: fields[key].required,
            })

            setInput({
                ...input,
                [key]: {
                    value,
                    errorExists,
                },
            })
        }
    }

    const onBlur = (e) => {
        const desiredValue = selectValue(e)
        const key = selectKey(e)
        const defaultResolver = FormConfig[fields[key].type].onBlur

        if (fields[key].onBlur) {
            fields[key].onBlur(key, desiredValue, {
                input,
                setInput,
                getValueAndError,
                defaultResolver,
                fields,
                validators,
            })
        } else {
            const { value, errorExists } = getValueAndError({
                resolver: defaultResolver,
                value: desiredValue,
                required: fields[key].required,
            })

            setInput({
                ...input,
                [key]: {
                    value,
                    errorExists,
                },
            })
        }
    }

    const reset = () => {
        setInput(defaultFormValues({ fields, keys }))
    }

    const config = createFormConfig({
        fields,
        input,
        onBlur,
        onChange,
        keys,
    })

    const validateInput = () => {
        let valid = true
        const newInput = Object.create(null)
        const validatedInput = Object.create(null)

        keys.forEach((key) => {
            const defaultResolver = FormConfig[fields[key].type].onSubmit
            const resolver = config[key].onSubmit ? config[key].onSubmit : defaultResolver
            const desiredValue = input[key]?.value
            const validatedValue = resolver(desiredValue, {
                input,
                setInput,
                defaultResolver,
                config,
            })

            const value = validatedValue?.value !== undefined && validatedValue?.label === undefined ? validatedValue.value : validatedValue
            const errorExists = !config[key].required && (value === '' || value === undefined)
                ? false
                : Boolean(validatedValue?.errorExists)

            if (value) {
                validatedInput[key] = value?.value ? value.value : value
            }

            if (errorExists) {
                valid = false
            }
            newInput[key] = {
                value: desiredValue,
                errorExists,
            }
        })

        setInput(newInput)
        return {
            valid,
            input: validatedInput,
        }
    }

    return {
        config,
        input,
        reset,
        validateInput,
        keys,
    }
}

export default useForm

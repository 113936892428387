import React from 'react'

import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation.js'

import Form from 'common/form/Form.js'
import { Dropdown } from 'common/FormUI.js'

import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'

import Fragment from 'onboardingQuestions/questionSets/Fragment.js'

const GET_QUESTIONS = `{
  allOnboardingQuestionSets(condition:{locked:true}){
    nodes{
      id
      name
      locked
    }
  }
}`

const CREATE_MAPPED_QUESTIONS = `mutation($templateId:UUID!, $questionId:UUID!){
  createMappedSigningTemplateQuestionSet(input:{mappedSigningTemplateQuestionSet:{mappedSigningTemplateId:$templateId, onboardingQuestionSetId:$questionId}}){
    mappedSigningTemplateQuestionSet{
      id
      mappedSigningTemplateId
      onboardingQuestionSetByOnboardingQuestionSetId{
        ${Fragment.toString()}
      }
    }
  }
}`

const formConfig = {
    fields: {
        name: {
            type: 'dropdownTypeahead',
            error: 'Please select a question set to add.',
        },
    },
}

const UI = ({
    allOnboardingQuestionSets,
    mappedTemplateId,
    resolve,
    form: {
        name,
        validateInput,
    },
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            new Mutation({
                mutation: CREATE_MAPPED_QUESTIONS,
                onSubmit: () => ({ templateId: mappedTemplateId, questionId: input.name }),
            }).submit()
        }
        resolve()
    }
    const options = allOnboardingQuestionSets.nodes.map(({ id, name: theName }) => ({ displayValue: theName, value: id }))
    const prefix = 'vrtl-signing-template' // im too laxy to create a new one right now
    return (
        <div className={`${prefix}__container`}>
            <div>
                <Dropdown {...name} options={options} />
            </div>
            <div className={`${prefix}__section`} />
            <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />
            <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />
        </div>
    )
}

export default Form(Delv.queryHOC(UI, { query: GET_QUESTIONS }), formConfig)

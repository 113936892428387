import * as React from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import DataGridToolbar from 'common/DataGridToolbar'
import Box from 'common/Box'
import ChipWithRef from 'common/ChipWithRef'
import Tooltip from 'common/Tooltip'
import Typography from 'common/Typography'

const PortfolioDataGridToolbar = ({
    querySelection,
    toggleQuerySelection,
    ...toolbarProps
}) => (
    <DataGridToolbar {...toolbarProps}>
        <Box sx={{ display: 'flex', flexDirection: 'column', pb: 0.5 }}>
            <Typography variant='caption'>
                Status
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
                {Boolean('onboarding' in querySelection) && (
                    <Tooltip title='Qualifying for investment in progress'>
                        <ChipWithRef
                            color='primary'
                            label='Onboarding'
                            size='small'
                            icon={querySelection.onboarding ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                            onClick={() => toggleQuerySelection('onboarding')}
                        />
                    </Tooltip>
                )}
                {Boolean('pending' in querySelection) && (
                    <Tooltip title='All required steps for this investment have been submitted and under review'>
                        <ChipWithRef
                            color='primary'
                            label='Pending'
                            size='small'
                            icon={querySelection.pending ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                            onClick={() => toggleQuerySelection('pending')}
                        />
                    </Tooltip>
                )}
                {Boolean('issued' in querySelection) && (
                    <Tooltip title='Investment is Issued.'>
                        <ChipWithRef
                            color='primary'
                            label='Issued'
                            size='small'
                            icon={querySelection.issued ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                            onClick={() => toggleQuerySelection('issued')}
                        />
                    </Tooltip>
                )}
            </Box>
        </Box>
    </DataGridToolbar>
)

export default PortfolioDataGridToolbar

import React from 'react'
import Delv from 'delv/delv-react'
import Card from 'common/Card.js'
import GridChildProvider from 'common/grid/GridChildProvider.js'
import HoverToolTip from 'common/HoverToolTip.js'

import './SendTestTemplate.scss'

const GET_MAPPING_FIELDS = `{
  allMappingFields{
    nodes{
      id
      description
      testValue
    }
  }
}`

const FieldCard = ({ id, description, testValue }) => (
    <HoverToolTip>
        <Card.section>
            <Card.label>{id}</Card.label>
            {testValue || 'N/A'}
        </Card.section>
        <div>{description}</div>
    </HoverToolTip>
)

const SendTestTemplate = () => (
    <div className='vrtl-signing-templates__container--templates'>
        <div className='gray-header row--justify-between'>Fields</div>
        <div className='vrtl-signing-test-description__container'>
            <div className='vrtl-signing-test-description__section'>
                Below is the mock data for all the fields that can be mapped from the vertalo system to docusign fields.
            </div>
            <div className='vrtl-signing-test-description__section'>
                The mapping values entered into the mapping template, or selected in the map to dropdown on the fields page is provided
                in parans next to the name of the data.
            </div>
            <div className='vrtl-signing-test-description__section'>
                Sending this test will postfix the vertalo provided values with the role name supplied with the field, this is because
                all recipients are set to the provided email address for testing. When the real documents are sent to the investor the
                role name will not appear. An example of this can be seen below:
                <div className='row'>
                    <Card.section>
                        <Card.label>Asset name (asset_name)</Card.label>
                        Test asset
                    </Card.section>
                    <Card.section>
                        <Card.label>Test will display as</Card.label>
                        Test asset (Issuer)
                    </Card.section>
                    <Card.section>
                        <Card.label>Real display for the "Issuer" role</Card.label>
                        Test asset
                    </Card.section>
                </div>
            </div>
        </div>
        <Delv.query query={GET_MAPPING_FIELDS}>
            <GridChildProvider arrayKey='allMappingFields.nodes'>
                <FieldCard />
            </GridChildProvider>
        </Delv.query>
    </div>
)

export default SendTestTemplate

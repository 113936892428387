const getAccountName = (userData) => userData?.account[0].name
const getAccountType = (userData) => userData?.account[0].type
const getUserRole = (userData) => userData?.userAccount[0].role.substring(8)
const getAccountInitials = (userData) => {
    const name = userData.account[0].name.replace(/[^a-zA-Z0-9 ]/g, '')

    const initials = name.split(' ').map((n) => n.substring(0, 1))

    return initials.join('')
}

export {
    getAccountName,
    getAccountType,
    getUserRole,
    getAccountInitials,
}

import * as React from 'react'

import Box from 'common/Box'
import Typography from 'common/Typography'

const DataGridFooter = ({
    loadAll,
    rows,
    totalCount = 0,
}) => {
    const viewAll = () => {
        if (rows.length === totalCount) {
            return
        }

        loadAll({ totalCount })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                px: 4,
                py: 2,
                gap: 0.5,
            }}
        >
            <Typography variant='body2'>{`${rows.length ? `1 - ${rows.length}` : 0} of `}</Typography>
            {
                loadAll ? (
                    <Typography color='primary' variant='body2' onClick={viewAll} sx={{ cursor: 'pointer' }}>{totalCount}</Typography>
                ) : (
                    <Typography variant='body2'>{totalCount}</Typography>
                )
            }
        </Box>
    )
}

export default DataGridFooter

import React from 'react'
import Div from 'common/Div.js'
import Map from 'common/Map.js'
import Delv from 'delv/delv-react.js'
import Security from 'components/security/index.js'
import SecurityListing from 'components/securityListing/index.js'
import Round from 'components/round/index.js'
import OrderBy from 'common/OrderBy.js'
import Asset from 'components/asset/index.js'
import OrderByHeader from './OrderByHeader.js'

import './styles.scss'

const SecuritiesPage = ({
    prefix = 'vrtl-securities-page',
    ...props
}) => {
    const GET_SECURITIES = {
        query: `{
            allSecurities {
              nodes {
                ${Security.fragment.toString()}
                securityListingBySecurityId {
                    ${SecurityListing.fragment.toString()}
                }
                roundsBySecurityId {
                  nodes {
                    ${Round.fragment.toString()}
                    assetByAssetId {
                      ${Asset.fragment.toString()}
                    }
                  }
                }
              }
            }
          }`,
        format: ({
            allSecurities,
        }) => {
            const orderByData = allSecurities.nodes.map((security) => {
                const atsName = security.securityListingBySecurityId?.accountByAtsId.name
                return { ...security, atsName }
            })
            return { orderByData }
        },
    }
    return (
        <Delv.query {...props} {...GET_SECURITIES}>
            <OrderBy.order>
                <OrderByHeader />
                <Map arrayKey='_orderBy.data'>
                    <Div className={`${prefix}__table--small ${prefix}--curved-top`}>
                        <Security.rowHeading
                            startSlot={<div className='inline-offset--2' />}
                        />
                        <Security.row
                            startSlot={<div className='inline-offset--2' />}
                        />
                    </Div>
                </Map>
            </OrderBy.order>
        </Delv.query>
    )
}

export default SecuritiesPage

import Row from './Row.js'
import Form from './Form.js'
import Fragment from './Fragment.js'
import RowHeading from './RowHeading.js'
import OrderByHeader from './OrderByHeader.js'
import Qualifications from './Qualifications.js'

import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    form: Form,
    rowHeading: RowHeading,
    orderByHeader: OrderByHeader,
    qualifications: Qualifications,
}
// this follows the pattern, but it is not very reusable

import React, { useEffect } from 'react'
import Box from 'common/Box'
import useFormView from 'hooks/UseFormView'
import Dropdown from 'common/Dropdown'
import { isMd } from 'constants/media-queries.js'
import states from './states.js'

const MailingAddress = ({
    fields, stateHandler, emitter, field, index, id, USA,
}) => {
    const {
        validateInput,
        formView: {
            lineOne,
            lineTwo,
            city,
            province,
            postalCode,
            country,
        },
        input,
        config,
    } = useFormView(fields)

    useEffect(() => {
        const submitMailingInput = () => {
            const { valid, input: mailingInput } = validateInput()
            if (valid) {
                stateHandler.address(field, index, { type: 'mailing', ...mailingInput })
            } else {
                stateHandler.error()
            }
        }

        emitter.on('submitInput', submitMailingInput, { id })
        return () => emitter.removeListener('submitInput', submitMailingInput, { id })
    })

    return (
        <Box sx={{
            gap: 4, display: 'flex', flexDirection: 'column', width: '100%',
        }}
        >
            <Box sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
                {lineOne}
                {lineTwo}
                {city}
            </Box>

            <Box sx={{ gap: 4, display: 'flex', [isMd]: { flexDirection: 'column' } }}>
                {country}
                {
                    input.country.value.value === USA.id ? (
                        <Dropdown name='province' label='State' options={states} fullWidth required onChange={config.province.onChange} />
                    ) : (
                        province
                    )
                }
                {postalCode}
            </Box>
        </Box>
    )
}

export default MailingAddress

import React, { useState } from 'react'
import Div from 'common/Div.js'
import Chip from 'common/Chip/index.js'
import cx from 'classnames'

import './styles.scss'

const Toggle = ({
    field,
    queries,
    multiSelect,
    children,
    shape,
    prefix = 'vrtl-query-selector-toggle',
}) => {
    const [selected, SetSelected] = useState(true)

    const onClick = () => {
        multiSelect.select({ value: { query: queries[field], field }, id: field })
        SetSelected(!selected)
    }

    return (
        <Div className={cx(`${prefix}__toggle`)}>
            <Chip shape={shape} onClick={onClick} isSelected={selected}>
                {children}
            </Chip>
        </Div>
    )
}

export default Toggle

import React from 'react'
import Done from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'
import Delete from '@material-ui/icons/Delete'

import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Input } from 'common/FormUI.js'

import Fragment from './Fragment.js'
import Row from './Row.js'

const CREATE_FIELD = `mutation($distributionId:UUID!,$value:String!, $name:String!){
  createDistributionCustomField(input:{distributionCustomField:{distributionId:$distributionId, value:$value, name:$name}}){
    distributionCustomField{
     ${Fragment}
     distributionId
      distributionByDistributionId{
        id
      }
    }
  }
}`

const UPDATE_FIELD = `mutation ($id: UUID!, $patch: DistributionCustomFieldPatch!) {
  updateDistributionCustomFieldById(input: {id: $id, distributionCustomFieldPatch: $patch}) {
    distributionCustomField {
      ${Fragment}
      distributionByDistributionId {
        id
      }
    }
  }
}`
const DELETE_FIELD = `mutation ($id: UUID!) {
  deleteDistributionCustomFieldById(input: {id: $id}) {
    distributionCustomField {
      id
      distributionByDistributionId {
        id
      }
    }
  }
}`

const FieldCardForm = ({
    id,
    distributionId,
    resolve,
    form: {
        name,
        value,
        setLoading,
        validateInput,
        loading,
        getChanged,
    },
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    new Mutation({
                        mutation: UPDATE_FIELD,
                        onSubmit: () => {
                            setLoading('create')
                            return {
                                id,
                                patch: changedInput,
                            }
                        },
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            } else {
                new Mutation({
                    mutation: CREATE_FIELD,
                    onSubmit: () => {
                        setLoading('create')
                        return {
                            distributionId,
                            ...input,
                        }
                    },
                    onResolve: resolve,
                }).submit()
            }
        } else {
            resolve()
        }
    }
    const deleteRow = (event) => {
        event.preventDefault()
        new Mutation({
            mutation: DELETE_FIELD,
            onSubmit: () => {
                setLoading('delete')
                return {
                    id,
                }
            },
            onResolve: resolve,
            cacheProcess: 'delete',
        }).submit()
    }

    if (loading === 'create') {
        return <Row name={name.value} value={value.value} />
    } if (loading === 'delete') {
        return <div />
    }

    const prefix = 'vrtl-custom-mapping-fields'
    const rowProps = {
        name: <Input {...name} />,
        value: <Input className='relative' {...value} />,
        remove: id && <Delete className={`${prefix}__icon-delete`} onClick={deleteRow} />,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        key: `form-${id}`,
    }

    return <Row {...rowProps} />
}
const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'name is required.',
        },
        value: {
            type: 'string',
            error: 'value is required.',
        },
    },
}

export default Form(FieldCardForm, formConfig)

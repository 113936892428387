import useQuery from 'delv/hooks/use-query'

const GET_USERS_DATA = `query UserData {
    getUsersData {
        user
        account
        userAccount
    }
}`

const UseUsersData = (options = {}) => {
    const result = useQuery(GET_USERS_DATA, {
        cacheProcess: 'query',
        ...options,
    })

    return result
}

export default UseUsersData

import * as React from 'react'

import AppBar from 'common/AppBar'
import NavLinks from 'common/Nav/Links'
import NavLogo from 'common/Nav/Logo'
import NavOptions from 'common/Nav/Options'
import Toolbar from 'common/Toolbar'
import Pinstripe from 'common/Pinstripe'
import { NAV_LINKS } from 'constants/links.js'
import useUsersData from 'hooks/UseUsersData'
import { accountTypeRedirect } from 'common/userData/UserData.js'

import './styles.scss'

const Nav = ({ prefix = 'vrtl-nav', baseUrl = '/' }) => {
    const { data, loading } = useUsersData()
    const accountType = data?.getUsersData?.account[0].type

    if (!data || loading) {
        return (
            <AppBar color='transparent' data-testid='nav' position='static'>
                <Toolbar className={`${prefix} ${prefix}--loading`} as='nav'>
                    <NavLogo baseUrl={baseUrl} />
                    <NavOptions getUsersData={undefined} />
                </Toolbar>
                <Pinstripe />
            </AppBar>
        )
    }

    return (
        <AppBar color='transparent' data-testid='nav' position='static'>
            <Toolbar className={prefix} as='nav'>
                <NavLogo baseUrl={accountTypeRedirect[accountType]} />
                <NavLinks links={NAV_LINKS} getUsersData={data.getUsersData} />
                <NavOptions getUsersData={data.getUsersData} />
            </Toolbar>
            <Pinstripe />
        </AppBar>
    )
}

export default Nav

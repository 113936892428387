import React from 'react'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'
import Popup from 'common/Popup.js'

import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation.js'
import { Input, Dropdown } from 'common/FormUI.js'

import Row from './Row.js'
import Fragment from './Fragment.js'

const UPDATE_FIELD = `mutation($id:UUID!, $patch:DistributionPatch!){
  updateDistributionById(input:{id:$id, distributionPatch:$patch}){
    distribution{
      ${Fragment.toString()}
    }
  }
}`

const DELETE_FIELD = `mutation($id:UUID!){
    deleteDistributionById(input:{id:$id}){
     distribution{
      ${Fragment.toString()}
    }
  }
}`

const STATUS = [
    {
        status: 'Drafted',
        value: 'drafted',
    },
    {
        status: 'Open',
        value: 'open',
    },
    {
        status: 'Closed',
        value: 'closed',
    },
]

const DeleteModal = ({
    popup, handleSubmit, contactName, prefix = 'vrtl-buyer-row',
}) => (
    <div>
        <h3>
            Delete Investor?
        </h3>
        <div>
            {`Are you sure you want to delete ${contactName} from this distribution?`}
        </div>
        <div className={`${prefix}__container--modal`}>
            <div className={`${prefix}__button--basic ${prefix}`} onClick={popup.close}>
                Cancel
            </div>
            <div className={`${prefix}__button--delete ${prefix}`} onClick={handleSubmit}>
                Delete
            </div>
        </div>
    </div>
)

const BuyerForm = ({
    id,
    distributionId,
    multiSelect,
    form: {
        status, amount, validateInput, getChanged, setLoading, loading,
    },
    resolve,
    ...props
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const changedInput = getChanged(input)
            if (changedInput) {
                const submitValue = {
                    id: distributionId,
                    patch: input,
                }
                setLoading('create')
                new Mutation({
                    mutation: UPDATE_FIELD,
                    onFetch: (promise, data) => {
                        data.queryManager.remove('roundPage', true)
                    },
                    onSubmit: () => submitValue,
                    onResolve: resolve,
                }).submit()
            } else {
                resolve()
            }
        }
    }

    const deleteRow = (event) => {
        event.preventDefault()

        setLoading('delete')

        new Mutation({
            mutation: DELETE_FIELD,
            onFetch: (promise, data) => {
                data.queryManager.remove('roundPage')
            },
            onSubmit: () => ({ id }),
            onResolve: () => {
                multiSelect.select([])
                resolve()
            },
            onError: (data) => {
                window.alert(data[0].message)
            },
            cacheProcess: 'delete',
        }).submit()
    }

    const prefix = 'vrtl-buyer-row'

    if (loading === 'create') {
        return <Row amount={amount.value} status={status.value} />
    }
    if (loading === 'delete') {
        return <div />
    }

    const removeModal = (
        <Popup.popup>
            <Popup.inner>
                <DeleteModal prefix={prefix} contactName={props.contactName} handleSubmit={deleteRow} />
            </Popup.inner>
            <Popup.trigger className={`${prefix}__icon-container`}>
                <Delete Delete className={`${prefix}__icon-delete `} />
            </Popup.trigger>
        </Popup.popup>
    )

    const displayProps = {
        ...props,
        key: `${prefix}-${id}`,
        amount: props.locked ? amount.value : <Input {...amount} />,
        status: <Dropdown options={STATUS} {...status} />,
        remove: !props.locked && id && removeModal,
        cancel: <Cancel className={`${prefix}__icon-cancel`} onClick={resolve} />,
        submit: <Done className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
    }

    return <Row {...displayProps} />
}

const formConfig = {
    fields: {
        status: {
            type: 'dropdown',
        },
        amount: {
            type: 'number',
        },
    },
}

export default Form(BuyerForm, formConfig)

import React from 'react'
import { ReactQuery } from 'delv/delv-react'
import Loading from 'common/loading/index.js'
import SafeReact from 'util/SafeReact.js'

const GET_USER_DATA = `query UserData {
  getUsersData {
    user
    account
    userAccount
  }
}`

const accountTypeRedirect = new Proxy({
    issuer: '/dashboard',
    broker: '/dashboard',
    agent: '/distributions',
    ats: '/securities',
    investor: '/portfolio',
    admin: '/admin/',
}, {
    get: (target, key) => target[key] || window.location.href,
    set: (target) => target,
})

export { accountTypeRedirect }

function UserData(props) {
    const user = props.getUsersData.user[0]
    const userAccount = props.getUsersData.userAccount[0]
    const account = props.getUsersData.account[0]
    const userRole = userAccount.role
    if (props.accounts && !props.accounts.includes(account.type)) {
        window.location.href = accountTypeRedirect[account.type]

        return <div />
    }
    if (props.roles && !props.roles.includes(userRole)) {
        return <div />
    }
    const {
        accounts, roles, children, ...otherProps
    } = props
    return SafeReact.cloneChildren(props.children, {
        account,
        userAccount,
        user,
        ...otherProps,
    })
}

export default (WrappedComponent, data) => {
    let accounts = false
    let roles = false
    if (data) {
        accounts = data.accounts
        roles = data.roles
    }
    return (props) => (
        <ReactQuery
            query={GET_USER_DATA}
            cacheProcess='query'
            loading={<Loading.fullPage />}
            ignoreLoadDelay
        >
            <UserData accounts={accounts} roles={roles}>
                <WrappedComponent {...props} />
            </UserData>
        </ReactQuery>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import DivFactory from './DivFactory.js'
import './SideNav.scss'

const Item = ({ view, location }) => {
    let link = location.pathname.split('/')
    const route = link.pop()
    link = [...link, view.route].join('/')

    const className = cx('vrtl-round-nav__tab', {
        'vrtl-round-nav__tab--active': route === view.route,
    })

    return (
        <Link to={link} className={className}>
            {view.navLabel}
        </Link>
    )
}

export default {
    nav: DivFactory('vrtl-round-nav__container'),
    item: Item,
}

import React from 'react'
import Format from 'util/format/Format.js'
import TrendingUp from '@material-ui/icons/TrendingUp'
import TrendingDown from '@material-ui/icons/TrendingDown'
import { Link } from 'react-router-dom'

export default (props) => {
    const account = props.accountByInvestorId
    const token = props.tokenByTokenId
    let klass
    let asset
    if (token.type !== 'Fiat') {
        klass = token.roundByTokenId
        asset = klass.assetByAssetId
    } else {
        asset = {
            name: token.name,
            type: token.type,
        }
    }
    const bca = props.blockchainAddressByBlockchainAddressId

    const amount = Format.numeric(props.amount, token.precision)
    return (
        <div className='ledger-card'>
            <div className='ledger-card-header'>
                {
                    account
                        ? (
                            <Link to={`/${account.type}/${account.id}`}>
                                {account.contactName}
                            </Link>
                        )
                        : (
                            <div>
                                {' '}
                                {props.isCapitalDeployment ? 'Capital deployment' : 'Unknown'}
                                {' '}
                            </div>
                        )
                }

                {amount.startsWith('-') ? (
                    <div className='trending-down'>
                        <TrendingDown />
                    </div>
                ) : (
                    <div className='trending-up'>
                        <TrendingUp />
                    </div>
                )}
            </div>
            <div>
                <Link>{Format.blockchainAddress(bca.address)}</Link>
                {bca.chain === 'vertalon-hawknet'
                    ? bca.accountsBlockchainAddressesByBlockchainAddressId.nodes
                        .length
                        ? ` (${bca.accountsBlockchainAddressesByBlockchainAddressId.nodes[0].name})`
                        : ' (Direct transfer)'
                    : ` On ${bca.chain}`}
            </div>
            <div>
                {amount}
                {' '}
                of
                {token.type !== 'Fiat' ? asset.name : asset.name}
            </div>
            <div>
                Created On:
                {Format.date(props.createdOn)}
            </div>
        </div>
    )
}

import SafeReact from 'util/SafeReact.js'

import './styles.scss'

const Select = ({
    queries,
    children,
    ...props
}) => {
    let collectedQuerries
    const len = props.multiSelect.getValues().length
    if (len === Object.values(queries).length) {
        return SafeReact.cloneChildren(children, {
            ...props, queries, noneFound: true, query: collectedQuerries,
        })
    }

    if (len) {
        const selected = props.multiSelect.getValues()

        const filteredQuerries = Object.entries(queries).map((entry) => {
            if (selected.some((element) => element.field !== entry[0])) {
                return entry[1]
            }
            return null
        })

        collectedQuerries = `{
            ${filteredQuerries.join('\n')}
        }`
    } else {
        collectedQuerries = `{
            ${Object.values(queries).join('\n')}
        }`
    }
    return SafeReact.cloneChildren(children, { ...props, queries, query: collectedQuerries })
}

export default Select

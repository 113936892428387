import React from 'react'

import Container from 'common/Container'
import Nav from 'common/Nav'
import Breadcrumbs from 'common/Breadcrumbs'
import Footer from 'common/Footer'
import { isIssuer } from 'util/permissions.js'

import BrokerView from './BrokerView.js'
import IssuerView from './IssuerView.js'
import './styles.scss'

const BREADCRUMBS = [
    {
        display: 'Assets',
        link: '/assets',
        active: true,
    },
]

const Assets = (props) => (
    <>
        <Nav />
        <Container>
            <Breadcrumbs items={BREADCRUMBS} />
            {isIssuer(props) ? <IssuerView {...props} /> : <BrokerView {...props} />}
        </Container>
        <Footer />
    </>
)

export default Assets

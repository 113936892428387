import React from 'react'
import Edit from '@material-ui/icons/Edit'

import IconHelper from 'common/IconHelper.js'

export default ({
    edit,
    cancel,
    submit,
    remove,
    executeOrder,
    vendorQualificationByVendorQualificationId: {
        name,
    },
}) => {
    const prefix = 'vrtl-qualification'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section`}>{executeOrder}</div>
            <div className={`${prefix}__section--3`}>{name}</div>
            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

import React, { useState } from 'react'
import DateTime from 'react-datetime'
import CB from '@material-ui/icons/CheckBox'
import Arrow from '@material-ui/icons/KeyboardArrowDown'
import CloseIcon from '@material-ui/icons/Close'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import PhoneInput from 'react-phone-number-input'
import Format from 'util/format/Format.js'

import { makeFormComponents } from './BareFormUI.js'
import { styled } from './unrender.js'

import 'react-phone-number-input/style.css'
import 'react-datetime/css/react-datetime.css'
import './FormUI.css'

const FieldContainer = styled(<div className='relative input-offset' />)

const {
    input: Input,
    date: Date,
    checkbox: CheckBox,
    radio: RadioButton,
    phone: PhoneNumber,
    error: ErrorWrapper,
    password: Password,
} = makeFormComponents({
    input: {
        container: FieldContainer,

        inner: styled(<input className='styled-input' />),
    },

    date: {
        container: FieldContainer,

        inner: (props) => <DateTime className='styled-datetime' data-testid={props['data-testid']} format {...props} />,
    },
    checkbox: {
        filled: styled(<CB className='icon-offset' />),

        unfilled: styled(<CheckBoxOutlineBlank className='icon-offset' />),
    },

    radio: {
        checked: styled(<RadioButtonChecked className='icon-offset' />),

        unchecked: styled(<RadioButtonUnchecked className='icon-offset' />),
    },

    phoneNumber: {
        input: (props) => (
            <PhoneInput defaultCountry='US' international withCountryCallingCode placeholder='Enter phone number' {...props} />
        ),
    },

    password: {
        container: FieldContainer,

        inner: styled(<input className='styled-input' />),
    },

    errorWrapper: FieldContainer,

    errorMessage: (props) => (
        <div {...props} className={['absolute-error', !props.children && 'hidden', props.className].filter(Boolean).join(' ')} />
    ),
})

const TextArea = styled(<textarea className='styled-textarea' />)

const Button = styled(<div className='styled-button' />)

const Dropdown = ({
    // dropdown wasnt working well so im replacing it with
    id,
    options,
    name,
    onHover,
    onChange,
    value,
    defaultLabel = 'Select',
    error,
    typeahead,
    defaultValue,
    'data-testid': testId,
    ...props
}) => {
    const [open, setOpen] = useState(!!(defaultValue && typeahead))

    const findValueFromDisplayValue = (displayVal, fullValue) => {
        if (displayVal) {
            if (typeof displayVal === 'string') {
                const filtered = options.filter((a) => a.displayValue.toLowerCase().startsWith(displayVal.toLowerCase()))
                if (filtered.length === 1) {
                    return fullValue ? filtered[0] : filtered[0].value
                }
            }
        }
        return null
    }

    const [inputState, setInputState] = useState({
        displayValue: defaultValue || '',
        value: findValueFromDisplayValue(defaultValue),
    })

    const openPopup = () => {
        if (!open) {
            setOpen(true)
        }
    }
    const closePopup = () => {
        if (open) {
            setOpen(false)
        }
    }

    const togglePopup = () => {
        setOpen(!open)
    }

    const selected = options.filter((item) => item.value === value)[0] || {}

    const items = options
        .map((element) => {
            const displayValue = element.displayValue || element[name]
            if (typeahead && !displayValue.toLowerCase().startsWith(inputState.displayValue.toLowerCase())) {
                return false
            }
            const onClick = () => {
                closePopup()
                if (typeahead) {
                    setInputState(element)
                }
                onChange(Format.DOMEvent(name, element.value))
            }

            const handleHover = onHover ? () => onHover(Format.DOMEvent(name, element.value)) : () => {}

            return (
                <div className='dropdown-item' onClick={onClick} onMouseEnter={handleHover} key={element.value}>
                    {displayValue}
                </div>
            )
        })
        .filter((a) => a)

    let trigger
    if (typeahead) {
        const keyDown = (event) => {
            if (event.key === 'Enter') {
                const valueFromDisplay = findValueFromDisplayValue(inputState.displayValue, true)
                if (valueFromDisplay) {
                    event.preventDefault()
                    onChange(Format.DOMEvent(name, valueFromDisplay.value))
                    setInputState(valueFromDisplay)
                    closePopup()
                }
            } else {
                openPopup()
            }
        }
        trigger = (
            <div className='vrtl-dropdown-typeahead-container'>
                <input
                    onKeyDown={keyDown}
                    onClick={openPopup}
                    onChange={(e) => setInputState({ displayValue: e.target.value, value: null })}
                    value={inputState.displayValue}
                    placeholder='Select'
                    data-testid={testId}
                />
                {inputState.value === null ? (
                    <Arrow onClick={togglePopup} className='vrtl-dropdown__icon--typeahead' />
                ) : (
                    <CloseIcon
                        className='vrtl-icon vrtl-dropdown__icon--typeahead'
                        onClick={() => {
                            setInputState({ displayValue: '', value: null })
                            onChange(Format.DOMEvent(name, ''))
                            closePopup()
                        }}
                    />
                )}
            </div>
        )
    } else {
        trigger = (
            <div className='dropdown-trigger' data-testid={testId} style={{ width: props.width }} onClick={togglePopup}>
                {selected[name] || selected.displayValue || defaultLabel}
                <Arrow className='vrtl-icon' />
            </div>
        )
    }

    return (
        <div className='relative input-offset dropdown-trigger-container' id={id || ''}>
            <div className='absolute-error'>{error}</div>
            {trigger}
            {open ? (
                <div className={`dropdown-items ${typeahead ? 'margin-right' : ''}`}>
                    {items.length ? items : <div className='dropdown-item'>None found</div>}
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

export default {
    input: Input,
    button: Button,
    date: Date,
    dropdown: Dropdown,
    checkbox: CheckBox,
    radionButton: RadioButton,
    textArea: TextArea,
    phoneNumber: PhoneNumber,
    password: Password,
    phone: PhoneNumber, // this is a legacy export, need to move to phoneNumber
}

export {
    Input, Button, Date, Dropdown, CheckBox, RadioButton, TextArea, ErrorWrapper, PhoneNumber, Password,
}

import React, { Component, useState } from 'react'
import Sort from '@material-ui/icons/Sort'
import Remove from '@material-ui/icons/Remove'
import { Dropdown, CheckBox } from 'common/FormUI.js'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'
import objectPath from 'object-path'
import numeral from 'numeral'

import './OrderBy.scss'

const SORTS = [
    {
        icon: <Remove className='vrtl-icon' />,
        sort: () => false,
    },
    {
        icon: <Sort className='vrtl-icon asc-sort-icon' />,
        sort: (key) => (a, b) => a[key] - b[key],
    },
    {
        icon: <Sort className='vrtl-icon' />,
        sort: (key) => (a, b) => b[key] - a[key],
    },
]

const OrderByItem = ({ className, children }) => <div className={cx(className)}>{children}</div>

const OrderBySelect = ({
    className = 'order-by-checkbox-container',
    multiSelect,
    _orderBy: {
        arrayKey,
        ...orderByProps
    },
    map = (item) => item.id,
}) => {
    const [selected, setSelected] = useState(false)
    const toggleSelected = () => {
        if (selected) {
            multiSelect.select([])
        } else {
            multiSelect.select(objectPath.get(orderByProps, arrayKey).map(map))
        }
        setSelected(!selected)
    }
    return (
        <div onClick={toggleSelected} className={cx(className)}>
            <CheckBox value={selected} className={cx(className)} />
        </div>
    )
}

// Field does not support functions, requires a string
const OrderBySort = ({
    className = 'order-by-field',
    addClassName = '',
    _orderBy: {
        setSort,
    },
    children,
    field,
    date,
    castToNumber,
}) => {
    const [sort, setSorts] = useState(0)
    const handleSortChange = () => {
        const sortIndex = (sort + 1) % SORTS.length
        const sortFunc = SORTS[sortIndex].sort(field)
        if (sortFunc) {
            if (castToNumber && date) {
                throw new Error('Passing both cases to order by is illegal.')
            }
            if (date) {
                setSort((a, b) => sortFunc({
                    [field]: new Date(a[field]).getTime(),
                }, {
                    [field]: new Date(b[field]).getTime(),
                }))
            } else if (castToNumber) {
                setSort((a, b) => sortFunc({
                    [field]: numeral(a[field]).value(),
                }, {
                    [field]: numeral(b[field]).value(),
                }))
            } else {
                setSort(sortFunc)
            }
        }

        setSorts(sortIndex)
    }

    return (
        <div onClick={handleSortChange} className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(children)}
            {SORTS[sort].icon}
        </div>
    )
}

const OrderByDropdown = ({
    className = 'order-by-row',
    _orderBy: {
        dropdownFeilds: orderByDropdownFields,
        setFilter,
    },
    children,
    field,
    dropdownFields,
    // width = '8rem',
}) => {
    const [dropdown, setDropdown] = useState('any')

    const fields = dropdownFields || orderByDropdownFields
    const filterFactor = (string) => (value) => value[field] === string
    const handleInput = (event) => {
        const value = event.target.value
        setDropdown(value)
        if (value === 'any') {
            setFilter(false)
        } else {
            setFilter(filterFactor(value))
        }
    }
    return (
        <div className={cx(className)}>
            {SafeReact.cloneChildren(children)}
            <div className='order-by-dropdown'>
                <Dropdown
                    onChange={handleInput}
                    name={field}
                    value={dropdown}
                    options={Object.values(fields[field])}
                />
            </div>
        </div>
    )
}

const OrderByFilter = ({
    className = 'order-by-field',
    addClassName = '',
    inputClassName,
    _orderBy: {
        setFilter,
    },
    children,
    field,
}) => {
    // eslint-disable-next-line no-unused-vars
    const [input, setInput] = useState('')

    const filterFactor = (string) => (value) => {
        if (field instanceof Function) {
            return field(value).toLowerCase().startsWith(string.toLowerCase())
        } if (value[field] instanceof Array) {
            return value[field].some((element) => element.toLowerCase().startsWith(string.toLowerCase()))
        }
        return (value[field] || '').toLowerCase().startsWith(string.toLowerCase())
    }
    const handleInput = (event) => {
        const value = event.target.value
        setInput(value)
        if (value === '') {
            setFilter(false)
        } else {
            setFilter(filterFactor(value))
        }
    }

    return (
        <div className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(children)}
            <div className={cx('order-by-input', inputClassName)}>
                <input
                    onChange={handleInput}
                    className='styled-input'
                    placeholder='Search'
                />
            </div>
        </div>
    )
}

const OrderByBar = ({
    className = 'order-by-bar',
    addClassName = '',
    _orderBy,
    children,
    ...props

}) => {
    const setFilterFactory = (index) => (filter) => _orderBy.setFilter(index, filter)
    return (
        <div className={cx(className, addClassName)}>
            {SafeReact.cloneChildren(children, ({ context: { index } }) => ({
                _orderBy: {
                    ..._orderBy,
                    setFilter: setFilterFactory(index),
                },
                ...props,
            }))}
        </div>
    )
}

class OrderBy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: {},
            maps: {},
            sort: false,
        }
    }

    setSort = (sort) => this.setState({ sort })

    setMap = (key, map) => {
        this.setState({
            maps: {
                // eslint-disable-next-line
                ...this.state.maps,
                [key]: map,
            },
        })
    }

    setFilter = (key, filter) => {
        this.setState({
            filters: {
                // eslint-disable-next-line
                ...this.state.filters,
                [key]: filter,
            },
        })
    }

    addValueToDropdown = (currentFields, element) => {
        if (this.props.dropdownFields) {
            this.props.dropdownFields.forEach((field) => {
                const elementFieldValue = element[field]
                if (!currentFields[field][elementFieldValue]) {
                    const newField = {
                        [field]: elementFieldValue,
                        value: elementFieldValue,
                    }

                    // eslint-disable-next-line
                    currentFields[field][elementFieldValue] = newField
                }
            })
        }
    }

    filterData = (data) => {
        const filters = Object.values(this.state.filters)
        const dropdownFields = Object.create(null)
        if (this.props.dropdownFields) {
            this.props.dropdownFields.forEach((field) => {
                const baseField = Object.create(null)
                baseField.none = {
                    [field]: 'Any',
                    value: 'any',
                }
                dropdownFields[field] = baseField
            })
        }

        const filteredData = data.filter((element) => {
            this.addValueToDropdown(dropdownFields, element)
            for (const filter of filters) {
                if (filter) {
                    if (!filter(element)) {
                        return false
                    }
                }
            }
            return true
        })
        return {
            filteredData,
            dropdownFields,
        }
    }

    sortData = (data) => {
        if (this.state.sort) {
            return data.sort(this.state.sort)
        }
        if (this.props.sort) {
            return data.sort(this.props.sort)
        }
        return data
    }

    mapData = (data) => {
        const maps = Object.values(this.state.maps)
        if (maps.length) {
            return data.map((element) => {
                for (const map in maps) {
                    if (map) {
                        // eslint-disable-next-line
                        element = map(element)
                    }
                }
                return element
            })
        }
        return data
    }

    handlePropArray = (arr) => {
        const { filteredData, dropdownFields } = this.filterData(arr)
        const mappedData = this.mapData(filteredData)
        this.sortData(mappedData) // mutates mappedData
        return {
            filteredData,
            dropdownFields,
            mappedData,
        }
    }

    render = () => {
        const {
            children,
            dropdownFields: remove,
            arrayKey,
            orderByData,
            ...otherProps
        } = this.props
        const data = arrayKey ? objectPath.get(otherProps, arrayKey) : orderByData
        if (data instanceof Array) {
            const newProps = this.handlePropArray(data)
            const dataKey = arrayKey || 'data'
            return SafeReact.cloneChildren(children, {
                _orderBy: {
                    [dataKey]: newProps.filteredData,
                    setFilter: this.setFilter,
                    setSort: this.setSort,
                    arrayKey: dataKey,
                    dropdownFeilds: newProps.dropdownFields, // this prop order matters
                },
                ...otherProps, // this prop order matters
            })
        }
        let dropdownFields = []
        const newProps = {}
        let temp

        const dataKey = arrayKey || 'data'
        Object.keys(data).forEach((key) => {
            temp = this.handlePropArray(data[key])
            newProps[key] = temp.filteredData
            dropdownFields = [...dropdownFields, ...data[key]]
        })
        return SafeReact.cloneChildren(children, {
            _orderBy: {
                ...newProps,
                setFilter: this.setFilter,
                setSort: this.setSort,
                arrayKey: dataKey,
                dropdownFeilds: this.handlePropArray(dropdownFields).dropdownFields, // this prop order matters
            },
            ...otherProps, // this prop order matters
        })
    }
}
export default {
    order: OrderBy,
    bar: OrderByBar,
    filter: OrderByFilter,
    sort: OrderBySort,
    select: OrderBySelect,
    dropdown: OrderByDropdown,
    item: OrderByItem,
}

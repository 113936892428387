import Document from 'components/document/index.js'

export default {
    blockchain_registrations: {
        query: (id) => `{
      blockchainRegistrationById(id:"${id}") {
        id
        status
        toAddress
        amount
        chain
        blockchainAddressByBlockchainAddressId {
          id
        }
      }
  }`,
        onResolve: ({
            data: {
                blockchainRegistrationById: { status, id },
            },
            type,
            ws,
        }) => {
            if (status === 'registered') {
                ws.unsubscribe({ type, ids: [id] })
            }
        },
    },
    tokens: {
        query: (id) => `{
          tokenById(id:"${id}"){
            id
            status
            contractsByTokenId{
              nodes{
                id
                status
                contractAddress
              }
            }
          }
        }`,
        onResolve: () => {},
    },
    contracts: {
        query: (id) => `{
          contractById(id: "${id}") {
            id
            chain
            status
            contractAddress
            integrationByCustodianId{
              id
              integrationPartnerByPartnerId{
                id
                name
              }
            }
            integrationByIssuancePlatformId{
              id
              integrationPartnerByPartnerId{
                id
                name
              }
            }
            tokenByTokenId {
              id
              status
              capTablesByTokenId {
                nodes {
                  id
                  amount
                  blockchainAddressByBlockchainAddressId {
                    id
                    chain
                    address
                    accountsBlockchainAddressesByBlockchainAddressId {
                      nodes {
                        id
                        name
                        accountByAccountId {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
              roundByTokenId {
                id
                allocationsByRoundId {
                  nodes {
                    id
                    status
                  }
                }
              }
            }
          }
        }`,
    },
    vendor_integrations: {
        query: (id) => `{
          accountIntegrationById(id: "${id}") {
            id
            stepStatus
            complete
            stepError
            vendorQualificationsByVendorIntegrationId {
              nodes {
                id
                integrationByIntegrationId {
                  id
                  service
                  integrationPartnerByPartnerId {
                    id
                    name
                  }
                }
              }
            }
            integrationByIntegrationId {
              id
            }
            integrationsIntegrationRequirementByCurrentStep {
              id
              integrationRequirementByRequirementId {
                id
                integrationRequirementFieldsByIntegrationRequirementId {
                  nodes {
                    id
                    order
                    displayKey
                    key
                    type
                    error
                    regex
                  }
                }
              }
            }
          }
      }`,
        onResolve: ({
            data: {
                accountIntegrationById: { id, complete },
            },
            type,
            ws,
        }) => {
            if (complete) {
                ws.unsubscribe({ type, ids: [id] })
            }
        },
    },
    account_qualifications: {
        query: (id) => `{
          accountQualificationById(id: "${id}") {
            id
            active
            status
            accountQualificationStepsByAccountQualificationId {
              nodes {
                id
                link
                blob
              }
            }
          }
      }`,
        onResolve: ({
            data: {
                accountQualificationById: { id, status },
            },
            type,
            ws,
        }) => {
            if (status === 'passed') {
                ws.unsubscribe({ type, ids: [id] })
            }
        },
    },
    steps: { // make this work with sets
        query: (id) => `{
      stepById(id: "${id}") {
        id
        status
        shortSteps {
          nodes {
            id
            renderAttributes
            status
            order
          }
        }
        currentStep {
          id
          schema
          status
          type
          outputData
          dynamicData
        }
      }
    }`,
        cacheProcess: (cache, data, emitter) => {
            const {
                stepById: {
                    id,
                    currentStep,
                    shortSteps: {
                        nodes: shortSteps,
                    },
                },
            } = data

            /* eslint-disable no-param-reassign */
            if (currentStep) {
                cache.Step[id].CurrentStep = currentStep.id
                cache.CurrentStep[currentStep.id] = currentStep
            } else {
                cache.Step[id].CurrentStep = null
            }
            cache.Step[id].shortStep = shortSteps.map((shortStep) => {
                cache.ShortStep[shortStep.id] = shortStep
                return shortStep.id
            })
            /* eslint-enable no-param-reassign */

            emitter.changeType('Step')
            emitter.changeType('ShortStep')
            emitter.changeType('CurrentStep')
            emitter.emitCacheUpdate()
        },
        onResolve: ({
            data: { stepById: { status } }, type, ws, subscribedId,
        }) => {
            if (status === 'complete' || status === 'failed') {
                ws.unsubscribe({ type, ids: [subscribedId] })
            }
        },
    },
    documents: {
        query: (id) => `{
            documentById(id: "${id}") {
                ${Document.fragment.toString()}
            }
          }`,
        onResolve: ({
            data: {
                documentById: {
                    uploadStatus,
                },
            },
            type,
            ws,
            subscribedId,
        }) => {
            if (uploadStatus === 'failed' || uploadStatus === 'uploaded') {
                ws.unsubscribe({ type, ids: [subscribedId] })
            }
        },
    },
}

import React from 'react'
import SafeReact from 'util/SafeReact.js'

const RowHeading = ({
    children,
    startSlot,
    endSlot,
    prefix = 'vrtl-investor-document-heading',
    ...props
}) => (
    <div className={`${prefix}__heading`}>
        {startSlot}
        {SafeReact.cloneChildren(children, props)}
        <div className={`${prefix}__section--2`}>
            Issuer
        </div>
        <div className={`${prefix}__section`}>
            Asset
        </div>
        <div className={`${prefix}__section`}>
            Round
        </div>
        {endSlot}
    </div>
)

export default RowHeading

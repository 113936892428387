import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'

const settingsBreadcrumb = {
    display: 'Settings',
    link: '/settings',
}

const accountBreadcrumbs = [
    settingsBreadcrumb,
    {
        active: true,
        display: 'Account',
        link: '/settings/account',
    },
]

const qualificationAnswersBreadcrumbs = [
    settingsBreadcrumb,
    {
        active: true,
        display: 'Qualification Answers',
        link: '/settings/qualification-answers',
    },
]

const delegateBreadcrumbs = [
    settingsBreadcrumb,
    {
        active: true,
        display: 'Delegates',
        link: '/settings/delegates',
    },
]

const integrationBreadcrumbs = [
    settingsBreadcrumb,
    {
        active: true,
        display: 'Integrations',
        link: '/settings/integrations',
    },
]

const accountTab = {
    label: 'Account',
    href: '/settings/account',
    Icon: InfoOutlinedIcon,
}

const qualificationTabs = {
    label: 'Qualification Answers',
    href: '/settings/qualification-answers',
    Icon: FormatAlignLeftIcon,
}

const delegatesTab = {
    label: 'Delegates',
    href: '/settings/delegates',
    Icon: PeopleAltOutlinedIcon,
}

const integrationsTab = {
    label: 'Integrations',
    href: '/settings/integrations',
    Icon: AddCircleOutlineOutlinedIcon,
}

const baseTabs = [
    accountTab,
    delegatesTab,
]

const allTabs = [
    ...baseTabs,
    integrationsTab,
]

const investorTabs = [
    ...allTabs.slice(0, 1),
    qualificationTabs,
    ...allTabs.slice(1),
]

const selectSettingsTabs = (type) => {
    const map = {
        agent: baseTabs,
        ats: baseTabs,
        investor: investorTabs,
        issuer: allTabs,
        broker: allTabs,
    }

    return map[type] || baseTabs
}

export {
    settingsBreadcrumb,
    accountBreadcrumbs,
    qualificationAnswersBreadcrumbs,
    delegateBreadcrumbs,
    integrationBreadcrumbs,
    selectSettingsTabs,
}

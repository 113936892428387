import React from 'react'

import Account from 'components/account/index.js'
import SubNavLayout from 'layouts/sub-nav-layout.js'
import { accountBreadcrumbs, selectSettingsTabs } from 'constants/settings'
import useQuery from 'delv/hooks/use-query'
import accountSetupFragment from 'components/account/setup/fragment.js'

const queryString = (accountId) => (`
    {
        accountById(id: "${accountId}") {
          ${accountSetupFragment}
        }
    }
`)

const accountSettingsFormat = ({
    accountById: {
        taxShort,
        accountAddressesByAccountId,
        partiesByAccountId,
        ...otherFields
    },
}) => {
    // Address type is recorded on the join table, vertalo.[account | party]_addresses
    const addresses = accountAddressesByAccountId.nodes.map(({ type, addressByAddressId }) => ({ ...addressByAddressId, type }))
    const parties = partiesByAccountId.nodes.map(({ partyAddressesByPartyId, ...party }) => ({
        ...party,
        partyAddressesByPartyId: partyAddressesByPartyId.nodes.map(({ type, addressByAddressId }) => ({ ...addressByAddressId, type })),
    }))

    return {
        taxShort,
        addresses,
        parties,
        ...otherFields,
    }
}

export { accountSettingsFormat }

const AccountSettingsPage = ({ account, userAccount }) => {
    const canEdit = userAccount.role !== 'account_viewer'
    const tabs = selectSettingsTabs(account.type)

    const { data, loading, error } = useQuery(queryString(account.id), { format: accountSettingsFormat })

    // Delv's generic error handling is broken so this error check ensures a loud failure if there is an problem with the query.
    if (loading || error) {
        return null
    }

    return (
        <SubNavLayout breadcrumbs={accountBreadcrumbs} tabs={tabs}>
            <Account.card {...data} canEdit={canEdit} />
        </SubNavLayout>
    )
}

export default AccountSettingsPage

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import ThemeProvider from 'providers/ThemeProvider'
import { createTheme } from 'util/theme-utils.js'

import './index.css'
import './card.scss'
import './colors.scss'
import App from './App.js'
import * as serviceWorker from './serviceWorker.js'

const integrationFactory = (route, cb) => (step) => {
    // eslint-disable-next-line
    let iframe
    let listeners = {}

    const emitIfExists = (eventName, data) => {
        if (listeners[eventName] instanceof Function) {
            listeners[eventName](data)
        }
    }

    listeners.cleanup = () => {
        listeners = {}
    }

    const mount = () => {
        window.vertalo.http
            .post(route, { stepId: step.id })
            .then(cb({ emitIfExists }))
            .catch((error) => {
                emitIfExists('error', error)
            })
        return () => emitIfExists('cleanup')
    }

    return {
        mount,
        on: (event, callback) => {
            listeners[event] = callback
        },
        off: (event) => {
            listeners[event] = undefined
        },
        setIframe: (e) => {
            iframe = e
        },
    }
}

window.vertalo = {
    // this is a "SDK" that we will give to people to support processes
    readme: 'Not implemented yet, the events are loading, ready, complete, error and change',
    configure: (httpLibrary) => {
        window.vertalo.http = httpLibrary
    },
    docusign: integrationFactory(
        '/api/v2/integration/docusign-link',
        ({ emitIfExists }) => (response) => {
            if (!(navigator.userAgent.indexOf('Chrome') > -1) && navigator.userAgent.indexOf('Safari') > -1) {
                // check if we are not chrome and are safari
                emitIfExists('ready', {
                    link: true,
                    src: response.data.link,
                    message: 'IFrame disabled on Safari, please follow this link, or complete this flow on Chrome.',
                })
            } else {
                emitIfExists('ready', {
                    src: response.data.link,
                })
            }
        },
    ),

    investready: integrationFactory(
        '/api/v2/integration/investready-credential',
        ({ emitIfExists }) => (response) => {
            if (!(navigator.userAgent.indexOf('Chrome') > -1) && navigator.userAgent.indexOf('Safari') > -1) {
                // check if we are not chrome and are safari
                emitIfExists('ready', {
                    link: true,
                    src: `https://www.investready.com/login/${response.data.token}?state=${response.data.state}`,
                    message: 'IFrame disabled on Safari, please follow this link, or complete this flow on Chrome.',
                })
            } else {
                emitIfExists('ready', {
                    src: `https://www.investready.com/login/${response.data.token}?state=${response.data.state}`,
                })
            }
        },
    ),
}

const theme = createTheme()

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router>
            <App />
        </Router>
    </ThemeProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

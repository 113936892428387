import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import Format from 'util/format/Format.js'
import DelvWebSocket from 'delv/websocket.js'
import IconHelper from 'common/IconHelper.js'
import { isUploader } from 'util/permissions.js'

import './styles.scss'

export default ({
    documentByDocumentId: {
        id,
        uploadStatus,
        downloadUrl,
        extension,
        createdOn,
        name,
        type,
        uploadedBy,
        documentSharingsByDocumentId,
    },
    prefix = 'vrtl-document-row',
    startSlot,
    subscribe = true,
    remove,
    edit,
    ...props
}) => {
    // @TODO, refactor when webhook subscription pattern is established
    if (subscribe && uploadStatus !== 'uploaded') {
        DelvWebSocket.subscribe({ type: 'documents', ids: [id] })
    }

    const uploadStatusMap = {
        uploaded: 'Uploaded',
        failed: 'Upload failed, please check file size and retry',
    }
    const status = uploadStatusMap[uploadStatus] || 'In Progress'

    const download = uploadStatus === 'uploaded' ? (
        <a
            className={`${prefix}__icon-download`}
            href={downloadUrl}
            target={extension === 'pdf' ? '_blank' : '_self'}
            rel='noopener noreferrer'
            download
        >
            <FileDownloadIcon className={`${prefix}--vrtl-blue-fill pointer`} />
        </a>
    ) : (
        <div className={`${prefix}__icon-offset`} />
    )

    const sharedWith = documentSharingsByDocumentId.nodes.map((role) => (role.name === 'ats' ? 'ATS' : role.name)).join(', ')

    return (
        <div className={`${prefix}__row`}>
            {startSlot}
            <div className={`${prefix}__section--2 ${prefix}--overflow-wrap`}>
                {name}
            </div>
            {
                isUploader(props, uploadedBy) ? (
                    <>
                        <div className={`${prefix}__section ${prefix}--pascal`}>
                            {status}
                        </div>
                        <div className={`${prefix}__section ${prefix}--pascal`}>
                            {sharedWith.length === 0 ? 'Me' : sharedWith}
                        </div>
                    </>
                ) : (
                    <div className={`${prefix}__section ${prefix}--pascal`}>
                        {type}
                    </div>
                )
            }
            <div className={`${prefix}__section`}>
                {Format.date(createdOn, 'MMM Do YYYY')}
            </div>
            {
                IconHelper([download, remove, edit], prefix)
            }
        </div>
    )
}

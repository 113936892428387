import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import Format from 'util/format/Format'
import Error from './Error.js'
import Warning from './Warning.js'

export default ({
    id,
    name,
    match,
    locked,
    templateCopyOn,
    templateUpdatedOn,
    deleted,
    active,
    edit,
    cancel,
    submit,
    remove,
    parentInfo,
}) => {
    const prefix = 'vrtl-mapped-template'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--3 row`}>
                {
                    id
                        ? (
                            <Link to={`${match.url}/${id}`} className={`${prefix}__section--3`}>
                                {name}
                            </Link>
                        )
                        : <div className={`${prefix}__section--3`}>{name}</div>
                }
                <div className={`${prefix}__section`}>
                    {
                        deleted
                            ? <Error {...parentInfo} />
                            : new Date(templateCopyOn).getTime() < new Date(templateUpdatedOn).getTime()
                                ? <Warning width='25rem' />
                                : <div className={`${prefix}__icon-offset`} />
                    }
                </div>
            </div>
            <div className={`${prefix}__section--2`}>{templateCopyOn && Format.date(templateCopyOn, 'MMM DD YYYY h:mma')}</div>
            <div className={`${prefix}__section--2`}>{templateUpdatedOn && Format.date(templateUpdatedOn, 'MMM DD YYYY h:mma')}</div>
            <div className={`${prefix}__section`}>{typeof locked === 'boolean' ? locked ? 'Locked' : 'Not locked' : locked}</div>
            <div className={`${prefix}__section`}>{typeof active === 'boolean' ? active ? 'Active' : 'Not active' : active}</div>
            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

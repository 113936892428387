import React from 'react'
import { isIssuer, isBroker } from 'util/permissions.js'
import HoverToolTip from 'common/HoverToolTip'

export default ({
    children,
    offset = '',
    prefix = 'vrtl-round-card',
    startSlot,
    endSlot,
    entityPage,
    ...props
}) => {
    const isTranche = props.type?.toLowerCase() === 'debt' || (props.term && props.coupon)
    const displaySecurity = entityPage && !isBroker(props)
    return (
        <div className={`${prefix}__container--heading`}>
            {startSlot}
            <div className={`${prefix}__section--3`}>
                {offset}
                Round Name
            </div>
            <div className={`${prefix}__section--2`}>Opens On</div>
            <div className={`${prefix}__section--2`}>Closes On</div>
            <div className={`${prefix}__section--2`}>Total Units</div>
            <div className={`${prefix}__section`}>Status</div>
            <HoverToolTip position='top center' width='fit-content'>
                <div className={`${prefix}__section--2 ${prefix}--hover-highlight`}>
                    Round Price
                </div>
                $ / Unit
            </HoverToolTip>
            <HoverToolTip position='top center' width='fit-content'>
                <div className={`${prefix}__section--2 ${prefix}--hover-highlight`}>
                    Latest Price
                </div>
                $ / Unit
            </HoverToolTip>
            {
                isIssuer(props)
                    ? (
                        <div className={`${prefix}__section--2`}>Listing ATS</div>
                    ) : (
                        <div className={`${prefix}__section--2`} />
                    )
            }
            {
                isTranche ? (
                    <>
                        <HoverToolTip position='top left' width='fit-content' arrow={false}>
                            <div className={`${prefix}__section--2 ${prefix}--hover-highlight`}>
                                Term
                            </div>
                            Years
                        </HoverToolTip>
                        <div className={`${prefix}__section--2`}>Coupon</div>
                    </>
                ) : (
                    <>
                        <div className={`${prefix}__section--2`} />
                        <div className={`${prefix}__section--2`} />
                    </>
                )
            }
            <div className={`${prefix}__section--2`}>Dataroom</div>
            {displaySecurity && <div className={`${prefix}__section--2`}>Security</div>}
            <div className={`${prefix}__icon-offset--2`} />
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
            <div className={`${prefix}__icon-offset`} />
            {endSlot}
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import Box from 'common/Box'
import CircularProgress from 'common/CircularProgress'
import Link from 'common/Link'
import Typography from 'common/Typography'

import CloseIcon from '@mui/icons-material/Close'

import Button from 'common/Button'
import Dialog from 'common/Dialog'
import DialogTitle from 'common/DialogTitle'
import UseToggle from 'hooks/UseToggle.js'

const AsyncIframeInternal = ({
    currentStep,
}) => {
    const [networkState, setNetworkState] = useState('loading')

    const iframeController = window.vertalo[currentStep.schema.type](currentStep)
    iframeController.on('ready', setNetworkState)
    iframeController.on('loading', setNetworkState)
    iframeController.on('complete', () => setNetworkState('complete'))
    iframeController.on('error', setNetworkState)

    useEffect(() => iframeController.mount(), [])

    if (networkState === 'loading') {
        return (
            <div style={{ margin: 'auto' }}>
                <CircularProgress />
            </div>
        )
    }

    if (networkState === 'complete') {
        return (
            <Typography variant='h5' as='h2'>
                Completed!
            </Typography>
        )
    }

    if (networkState.error) {
        return (
            <Typography color='error'>
                {`There was an error ${networkState.error}`}
            </Typography>
        )
    }

    if (networkState.link) {
        return (
            <Box>
                <Link href={networkState.src} target='_blank' rel='noopener noreferrer'>{networkState.message}</Link>
            </Box>
        )
    }

    return (
        <iframe
            className='vrtl-process-iframe'
            data-testid='iframe-step'
            ref={(e) => iframeController.setIframe(e)}
            src={currentStep.schema.link}
            key={currentStep.id}
            {...networkState}
        />
    )
}

const AsyncIframe = ({ currentStep }) => {
    const { toggle, enable, disable } = UseToggle()

    const title = currentStep?.renderAttributes?.title

    return (
        <>
            <Button variant='contained' onClick={enable}>Begin</Button>
            <Dialog
                fullScreen
                open={toggle}
                onClose={disable}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title}
                    <CloseIcon onClick={disable} sx={{ cursor: 'pointer' }} />
                </DialogTitle>
                <AsyncIframeInternal currentStep={currentStep} />
            </Dialog>
        </>
    )
}

export default AsyncIframe

import React from 'react'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs'

import Link from 'common/Link'
import Typography from 'common/Typography'

import './styles.scss'

const Breadcrumbs = ({ items }) => (
    <MUIBreadcrumbs data-testid='breadcrumbs' sx={{ pb: 3 }}>
        {
            items.map(({
                display, link, active, component,
            }) => {
                if (component) {
                    return <component />
                }

                return active
                    ? <Typography color='text.primary' key={display} weight='bold'>{display}</Typography>
                    : <Link to={link} color='text.secondary' key={display} underline='none'>{display}</Link>
            })
        }
    </MUIBreadcrumbs>
)

export default Breadcrumbs

import React from 'react'

import IconHelper from 'common/IconHelper.js'
import { Link } from 'react-router-dom'

export default ({
    id,
    name,
    match,
}) => {
    const prefix = 'vrtl-mapped-template'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--3 row`}>
                {
                    id
                        ? (
                            <Link to={`${match.url}/${id}`} className={`${prefix}__section--3`}>
                                {name}
                            </Link>
                        )
                        : <div className={`${prefix}__section--3`}>{name}</div>
                }
            </div>
            {
                IconHelper([false, false, false], prefix)
            }
        </div>
    )
}

import React, { useState } from 'react'
import ReactPopup from 'reactjs-popup'
import Close from '@material-ui/icons/Close'
import SafeReact from 'util/SafeReact.js'
import cx from 'classnames'
import Button from 'common/Button/index.js'
import DivFactory from './DivFactory.js'
import './Popup.css'

const Popup = ({
    className = 'route-popup', addClassName = '', children, ...props
}) => {
    const [open, setOpen] = useState(false)

    const openPopup = () => {
        if (!open) {
            setOpen(true)
        }
    }
    const closePopup = () => {
        if (open) {
            setOpen(false)
        }
    }

    const [inner, trigger] = SafeReact.cloneChildren(children, {
        popup: {
            open: openPopup,
            close: closePopup,
        },
        ...props,
        onResolve: (data) => {
            closePopup()
            if (props.onResolve) {
                props.onResolve(data)
            }
        },
    })

    // react popup appends stuff to classname, so the order is correct here
    return (
        <>
            <ReactPopup
                className={cx(className, addClassName)}
                closeOnDocumentClick={false}
                open={open}
                onClose={closePopup}
            >
                {inner}
            </ReactPopup>
            {trigger}
        </>
    )
}

const Exit = ({ className = 'close-icon-container', addClassName = '', popup: { close } }) => (
    <div className={cx(className, addClassName)}>
        <Close onClick={close} />
    </div>
)

const Trigger = ({
    className = 'styled-button',
    addClassName = '',
    children,
    popup: { open },
}) => (
    <div className={cx(className, addClassName)} onClick={open}>
        {SafeReact.cloneChildren(children)}
    </div>
)

const MaterialButton = ({
    children,
    popup: { close },
}) => [
    <div className='row' style={{ justifyContent: 'center', paddingTop: '1rem' }}>
        <Button onClick={() => close()} variant='contained'>
            {SafeReact.cloneChildren(children)}
        </Button>
    </div>,
    <div />,
]

export default {
    popup: Popup,
    inner: DivFactory('popup-inner'),
    close: Exit,
    trigger: Trigger,
    materialButton: MaterialButton,
}

import gql from 'graphql-tag'
import sha256 from 'crypto-js/sha256'

import selectGqlArgument from '../selectors/gql-parsing-selectors.js'

const createCacheContext = ({ cacheProcess, query }) => {
    const queryParsed = gql`
        ${query}
    `
    const queryHash = sha256(query).toString()
    const partialQuery = query
        .replace(/\s?first:\s?[0-9]+,?\s?/u, '')
        .replace(/\s?offset:\s?[0-9]+,?\s?/u, '')
    const partialHash = sha256(partialQuery).toString()
    const offset = selectGqlArgument(queryParsed, 'offset')
    const first = selectGqlArgument(queryParsed, 'first')

    const cacheContext = {
        cacheProcess,
        query: {
            raw: query,
            hash: queryHash,
            partial: partialQuery,
            partialHash,
            parsed: queryParsed,
        },
        node: {},
        parent: {},
    }

    if (first && offset) {
        cacheContext.pagination = {
            first,
            offset,
        }
    }

    return cacheContext
}

const prepareCacheContextHistory = (context) => {
    if (Object.keys(context.node).length) {
        context.parent = {
            parent: context.parent,
            ...context.node,
        }
    }

    context.node = {}
}

export { createCacheContext, prepareCacheContextHistory }

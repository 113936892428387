import React from 'react'

import Delv from 'delv/delv-react'
import EditSwitch from 'common/NewEditSwitch.js'
import GenericForm from 'common/form/GenericForm.js'
import { Dropdown } from 'common/FormUI.js'

import './Email.scss'

const GET_EMAIL_PREFERENCES = (id) => `{
    vendorDefaultEmailPreferenceByAccountId(accountId:"${id}"){
      id
      accountId
      sendDistribution
    }
}`

const updateQuery = () => `mutation($id:UUID!, $patch:VendorDefaultEmailPreferencePatch!){
    updateVendorDefaultEmailPreferenceById(input:{id:$id, vendorDefaultEmailPreferencePatch:$patch}){
      vendorDefaultEmailPreference{
        id
        accountId
        sendDistribution
      }
    }
}`

const formConfig = {
    fields: {
        sendDistribution: {
            type: 'dropdown',
            customUI: ({ value }) => {
                const options = [
                    { displayValue: 'Enabled', value: true },
                    { displayValue: 'Disabled', value: false },
                ]
                return <Dropdown {...value} options={options} />
            },
        },
    },
}

const EmailContent = ({
    sendDistribution,
    edit,
    cancel,
    submit,
}) => {
    const prefix = 'vrtl-email-settings-display'
    return (
        <div className={`${prefix}__section--split`}>
            <div className={`${prefix}__label`}>
                {typeof sendDistribution === 'boolean' ? sendDistribution ? 'Enabled' : 'Disabled' : sendDistribution}
                <div className='icon-offset' />
                {edit}
                {cancel}
                {submit}
            </div>
        </div>
    )
}

const EditEmailContent = GenericForm({
    updateField: updateQuery,
    prefix: 'vrtl-onboarding-question-set-row',
    formConfig,
    Display: EmailContent,
})

const Emails = ({
    canEdit,
    account,
}) => {
    const prefix = 'vrtl-email-settings'
    return (
        <>
            <div className={`${prefix}__section`}>
                <div className={`${prefix}__label`}>
                    Email Notifications:
                </div>
                <div className={`${prefix}__content`}>
                    <Delv.query
                        query={GET_EMAIL_PREFERENCES(account.id)}
                        format={({ vendorDefaultEmailPreferenceByAccountId }) => vendorDefaultEmailPreferenceByAccountId}
                        key='emails'
                    >
                        <EditSwitch.switch canEdit={canEdit} editIcon>
                            <EmailContent />
                            <EditEmailContent {...account} />
                        </EditSwitch.switch>
                    </Delv.query>
                </div>
            </div>
            <div className={`${prefix}__section`}>
                <div className={`${prefix}__sub-text`}>
                    Default setting for receiving email notifications on newly created allocations.
                </div>
            </div>
        </>
    )
}

export default Emails

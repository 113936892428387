import React from 'react'

const data = `id
vendorQualificationByVendorQualificationId{
  id
  name
}`
const distribution = `distributionQualificationsByDistributionId{
  nodes{
    ${data}
    executeOrder
    stepByStepId{
      id
      status
    }
  }
}`
const allocation = `allocationQualificationsByAllocationId{
  nodes{
    executeOrder
    ${data}
  }
}`

const fragment = {
    data,
    distribution,
    allocation,
}

export { fragment }

const QualificationCard = ({
    className = 'card card-container buyer-action-qualifications-card',
    addClassName = '',
    vendorQualificationByVendorQualificationId: {
        name,
    },
}) => (
    <div className={`${className} ${addClassName}`}>
        <div className='card-label'>Qualification</div>
        <div>{name}</div>
    </div>
)

export default QualificationCard

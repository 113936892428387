import React from 'react'
import Card from 'common/Card.js'
import SafeReact from 'util/SafeReact.js'

export default ({ summary, children, ...props }) => {
    const values = Object.values(summary)
    return (
        <div>
            <Card.card>
                {/* eslint-disable react/no-array-index-key */}
                {values.map(({ label, value }, index) => (
                    <Card.section key={index}>
                        <Card.label>{label}</Card.label>
                        {value}
                    </Card.section>
                ))}
                {/* eslint-enable react/no-array-index-key */}
                {children && SafeReact.cloneChildren(children, props)}
            </Card.card>
        </div>
    )
}

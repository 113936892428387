const selectLocalCurrency = (value) => {
    if (!value && value !== 0) {
        return undefined
    }

    const currencyValue = new Intl.NumberFormat(
        window.navigator?.language || 'en-US',
        {
            style: 'currency',
            currency: window.vertalo.currency || 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    ).format(value)

    return currencyValue
}

export { selectLocalCurrency }

/* eslint-disable camelcase */
import React, { useState } from 'react'
import { grey } from '@mui/material/colors'

import PromiseTimeout from 'util/promiseTimeout.js'
import { Input } from 'common/FormUI.js'
import Form from 'common/form/Form.js'
import Typography from 'common/Typography'
import Link from 'common/Link'
import Button from 'common/Button'
import Card from 'common/Card/index.js'
import Logo from 'common/Logo'
import Box from 'common/Box'

import QueryString from 'query-string'

import Mutation from 'delv/Mutation.js'
import './styles.scss'

const RESEND_EMAIL = `mutation($id:UUID!){
  resendEmail(input:{id:$id}){
    clientMutationId
  }
}`

const ExpiredToken = ({ email_id }) => {
    const [state, setState] = useState('default')
    const resendEmail = () => {
        new Mutation({
            mutation: RESEND_EMAIL,
            onSubmit: () => ({ id: email_id }),
            onResolve: PromiseTimeout(() => {
                setState('complete')
            }, 300),
        }).submit()
        setState('loading')
    }

    if (state === 'loading') {
        return (
            <div className='vrtl-account-claim__body'>
                <Typography>Sending...</Typography>
            </div>
        )
    }
    if (state === 'complete') {
        return (
            <div className='vrtl-account-claim__body'>
                <Typography>Email re-sent!</Typography>
            </div>
        )
    }
    if (state === 'default') {
        return (
            <div className='vrtl-account-claim__body'>
                <Typography>Claiming token expired, click to resend token!</Typography>
                <Button className='login-provider-button' onClick={resendEmail} variant='contained' sx={{ mt: 2 }}>
                    Resend
                </Button>
            </div>
        )
    }
    return (
        <div className='vrtl-account-claim__body'>
            <Typography>Unexpected error occured!</Typography>
            <Typography color='error'>{state}</Typography>
        </div>
    )
}

const SEND_ACCOUNT_CLAIM = `mutation ($email:String!){
  sendClaimAccountEmail(input:{email:$email}){
      clientMutationId
  }
}`

const NoStatus = Form(
    ({
        form: {
            email, validateInput, setLoading, loading,
        },
    }) => {
        const sendEmail = () => {
            const input = validateInput()
            if (!input.hasErrors) {
                new Mutation({
                    mutation: SEND_ACCOUNT_CLAIM,
                    onSubmit: () => input,
                    onResolve: () => setLoading('complete'),
                }).submit()

                setLoading('loading')
            }
        }

        const prefix = 'vrtl-account-claim'

        if (loading === 'loading') {
            return (
                <div className={`${prefix}__body`}>
                    <Typography>Sending...</Typography>
                </div>
            )
        }
        if (loading === 'complete') {
            return (
                <div className={`${prefix}__body`}>
                    <Typography>Check your inbox for the claiming message</Typography>
                </div>
            )
        }

        return (
            <div className={`${prefix}__body`}>
                <Typography>Enter the email for the account you want to claim</Typography>
                <div className={`${prefix}__form-container`}>
                    <Typography className={`${prefix}__input-label`}>Email</Typography>
                    <Input {...email} />
                </div>
                <Button variant='contained' onClick={sendEmail} className={`styled-button ${prefix}__submit-btn`}>
                    Claim
                </Button>
            </div>
        )
    },
    {
        fields: {
            email: {
                type: 'email',
                error: 'Email is required.',
            },
        },
    },
)

export default ({ location: { search } }) => {
    const prefix = 'vrtl-account-claim'
    const { status, email_id } = QueryString.parse(search)

    return (
        <Box className={prefix} sx={{ px: 1, bgcolor: grey[300] }}>
            <Card className='column' sx={{ padding: 4 }}>
                <div className='login-header'>
                    <Logo prefix={`${prefix}-logo`} logo='color' />
                </div>
                {!status ? (
                    <NoStatus />
                ) : status === 'no-token' ? (
                    <div className='vrtl-account-claim__body'>
                        <Typography>Login to Vertalo</Typography>
                        <Link to='/login' className='vrtl-account-claim__link'>
                            <div className='login-provider-button'>Login</div>
                        </Link>
                    </div>
                ) : status === 'expired-token' ? (
                    email_id ? (
                        <ExpiredToken email_id={email_id} />
                    ) : (
                        <div className='vrtl-account-claim__body'>
                            <Typography>Link is expired.</Typography>
                        </div>
                    )
                ) : status === 'invalid-token' ? (
                    <div className='vrtl-account-claim__body'>
                        <Typography>
                            {'Invalid token, contact '}
                            <a href='mailto:support@vertalo.com'>support</a>
                            {' for help claiming your account.'}
                        </Typography>
                    </div>
                ) : status === 'used' ? (
                    <div className='vrtl-account-claim__body'>
                        <Typography>Account has already been claimed!</Typography>
                        <Typography>Login to Vertalo</Typography>
                        <Link to='/login' className='vrtl-account-claim__link'>
                            <div className='login-provider-button'>Login</div>
                        </Link>
                    </div>
                ) : status === 'complete' ? (
                    <div className='vrtl-account-claim__body'>
                        <Typography>Congratulations, you've claimed your vertalo account!</Typography>
                        <Link to='/account-role' className='vrtl-account-claim__link'>
                            <div className='login-provider-button'>Login</div>
                        </Link>
                    </div>
                ) : (
                    ''
                )}
            </Card>
        </Box>
    )
}
/* eslint-enable camelcase */

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
status
accountByInvestorId{
  id
  name
  contactName
  email
}`)

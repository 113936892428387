import { makeSnakeCase } from '../../util/format/string-format.js'

const createFilterString = (arg) => {
    if (!arg || (!arg.filter && arg.filter !== false)) {
        return ''
    }

    if (typeof arg.filter === 'string') {
        return `${arg.type}: "${arg.filter}"`
    }

    return `${arg.type}: ${arg.filter}`
}

const argsToString = (args = {}) => {
    const argStrings = []

    if (args.sort) {
        Object.values(args.sort).forEach((arg) => {
            if (!Array.isArray(arg) && arg?.sort) {
                const fieldNames = makeSnakeCase(arg.fieldName).toUpperCase()
                argStrings.push(`orderBy: [${fieldNames}_${arg.sort}, ID_ASC]`)
            }
        })
    }

    if (args.filter) {
        const filterLists = {}
        Object.values(args.filter).forEach((field) => {
            if (field) {
                Object.values(field).forEach((arg) => {
                    if (arg.filter !== undefined) {
                        const filterBody = createFilterString(arg)
                        if (filterBody) {
                            if (!filterLists[arg.fieldName]) {
                                filterLists[arg.fieldName] = []
                            }
                            filterLists[arg.fieldName].push(filterBody)
                        }
                    }
                })
            }
        })

        if (Object.values(filterLists).length) {
            const filterBody = Object.keys(filterLists).map((key) => `${key}: { ${filterLists[key].join(', ')} }`)

            const filterString = `filter: { ${filterBody.join(', ')} }`
            argStrings.push(filterString)
        }
    }

    if (args.pagination?.pageSize) {
        argStrings.push(`offset: ${args.pagination.offset}, first: ${args.pagination.pageSize}`)
    }

    return argStrings.length ? `(${argStrings.join(', ')})` : ''
}

export { argsToString }

import React from 'react'
import DownArrow from '@material-ui/icons/KeyboardArrowDown'
import RightArrow from '@material-ui/icons/KeyboardArrowRight'
import cx from 'classnames'

import './styles.scss'

const Arrow = ({
    prefix = 'vrtl-collapsible',
    open,
    addClassName,
    setOpen,
}) => (open ? (
    <DownArrow onClick={() => setOpen(false)} className={cx(`${prefix}__dropdown-arrow`, addClassName)} />
) : (
    <RightArrow onClick={() => setOpen(true)} className={cx(`${prefix}__dropdown-arrow`, addClassName)} />
))

export default Arrow

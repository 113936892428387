import React from 'react'
import Form from 'common/form/Form.js'
import { Dropdown } from 'common/FormUI.js'

const formConfig = {
    fields: {
        form: {
            type: 'dropdown',
            default: 'table',
        },
        viewing: {
            type: 'dropdown',
            default: 'cap',
        },
    },
}

const viewingOptions = [
    {
        value: 'SOP',
        viewing: 'Schedule of purchasers',
    },
    {
        value: 'cap',
        viewing: 'Cap Table',
    },
]

const CapitalizationViewSwitch = ({
    form: {
        viewing,
    },
    children,
}) => {
    const renderValue = viewing.value === 'cap' ? children[0] : children[1]

    return (
        <div className='row flex-1'>
            <div className='capitalization-left'>
                <div className='padded-card-container'>
                    <div className='capitalization-filter-item-label'>
                        Viewing
                    </div>
                    <Dropdown options={viewingOptions} {...viewing} />
                </div>
            </div>
            <div className='capitalization-right'>{renderValue}</div>
        </div>
    )
}

export default Form(CapitalizationViewSwitch, formConfig)

import React from 'react'
import Format from 'util/format/Format.js'

import OpenInNew from '@material-ui/icons/OpenInNew'

export default ({
    status,
    sentOn,
    documentId,
    documentUri,
    accountBySentTo: {
        name,
    },
}) => {
    const prefix = 'vrtl-sent-documents'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{status}</div>
            <div className={`${prefix}__section`}>{sentOn !== 0 ? Format.date(sentOn) : 'Sent not verified'}</div>
            <div className={`${prefix}__section`}>
                {documentId}
                <a href={documentUri} className={`${prefix}__a`} target='_blank' rel='noopener noreferrer'><OpenInNew className={`${prefix}__icon-link`} /></a>
            </div>
        </div>
    )
}

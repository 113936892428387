import React from 'react'
import Mutation from 'delv/Mutation'
import Form from 'common/form/Form'
import { Input, Dropdown } from 'common/FormUI.js'
import Delete from '@material-ui/icons/Delete'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'
import Row from './Row.js'
import Fragment from './Fragment.js'

const CREATE_ASSET = `mutation ($asset: AssetInput!) {
  createAsset(input: {asset: $asset}) {
    asset {
      ${Fragment.toString()}
      issuerId
      roundsByAssetId {
        nodes {
          id
        }
      }
    }
  }
}`

const UPDATE_ASSET = `mutation($id:UUID!, $asset:AssetPatch!){
  updateAssetById(input:{id:$id, assetPatch:$asset}){
    asset{
      id
      name
      status
      authorizedTotal
      assetTypeByType {
        type
        accountingType
      }
    }
  }
}`

const STATUS = [
    { status: 'Drafted', value: 'drafted' },
    { status: 'Active', value: 'active' },
    { status: 'Closed', value: 'closed' },
    { status: 'Stale', value: 'stale' },
]

const formConfig = {
    fields: {
        name: {
            type: 'string',
            error: 'Name is required.',
        },
        authorizedTotal: {
            type: 'number',
            error: 'Authorized total is required.',
        },
        type: {
            type: 'dropdown',
            error: 'Asset type required.',
            default: ({ assetTypeByType }) => {
                if (assetTypeByType) {
                    return assetTypeByType.type
                }
                return null
            },
        },
        status: {
            type: 'dropdown',
            error: 'Status required.',
        },
    },
    nodeType: 'asset',
}

const AssetForm = ({
    id,
    allAssetTypes,
    resolve,
    displayProps,
    form: {
        name,
        authorizedTotal,
        type,
        status,
        validateInput,
        getChanged,
        nodeType,
    },
}) => {
    const types = allAssetTypes.nodes.map((item) => ({
        type: item.type,
        value: item.type,
    }))
    const handleSubmit = () => {
        const input = validateInput()
        if (!input.hasErrors) {
            if (id) {
                const changedInput = getChanged(input)
                if (changedInput) {
                    const returnVal = {
                        id,
                        asset: changedInput,
                    }
                    new Mutation({
                        mutation: UPDATE_ASSET,
                        onSubmit: () => returnVal,
                        onResolve: resolve,
                    }).submit()
                } else {
                    resolve()
                }
            } else {
                new Mutation({
                    mutation: CREATE_ASSET,
                    onSubmit: () => ({ asset: input }),
                    onResolve: resolve,
                }).submit()
            }
        }
    }
    const prefix = 'vrtl-asset-card'
    const newDisplayProps = {
        ...displayProps,
        name: <Input {...name} />,
        authorizedTotal: <Input {...authorizedTotal} />,
        status: <Dropdown options={STATUS} {...status} width='6rem' />,
        cancel: id ? <Cancel id={nodeType.value.cancel.id} className={`${prefix}__icon-cancel`} onClick={resolve} />
            : <Delete Delete className={`${prefix}__icon-delete`} onClick={resolve} />,
        submit: <Done id={nodeType.value.submit.id} className={`${prefix}__icon-submit`} onClick={handleSubmit} />,
        assetTypeByType: {
            type: id ? <div {...type} className={`${prefix}__section--2`}>{type.value}</div> : <Dropdown options={types} {...type} />,
        },
    }

    return <Row {...newDisplayProps} />
}

export default Form(
    AssetForm,
    formConfig,
)

import React from 'react'
import { Link } from 'react-router-dom'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import Cancel from '@material-ui/icons/Cancel'
import Done from '@material-ui/icons/CheckCircle'
import Add from '@material-ui/icons/AddCircle'

import Delv from 'delv/delv-react'
import Mutation from 'delv/Mutation'
import Process from 'process/Process.js'
import QuestionSets from 'onboardingQuestions/questionSets/index.js'

import Form from 'common/form/Form'
import ViewAppend from 'common/ViewAppend.js'
import { CheckBox, Dropdown } from 'common/FormUI.js'
import Card from 'common/Card.js'
import Div from 'common/Div.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Popup from 'common/Popup.js'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import HoverToolTip from 'common/HoverToolTip.js'
import NoneFound from 'common/NoneFound/index.js'

import SentDocuments from './sentDocuments/index.js'
import MappedTemplate from './mappedTemplate/index.js'
import SigningRole from './signingRole/index.js'
import AddQuestionSetForm from './MappedSigningTemplateQuestionSetForm.js'

const GET_DOCUMENTS = (id) => `{
  allSigningDocuments(condition:{mappedSigningTemplateId:"${id}"}){
    nodes{
      id
      name
      mappedSigningTemplateId
    }
  }
}`

const GET_ROLES = {
    query: (id) => `{${Delv.nest('allSigningRoles', SigningRole.fragment, { mappedSigningTemplateId: id })}}`,
    format: (data) => ({
        orderByData: data.allSigningRoles.nodes,
    }),
}
const GET_QUESTION_SETS = {
    query: (id) => QuestionSets.fragment
        .nest('onboardingQuestionSetByOnboardingQuestionSetId')
        .merge('id')
        .nest('allMappedSigningTemplateQuestionSets', { mappedSigningTemplateId: id })
        .nest('query')
        .toString(),
    format: (data) => ({
        orderByData: data.allMappedSigningTemplateQuestionSets.nodes.map(
            ({ onboardingQuestionSetByOnboardingQuestionSetId }) => onboardingQuestionSetByOnboardingQuestionSetId,
        ),
    }),
}

const FIX_THIS_REFETCH_FEATURE = (id) => `{
    allSigningDocuments(condition:{mappedSigningTemplateId:"${id}"}){
      nodes{
        id
        name
        mappedSigningTemplateId
      }
    }
    ${Delv.nest('allSigningRoles', SigningRole.fragment, { mappedSigningTemplateId: id })}
}`

const DocumentCard = ({ id, name, match }) => (
    <Link to={`${match.url}/${id}`}>
        <div className='buyer'>{name}</div>
    </Link>
)

const GET_SENT_DOCUMENTS = {
    query: (id) => `{${Delv.nest('allSentSigningDocuments', SentDocuments.fragment, {
        mappedSigningTemplateId: id,
    })}}`,
    format: (data) => ({
        orderByData: data.allSentSigningDocuments.nodes.map(({ sentOn, ...other }) => ({
            ...other,
            sentOn: sentOn || 0,
        })),
    }),
}

const Right = ({ match, mappedSigningTemplateById: { id, locked } }) => (
    <div className='column flex-1'>
        {locked && (
            <div className='vrtl-signing-templates__container--sent-documents'>
                <div className='gray-header row--justify-between'>Sent documents</div>
                <Delv.query {...GET_SENT_DOCUMENTS} args={() => match.params.mapped_template_id}>
                    <OrderBy.order dropdownFields={['status']}>
                        <SentDocuments.orderByHeader />
                        <Div className='buyers-container'>
                            <Div className='order-by-inner'>
                                <Map
                                    arrayKey='_orderBy.data'
                                    noneFound={<NoneFound>No documents sent yet.</NoneFound>}
                                >
                                    <SentDocuments.row />
                                </Map>
                            </Div>
                        </Div>
                    </OrderBy.order>
                </Delv.query>
            </div>
        )}
        <div className='row flex-1'>
            <div className='vrtl-signing-templates__container--templates'>
                <div className='gray-header row--justify-between'>Documents</div>
                <div className='buyers-container'>
                    <div className='order-by-inner'>
                        <Delv.query query={GET_DOCUMENTS(id)}>
                            <Map
                                arrayKey='allSigningDocuments.nodes'
                                noneFound={(
                                    <NoneFound>
                                        No documents found. Sync template to load documents.
                                    </NoneFound>
                                )}
                            >
                                <DocumentCard match={match} />
                            </Map>
                        </Delv.query>
                    </div>
                </div>
            </div>
            <div className='flex-2 column'>
                <div className='vrtl-signing-templates__container--roles'>
                    <div className='gray-header row--justify-between'>Roles</div>
                    <Delv.query {...GET_ROLES} args={() => match.params.mapped_template_id}>
                        <OrderBy.order dropdownFields={['mapTo', 'type']}>
                            <SigningRole.orderByHeader />
                            <Div className='buyers-container'>
                                <Div className='order-by-inner'>
                                    <Map arrayKey='_orderBy.data'>
                                        <EditSwitch.switch canEdit={!locked}>
                                            <SigningRole.row />
                                            <SigningRole.form templateId={id} />
                                        </EditSwitch.switch>
                                    </Map>
                                </Div>
                            </Div>
                        </OrderBy.order>
                    </Delv.query>
                </div>
                <div className='vrtl-signing-templates__container--questions'>
                    <div className='gray-header row--justify-between'>Questions</div>
                    <ViewAppend.container target canEdit={!locked}>
                        <Delv.query
                            {...GET_QUESTION_SETS}
                            args={() => match.params.mapped_template_id}
                        >
                            <OrderBy.order>
                                <QuestionSets.orderByHeader>
                                    <ViewAppend.icon
                                        append={(
                                            <AddQuestionSetForm
                                                mappedTemplateId={match.params.mapped_template_id}
                                            />
                                        )}
                                    >
                                        <Add />
                                    </ViewAppend.icon>
                                </QuestionSets.orderByHeader>
                                <Div className='buyers-container'>
                                    <Div className='order-by-inner'>
                                        <ViewAppend.target />
                                        <Map arrayKey='_orderBy.data'>
                                            <QuestionSets.row />
                                            {/* <EditSwitch.switch canEdit={!locked}>
                                                <QuestionSets.form templateId={id}/>
                                            </EditSwitch.switch> */}
                                        </Map>
                                    </Div>
                                </Div>
                            </OrderBy.order>
                        </Delv.query>
                    </ViewAppend.container>
                </div>
            </div>
        </div>
    </div>
)

const LeftCard = ({
    mappedSigningTemplateById: {
        stepByStepId,
        id,
        name,
        active,
        locked,
        templateCopyOn,
        deleted,
        qualificationStepType,
        signingTemplateBySigningTemplateId: { updatedOn, deleted: parentDeleted, name: parentName },
    },
    edit,
}) => {
    const warning = {
        updatedOn,
        parentDeleted,
        parentName,
        deleted,
        templateCopyOn,
        position: 'bottom right',
    }

    return (
        <div>
            <div className='vrtl-signing-left__section'>
                <div className='row--justify-between'>
                    <div className='vrtl-signing__header'>Mapped template</div>
                    <div>
                        <Warning {...warning} />
                        {edit && !deleted && (
                            <Edit
                                className='field-edit-icon'
                                onClick={edit}
                                style={{ color: 'gray' }}
                            />
                        )}
                    </div>
                </div>
                <Card.section>
                    <Card.label>Template name</Card.label>
                    {name}
                </Card.section>
                <Card.section>
                    <Card.label>Is active</Card.label>
                    {active ? 'Yes' : 'No'}
                </Card.section>
                <Card.section>
                    <Card.label>Is locked</Card.label>
                    {locked ? 'Yes' : 'No'}
                </Card.section>
                <Card.section>
                    <Card.label>Signing type</Card.label>
                    {qualificationStepType}
                </Card.section>
                {/* <Card.section>
                <Card.label>Testing</Card.label>
                <Link to={`/${[basePath, 'test', ...pathTail].join('/')}`}>Send test</Link>
            </Card.section> */}
                {/* eslint-disable-next-line */}
                {stepByStepId.status != 'complete' && (
                    <Card.section>
                        <Card.label>Mapping process</Card.label>
                        {stepByStepId.currentStep.outputData?.error && <div className='error'>{stepByStepId.currentStep.outputData?.error}</div>}
                        <Process.process proc={stepByStepId} refetch={FIX_THIS_REFETCH_FEATURE(id)}>
                            <Div className='vrtl-process-short-steps'>
                                <Process.shortSteps>
                                    <Process.shortStep />
                                </Process.shortSteps>
                            </Div>
                            <Div className='vrtl-step-card__footer'>
                                <Popup.popup>
                                    <Popup.inner>
                                        <Popup.close />
                                        <Process.currentStep />
                                        <div />
                                    </Popup.inner>
                                    <Popup.trigger>Next step</Popup.trigger>
                                </Popup.popup>
                            </Div>
                        </Process.process>
                    </Card.section>
                )}
            </div>
        </div>
    )
}

const UPDATE_MAPPED_TEMPLATE = `mutation ($id: UUID!, $patch: MappedSigningTemplatePatch!) {
  updateMappedSigningTemplateById(input: {id: $id, mappedSigningTemplatePatch: $patch}) {
    mappedSigningTemplate{
      id
      locked
      active
      qualificationStepType
    }
  }
}`

const LeftForm = ({
    mappedSigningTemplateById: {
        id, name, active: baseActive, locked: baseLocked,
    },
    form: {
        active,
        locked,
        qualificationStepType,
        validateInput,
        getChanged,
        setLoading,
        error: { onError, error },
    },
    resolve,
}) => {
    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const changedInput = getChanged(input)
            if (changedInput) {
                new Mutation({
                    mutation: UPDATE_MAPPED_TEMPLATE,
                    onSubmit: () => {
                        setLoading(true)
                        return {
                            id,
                            patch: changedInput,
                        }
                    },
                    onResolve: resolve,
                    onError,
                }).submit()
            } else {
                resolve()
            }
        } else {
            resolve()
        }
    }
    const qualificationOptions = [
        { displayValue: 'Email', value: 'email' },
        { displayValue: 'Inline', value: 'inline' },
    ]

    return (
        <div>
            <div className='vrtl-signing-left__section'>
                <div className='vrtl-signing__header'>
                    Mapped template
                    {!baseLocked && (
                        <Popup.popup>
                            <Popup.inner>
                                <Popup.close />
                                <div>Are you sure you'd like to delete this?</div>
                                <div className='row--justify-between'>
                                    <div className='styled-button'>No</div>
                                    <div className='styled-button'>Yes</div>
                                </div>
                            </Popup.inner>
                            <Popup.trigger className=''>
                                <Delete className='field-edit-icon' style={{ color: 'gray' }} />
                            </Popup.trigger>
                        </Popup.popup>
                    )}
                </div>
                <Card.section>
                    <Card.label>Template name</Card.label>
                    {name}
                </Card.section>
                <Card.section>
                    <Card.label>Is active</Card.label>
                    {baseLocked ? <CheckBox {...active} /> : baseActive ? 'Yes' : 'No'}
                </Card.section>
                <Card.section>
                    <Card.label>Is locked</Card.label>
                    {!baseLocked ? <CheckBox {...locked} /> : 'Yes'}
                </Card.section>
                <Card.section>
                    <Card.label>Signing type</Card.label>
                    {!baseLocked ? <Dropdown {...qualificationStepType} options={qualificationOptions} /> : qualificationStepType.value}
                </Card.section>
                <div className='error'>{error}</div>
                <div className='row'>
                    <div className='round-form-center-icon'>
                        <Cancel
                            className='dropdown-arrow'
                            style={{ color: 'red' }}
                            onClick={resolve}
                        />
                    </div>
                    <div className='flex-1' />
                    <div className='round-form-center-icon'>
                        <Done
                            className='dropdown-arrow'
                            style={{ color: 'green' }}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const GET_LOCKED_STATUS = {
    query: (id) => `{
        mappedSigningTemplateById(id:"${id}"){
            ${MappedTemplate.fragment}
            ${Process.fragment}
            signingTemplateBySigningTemplateId{
              id
              name
              deleted
              updatedOn
            }
        }
    }`,
    args: (props) => props.match.params.mapped_template_id,
}

const Warning = ({
    parentDeleted,
    parentName,
    deleted,
    updatedOn,
    templateCopyOn,
    position,
    width,
}) => {
    if (deleted) {
        return (
            <HoverToolTip position={position} width={width}>
                <ErrorIcon className='field-edit-icon' style={{ color: 'red' }} />
                <div>
                    Mapped template has been edited. Template usage disabled. in flight
                    qualifications using this mapped template may fail to send, or send incorrectly.
                </div>
            </HoverToolTip>
        )
    }
    if (
        templateCopyOn
        && (parentDeleted || new Date(updatedOn).getTime() > new Date(templateCopyOn).getTime())
    ) {
        return (
            <HoverToolTip position='bottom right'>
                <WarningIcon className='field-edit-icon' style={{ color: 'orange' }} />
                <div>
                    <div>
                        The template this mapping was based off has been
                        {' '}
                        {parentDeleted ? 'deleted' : 'updated'}
                        {' '}
                        since this copy was made.
                    </div>
                    <div>
                        This mapped template is still usable
                        {' '}
                        {!parentDeleted
                            && `, however changes that occured in "${parentName}" will not be reflected here.`}
                    </div>
                    {!parentDeleted && (
                        <div>
                            If you need to update your mapping for use in the vertalo system, create
                            a new mapped template.
                        </div>
                    )}
                </div>
            </HoverToolTip>
        )
    }
    return ''
}

export default {
    right: Delv.queryHOC(Right, GET_LOCKED_STATUS), // this is fine to do because delv wont make the same request twice
    left: Delv.queryHOC(
        EditSwitch.hoc(
            LeftCard,
            Form(LeftForm, {
                fields: {
                    locked: {
                        named: 'locked',
                        type: 'boolean',
                    },
                    active: {
                        named: 'active',
                        type: 'boolean',
                    },
                    qualificationStepType: {
                        name: 'qualificationStepType',
                        type: 'dropdown',
                    },
                },
                defaultValueKey: 'mappedSigningTemplateById',
            }),
        ),
        GET_LOCKED_STATUS,
    ),
}

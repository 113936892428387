import React from 'react'

import Card from 'common/Card.js'
import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'

import './styles.scss'

export default ({
    type,
    city,
    province,
    postalCode,
    lineOne,
    lineTwo,
    countryByCountry,
    name,
    edit,
    submit,
    cancel,
}) => {
    const prefix = 'vrtl-address-card'
    return (
        <div className={`${prefix}__container`}>
            <Card.section>
                <div className={`${prefix}__label`}>Type</div>
                {type}
                {IconHelper([[edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />, submit], cancel], prefix)}
            </Card.section>
            <Card.section>
                <div className={`${prefix}__label`}>Country</div>
                {countryByCountry ? countryByCountry.name : name}
            </Card.section>
            <Card.section>
                <div className={`${prefix}__label`}>City</div>
                {city}
            </Card.section>
            <Card.section>
                <div className={`${prefix}__label`}>State/Province</div>
                {province}
            </Card.section>
            <Card.section>
                <div className={`${prefix}__label`}>Zip/Postal code</div>
                {postalCode}
            </Card.section>
            <Card.section>
                <div className={`${prefix}__label`}>Line one</div>
                {lineOne}
            </Card.section>
            {
                lineTwo
                    ? (
                        <Card.section>
                            <div className={`${prefix}__label`}>Line two</div>
                            {lineTwo}
                        </Card.section>
                    )
                    : ''
            }

        </div>
    )
}

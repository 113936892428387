import React from 'react'
import Add from '@material-ui/icons/AddCircle'

import Delv from 'delv/delv-react.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Div from 'common/Div.js'
import ViewAppend from 'common/ViewAppend.js'
import Card from 'common/Card.js'
import OrderBy from 'common/OrderBy.js'
import Asset from 'components/asset/index.js'
import Round from 'components/round/index.js'
import Map from 'common/Map.js'
import Security from 'components/security/index.js'
import { canEditDefault } from 'util/permissions.js'
import NoneFound from 'common/NoneFound/index.js'

const GET_ASSET_TYPES = `{
  allAssetTypes{
    nodes{
      type
      accountingType
    }
  }
}`

const IssuerView = (props) => {
    const GET_ASSETS = {
        query: `{
            allAssets(orderBy: CREATED_ON_DESC) {
                nodes{
                    ${Asset.fragment.toString()}
                    roundsByAssetId(orderBy: OPENS_ON_DESC){
                        nodes{
                            ${Round.fragment.toString()}
                            trancheBondDatumByRoundId {
                                ${Round.tranche.fragment.toString()}
                            }
                            securityBySecurityId {
                                ${Security.fragment.toString()}
                                securityListingBySecurityId{
                                    id
                                    accountByAtsId{
                                      id
                                      name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`,
        format: (response) => {
            const orderByData = response.allAssets.nodes.map((node) => {
                const { type, accountingType } = node.assetTypeByType

                const rounds = node.roundsByAssetId.nodes.map((round) => {
                    const unitValue = round.securityBySecurityId.unitValue
                    const atsName = round.securityBySecurityId?.securityListingBySecurityId?.accountByAtsId.name

                    return {
                        ...round,
                        term: round.trancheBondDatumByRoundId?.term,
                        coupon: round.trancheBondDatumByRoundId?.coupon,
                        unitValue,
                        atsName,
                    }
                })

                return {
                    type,
                    accountingType,
                    rounds,
                    ...node,
                }
            })
            return {
                orderByData,
            }
        },
    }

    const roundDisplayProps = {
        offset: <div className='icon-offset--2' />,
    }

    const assetDisplayProps = {
        offset: <div className='icon-offset' />,
    }

    const addAssetForm = (
        <Delv.query query={GET_ASSET_TYPES} cacheProcess='query'>
            <Div className='asset-asset-card-container'>
                <Asset.rowHeading offset={<div className='icon-offset' />} />
                <Asset.form displayProps={assetDisplayProps} />
            </Div>
        </Delv.query>
    )

    const canEdit = canEditDefault(props)
    const prefix = 'vrtl-asset-issuer-view'
    return (
        <ViewAppend.container target canEdit={canEdit}>
            <Delv.query {...props} {...GET_ASSETS}>
                <OrderBy.order dropdownFields={['type', 'status']}>
                    <Asset.orderByHeader>
                        <ViewAppend.icon append={addAssetForm}>
                            <Add />
                        </ViewAppend.icon>
                    </Asset.orderByHeader>
                    <ViewAppend.target />
                    <Map
                        arrayKey='_orderBy.data'
                        noneFound={(
                            <ViewAppend.noneFound>
                                <NoneFound size='lg'>No assets found.</NoneFound>
                            </ViewAppend.noneFound>
                        )}
                    >
                        <Div className={`${prefix}__table--small ${prefix}--curved-top`}>
                            <Asset.rowHeading offset={<div className='inline-offset' />} />
                            <Card.collapsible className='' isOpen>
                                <EditSwitch.switch canEdit={canEdit}>
                                    <Asset.row collapse={<Card.arrow />} />
                                    <Delv.query
                                        query={GET_ASSET_TYPES}
                                        networkPolicy='cache-first'
                                        cacheProcess='query'
                                    >
                                        <Asset.form displayProps={assetDisplayProps} />
                                    </Delv.query>
                                </EditSwitch.switch>
                                <ViewAppend.container
                                    target
                                    canEdit={canEdit}
                                    cleanProps={({
                                        id, name, status, ...properties
                                    }) => ({ assetId: id, ...properties })}
                                >
                                    <Round.rowHeading offset={<div className='inline-offset--2' />}>
                                        <ViewAppend.icon
                                            append={<Round.form displayProps={roundDisplayProps} />}
                                        >
                                            <Add id='round_add' />
                                        </ViewAppend.icon>
                                    </Round.rowHeading>
                                    <ViewAppend.target />
                                    <Map
                                        arrayKey='rounds'
                                        noneFound={(
                                            <ViewAppend.noneFound>
                                                <NoneFound>No rounds found.</NoneFound>
                                            </ViewAppend.noneFound>
                                        )}
                                    >
                                        <EditSwitch.switch canEdit={canEdit}>
                                            <Round.row offset={<div className='inline-offset--2' />} />
                                            <Round.form displayProps={roundDisplayProps} />
                                        </EditSwitch.switch>
                                    </Map>
                                </ViewAppend.container>
                            </Card.collapsible>
                        </Div>
                    </Map>
                </OrderBy.order>
            </Delv.query>
        </ViewAppend.container>
    )
}

export default IssuerView

import * as Validators from 'common/FormV2/validators'

const config = {
    email: {
        hasChanged: Validators.change,
        onBlur: Validators.email,
        onChange: Validators.whitespace,
        onSubmit: Validators.email,
    },
    link: {
        hasChanged: Validators.yes,
        onBlur: Validators.link,
        onChange: Validators.none,
        onSubmit: Validators.link,
    },
    string: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.exists,
    },
    phone: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.phone,
    },
    date: {
        hasChanged: Validators.change,
        onBlur: Validators.dateDisplay,
        onChange: Validators.none,
        onSubmit: Validators.dateString,
    },
    number: {
        hasChanged: Validators.change,
        onBlur: Validators.formatNumberWithComma,
        onChange: Validators.number,
        onSubmit: Validators.formatNumber,
    },
    boolean: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.exists,
        defaultValue: 'yes',
    },
    dropdown: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.dropdown,
    },
    dropdownTypeahead: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.dropdownTypeahead,
        defaultValue: '',
    },
    dropdownTypeaheadWithData: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.dropdownTypeahead,
        defaultValue: '',
    },
    blockchainAddress: {
        hasChanged: Validators.change,
        onBlur: Validators.blockchainAddress,
        onChange: Validators.none,
        onSubmit: Validators.blockchainAddress,
    },
    integer: {
        hasChanged: Validators.change,
        onBlur: Validators.formatIntegerWithComma,
        onChange: Validators.integer,
        onSubmit: Validators.formatInteger,
    },
    password: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.exists,
    },
    textArea: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.exists,
    },
    checkbox: {
        hasChanged: Validators.change,
        onBlur: Validators.none,
        onChange: Validators.none,
        onSubmit: Validators.checkbox,
    },
}

export default config

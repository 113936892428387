import { StyleSheet, Font } from '@react-pdf/renderer'

Font.register('@react-pdf/renderer', { fontFamily: 'Helvetica-Bold' })
Font.register('@react-pdf/renderer', { fontFamily: 'Helvetica' })

export default StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 60,
        fontFamily: 'Helvetica',
    },
    table: {
        display: 'table',
        width: 'auto',
        marginBottom: 20,
    },
    row: {
        margin: 'auto',
        flexDirection: 'row',
    },
    header: {
        fontSize: 16,
        padding: 5,
    },
    header_cell: {
        width: '33.3333333333%',
        fontSize: 16,
        padding: 10,
        paddingLeft: 5,
    },
    cell: {
        width: '33.3333333333%',
        padding: 5,
        fontSize: 12,
    },
    container: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    container_column: {
        marginTop: 10,
        marginBottom: 20,
        flexDirection: 'column',
    },
    container_row: {
        padding: 2.5,
        marginTop: 10,
        marginBottom: 20,
        flexDirection: 'row',
    },
    container_box: {
        marginBottom: 20,
    },
    title: {
        fontSize: 18,
    },
    title_page: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 26,
        marginBottom: 50,
        paddingTop: 20,
        width: '100%',
    },
    title_colored: {
        backgroundColor: '#09518E',
        color: '#FFFFFF',
        padding: 2.5,
    },
    logo_container: {
        width: 200,
        height: 70,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    footer: {
        fontSize: 10,
        marginTop: 5,
        position: 'absolute',
        textAlign: 'center',
        bottom: 20,
        left: 0,
        right: 0,
    },
    center: {
        textAlign: 'center',
    },

})

import React from 'react'
import Format from 'util/format/Format.js'

const HeaderItem = ({ children }) => (
    <div className='investor-messages-header-left'>{children}</div>
)

const Compact = ({
    name, subject, date, investorId, messageId,
}) => (
    <div className='investor-message'>
        <div className='investor-message-name'>
            <a href={`/investor/${investorId}`}>{name}</a>
        </div>
        <div className='investor-message-title'>
            <a href={`/message/${messageId}`}>{subject}</a>
        </div>
        <div className='investor-message-date'>{Format.date(date)}</div>
    </div>
)

const Header = ({ className = 'investor-messages-header', children }) => <div className={className}>{children}</div>

const Container = ({
    className = 'investor-messages',
    children,
}) => <div className={className}>{children}</div>

export default {
    compact: Compact,
    header: Header,
    container: Container,
    headerItem: HeaderItem,
}

import React from 'react'
import OrderBy from 'common/OrderBy.js'
import SafeReact from 'util/SafeReact.js'

export default ({
    prefix = 'vrtl-document-order-by-header',
    children,
    sharedVia,
    ...props
}) => (
    <OrderBy.bar {...props} addClassName={`${prefix}--curved-top ${prefix}--gap ${prefix}__heading`}>
        <OrderBy.filter
            field='name'
            className={`${prefix}__section--2`}
            inputClassName='no-margin'
        />
        {sharedVia ? (
            <div className={`${prefix}__section`}>
                Shared Via
            </div>
        ) : (
            <>
                <div className={`${prefix}__section`}>
                    Upload Status
                </div>
                <div className={`${prefix}__section`}>
                    Shared With
                </div>
            </>
        )}
        <OrderBy.sort date field='createdOn' className={` ${prefix}__section`}>
            Uploaded On
        </OrderBy.sort>
        <div className={`${prefix}__icon-offset`} />
        <div className={`${prefix}__icon-offset`} />
        {SafeReact.cloneChildren(children, props)}
    </OrderBy.bar>
)

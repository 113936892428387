import React from 'react'
import Format from 'util/format/Format.js'

// Not to be confused with src/round/InvestorCard.js.  They're different components, the name collision is tech-debt.
export default ({
    name,
    amount,
    asset,
    paidOn,
    status,
    currency,
    percentage,
}) => (
    <div className='buyer'>
        <div className='buyer-name-section flex-1'>{name}</div>
        <div className='buyer-unit-section flex-1'>
            <span>
                {Format.number(amount)}
                {' '}
                {currency}
            </span>
            <span className='buyer-percent-text'>{percentage}</span>
        </div>
        <div className='buyer-status-section flex-1'>
            <span className='buyer-status-text'>{asset}</span>
        </div>
        <div className='buyer-status-section flex-1'>
            <span className='buyer-status-text'>
                {paidOn ? Format.date(paidOn) : status}
            </span>
        </div>
    </div>
)

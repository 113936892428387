import React from 'react'
import AddCircle from '@material-ui/icons/AddCircle'

import Breadcrumbs from 'common/Breadcrumbs'
import Container from 'common/Container'
import Footer from 'common/Footer'
import Nav from 'common/Nav'
import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import MultiSelect from 'common/MultiSelect.js'
import Div from 'common/Div.js'
import Customer from 'customer/index.js'
import Delv from 'delv/delv-react'
import ViewAppend from 'common/ViewAppend.js'
import EditSwitch from 'common/NewEditSwitch.js'
import NoneFound from 'common/NoneFound/index.js'

import ImportCustomersForm from './ImportCustomersForm.js'

const BREADCRUMBS = [
    {
        display: 'Contacts',
        link: '/contacts',
        active: true,
    },
]

const Contacts = ({ userAccount: { role }, account: { type } }) => {
    const canEdit = role !== 'account_viewer' && type !== 'agent' && type !== 'ats'
    const GET_CUSTOMERS = Customer.fragment.nest('allCustomers').nest('query').toString()
    const FORMAT_CUSTOMERS = (data) => ({ orderByData: data.allCustomers.nodes })

    return (
        <Div>
            <Nav />
            <Container>
                <Breadcrumbs items={BREADCRUMBS} />
                {canEdit && (
                    <div className='row'>
                        <ImportCustomersForm />
                    </div>
                )}
                <MultiSelect>
                    <Delv.query query={GET_CUSTOMERS} format={FORMAT_CUSTOMERS}>
                        <OrderBy.order>
                            <ViewAppend.container canEdit={canEdit} target>
                                <Customer.orderByHeader>
                                    <ViewAppend.icon
                                        append={
                                            <Customer.form offset={<div className='icon-offset' />} />
                                        }
                                    >
                                        <AddCircle />
                                    </ViewAppend.icon>
                                </Customer.orderByHeader>
                                <Div className='buyers-container'>
                                    <Div className='order-by-inner'>
                                        <ViewAppend.target />
                                        <Map
                                            arrayKey='_orderBy.data'
                                            noneFound={<NoneFound>No contacts found.</NoneFound>}
                                        >
                                            <EditSwitch.switch canEdit={canEdit}>
                                                <Customer.row />
                                                <Customer.form
                                                    offset={<div className='icon-offset' />}
                                                />
                                            </EditSwitch.switch>
                                        </Map>
                                    </Div>
                                </Div>
                            </ViewAppend.container>
                        </OrderBy.order>
                    </Delv.query>
                </MultiSelect>
            </Container>
            <Footer />
        </Div>
    )
}

export default Contacts

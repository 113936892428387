const accountAlias = {
    all: ['broker', 'issuer', 'investor', 'agent', 'ats'],
    vendor: ['broker', 'issuer'],
    nonInvestor: ['broker', 'issuer', 'agent', 'ats'],
    core: ['broker', 'issuer', 'investor'],
    issuer: ['issuer'],
    broker: ['broker'],
    investor: ['investor'],
    ats: ['ats'],
    agent: ['agent'],
}

const expand = (accounts) => {
    if (accounts) {
        return accounts instanceof Array
            ? accounts.reduce((acc, val) => [...acc, ...accountAlias[val]], [])
            : accountAlias[accounts]
    }

    return undefined
}

export default { expand }

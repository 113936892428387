import * as React from 'react'

import Checkbox from 'common/Checkbox'
import FormControl from 'common/FormControl'
import FormControlLabel from 'common/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from 'common/FormGroup'

const InputCheckbox = ({
    id: propId,
    'data-testid': testId,
    helperText,
    label,
    name,
    onChange,
    error,
    CheckboxGroupProps = {},
    CheckboxProps = {},
    value = false,
    required,
}) => {
    const id = propId || label?.replace('/ /g', '-')
    const handleChange = (e) => {
        onChange({
            target: {
                value: e.target.checked,
                name,
            },
        })
    }

    return (
        <FormControl data-testid={testId} error={error} required={required}>
            <FormGroup
                aria-labelledby={id}
                name={name}
                value={value}
                {...CheckboxGroupProps}
            >
                <FormControlLabel
                    control={<Checkbox {...CheckboxProps} checked={Boolean(value)} />}
                    name={name}
                    label={label}
                    onChange={handleChange}
                    value={value}
                />
                <FormHelperText>{helperText}</FormHelperText>
            </FormGroup>
        </FormControl>
    )
}

export default InputCheckbox

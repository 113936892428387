import React from 'react'

export default ({
    children,
    offset = '',
    ...props
}) => {
    const prefix = 'vrtl-asset-class-card'
    return (
        <div className={`${prefix}__heading-container`}>
            <div className={`${prefix}__section--3`}>
                {offset}
                Investor
            </div>
            <div className={`${prefix}__section--2`}>Amount</div>
            {
                children
                    ? React.cloneElement(children, { ...props, className: `${prefix}__icon-add` })
                    : <div className={`${prefix}__icon-offset`} />
            }
            <div className={`${prefix}__icon-offset`} />
        </div>
    )
}

import GenericForm from 'common/form/GenericForm.js'
import DelegateCard from './DelegateCard.js'

import './styles.scss'

const CREATE_USERS_ACCOUNT = `mutation ($firstName: String!, $lastName: String!, $email: String!, $role:String!) {
  makeDelegate(input: {email: $email, firstName: $firstName, lastName: $lastName, role:$role}) {
    usersAccount {
      id
      status
      role
      userByUserId {
        id
        firstName
        lastName
        status
        emailsByUserId {
          nodes {
            id
            email
          }
        }
      }
    }
  }
}`

const UPDATE_USER_ACCOUNT = `mutation ($id: UUID!, $patch: UsersAccountPatch!) {
    updateUsersAccountById(input: {id: $id, usersAccountPatch: $patch}) {
        usersAccount {
            id
            role
            status
        }
    }
}`

const roleOptions = [
    { displayValue: 'Admin', value: 'account_admin' },
    { displayValue: 'Viewer', value: 'account_viewer' },
]

const statuses = [
    { displayValue: 'Suspended', value: 'suspended' },
    { displayValue: 'Active', value: 'active' },
]

const formConfig = {
    fields: {
        firstName: {
            type: 'string',
            error: 'First name is required.',
            skipRenderOn: ({ type }) => type === 'update',

        },
        lastName: {
            type: 'string',
            error: 'Last name is required.',
            skipRenderOn: ({ type }) => type === 'update',

        },
        email: {
            type: 'email',
            error: 'Email is required.',
            skipRenderOn: ({ type }) => type === 'update',
        },
        role: {
            type: 'dropdown',
            error: 'Role is required.',
            props: {
                options: roleOptions,
            },
        },
        status: {
            type: 'dropdown',
            error: 'Status is required.',
            props: {
                options: statuses,
            },
        },
    },
}

export default GenericForm({
    Display: DelegateCard,
    createField: CREATE_USERS_ACCOUNT,
    updateField: UPDATE_USER_ACCOUNT,
    prefix: 'vrtl-delegate-form',
    formConfig,
})

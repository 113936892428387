import React, { Component } from 'react'
import FormValidator from 'util/form/Form.js'
import Blur from 'util/form/validators/blur.js'

function Form(WrappedComponenet, formDefinition) {
    return class extends Component {
        constructor(props) {
            super(props)
            this.state = this.getDefaultState()
            this.formValidators = props.viewSwitch ? {} : false
        }

        componentDidUpdate = (prevProps) => {
            if (
                prevProps.viewSwitch
                && prevProps.viewSwitch.UI !== this.props.viewSwitch.UI
            ) {
                // eslint-disable-next-line
                this.setState({ ...this.getDefaultState(), ...this.state })
            }
        }

        getDefaultState = () => {
            let definition = formDefinition
            if (formDefinition instanceof Array) {
                if (this.props.viewSwitch) {
                    definition = formDefinition[this.props.viewSwitch.UI]
                } else {
                    throw new Error(
                        'Form definition cannot be an array unless it is nested within a view switch',
                    )
                }
            }

            const state = {}
            const defaultProps = {}

            Object.keys(definition.fields).forEach((key) => {
                let props = this.props
                // Form conflicts with GenericForm, Form needs to be refactored to consolidate logic here.
                if (definition.fields[key].skipRender && definition.fields[key].skipRender(props)) {
                    return
                }

                if (
                    definition.defaultValueKey
                    && props[definition.defaultValueKey]
                ) {
                    props = props[definition.defaultValueKey]
                }
                const value = definition.fields[key]
                let defaultValue
                if (value.default instanceof Function) {
                    defaultValue = value.default(props)
                } else if (
                    value.default
                    || value.default === false
                    || value.default === 0
                ) {
                    defaultValue = value.default
                } else if (
                    props[key]
                    || props[key] === false
                    || props[key] === 0
                ) {
                    defaultValue = props[key]
                    defaultProps[key] = props[key]
                } else {
                    if (props[key]) {
                        defaultProps[key] = props[key]
                    }
                    defaultValue = props[key] || value.default || ''
                }
                if (defaultValue) {
                    state[key] = Blur[value.type](defaultValue)
                } else {
                    state[key] = defaultValue
                }
            })

            if (definition.nodeType) {
                state.nodeType = {
                    type: definition.nodeType,
                    cancel: {
                        id: `${definition.nodeType}_cancel`,
                    },
                    submit: {
                        id: `${definition.nodeType}_submit`,
                    },
                }
            }

            this.formValidator = new FormValidator(definition, state)
            return state
        }

        handleBlur = (event) => {
            this.setState(this.formValidator.verifyBlur(event))
        }

        handleChange = (event) => {
            this.setState(this.formValidator.verifyChange(event))
        }

        validateInput = () => {
            const verifiedState = this.formValidator.verifySubmit(this.state)
            if (verifiedState.hasErrors) {
                this.setState(verifiedState.values)
                return verifiedState
            }
            return verifiedState.values
        }

        getChanged = (state) => {
            const changedValues = this.formValidator.getChanged(state)
            return changedValues
        }

        resetState = () => {
            this.setState(this.getDefaultState())
        }

        setError = (error) => {
            this.setState({ error })
        }

        onError = (error) => {
            this.setError(error[0].message)
        }

        setLoading = (loading) => {
            this.setState({ loading })
        }

        getDefinition = (node) => {
            const key = node.name
            return formDefinition.fields[key]
        }

        render = () => {
            const formFields = {}
            for (const key of Object.keys(this.state)) {
                if (key.endsWith('Error')) {
                    // old implementation, now we include errors on the main key
                } else if (key !== 'error') {
                    formFields[key] = {
                        name: key,
                        id: this.state.nodeType ? `${this.state.nodeType.type.toLowerCase()}_${key.toLowerCase()}` : '',
                        value: this.state[key],
                        onBlur: this.handleBlur,
                        onChange: this.handleChange,
                        error: this.state[`${key}Error`],
                    }
                    const formDef = formDefinition.fields[key]
                    if (formDef) {
                        if (formDef.type === 'dropdownTypeahead') {
                            formFields[key].typeahead = true
                        }
                        if (formDef.type === 'dropdown' && formDef.options) {
                            if (formDef.options instanceof Function) {
                                formFields[key].options = formDef.options(this.props)
                            } else {
                                formFields[key].options = formDef.options
                            }
                        }
                        if (formDef.props) {
                            formFields[key] = {
                                ...formFields[key],
                                ...formDef.props,
                            }
                        }
                    }
                }
            }
            const form = {
                error: {
                    onError: this.onError,
                    error: this.state.error,
                    set: this.setError,
                },
                getDefinition: this.getDefinition,
                handleBlur: this.handleBlur,
                handleChange: this.handleChange,
                validateInput: this.validateInput,
                resetState: this.resetState,
                getChanged: this.getChanged,
                setLoading: this.setLoading,
                ...formFields,
                loading: this.state.loading,
            }
            return <WrappedComponenet form={form} {...this.props} />
        }
    }
}

export default Form

import * as React from 'react'

import DropdownTypeahead from 'common/DropdownTypeahead'
import useQuery from 'delv/hooks/use-query'

const DropdownTypeaheadWithData = ({
    query,
    queryOptions,
    ...props
}) => {
    const { data, loading } = useQuery(query, queryOptions)

    return (
        <DropdownTypeahead
            {...props}
            loading={loading}
            options={data || []}
        />
    )
}

export default DropdownTypeaheadWithData

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const makeKebabCase = (str) => str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-')

const makeSnakeCase = (str) => str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_')

const makePascalCase = (str) => makeKebabCase(str)
    .split('-')
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join('')

const makeTitleCase = (str) => makeKebabCase(str)
    .split('-')
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

const makeCamelCase = (str) => makeKebabCase(str)
    .split('-')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()))
    .join('')

const camelCaseToTitleCase = (str) => {
    const spacedString = str.replace(/([A-Z])/g, ' $1')
    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1)
}

module.exports = {
    capitalize,
    camelCaseToTitleCase,
    makeCamelCase,
    makeKebabCase,
    makeSnakeCase,
    makePascalCase,
    makeTitleCase,
}

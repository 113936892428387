import React from 'react'
import Mutation from 'delv/Mutation'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Form from 'common/form/Form'
import UseViewSwitch from 'hooks/UseViewSwitch.js'
import './Tokenize.css'

const formConfig = {
    fields: {
        name: {
            type: 'string',
            submit: (value) => {
                if (!value.length) {
                    return {
                        error: true,
                        message: 'Token name is required.',
                    }
                }
                if (!value.match(/^[A-z0-9\~\!\@\#\$\%\^\&\*\(\)\_\+\{\}\|\[\]\;\''\:\"\,\.\/\<\>\?\`\-\=\s]{1,40}$/)) {
                    return {
                        error: true,
                        message: 'Token name must be less than 40 characters.',
                    }
                }
                return value
            },
        },
        symbol: {
            type: 'string',
            submit: (value) => {
                if (!value.length) {
                    return {
                        error: true,
                        message: 'Token symbol is required.',
                    }
                }
                if (!value.match(/^[A-z0-9\~\!\@\#\$\%\^\&\*\(\)\_\+\{\}\|\[\]\;\''\:\"\,\.\/\<\>\?\`\-\=\s]{1,40}$/)) {
                    return {
                        error: true,
                        message: 'Token symbol must be less than 40 characters.',
                    }
                }
                return value
            },
        },
        chain: {
            type: 'dropdown',
            submit: (value) => {
                if (!value) {
                    return {
                        error: true,
                        message: 'Chain is required.',
                    }
                }
                return value
            },
        },
    },
    defaultValueKey: 'token',
}

const MAKE_TOKEN = `mutation($token:MakeTokenInput!){
    makeToken(input:$token){
    token{
      id
      status
      name
      symbol
      contractsByTokenId{
        nodes{
          id
          status
          chain
          contractAddress
          integrationByCustodianId{
            id
          }
          integrationByIssuancePlatformId{
            id
          }
        }
      }
    }
  }
}`

const CreateToken = (props) => {
    const input = props.form.validateInput()
    if (!input.hasErrors) {
        const returnVal = {
            token: {
                _tokenId: props.token.id,
                _name: input.name,
                _symbol: input.symbol,
                _chain: input.chain,
                _contractAddressId: props.token.contract ? props.token.contract.id : null,
            },
        }
        new Mutation({
            mutation: MAKE_TOKEN,
            onSubmit: () => returnVal,
            onResolve: props.popup.close,
        }).submit()
    }
}

const TokenizeViewSwitch = ({ children, ...props }) => {
    const {
        setUI, next, back, view,
    } = UseViewSwitch()

    const getNextButtonEvent = () => {
        if (view === 0) {
            const input = props.form.validateInput()
            if (!input.hasErrors) {
                next()
            }
        } else if (view === 1) {
            CreateToken(props)
        }
    }

    const child = children[view]
    return (
        <div className='token-form'>
            <div className='flex-1'>{React.Children.map(child, (fnChild) => React.cloneElement(fnChild, { ...props, setUI }))}</div>
            <div className='class-form-arrows-container'>
                {view === 0 ? (
                    <div />
                ) : (
                    <div className='class-form-arrow-container' onClick={back}>
                        <ArrowBack className='class-form-arrow' />
                        <div className='class-form-arrow-label'>BACK</div>
                    </div>
                )}
                <div className='class-form-arrow-container' onClick={getNextButtonEvent}>
                    <div className='class-form-arrow-label'>{view ? 'FINISH' : 'REVIEW'}</div>
                    <ArrowForward className='class-form-arrow' />
                </div>
            </div>
        </div>
    )
}

export default Form(TokenizeViewSwitch, formConfig)

const leafResolver = ({
    fieldName, root, context, cache, info,
}) => {
    const key = fieldName === info.resultKey ? fieldName : info.resultKey

    if (!Object.prototype.hasOwnProperty.call(root, key)) {
        throw new Error(
            `Some of the leaf data requested in the query is not in the cache ${fieldName}`,
        )
    }

    if (cache[context.parent?.__typename]?.[context.query.hash]?.[key]) {
        return cache[context.parent.__typename][context.query.hash][key]
    }

    return root[key]
}

export { leafResolver }

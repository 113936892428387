import * as React from 'react'

import Typography from 'common/Typography'
import Format from 'util/format/Format'

const PercentCell = ({ value }) => (
    <Typography variant='body2'>
        {`${Format.numberFixed(value)}%`}
    </Typography>
)

export default PercentCell

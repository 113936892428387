import React from 'react'
import Delv from 'delv/delv-react'

import OrderBy from 'common/OrderBy.js'
import Map from 'common/Map.js'
import Div from 'common/Div.js'
import Card from 'common/Card.js'
import EditSwitch from 'common/NewEditSwitch.js'
import SigningField from './signingField/index.js'

const GET_MAPPED_FIELDS = {
    query: (id) => `{${Delv.nest('allSigningFields', SigningField.fragment, { signingDocumentId: id })}}`,
    format: ({ allSigningFields }) => {
        const nodes = allSigningFields.nodes.map(({ signingRoleBySigningRoleId: { name }, mappingFieldByMapTo, ...other }) => ({
            roleName: name,
            mapTo: mappingFieldByMapTo.field,
            description: mappingFieldByMapTo.description,
            ...other,
        }))

        return {
            orderByData: nodes,
        }
    },
}

const Right = ({ match, mappedSigningTemplateById: { locked } }) => (
    <div className='vrtl-signing-templates__container--templates'>
        <div className='gray-header row--justify-between'>Fields</div>
        <Delv.query {...GET_MAPPED_FIELDS} args={() => match.params.document_id}>
            <OrderBy.order dropdownFields={['roleName', 'mapTo']}>
                <SigningField.orderByHeader />
                <Div className='buyers-container'>
                    <Div className='order-by-inner'>
                        <Map arrayKey='_orderBy.data'>
                            <EditSwitch.switch canEdit={!locked}>
                                <SigningField.row />
                                <SigningField.form templateId={match.params.mapped_template_id} />
                            </EditSwitch.switch>
                        </Map>
                    </Div>
                </Div>
            </OrderBy.order>
        </Delv.query>
    </div>
)
Right.delvConfig = {
    query: (id) => `{
mappedSigningTemplateById(id:"${id}"){
        id
        locked
    }
}`,
    args: (props) => props.match.params.mapped_template_id,
    networkPolicy: 'cache-first',
}

const Left = ({ signingDocumentById: { name } }) => (
    <div className='vrtl-signing-left__section'>
        <div className='vrtl-signing__header'>Document</div>
        <Card.section>
            <Card.label>Document name</Card.label>
            {name}
        </Card.section>
    </div>
)

Left.delvConfig = {
    query: (id) => `{
        signingDocumentById(id:"${id}"){
            id
            name
        }
    }`,
    args: (props) => props.match.params.document_id,
}

export default {
    right: Delv.queryHOC(Right, Right.delvConfig),
    left: Delv.queryHOC(Left, Left.delvConfig),
}

import dashCopy from '../../util/dashCopy/index.js'

const selectIdsFromCollection = ({
    collection, context, filterCache,
}) => {
    if (Array.isArray(collection)) return collection

    const clone = dashCopy.cloneDeep(collection)
    delete clone.totalCount

    let ids = []

    if (context.parent.args) {
        const filteredCache = filterCache(clone, context.parent.args, context)
        ids = filteredCache.data ? Object.keys(filteredCache.data) : Object.keys(filteredCache)
    } else if (typeof clone === 'string') {
        ids.push(clone)
    } else {
        ids = Object.keys(clone)
    }

    return ids
}

export { selectIdsFromCollection }

import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'

const FieldCard = ({
    name,
    type,
    mapTo,
    toName,
    toEmail,
    edit,
    cancel,
    submit,
}) => {
    const prefix = 'vrtl-signing-role'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{mapTo}</div>
            <div className={`${prefix}__section`}>{type}</div>
            <div className={`${prefix}__section`}>{toName || 'N/A'}</div>
            <div className={`${prefix}__section`}>{toEmail || 'N/A'}</div>
            {
                IconHelper([cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

export default FieldCard

import React from 'react'

import OrderBy from 'common/OrderBy.js'

export default (props) => {
    const prefix = 'vrtl-signing-template'
    return (
        <OrderBy.bar {...props}>
            <OrderBy.filter field='name' className={`${prefix}__section`}>
                Name
            </OrderBy.filter>
            <OrderBy.sort date field='updatedOn' className={`${prefix}__section`}>
                Updated on
            </OrderBy.sort>
            <OrderBy.sort date field='syncedOn' className={`${prefix}__section`}>
                Synced on
            </OrderBy.sort>
            <div className={`${prefix}__icon-offset`} />
        </OrderBy.bar>
    )
}

import React from 'react'
import Form from 'common/form/Form.js'
import Mutation from 'delv/Mutation.js'
import {
    Input, Date as DateInput, Dropdown, CheckBox,
} from 'common/FormUI.js'
import './VestingForm.scss'

/* eslint-disable max-len */
const mutation = {
    distribution: `mutation ($id:UUID!, $automatic:Boolean!, $cliff:Int!, $periods:Int!, $duration:String!, $startsOn:Datetime!) {
  createVestingSchedule(input: {vestingSchedule: {distributionId: $id, automatic: $automatic, cliff: $cliff, periods: $periods, duration: $duration, startsOn: $startsOn}}) {
    vestingSchedule {
      id
      cliff
      periods
      automatic
      currentPeriod
      startsOn
      duration
      vestingEventsByVestingScheduleId {
        nodes {
          id
          startPeriod
          endPeriod
        }
      }
      distributionByDistributionId {
        id
      }
    }
  }
}`,
    allocation: `mutation ($id:UUID!, $automatic:Boolean!, $cliff:Int!, $periods:Int!, $duration:String!, $startsOn:Datetime!) {
        createAllocationsVestingSchedule(input: { allocationsVestingSchedule: { allocationId: $id, automatic: $automatic, cliff: $cliff, periods: $periods, duration: $duration, startsOn: $startsOn }}) {
          allocationsVestingSchedule {
            id
            cliff
            periods
            automatic
            startsOn
            duration
            allocationByAllocationId {
              id
            }
          }
        }
      }`,
}
/* eslint-enable max-len */

const VestingForm = ({
    cancel,
    popup,
    type,
    form: {
        automatic, cliff, periods, duration, startsOn, validateInput,
    },
    ...props
}) => {
    const onResolve = cancel == null ? popup.close : cancel

    const handleSubmit = (event) => {
        event.preventDefault()
        const input = validateInput()
        if (!input.hasErrors) {
            const submitValue = {
                id: props[`${type}Id`],
                ...input,
            }
            new Mutation({
                mutation: mutation[type],
                onSubmit: () => submitValue,
                onResolve,
            }).submit()
        }
    }

    return (
        <div className='margin'>
            <div className='card'>
                <div className='vesting-section'>
                    <div className='card-label'>PERIOD DURATION</div>
                    <Dropdown
                        options={[{ duration: 'MONTHS', value: 'month' }]}
                        width='8rem'
                        {...duration}
                    />
                </div>
                <div className='vesting-section'>
                    <div className='card-label'>CLIFF PERIODS</div>
                    <div className='vesting-input-container'>
                        <Input {...cliff} />
                    </div>
                </div>
                <div className='vesting-section'>
                    <div className='card-label'>PERIODS</div>
                    <div className='vesting-input-container'>
                        <Input {...periods} />
                    </div>
                </div>
                <div className='vesting-section'>
                    <div className='card-label'>START DATE</div>
                    <DateInput {...startsOn} />
                </div>
            </div>
            <div className='card'>
                <div className='vesting-section'>
                    <div className='vesting-automatic-container'>
                        AUTOMATIC VESTING:
                        <CheckBox {...automatic} />
                    </div>
                </div>
            </div>
            <div className='price-form-button-container'>
                <div className='price-form-button' onClick={popup.close}>
                    Cancel
                </div>
                <div className='price-form-button' onClick={handleSubmit}>
                    Submit
                </div>
            </div>
        </div>
    )
}

const formConfig = {
    // this will need to allow for selection from customers
    fields: {
        cliff: {
            type: 'integer',
            error: 'Cliff is required.',
            default: 1,
        },
        duration: {
            type: 'dropdown',
            default: 'month',
        },
        periods: {
            type: 'integer',
            error: 'Periods is required',
        },
        automatic: {
            type: 'boolean',
            default: true,
        },
        startsOn: {
            type: 'date',
            error: 'Starts on is required',
            default: (props) => props.defaultVestingStart || new Date().toISOString(),
        },
    },
}

export default Form(VestingForm, formConfig)

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
name
authorizedLink
unauthorizedLink
allocationId
accountByAgentId{
  id
  name
  email
}`)

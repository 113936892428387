import * as React from 'react'
import * as DateFns from 'date-fns'
import FormUI from 'common/FormUI.js'

const DatePicker = ({
    'data-testid': testID, onChange, onBlur, helperText, errorMessage, error, key, value, name, fullWidth, required, ...props
}) => {
    const handleFactory = (callBack) => (e) => {
        const newValue = e.target.value
        callBack({
            target: {
                value: newValue,
                name,
            },
        })
    }

    const handleChange = handleFactory(onChange)
    const handleBlur = handleFactory(onBlur)

    const formattedValue = value && new Date(value).toString() !== 'Invalid Date' && new Date(value).toISOString() === value
        ? DateFns.format(new Date(value), 'MM/dd/yyyy')
        : value || ''

    return (
        <div>
            <FormUI.date onChange={handleChange} onBlur={handleBlur} name={name} value={formattedValue} />
            <div>{props.label || helperText}</div>
        </div>
    )
}

export default DatePicker

import * as React from 'react'
import Typography from 'common/Typography'
import Box from 'common/Box'

const StepHeader = ({ currentStep, activeStep, isCurrentStep }) => {
    const title = activeStep?.renderAttributes?.title
    const instructions = activeStep?.renderAttributes?.instructions
    const about = activeStep?.renderAttributes?.about

    return currentStep.status !== 'completed' && (
        <Box data-testid='step-header' sx={{ py: 2 }}>
            <Typography variant='h4' as='h1' sx={{ my: 2 }}>{title}</Typography>
            { Boolean(about) && (
                <Typography variant='body1' sx={{ my: 1 }}>
                    <strong>About: </strong>
                    {about}
                </Typography>
            )}
            { isCurrentStep && Boolean(instructions) && (
                <Typography variant='body1' sx={{ my: 1 }}>
                    <strong>Instructions: </strong>
                    {instructions}
                </Typography>
            )}
        </Box>
    )
}

export default StepHeader

import * as React from 'react'

import Link from 'common/Link/index.js'

const Logo = ({
    href, to, logo, prefix = 'vrtl-logo', ...props
}) => {
    const map = {
        colorBug: `${window.location.origin}/content/logos/color-bug`,
        white: `${window.location.origin}/content/logos/white-full`,
        color: `${window.location.origin}/content/logos/color-full`,
    }

    const src = map[logo] || logo

    const LogoImg = () => (
        <img
            className={`${prefix}__img`}
            src={src}
            alt='vertalo'
            data-testid={`${logo}-logo`}
            {...props}
        />
    )

    if (href || to) {
        return (
            <Link className={prefix} data-testid='logo-link' href={href} to={to}>
                <LogoImg />
            </Link>
        )
    }

    return (
        <div className={prefix} data-testid='logo-box'>
            <LogoImg />
        </div>
    )
}

export default Logo

import Fragment from './Fragment.js'
import AllowedShares from './allowedShares/index.js'
import DocumentSharing from './documentSharing/index.js'
import Row from './Row.js'
import RowHeading from './RowHeading.js'
import Form from './Form.js'
import Upload from './Upload.js'
import OrderByHeader from './OrderByHeader.js'

import './styles.scss'

export default {
    fragment: Fragment,
    row: Row,
    rowHeading: RowHeading,
    form: Form,
    upload: Upload,
    allowedShares: AllowedShares,
    sharing: DocumentSharing,
    orderByheader: OrderByHeader,
}

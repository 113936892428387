import React from 'react'

import IconHelper from 'common/IconHelper.js'
import Edit from '@material-ui/icons/Edit'

import './styles.scss'

export default ({
    name,
    executeOrder,
    edit,
    cancel,
    submit,
    remove,
}) => {
    const prefix = 'vrtl-onboarding-question-join-row'
    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section`}>{name}</div>
            <div className={`${prefix}__section`}>{executeOrder}</div>
            {
                IconHelper([remove, cancel, [submit, edit && <Edit className={`${prefix}__icon-edit`} onClick={edit} />]], prefix)
            }
        </div>
    )
}

import * as React from 'react'

import Box from 'common/Box'
import Button from 'common/Button'
import FormV2 from 'common/FormV2'
import Typography from 'common/Typography'
import useFormView from 'hooks/UseFormView'

const buildFormConfig = (props) => {
    let data = {}

    if (props.dynamicData.data) {
        data = props.dynamicData.data
    }

    if (props.outputData) {
        data = { ...data, ...props.outputData }
    }

    const fields = props.schema.fields
    const formConfig = {
        fields: {},
    }

    fields
        .sort((a, b) => a.order - b.order)
        .forEach(({
            displayKey, key, type, error, regex, message,
        }) => {
            const config = {
                type,
                regex,
                label: displayKey,
                errorMessage: error,
                helperText: message,
            }

            if (data && data[key]) {
                config.defaultValue = data[key]
            }

            if (regex.toString() === '^-?(?:[0-9]+(?:\\.[0-9]*)?|[0-9]*\\.[0-9]+)$') {
                config.type = 'integer'
                config.onSubmit = (value, { defaultResolver }) => {
                    const initialResult = defaultResolver(value)

                    if (initialResult?.errorExists) {
                        return initialResult
                    }

                    return initialResult && initialResult.match(regex) ? initialResult : { errorExists: true, initialResult }
                }
            } else if (regex && ['string', 'password'].includes(type)) {
                config.onSubmit = (value, { defaultResolver }) => {
                    const initialResult = defaultResolver(value)

                    if (initialResult?.errorExists) {
                        return initialResult
                    }

                    return initialResult && initialResult.match(regex) ? initialResult : { errorExists: true, initialResult }
                }
            } else if (type === 'boolean') {
                config.type = 'boolean'
                config.options = [

                ]
            }

            formConfig.fields[key] = config
        })

    return formConfig.fields
}

const Disclosure = (props) => (
    <div>
        {props.text}
        {' '}
        <a target='_blank' href={props.link} rel='noreferrer'>View disclosure.</a>
    </div>
)

const StepForm = ({
    completeStep,
    variables,
    error,
    openSnackbar,
    ...props
}) => {
    const submissionError = props.outputData?.error
    if (props.schema.forms) {
        props.schema = { ...props.schema, ...props.schema.forms.new }
    }
    const title = props.schema.title
    const fields = buildFormConfig(props)
    const { validateInput, reset: resetForm, ...form } = useFormView({ fields })

    const handleSubmit = (event) => {
        event.preventDefault()
        const { valid, input } = validateInput()
        if (valid) {
            completeStep({
                variables: {
                    ...variables,
                    blob: input,
                },
            })
        } else {
            openSnackbar()
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='subtitle1'>{title}</Typography>
            {Boolean(submissionError) && <Typography color='error'>{submissionError}</Typography>}
            <FormV2 {...form} />
            {
                Boolean(props.schema.disclosures)
                && (
                    <Box>
                        <Typography variant='subtitle1'>Disclosures:</Typography>
                        {props.schema.disclosures.map((a) => <Disclosure {...a} />)}
                    </Box>
                )
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button variant='outlined' onClick={resetForm}>Reset</Button>
                <Button variant='contained' onClick={handleSubmit}>Continue</Button>
            </Box>
        </Box>
    )
}

export default StepForm

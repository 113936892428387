const getColumnSize = (density) => {
    const map = {
        compact: 36,
        standard: 54,
        comfortable: 67,
    }

    return map[density] || 54
}

const removeReactFromColumns = (columns) => {
    const columnJSON = JSON.stringify(columns.map((col) => ({
        ...col,
        filterOperators: undefined,
        renderCell: undefined,
        renderHeader: undefined,
        valueFormatter: undefined,
    })))

    return columnJSON
}

const addReactToColumns = (originalColumns) => (columns) => {
    try {
        const parsedColumns = JSON.parse(columns)

        const reactifiedColumns = parsedColumns.map((col) => {
            const {
                filterOperators,
                renderCell,
                renderHeader,
                valueFormatter,
            } = originalColumns.find((c) => c.field === col.field)

            const newCol = { ...col }

            Object.entries({
                filterOperators,
                renderCell,
                renderHeader,
                valueFormatter,
            }).forEach(([key, value]) => {
                if (value) {
                    newCol[key] = value
                }
            })

            return newCol
        })

        const missingColumns = originalColumns.filter(({ field }) => !parsedColumns.some(({ field: parsedField }) => field === parsedField))

        return [...reactifiedColumns, ...missingColumns]
    } catch (error) {
        throw new Error(error)
    }
}

const syncVisibilityModel = (source) => (stored) => {
    try {
        const parsed = JSON.parse(stored)

        const actual = Object.keys(source).reduce((acc, key) => {
            acc[key] = parsed[key] === undefined ? source[key] : parsed[key]
            return acc
        }, {})

        return actual
    } catch (e) {
        throw new Error(e)
    }
}

export {
    getColumnSize,
    removeReactFromColumns,
    addReactToColumns,
    syncVisibilityModel,
}

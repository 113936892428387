import { resolverMap } from '../resolvers/index.js'
import { selectNodeType } from '../selectors/node-type-selector.js'
import { prepareCacheContextHistory } from './cache-context-service.js'

const resolverService = (fieldName, root, args, context, info, {
    cache, filterCache, filterCacheByIds, keyConflict,
}) => {
    const nodeType = selectNodeType({
        fieldName, root, cache, info,
    })

    const nodeResolver = resolverMap[nodeType]

    const result = nodeResolver ? nodeResolver({
        fieldName, root, args, context, info, cache, filterCache, filterCacheByIds, keyConflict,
    }) : null

    if (fieldName !== 'totalCount') {
        prepareCacheContextHistory(context)
    }

    return result
}

export { resolverService }

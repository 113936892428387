import React from 'react'

import './styles.scss'

const CheckMark = ({ isSelected }) => (
    <div className='vrtl__checkmark--container'>
        {isSelected && (
            <>
                <div className='vrtl__checkmark--stem ' />
                <div className='vrtl__checkmark--kick' />
            </>
        )}
    </div>
)

export default CheckMark

/* eslint-disable max-classes-per-file */
import * as React from 'react'

import Delv from './delv.js'
import Query from './Query.js'
import TypeMap from './TypeMap.js'
import { argsToString } from './services/query-arg-service.js'
import Loading from '../common/loading/index.js'

const getArgs = (props) => (props.args instanceof Function ? props.args(props) : props[props.args])

const DelvReact = (props) => {
    const [isReady, setReady] = React.useState(false)

    React.useEffect(() => {
        Delv.config({
            ...props.config,
            onReady: () => setReady(true),
        })
    })

    return isReady ? props.children : <div>loading</div>
}

const ReactQuery = (props) => {
    const now = new Date()
    const [queryResult, setQueryResult] = React.useState({})
    const [stateLoading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)
    const [query, setQuery] = React.useState(false)

    const onError = (err) => {
        if (props.onError) {
            props.onError(err)
        } else {
            setLoading(false)
            setError(err.error)
        }
    }

    const onFetch = (promise, data) => {
        setLoading(true)
        if (props.onFetch) {
            props.onFetch(promise, data)
        }
    }

    const onResolve = (data) => {
        // pretty sus loading logic
        if (props.ignoreLoadDelay) {
            setQueryResult(data)
            setLoading(false)
            if (props.onResolve) {
                props.onResolve(data)
            }
        } else {
            const DELAY_TIME = 600
            const networkTiming = (now - new Date())
            const difference = DELAY_TIME - networkTiming
            const networkDelay = difference > 0 ? difference : 0
            const delay = networkTiming < 50 ? 0 : networkDelay
            if (delay === 0) {
                setQueryResult(data)
                setLoading(false)
                if (props.onResolve) {
                    props.onResolve(data)
                }
            } else {
                setTimeout(() => {
                    setQueryResult(data)
                    setLoading(false)
                    if (props.onResolve) {
                        props.onResolve(data)
                    }
                }, delay)
            }
        }
    }

    const resetProps = React.useCallback(() => {
        if (query) {
            query.removeCacheListener()
            query.removeListeners()
        }
        const queryOptions = { args: argsToString(props.args) }
        const tmpQuery = props.query instanceof Function ? props.query(getArgs(props), queryOptions) : props.query
        const queryInput = {
            query: tmpQuery,
            variables: props.variables,
            networkPolicy: props.networkPolicy,
            onFetch,
            onResolve,
            onError,
            cacheProcess: props.cacheProcess,
            formatResult: false,
        }
        const newQuery = new Query(queryInput)

        if (newQuery.networkPolicy !== 'network-policy') {
            newQuery.addCacheListener()
        }

        return newQuery
    })

    React.useLayoutEffect(() => {
        const newQuery = resetProps()
        newQuery.query()
        setQuery(newQuery)
        return () => {
            if (query) {
                query.removeCacheListener()
                query.removeListeners()
            }
        }
    }, [props.query, props.pagination?.offset, props.args])

    const cloneResult = () => JSON.parse(JSON.stringify(queryResult))

    const {
        query: propsQuery,
        variables,
        networkPolicy,
        formatResult,
        cacheProcess,
        children,
        loading,
        format,
        ...otherProps
    } = props

    if (stateLoading && !props.skipLoading) {
        return loading || <Loading.default />
    }

    if (error) {
        return React.cloneElement(children, {
            ...error,
            ...otherProps,
        })
    }
    const result = cloneResult()

    const resultNode = Object.values(result).find((res) => res && typeof res === 'object' && 'totalCount' in res)
    const totalCount = resultNode?.totalCount

    if (format) {
        return React.cloneElement(children, {
            ...format(result, props),
            ...otherProps,
            pagination: {
                ...props.pagination,
                ...props.args?.pagination,
                totalCount,
            },
        })
    }
    return React.cloneElement(children, {
        ...result,
        ...otherProps,
        pagination: {
            ...props.pagination,
            ...props.args?.pagination,
            totalCount,
        },
    })
}

const ReactQueryHOC = (WrappedComponent, config) => (props) => {
    let newQuery = config.query
    if (config.query instanceof Function) {
        let args = config.args
        if (args instanceof Function) {
            args = config.args(props)
        } else {
            args = props[args]
        }
        newQuery = config.query(args)
    }
    const { args, query, ...newProps } = config
    const loadingState = config.loading?.component || <Loading.fullPage />
    return (
        <ReactQuery query={newQuery} {...newProps} loading={loadingState}>
            <WrappedComponent {...props} />
        </ReactQuery>
    )
}

const DelvMap = ({
    arrayKey, children, noneFound = <div />, ...props
}) => {
    const data = props[arrayKey]
    if (data.nodes && data.nodes.length) {
        return data.nodes.map((node) => React.cloneElement(children, { key: node.id, ...props, ...node }))
    }
    if (typeof noneFound.type === 'string') {
        return noneFound
    }
    return React.cloneElement(noneFound, props)
}

const DelvMultiSelect = ({
    children,
    ...props
}) => {
    const selected = props.multiSelect.getValues()[0]
    const [selectedChild, nonSelected] = children
    if (selected) {
        return React.cloneElement(selectedChild, { ...props, key: selected.id, args: () => selected.id })
    }
    return nonSelected
}
/* eslint-enable max-classes-per-file */

export default {
    query: ReactQuery,
    map: DelvMap,
    queryHOC: ReactQueryHOC,
    multiSelect: DelvMultiSelect,
    nest: TypeMap.nest,
}
export { DelvReact, ReactQueryHOC, ReactQuery }

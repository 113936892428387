import React from 'react'
import cx from 'classnames'
import SafeReact from 'util/SafeReact.js'

const factory = (classNameDefault) => ({
    className = classNameDefault,
    addClassName = '',
    children,
    cleanProps = (props) => props,
    ...props
}) => <div className={cx(className, addClassName)}>{SafeReact.cloneChildren(children, cleanProps(props))}</div>
export default factory

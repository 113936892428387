import React from 'react'

// REFACTOR *SafeReact
export const unrender = (element) => {
    const Unrenderer = (props) => React.cloneElement(element, props)

    return Unrenderer
}

export const styled = (element) => {
    // TODO merge style prop
    if (element.props && element.props.className) {
        const StyledUnrenderer = (props) => {
            const className = [
                element.props.className,
                props.className,
            ].filter(Boolean).join(' ')

            return React.cloneElement(element, { ...props, className })
        }

        return StyledUnrenderer
    }
    return unrender(element)
}

import React from 'react'
import Format from 'util/format/Format.js'
import Link from 'common/Link/index'

// Not to be confused with src/capitalization/InvestorCard.js. They're different components, the name collision is tech-debt.
const InvestorCard = ({
    id,
    name,
    amount,
    status,
    prefix = 'buyer',
    purchasePrice,
    issuanceType,
    chainData,
}) => {
    const selectNameDisplay = (typeOfIssuance, investorName, chain) => {
        const chainExplorerLinks = {
            // We've removed support for ethereum-ganache, but we must support ganache explorer links until all existing deployments are moved to eth-vtlo.
            'ethereum-ganache': (
                <div className={`${prefix}__section`}>
                    <Link
                        href={`/vnet-explorer/txpage?block=${chainData?.block_number}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {investorName}
                    </Link>
                </div>
            ),
        }

        if (typeOfIssuance === 'holding') {
            return (
                <div className={`${prefix}__section`}>
                    <Link className='pointer' to={`/holding/${id}`}>
                        {investorName}
                    </Link>
                </div>
            )
        }

        if (chainExplorerLinks[chain]) {
            return chainExplorerLinks[chain]
        }

        return investorName
    }

    return (
        <div className={`${prefix}__container`}>
            <div className={`${prefix}__section--2`}>{selectNameDisplay(issuanceType, name, status)}</div>
            <div className={`${prefix}__section`}>{Format.number(amount)}</div>
            <div className={`${prefix}__section`}>{purchasePrice}</div>
            <div className={`${prefix}__section ${prefix}--text-xsm ${prefix}--center`}>{status}</div>
        </div>
    )
}

export default InvestorCard

import Fragment from 'delv/Fragment.js'

export default new Fragment(`id
type
name
email
countryByJurisdiction{
  id
  alpha3
}`)

import * as React from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'common/Box'
import TabLink from 'common/TabLink'
import Tabs from 'common/Tabs'

const SubNav = ({
    BoxProps,
    tabs = [],
}) => {
    const BoxPropsSx = { width: '100%', ...(BoxProps?.Sx || {}) }
    const indexOfRoute = tabs.findIndex(({ href }) => href === window.location.pathname)
    const value = indexOfRoute !== -1 ? indexOfRoute : 0

    const history = useHistory()
    const handleChange = (_e, newValue) => {
        history.push(tabs[newValue].href)
    }

    return (
        <Box data-testid='sub-nav' sx={{ width: '100%', ...BoxPropsSx }}>
            <Tabs
                variant='scrollable'
                scrollButtons='auto'
                allowScrollButtonsMobile
                indicatorColor={null}
                value={value}
                onChange={handleChange}
                aria-label='asdf'
            >
                {tabs.map(({ Icon = () => null, href, label }) => (
                    <TabLink icon={<Icon />} key={`${href}_${label}`} label={label} href={href} />
                ))}
            </Tabs>
        </Box>
    )
}

export default SubNav
